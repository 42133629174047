import React from "react";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { getExcelColumnName } from "../lib/utils";
import * as t from "../lib/types";
import { IPrevField } from "./FieldsTable";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";

interface AiFuzzyLookupMatcherProps {
    filter_idx: number;
    fallback_sequence_idx: number;
    fields: t.IContextField[];
    prev_fields: IPrevField[];
    lookup_tables: t.ILookupTableBase[];
    ai_fuzzy_filter: t.IFuzzyMatchFields;
    lookup_table_uuid: string;
    updateSourceFields: (source_fields: string[]) => void;
    updateAiFuzzyMatcher: (key: "source_fields" | "lookup_fields" | "guidelines", val: any) => void;
    deleteAiFuzzyMatcher: () => void;
}

export function AiFuzzyLookupMatcher({
    filter_idx,
    fields,
    prev_fields,
    lookup_tables,
    ai_fuzzy_filter,
    lookup_table_uuid,
    updateAiFuzzyMatcher,
    deleteAiFuzzyMatcher
}: AiFuzzyLookupMatcherProps) {
    const getLookupTableHeaderValues = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((header, idx) => `${getExcelColumnName(idx)} - ${header}`);
    };

    const getLookupTableHeaderIds = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((_header, idx) => `${idx}`);
    };

    const addSourceField = () => {
        if (ai_fuzzy_filter !== undefined) {
            // Get all available fields
            const all_available_fields = [...prev_fields.map(f => f.field_uuid), ...fields.map(f => f.uuid)];
            // Filter out fields that are already selected
            const unused_fields = all_available_fields.filter(field_uuid =>
                !ai_fuzzy_filter.source_fields.includes(field_uuid)
            );

            // If there are unused fields, add the first unused one
            if (unused_fields.length > 0) {
                const new_source_fields = [
                    ...(ai_fuzzy_filter.source_fields ?? []),
                    unused_fields[0]
                ];
                updateAiFuzzyMatcher("source_fields", new_source_fields);
            }
        }
    };


    const onSourceFieldChange = (idx: number, field_uuid: string) => {
        if (ai_fuzzy_filter !== undefined) {
            const new_source_fields = [...ai_fuzzy_filter.source_fields];
            new_source_fields[idx] = field_uuid;
            updateAiFuzzyMatcher("source_fields", new_source_fields);
        }
    };

    const removeSourceField = (idx: number) => {
        if (ai_fuzzy_filter !== undefined) {
            const new_source_fields = [...ai_fuzzy_filter.source_fields];
            new_source_fields.splice(idx, 1);
            updateAiFuzzyMatcher("source_fields", new_source_fields);
        }

    };

    const addLookupField = () => {
        if (ai_fuzzy_filter !== undefined) {
            const selected_lookup_table = lookup_tables.find(lt => lt.uuid === lookup_table_uuid);
            if (selected_lookup_table?.headers) {
                // Get all used field indices
                const used_indices = new Set(ai_fuzzy_filter.lookup_fields);
                // Find first unused index
                const unused_index = selected_lookup_table.headers.findIndex((_, idx) => !used_indices.has(idx));
                // Only add if we found an unused index
                if (unused_index !== -1) {
                    const new_lookup_fields = [
                        ...(ai_fuzzy_filter.lookup_fields ?? []),
                        unused_index
                    ];
                    updateAiFuzzyMatcher("lookup_fields", new_lookup_fields);
                }
            }
        }
    };

    const onLookupFieldChange = (idx: number, header_idx: number) => {
        if (ai_fuzzy_filter !== undefined) {
            const new_lookup_fields = [...ai_fuzzy_filter.lookup_fields];
            new_lookup_fields[idx] = header_idx;
            updateAiFuzzyMatcher("lookup_fields", new_lookup_fields);
        }
    };

    const removeLookupField = (idx: number) => {
        if (ai_fuzzy_filter !== undefined) {
            const new_lookup_fields = [...ai_fuzzy_filter.lookup_fields];
            new_lookup_fields.splice(idx, 1);
            updateAiFuzzyMatcher("lookup_fields", new_lookup_fields);
        }
    };

    const addAllSourceFields = () => {
        if (ai_fuzzy_filter !== undefined) {
            const all_fields = [
                ...prev_fields.map(f => f.field_uuid),
                ...fields.map(f => f.uuid)
            ];
            updateAiFuzzyMatcher("source_fields", all_fields);
        }
    };

    const addAllLookupFields = () => {
        if (ai_fuzzy_filter !== undefined) {
            const selected_lookup_table = lookup_tables.find(lt => lt.uuid === lookup_table_uuid);
            const all_lookup_fields = selected_lookup_table?.headers.map((_, idx) => idx) || [];
            updateAiFuzzyMatcher("lookup_fields", all_lookup_fields);
        }
    };

    return (
        <div className="space-y-4">
            <div className="flex flex-row items-center">
                <span className="text-sm font-semibold">{filter_idx + 1}. AI Matcher Agent</span>
                <div className="grow" />
                <Button
                    icon={TrashIcon}
                    onClick={deleteAiFuzzyMatcher}
                />
            </div>

            <div className="grid grid-cols-2 gap-x-4">
                {/* Source Fields Section */}
                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Source Fields
                        </label>
                        <Button
                            text="All"
                            icon={PlusIcon}
                            onClick={addAllSourceFields}
                        />
                        <Button
                            text="Field"
                            icon={PlusIcon}
                            onClick={addSourceField}
                        />
                    </div>
                    <div className="my-2 sm:mt-0">
                        <div className="space-y-2">
                            {ai_fuzzy_filter !== undefined && ai_fuzzy_filter.source_fields.map((field, idx) => (
                                <div key={`source_field_${idx}`} className="flex items-center gap-2">
                                    <div className="grow">
                                        <Dropdown
                                            values={prev_fields.map(f => `[${f.context_name}] ${f.field_name}`).concat(fields.map(f => f.name))}
                                            ids={prev_fields.map(f => f.field_uuid).concat(fields.map(f => f.uuid))}
                                            selected={field}
                                            onChange={(field_uuid) => onSourceFieldChange(idx, field_uuid)}
                                        />
                                    </div>
                                    {ai_fuzzy_filter.source_fields.length > 1 && (
                                        <Button
                                            icon={TrashIcon}
                                            onClick={() => removeSourceField(idx)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                    </div>
                </div>

                {/* Lookup Fields Section */}
                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Lookup Fields
                        </label>
                        <div className="flex space-x-2">
                            <Button
                                text="All"
                                icon={PlusIcon}
                                onClick={addAllLookupFields}
                            />
                            <Button
                                text="Field"
                                icon={PlusIcon}
                                onClick={addLookupField}
                            />
                        </div>
                    </div>
                    <div className="my-2 sm:mt-0">
                        <div className="space-y-2">
                            {ai_fuzzy_filter !== undefined && ai_fuzzy_filter.lookup_fields.map((field, idx) => (
                                <div key={`lookup_field_${idx}`} className="flex items-center gap-2">
                                    <div className="grow">
                                        <Dropdown
                                            values={getLookupTableHeaderValues(lookup_table_uuid)}
                                            ids={getLookupTableHeaderIds(lookup_table_uuid)}
                                            selected={field ? field.toString() : ""}
                                            onChange={(header_idx) => onLookupFieldChange(idx, parseInt(header_idx, 10))}
                                        />
                                    </div>
                                    {ai_fuzzy_filter.lookup_fields.length > 1 && (
                                        <Button
                                            icon={TrashIcon}
                                            onClick={() => removeLookupField(idx)}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Guidelines section */}
            <div>
                <div className="flex items-center justify-between mb-2">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                        Guidelines
                    </label>
                </div>
                <div className="space-y-2">
                    {(ai_fuzzy_filter?.guidelines ?? []).map((guideline, idx) => (
                        <div key={`guideline_${idx}`} className="flex items-center gap-2">
                            <span className="text-sm text-gray-500 w-6">{idx + 1}.</span>
                            <input
                                type="text"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={guideline}
                                onChange={(e) => {
                                    if (ai_fuzzy_filter !== undefined) {
                                        const new_guidelines = [...ai_fuzzy_filter.guidelines];
                                        new_guidelines[idx] = e.target.value;
                                        updateAiFuzzyMatcher("guidelines", new_guidelines);
                                    }
                                }}
                            />
                            <Button
                                icon={idx === (ai_fuzzy_filter?.guidelines ?? []).length - 1 ? PlusIcon : TrashIcon}
                                onClick={() => {
                                    if (ai_fuzzy_filter !== undefined) {
                                        if (idx === ai_fuzzy_filter.guidelines.length - 1) {
                                            // Last item - add new guideline
                                            const new_guidelines = [...ai_fuzzy_filter.guidelines, ""];
                                            updateAiFuzzyMatcher("guidelines", new_guidelines);
                                        } else {
                                            // Not last item - delete current guideline
                                            const new_guidelines = [...ai_fuzzy_filter.guidelines];
                                            new_guidelines.splice(idx, 1);
                                            updateAiFuzzyMatcher("guidelines", new_guidelines);
                                        }
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}