import {
    useEffect,
    useState
} from "react";

import {
    IContextBase,
    IEndpointOutputNameElement
} from "../lib/types";
import { DropdownMenu } from "./DropdownMenu";
import { MultiselectInputField } from "./MultiselectInputField";
import { EndpointSplitByField } from "../screens/NewEndpoint";
import { ButtonGroup } from "./Button";
import { Textbox } from "./Textbox";

type EndpointOutputNameProps = {
    object_contexts: IContextBase[];
    endpoint_split_by_field: EndpointSplitByField[];
    elements: IEndpointOutputNameElement[];
    addElement: (element: IEndpointOutputNameElement) => void;
    removeElement: (element_idx: number) => void;
    title: string;
    separator?: "underline" | "space";
}

interface IOutputNameField {
    context_uuid: string;
    field_uuid: string;
    field_name: string;
}

export function EndpointOutputName(props: EndpointOutputNameProps) {
    const { object_contexts, endpoint_split_by_field, elements, addElement, removeElement, title, separator } = props;

    const [fields, setFields] = useState<IOutputNameField[]>([]);
    const [showCustomTextInput, setShowCustomTextInput] = useState(false);
    const [constant_text, setConstantText] = useState("");

    useEffect(() => {
        const already_added_fields = new Set<string>();
        // initialize fields
        const new_fields: IOutputNameField[] = object_contexts.flatMap(c => c.fields.map(f => ({
            context_uuid: c.uuid,
            field_uuid: f.uuid,
            field_name: f.name
        })));
        for (const field of new_fields) {
            already_added_fields.add(field.field_uuid);
        }
        // we can also add fields from template_split_fields
        for (const split_field of endpoint_split_by_field) {
            const { context_uuid, field_name, field_uuid } = split_field;
            // check if field is already in new_fields
            if (already_added_fields.has(field_uuid)) {
                continue;
            }
            new_fields.push({
                context_uuid,
                field_uuid,
                field_name
            });
            already_added_fields.add(field_uuid);
        }
        // if split_field context_type is object, also add it
        for (const split_field of endpoint_split_by_field) {
            const { context, context_type } = split_field;
            if (context_type === "object") {
                for (const field of context.fields) {
                    if (already_added_fields.has(field.uuid)) {
                        continue;
                    }
                    new_fields.push({
                        context_uuid: context.uuid,
                        field_uuid: field.uuid,
                        field_name: field.name
                    });
                    already_added_fields.add(field.uuid);
                }
            }
        }

        setFields(new_fields);
    }, [object_contexts, endpoint_split_by_field]);

    const handleAddConstant = () => {
        if (constant_text.trim()) {
            addElement({ type: "constant", constant: constant_text.trim() });
            setConstantText("");
            setShowCustomTextInput(false);
        }
    };

    const getDisplayValue = (element: IEndpointOutputNameElement, index: number): string => {
        if (element.type === "field") {
            const field = fields.find(f => f.context_uuid === element.context_uuid && f.field_uuid === element.field_uuid);
            return field?.field_name || "";
        }
        return `"${element.constant}"`;
    };

    const dropdownItems = [
        {
            title: "Custom text...",
            onClick: () => setShowCustomTextInput(true)
        },
        ...fields.map(f => ({
            title: f.field_name,
            onClick: () => { addElement({ type: "field", context_uuid: f.context_uuid, field_uuid: f.field_uuid }) }
        }))
    ];

    return <div className="w-full flex flex-col">
        <div className="flex flex-row items-center">
            <label className="flex-grow text-sm font-medium text-gray-700">{title}</label>
            <DropdownMenu
                title="Add field"
                align="right"
                items={dropdownItems}
            />
        </div>
        {showCustomTextInput && (
            <div className="mt-3 flex items-center gap-2">
                <Textbox
                    placeholder="Enter custom text"
                    value={constant_text}
                    onChange={(value) => setConstantText(value)}
                />
                <ButtonGroup
                    buttons={[
                        {
                            text: "Add",
                            onClick: handleAddConstant,
                            disabled: !constant_text.trim()
                        },
                        {
                            text: "Cancel",
                            onClick: () => {
                                setShowCustomTextInput(false);
                                setConstantText("");
                            }
                        }
                    ]}
                />
            </div>
        )}
        <div className="mt-3 w-full">
            <MultiselectInputField
                values={elements.map(getDisplayValue)}
                placeholder="No elements selected"
                separator={separator || "underline"}
                onRemove={removeElement}
            />
        </div>
    </div>;
}