import {
    Fragment,
    RefObject,
    createRef,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import {
    BackendObj
} from "../lib/backend";

import {
    Cog6ToothIcon,
    MinusIcon,
    QuestionMarkCircleIcon
} from "@heroicons/react/20/solid"
import {
    ChevronDoubleDownIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronDoubleUpIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    PlusIcon,
    TrashIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'
import {
    Dialog,
    Transition
} from '@headlessui/react'

import * as t from "../lib/types";
import {
    classNames,
    getExcelColumnName,
    getHierarchicalContextExample,
    isValidCodeName,
    newUuid
} from "../lib/utils";
import {
    languages,
    USER_ROLES,
    CONTEXT_TYPES,
    CONTEXT_FIELD_CONDITION_TYPE,
    ORG_TYPES,
    ORG_ROLES
} from "../lib/consts";
import {
    ContextFieldComputeMetadataType,
    ContextFieldConditionType,
    IContextFieldValidator,
    IExtractDate,
    IExtractDateFormatVariant,
    IExtractDateParseMonthYear,
    IExtractDateParseWeekYear,
    IExtractEnum,
    IExtractEnumValue,
    IFieldValidatorEqualsField,
    IFieldValidatorFormula,
    IFieldValidatorOCDate,
    IFieldValidatorDateRange,
    IFieldValidatorNumberRange,
    IFieldValidatorPivotRange,
    IFieldValidatorRange,
    IFieldValidatorRegex,
    IValidationErrorStatus
} from "../lib/backend/extractions.types.generated";
import { selectMemberships, selectUser } from "../lib/scraper.slice";

import { Button, ButtonGroup } from "./Button";
import {
    CompactTabs,
    ITab
} from "./Tabs";
import { DropdownMenu, IDropdownMenuItem } from "./DropdownMenu";
import { Dropdown } from "../components/Dropdown";
import { HierarchicalRecord } from "./ItemTables";
import { Textbox } from "./Textbox";
import { Checkbox } from "./Checkbox";
import { MultiselectInputField } from "./MultiselectInputField";
import { TextboxModal } from "./TextboxModal";
import { EnumTable } from "./EnumTable";
import { IRichDropdownValue, RichDropdown } from "./RichDropdown";
import { Tooltip } from "react-tooltip";
import { FallbackSequence } from "./LookupFilter";

const DATE_VARIANT_TYPES = [
    { type: "date", title: "Normal Date", example: "12.31.2024", default_name: "day" },
    { type: "week_year", title: "Week of Year", example: "Week 52 of 2024", default_name: "week" },
    { type: "month_year", title: "Month of Year", example: "June 2024", default_name: "month" },
    { type: "bme_month_year", title: "Beg/Mid/End of Month of Year", example: "mid May 2024", default_name: "beg/mid/end month" }
] as const;

export interface IPrevField {
    context_name: string;
    context_type: t.ContextType;
    field_name: string;
    field_uuid: string;
}

type FieldSettingsProps = {
    type: "edit" | "override";
    open: boolean;
    field_idx: number;
    fields_count: number;
    field?: t.IContextField;
    fields: t.IContextField[];
    prev_fields: IPrevField[];
    context_type: t.ContextType;
    extract_params?: t.IExtractParams;
    lookup_tables: t.ILookupTableBase[];
    contexts?: { uuid: string, name: string, type: t.ContextType }[];
    selected_context_uuid?: string;
    template_org_uuid: string;
    setOpen: (open: boolean) => void;
    setField: (field_idx: number, field: t.IContextField) => void;
    moveField: (curr_idx: number, diff_idx: number) => void;
    deleteField: (field_idx: number) => void;
    setFieldContext?: (field_uuid: string, context_uuid: string) => void;
};

type MultiValueKeys = "synonyms" | "examples" | "negative_examples" | "prefixes" | "suffixes";

export function FieldSettings(props: FieldSettingsProps) {
    const {
        type,
        open,
        field_idx,
        fields_count,
        field,
        fields,
        prev_fields,
        context_type,
        extract_params,
        lookup_tables,
        contexts,
        selected_context_uuid,
        template_org_uuid,
        setOpen,
        setField,
        moveField,
        deleteField,
        setFieldContext
    } = props;

    // check if user is admin
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);
    const is_admin = user && user.role === USER_ROLES.admin;
    // advanced features (formulas) are only available for org-admins of business orgs
    const is_org_admin = memberships.filter((m) => m.org.type === ORG_TYPES.business && m.org.uuid === template_org_uuid && m.role === ORG_ROLES.admin).length > 0;

    const [selected_tab, setSelectedTab] = useState<string>("general");
    const [tabs, setTabs] = useState<ITab[]>([{ name: "General", key: "general" }]);
    const [new_field_dialog, setNewFieldDialog] = useState<MultiValueKeys | undefined>(undefined);
    const [new_field_dialog_init_text, setNewFieldDialogInitText] = useState<string | undefined>(undefined);
    const [new_field_dialog_idx, setNewFieldDialogIdx] = useState<number | undefined>(undefined);
    const [is_valid_admin_json, setIsValidAdminJSON] = useState<boolean>(true);

    useEffect(() => {
        const tabs = [];
        // add tabs based on field type
        let new_selected_tab = selected_tab;
        if (field && field.type === "extract") {
            tabs.push({ name: "General", key: "general" });
            new_selected_tab = "general";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "fallback_sequence") {
            tabs.push({ name: "Sequence", key: "fallback_sequence", hide: type === "override" });
            new_selected_tab = "fallback_sequence";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "formula") {
            tabs.push({ name: "Formula", key: "formula" });
            new_selected_tab = "formula";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "document_name") {
            tabs.push({ name: "Document Name", key: "document_name" });
            new_selected_tab = "document_name";
        } else if (field && field.type === "compute" && field.compute && field.compute.type === "metadata") {
            tabs.push({ name: "Metadata", key: "metadata" });
            new_selected_tab = "metadata";
        } else if (field && field.type === "lookup_table_value") {
            tabs.push({ name: "Lookup Table Value", key: "lookup_table_value" });
            new_selected_tab = "lookup_table_value";
        }
        tabs.push({ name: "Rules", key: "rules", hide: type === "override" });
        tabs.push({ name: "Details", key: "details", hide: type === "override" });
        tabs.push({ name: "Admin", key: "admin", only_admin: true });
        setTabs(tabs);
        // make sure selected tab is valid
        if (tabs.length > 0 && !tabs.find((tab) => tab.key === selected_tab)) {
            setSelectedTab(new_selected_tab);
        }
        setIsValidAdminJSON(true);
    }, [type, selected_tab, field, field_idx, lookup_tables]);

    const updateField = (key: "name" | "datatype" | "code" | "format" | "translate" | "confirm_name" | "enum_data" | "date_data", value: string | IExtractEnumValue[] | IExtractDate) => {
        if (field) {
            const new_field = { ...field };
            if (key === "name") {
                new_field.name = value as string;
            } else if (key === "datatype") {
                if (["string", "number", "enum", "date"].includes(value as string) && context_type !== "lookup_table") {
                    new_field.type = "extract";
                    new_field.datatype = value as "string" | "number" | "enum" | "date";
                    new_field.extract = {
                        ...new_field.extract,
                        date_data: {
                            input_format_variants: [{
                                name: DATE_VARIANT_TYPES[0].default_name,
                                format: "",
                                parse_data: { type: DATE_VARIANT_TYPES[0].type }
                            }]
                        }
                    }
                } else if (["string", "number"].includes(value as string) && context_type === "lookup_table") {
                    new_field.type = "lookup_table_value";
                    new_field.datatype = value as "string" | "number";
                } else if (value === "fallback_sequence") {
                    new_field.type = "compute";
                    new_field.datatype = "string";
                    new_field.compute = {
                        type: "fallback_sequence",
                        fallback_sequence: {
                            sequence: [],
                            final_fallback_constant: "",
                            val_on_empty: "constant",
                            handle_non_unique: "list"
                        }
                    };
                } else if (value === "formula") {
                    new_field.type = "compute";
                    new_field.datatype = "string";
                    new_field.compute = { type: "formula", formula: "" };
                } else if (value === "metadata") {
                    new_field.type = "compute";
                    new_field.datatype = "string";
                    new_field.compute = { type: "metadata", metadata: { type: "document_name" } };
                }
            } else if (key === "code") {
                if (value === "") {
                    new_field.code = undefined;
                } else if (isValidCodeName(value as string)) {
                    new_field.code = value as string;
                }
            } else if (key === "format") {
                new_field.extract = { ...new_field.extract, format: value as string };
            } else if (key === "translate") {
                new_field.extract = { ...new_field.extract, translate: value as string };
            } else if (key === "confirm_name") {
                new_field.confirm_name = (typeof value === "string") && value.length > 0 ? value as string : undefined;
            } else if (key === "enum_data") {
                const enum_data: IExtractEnum | undefined = Array.isArray(value) && value.length > 0 ?
                    { type: "list", values: value as IExtractEnumValue[] } : undefined;
                new_field.extract = { ...new_field.extract, enum_data };
            } else if (key === "date_data") {
                const date_data: IExtractDate | undefined = typeof value === "object" ? value as IExtractDate : undefined;
                new_field.extract = { ...new_field.extract, date_data };
            }
            setField(field_idx, new_field);
        }
    };

    const updateCheckbox = (key: "verbatim" | "prioritize_handwritten" | "skip_on_confirm", value: boolean) => {
        if (field) {
            const new_field = { ...field, };
            if (key === "verbatim") {
                new_field.extract = { ...new_field.extract, verbatim: value, format: undefined };
            } else if (key === "prioritize_handwritten") {
                new_field.extract = { ...new_field.extract, prioritize_handwritten: value };
            } else if (key === "skip_on_confirm") {
                new_field.skip_on_confirm = value;
            }
            setField(field_idx, new_field);
        }
    };

    const getRangePossibleDisplayHeaders = (lookup_table_uuid?: string): { title: string, idx: number }[] => {
        if (lookup_table_uuid === undefined) { return []; }
        const lookup_table = lookup_tables.find((table) => table.uuid === lookup_table_uuid);
        if (lookup_table === undefined) { return []; }

        const existing_headers_idx = field?.range?.display_headers_idx || [];

        return lookup_table.headers.map((header, idx) => ({
            title: `${getExcelColumnName(idx)} - ${header}`,
            idx: idx
        })).filter((header) => !existing_headers_idx.includes(header.idx));
    }

    const getRangeSelectedDisplayHeaders = (lookup_table_uuid?: string): { title: string, idx: number }[] => {
        if (lookup_table_uuid === undefined) { return []; }
        const lookup_table = lookup_tables.find((table) => table.uuid === lookup_table_uuid);
        if (lookup_table === undefined) { return []; }

        const existing_headers_idx = field?.range?.display_headers_idx || [];

        return existing_headers_idx.map((idx) => ({
            title: `${getExcelColumnName(idx)} - ${lookup_table.headers[idx]}`,
            idx: idx
        }));
    }

    type IUpdateRangeKey = "lookup_table_uuid" | "header_idx" | "add_display_header_idx" | "remove_display_header_idx";
    const updateRange = (key: IUpdateRangeKey, value: string | number) => {
        if (field) {
            const new_field = { ...field };
            if (key === "lookup_table_uuid") {
                new_field.range = (value === "") ? undefined : {
                    type: "lookup_table_value_range",
                    lookup_table_uuid: value as string,
                    header_idx: 0,
                    display_headers_idx: [],
                    connected_fields: []
                };
            } else if (key === "header_idx") {
                if (field.range !== undefined) {
                    new_field.range = {
                        ...field.range,
                        header_idx: parseInt(value as string, 10)
                    };
                }
            } else if (key === "add_display_header_idx") {
                if (field.range !== undefined) {
                    new_field.range = {
                        ...field.range,
                        display_headers_idx: [...field.range.display_headers_idx, value as number]
                    };
                }
            } else if (key === "remove_display_header_idx") {
                if (field.range !== undefined) {
                    const remove_header_idx = field.range.display_headers_idx[value as number];
                    new_field.range = {
                        ...field.range,
                        display_headers_idx: field.range.display_headers_idx.filter((idx) => idx !== remove_header_idx)
                    };
                }
            }
            setField(field_idx, new_field);
        }
    }

    const updateConnectedField = (key: "add" | "remove", field_uuid: string, header_idx?: number) => {
        if (field !== undefined && field.range !== undefined) {
            const new_field = { ...field };
            if (key === "add" && header_idx !== undefined) {
                const existing_connected_field_idx = field.range.connected_fields.findIndex((field) => field.field_uuid === field_uuid);
                if (existing_connected_field_idx === -1) {
                    // new field
                    new_field.range = {
                        ...field.range,
                        connected_fields: [...field.range.connected_fields, { field_uuid, header_idx }]
                    };
                } else {
                    // existing field
                    const new_connected_fields = [...field.range.connected_fields];
                    new_connected_fields[existing_connected_field_idx] = { field_uuid, header_idx };
                    new_field.range = { ...field.range, connected_fields: new_connected_fields };
                }
            } else if (key === "remove") {
                const new_connected_fields = field.range.connected_fields.filter((field) => field.field_uuid !== field_uuid);
                new_field.range = { ...field.range, connected_fields: new_connected_fields };
            }
            setField(field_idx, new_field);
        }
    }

    const onDateVariantUpdate = (variant: IExtractDateFormatVariant, idx: number) => {
        if (field) {
            const new_field = { ...field };
            const variants = field.extract?.date_data?.input_format_variants || [];
            variants[idx] = variant;
            new_field.extract = {
                ...new_field.extract,
                date_data: {
                    ...new_field.extract?.date_data,
                    input_format_variants: variants
                }
            };
            setField(field_idx, new_field);
        }
    }

    const onDateVariantUpdateDayOfWeek = (day_in_week: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday", idx: number) => {
        if (field && field.extract && field.extract.date_data) {
            const new_field = { ...field };
            const variants = field.extract.date_data.input_format_variants || [];
            if (variants[idx].parse_data?.type === "week_year") {
                const variant = variants[idx] as IExtractDateFormatVariant & { parse_data: IExtractDateParseWeekYear };
                variants[idx] = { ...variant, parse_data: { ...variant.parse_data, day_in_week } };
            }
            new_field.extract = {
                ...new_field.extract,
                date_data: { ...new_field?.extract?.date_data, input_format_variants: variants }
            };
            setField(field_idx, new_field);
        }
    }

    const onDateVariantUpdateDayInMonth = (day_in_month: number, idx: number) => {
        if (field && field.extract && field.extract.date_data) {
            const new_field = { ...field };
            const variants = field.extract.date_data.input_format_variants || [];
            if (variants[idx].parse_data?.type === "month_year") {
                const variant = variants[idx] as IExtractDateFormatVariant & { parse_data: IExtractDateParseMonthYear };
                variants[idx] = { ...variant, parse_data: { ...variant.parse_data, day_in_month } };
            }
            new_field.extract = {
                ...new_field.extract,
                date_data: { ...new_field?.extract?.date_data, input_format_variants: variants }
            };
            setField(field_idx, new_field);
        }
    }

    const onGuidelineUpdate = (guideline: string, idx: number) => {
        if (field) {
            const new_field = { ...field };
            // first time we add a guideline
            if (field.extract?.guidelines === undefined || field.extract?.guidelines.length === 0) {
                new_field.extract = { ...new_field.extract, guidelines: [guideline] };
            } else {
                let guidelines = field.extract.guidelines;
                guidelines[idx] = guideline;
                guidelines = guidelines.filter((guideline) => guideline.length > 0);
                new_field.extract = { ...new_field.extract, guidelines: guidelines.length > 0 ? guidelines : undefined };
            }
            setField(field_idx, new_field);
        }
    };

    const onGuidelineButton = (idx: number) => {
        if (field) {
            const new_field = { ...field };
            const guidelines = field.extract?.guidelines || [];
            if ((idx + 1) < guidelines.length) {
                // if idx of existing guideline, remove it
                const new_guidelines = guidelines.filter((_, i) => i !== idx);
                new_field.extract = { ...new_field.extract, guidelines: new_guidelines.length > 0 ? new_guidelines : undefined };
            } else {
                // if idx is new guideline, add it
                new_field.extract = { ...new_field.extract, guidelines: [...guidelines, ""] };
            }
            setField(field_idx, new_field);
        }
    }

    const onNewFieldDialogClose = (result: boolean, value?: string) => {
        if (field && result && value !== undefined) {
            const new_field = { ...field };
            if (new_field_dialog === "synonyms") {
                if (new_field_dialog_idx !== undefined) {
                    const synonyms = [...(field.extract?.synonyms || [])];
                    synonyms[new_field_dialog_idx] = value;
                    new_field.extract = { ...new_field.extract, synonyms };
                } else {
                    const synonyms = [...(field.extract?.synonyms || []), value];
                    new_field.extract = { ...new_field.extract, synonyms };
                }
            } else if (new_field_dialog === "examples") {
                if (new_field_dialog_idx !== undefined) {
                    const examples = [...(field.extract?.examples || [])];
                    examples[new_field_dialog_idx] = value;
                    new_field.extract = { ...new_field.extract, examples };
                } else {
                    const examples = [...(field.extract?.examples || []), value];
                    new_field.extract = { ...new_field.extract, examples };
                }
            } else if (new_field_dialog === "negative_examples") {
                if (new_field_dialog_idx !== undefined) {
                    const negative_examples = [...(field.extract?.negative_examples || [])];
                    negative_examples[new_field_dialog_idx] = value;
                    new_field.extract = { ...new_field.extract, negative_examples };
                } else {
                    const negative_examples = [...(field.extract?.negative_examples || []), value];
                    new_field.extract = { ...new_field.extract, negative_examples };
                }
            } else if (new_field_dialog === "prefixes") {
                if (new_field_dialog_idx !== undefined) {
                    const prefixes = [...(field.extract?.prefixes || [])];
                    prefixes[new_field_dialog_idx] = value;
                    new_field.extract = { ...new_field.extract, prefixes };
                } else {
                    const prefixes = [...(field.extract?.prefixes || []), value];
                    new_field.extract = { ...new_field.extract, prefixes };
                }
            } else if (new_field_dialog === "suffixes") {
                if (new_field_dialog_idx !== undefined) {
                    const suffixes = [...(field.extract?.suffixes || [])];
                    suffixes[new_field_dialog_idx] = value;
                    new_field.extract = { ...new_field.extract, suffixes };
                } else {
                    const suffixes = [...(field.extract?.suffixes || []), value];
                    new_field.extract = { ...new_field.extract, suffixes };
                }
            }
            setField(field_idx, new_field);
        }
        setNewFieldDialog(undefined);
        setNewFieldDialogInitText(undefined);
        setNewFieldDialogIdx(undefined);
    };

    const deleteFieldElement = (key: MultiValueKeys, idx: number) => {
        if (field) {
            const new_field = { ...field };
            if (key === "synonyms") {
                const new_vals = [...(field.extract?.synonyms || [])].filter((_, i) => i !== idx);
                new_field.extract = { ...new_field.extract, synonyms: new_vals.length > 0 ? new_vals : undefined };
            } else if (key === "examples") {
                const new_vals = [...(field.extract?.examples || [])].filter((_, i) => i !== idx);
                new_field.extract = { ...new_field.extract, examples: new_vals.length > 0 ? new_vals : undefined };
            } else if (key === "negative_examples") {
                const new_vals = [...(field.extract?.negative_examples || [])].filter((_, i) => i !== idx);
                new_field.extract = { ...new_field.extract, negative_examples: new_vals.length > 0 ? new_vals : undefined };
            } else if (key === "prefixes") {
                const new_vals = [...(field.extract?.prefixes || [])].filter((_, i) => i !== idx);
                new_field.extract = { ...new_field.extract, prefixes: new_vals.length > 0 ? new_vals : undefined };
            } else if (key === "suffixes") {
                const new_vals = [...(field.extract?.suffixes || [])].filter((_, i) => i !== idx);
                new_field.extract = { ...new_field.extract, suffixes: new_vals.length > 0 ? new_vals : undefined };
            }
            setField(field_idx, new_field);
        }
    }

    const openNewFieldDialog = (key: MultiValueKeys, values?: string[], idx?: number) => {
        setNewFieldDialog(key);
        setNewFieldDialogInitText(values ? values[idx || 0] : undefined);
        setNewFieldDialogIdx(idx);
    }

    const getNewFieldDialogTitle = () => {
        const is_edit = new_field_dialog_idx !== undefined;
        if (new_field_dialog === "synonyms") {
            return is_edit ? "Edit Synonym" : "Add Synonym";
        } else if (new_field_dialog === "examples") {
            return is_edit ? "Edit Example" : "Add Example";
        } else if (new_field_dialog === "negative_examples") {
            return is_edit ? "Edit Negative Example" : "Add Negative Example";
        } else if (new_field_dialog === "prefixes") {
            return is_edit ? "Edit Prefix" : "Add Prefix";
        } else if (new_field_dialog === "suffixes") {
            return is_edit ? "Edit Suffix" : "Add Suffix";
        }
        return "";
    };

    // conditions

    const addCondition = (type: ContextFieldConditionType) => {
        if (field) {
            if (type === "only_with") {
                const conditions: t.IExtractCondition[] = [
                    ...(field?.conditions || []),
                    { type, field_uuid: fields[0].uuid }
                ];
                setField(field_idx, { ...field, conditions });
            } else if (type === "skip_if_match") {
                const conditions = [
                    ...(field?.conditions || []),
                    { type, field_value: "" }
                ];
                setField(field_idx, { ...field, conditions });
            } else if (type === "skip_if_not_match") {
                const conditions = [
                    ...(field?.conditions || []),
                    { type, field_value: "" }
                ];
                setField(field_idx, { ...field, conditions });
            } else if (type === "skip_if_includes") {
                if (lookup_tables.length > 0) {
                    const conditions = [
                        ...(field?.conditions || []),
                        {
                            type, range: {
                                lookup_table_uuid: lookup_tables[0].uuid,
                                header_idx: 0
                            }
                        }
                    ];
                    setField(field_idx, { ...field, conditions });
                }
            } else if (type === "skip_if_not_includes_any") {
                if (lookup_tables.length > 0) {
                    const conditions = [
                        ...(field?.conditions || []),
                        {
                            type, range: {
                                lookup_table_uuid: lookup_tables[0].uuid,
                                header_idx: 0
                            }
                        }
                    ];
                    setField(field_idx, { ...field, conditions });
                }
            } else {
                throw new Error("Invalid condition type " + type);
            }
        }
    };

    const deleteCondition = (condition_idx: number) => {
        if (field && field.conditions && field.conditions.length > condition_idx) {
            const conditions = [
                ...field.conditions.splice(0, condition_idx),
                ...field.conditions.splice(condition_idx + 1)
            ];
            setField(field_idx, { ...field, conditions });
        }
    };

    const updateCondition = (condition_idx: number, key: "field_uuid" | "field_value" | "type", value: string) => {
        if (key === "type") {
            if (field && field.conditions && field.conditions.length > condition_idx) {
                // make sure value corresponds to a valid condition type
                if (!CONTEXT_FIELD_CONDITION_TYPE[value]) {
                    throw new Error("Invalid condition type " + value);
                }
                const conditions = [...field.conditions];
                conditions[condition_idx].type = value as ContextFieldConditionType;
                setField(field_idx, { ...field, conditions });
            }
        } else if (key === "field_uuid") {
            if (field && field.conditions && field.conditions.length > condition_idx) {
                const conditions = [...field.conditions];
                conditions[condition_idx].field_uuid = value;
                setField(field_idx, { ...field, conditions });
            }
        } else if (key === "field_value") {
            if (field && field.conditions && field.conditions.length > condition_idx) {
                const conditions = [...field.conditions];
                conditions[condition_idx].field_value = value;
                setField(field_idx, { ...field, conditions });
            }
        } else {
            throw new Error("Invalid key " + key + " in updateCondition");
        }
    }

    const updateConditionRangeTable = (condition_idx: number, lookup_table_uuid: string) => {
        if (field && field.conditions && field.conditions.length > condition_idx) {
            const conditions = [...field.conditions];
            if (conditions[condition_idx].type === "skip_if_includes") {
                const range = conditions[condition_idx].range;
                if (range !== undefined) {
                    range.lookup_table_uuid = lookup_table_uuid;
                    range.header_idx = 0;
                }
            }
            setField(field_idx, { ...field, conditions });
        }
    }

    const updateConditionRangeHeader = (condition_idx: number, header_idx: number) => {
        if (field && field.conditions && field.conditions.length > condition_idx) {
            const conditions = [...field.conditions];
            if (conditions[condition_idx].type === "skip_if_includes") {
                const range = conditions[condition_idx].range;
                if (range !== undefined) {
                    range.header_idx = header_idx;
                }
            }
            setField(field_idx, { ...field, conditions });
        }
    }

    /// verifications

    const addValidationRange = () => {
        if (field && lookup_tables.length > 0) {
            const range: IFieldValidatorRange = {
                name: "Range",
                analytics_tag: "range",
                level: "warn",
                type: "range",
                lookup_table_uuid: lookup_tables[0].uuid,
                header_idx: 0
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), range];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationPivotRange = () => {
        if (field && fields.length > 0 && lookup_tables.length > 0) {
            const pivot_range: IFieldValidatorPivotRange = {
                name: "Pivot Range",
                analytics_tag: "pivot_range",
                level: "warn",
                type: "pivot_range",
                lookup_table_uuid: lookup_tables[0].uuid,
                key_field_uuid: fields[0].uuid,
                key_header_idx: 0,
                value_header_idx: 0
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), pivot_range];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationEqualsField = () => {
        if (field && fields.length > 0) {
            const equals_field: IFieldValidatorEqualsField = {
                name: "Equals Field",
                analytics_tag: "equals_field",
                level: "warn",
                type: "equals_field",
                field_uuid: fields[0].uuid
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), equals_field];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationRegex = () => {
        if (field) {
            const regex: IFieldValidatorRegex = {
                name: "Regex",
                analytics_tag: "regex",
                level: "warn",
                type: "regex",
                pattern: "^.+$"
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), regex];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationOCDate = () => {
        if (field) {
            const oc_date_validation: IFieldValidatorOCDate = {
                name: "OC Date Validation",
                analytics_tag: "oc_date_validation",
                level: "warn",
                type: "oc_date_validation",
                po_created_date_ISO_field: {
                    field_uuid: fields[0].uuid,
                    context_uuid: selected_context_uuid || ""
                },
                po_requested_date_ISO_field: {
                    field_uuid: fields[0].uuid,
                    context_uuid: selected_context_uuid || ""
                },
                extracted_part_number_field: {
                    field_uuid: fields[0].uuid,
                    context_uuid: selected_context_uuid || ""
                }
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), oc_date_validation];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationDateRange = () => {
        if (field) {
            const date_range_validation: IFieldValidatorDateRange = {
                name: "Date Range",
                analytics_tag: "date_range",
                level: "warn",
                type: "date_range_validation",
                reference_date_field: {
                    field_uuid: fields[0].uuid,
                    context_uuid: selected_context_uuid || ""
                },
                days_before: undefined,
                days_after: undefined
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), date_range_validation];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationNumberRange = () => {
        if (field) {
            const number_range_validation: IFieldValidatorNumberRange = {
                name: "Number Range",
                analytics_tag: "number_range",
                level: "warn",
                type: "number_range_validation",
                reference_number_field: {
                    field_uuid: fields[0].uuid,
                    context_uuid: selected_context_uuid || ""
                },
                less_than: undefined,
                greater_than: undefined
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), number_range_validation];
            setField(field_idx, { ...field, validators });
        }
    }

    const addValidationFormula = () => {
        if (field) {
            const formula: IFieldValidatorFormula = {
                name: "Formula",
                analytics_tag: "formula",
                level: "warn",
                type: "formula",
                formula: ""
            };
            const validators: IContextFieldValidator[] = [...(field.validators || []), formula];
            setField(field_idx, { ...field, validators });
        }
    }

    const deleteValidation = (validator_idx: number) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [
                ...field.validators.splice(0, validator_idx),
                ...field.validators.splice(validator_idx + 1)
            ];
            setField(field_idx, { ...field, validators });
        }
    }

    const updateValidation = (validator_idx: number, key: "name" | "analytics_tag" | "level", value: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const validator = validators[validator_idx];
            if (key === "name") { validator.name = value as string; }
            if (key === "analytics_tag") { validator.analytics_tag = value as string; }
            if (key === "level") { validator.level = value as IValidationErrorStatus; }
            setField(field_idx, { ...field, validators });
        }
    }

    const updateValidationRangeTable = (validator_idx: number, lookup_table_uuid: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const range = validators[validator_idx];
            if (range.type === "range") {
                if (range !== undefined) {
                    range.lookup_table_uuid = lookup_table_uuid;
                    range.header_idx = 0;
                }
            }
            setField(field_idx, { ...field, validators });
        }
    }

    const updateValidationRange = (validator_idx: number, key: "header" | "compensate_leading_zeros" | "case_insensitive" | "compensate_ocr_errors", value: number | boolean) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const range = validators[validator_idx];
            if (range.type === "range") {
                if (range !== undefined) {
                    if (key === "header") { range.header_idx = value as number; }
                    if (key === "compensate_leading_zeros") { range.compensate_leading_zeros = value as boolean; }
                    if (key === "case_insensitive") { range.case_insensitive = value as boolean; }
                    if (key === "compensate_ocr_errors") { range.compensate_ocr_errors = value as boolean; }
                }
                setField(field_idx, { ...field, validators });
            }
        }
    }

    const updateValidationPivotRangeTable = (validator_idx: number, lookup_table_uuid: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const pivot_range = validators[validator_idx];
            if (pivot_range.type === "pivot_range") {
                if (pivot_range !== undefined) {
                    pivot_range.lookup_table_uuid = lookup_table_uuid;
                    pivot_range.key_field_uuid = fields[0].uuid;
                    pivot_range.key_header_idx = 0;
                    pivot_range.value_header_idx = 0;
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    const updateValidationPivotRangeValue = (validator_idx: number, key: "field" | "key" | "value" | "compensate_leading_zeros" | "compensate_ocr_errors" | "case_insensitive", val: string | number | boolean) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const pivot_range = validators[validator_idx];
            if (pivot_range.type === "pivot_range") {
                if (pivot_range !== undefined) {
                    if (key === "field") { pivot_range.key_field_uuid = val as string; }
                    if (key === "key") { pivot_range.key_header_idx = val as number; }
                    if (key === "value") { pivot_range.value_header_idx = val as number; }
                    if (key === "compensate_leading_zeros") { pivot_range.compensate_leading_zeros = val as boolean; }
                    if (key === "compensate_ocr_errors") { pivot_range.compensate_ocr_errors = val as boolean; }
                    if (key === "case_insensitive") { pivot_range.case_insensitive = val as boolean; }
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    const updateValidationEqualsField = (validator_idx: number, field_uuid: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const equals_field = validators[validator_idx];
            if (equals_field.type === "equals_field") {
                if (equals_field !== undefined) {
                    equals_field.field_uuid = field_uuid;
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    const updateValidationFormula = (validator_idx: number, formula: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const formula_validator = validators[validator_idx];
            if (formula_validator.type === "formula") {
                formula_validator.formula = formula;
                setField(field_idx, { ...field, validators });
            }
        }
    }

    const updateValidationRegex = (validator_idx: number, pattern: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const regex = validators[validator_idx];
            if (regex.type === "regex") {
                if (regex !== undefined) {
                    regex.pattern = pattern;
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    const updateValidationOCDate = (validator_idx: number, key: "po_created_date_ISO_field" | "po_requested_date_ISO_field" | "extracted_part_number_field", field_uuid: string, context_uuid: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const oc_date_validation = validators[validator_idx];
            if (oc_date_validation.type === "oc_date_validation") {
                if (oc_date_validation !== undefined) {
                    if (key === "po_created_date_ISO_field") {
                        oc_date_validation.po_created_date_ISO_field.field_uuid = field_uuid;
                        oc_date_validation.po_created_date_ISO_field.context_uuid = context_uuid;
                    }
                    if (key === "po_requested_date_ISO_field") {
                        oc_date_validation.po_requested_date_ISO_field.field_uuid = field_uuid;
                        oc_date_validation.po_requested_date_ISO_field.context_uuid = context_uuid;
                    }
                    if (key === "extracted_part_number_field") {
                        oc_date_validation.extracted_part_number_field.field_uuid = field_uuid;
                        oc_date_validation.extracted_part_number_field.context_uuid = context_uuid;
                    }
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    const updateRangeDateField = (validator_idx: number, key: "reference_date_field" | "days_before" | "days_after" | "switch_reference_field", value: string | boolean, context_uuid: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const date_range_validation = validators[validator_idx];
            if (date_range_validation.type === "date_range_validation") {
                if (date_range_validation !== undefined) {
                    if (key === "reference_date_field") {
                        date_range_validation.reference_date_field.field_uuid = value as string;
                        date_range_validation.reference_date_field.context_uuid = context_uuid;
                    }
                    if (key === "days_before") {
                        date_range_validation.days_before = value === "" ? undefined : parseInt(value as string, 10);
                    }
                    if (key === "days_after") {
                        date_range_validation.days_after = value === "" ? undefined : parseInt(value as string, 10);
                    }
                    if (key === "switch_reference_field") {
                        date_range_validation.switch_reference_field = value as boolean;
                    }
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    const updateRangeNumberField = (validator_idx: number, key: "reference_number_field" | "less_than" | "greater_than" | "switch_reference_field", value: string | boolean, context_uuid: string) => {
        if (field && field.validators && field.validators.length > validator_idx) {
            const validators = [...field.validators];
            const number_range_validation = validators[validator_idx];
            if (number_range_validation.type === "number_range_validation") {
                if (number_range_validation !== undefined) {
                    if (key === "reference_number_field") {
                        number_range_validation.reference_number_field.field_uuid = value as string;
                        number_range_validation.reference_number_field.context_uuid = context_uuid;
                    }
                    if (key === "less_than") {
                        number_range_validation.less_than = value === "" ? undefined : parseFloat(value as string);
                    }
                    if (key === "greater_than") {
                        number_range_validation.greater_than = value === "" ? undefined : parseFloat(value as string);
                    }
                    if (key === "switch_reference_field") {
                        number_range_validation.switch_reference_field = value as boolean;
                    }
                    setField(field_idx, { ...field, validators });
                }
            }
        }
    }

    /// postprocess

    const addFieldPostprocessFormula = () => {
        if (field) {
            const postprocess_formula: t.IContextFieldPostprocess = {
                type: "formula",
                formula: "$$VAL"
            };
            const postprocess: t.IContextFieldPostprocess[] = [...(field.postprocess || []), postprocess_formula];
            setField(field_idx, { ...field, postprocess });
        }
    }

    const deleteFieldPostprocess = (postprocess_idx: number) => {
        if (field && field.postprocess && field.postprocess.length > postprocess_idx) {
            const postprocess = [
                ...field.postprocess.splice(0, postprocess_idx),
                ...field.postprocess.splice(postprocess_idx + 1)
            ];
            setField(field_idx, { ...field, postprocess });
        }
    };

    const updateFieldPostprocessFormula = (postprocess_idx: number, formula: string) => {
        if (field && field.postprocess && field.postprocess.length > postprocess_idx) {
            if (field.postprocess[postprocess_idx].type === "formula") {
                const postprocess = [...field.postprocess];
                postprocess[postprocess_idx].formula = formula;
                setField(field_idx, { ...field, postprocess });
            }
        }
    }

    const updateFormulaFieldValue = (key: "datatype" | "formula", value: string) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.type === "compute" && new_field.compute) {
                if (new_field.compute.type === "formula") {
                    if (key === "datatype") {
                        new_field.datatype = value as t.IContextFieldDataType;
                    }
                    if (key === "formula") {
                        new_field.compute.formula = value;
                    }
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const updateMetadataFieldValue = (metadata_type: ContextFieldComputeMetadataType) => {
        if (field === undefined) { return; }
        const new_field = { ...field };
        if (new_field.type === "compute" && new_field.compute && new_field.compute.type === "metadata") {
            new_field.compute.metadata = {
                type: metadata_type,
            };

            // new_field.datatype = value as t.IContextFieldDataType;
            // if metadata type is constant, initiate the constant value to empty string and type string (user enters the value and change the type on GUI)
            if (metadata_type === "constant") {
                new_field.compute.raw_value_constant = new_field.datatype === "number" ? 0 : "";
            } else {
                // clear constants if not a constant type
                new_field.compute.raw_value_constant = undefined;
            }

            setField(field_idx, new_field);
        }
    }

    const updateLookupTableValue = (value_header_idx: number) => {
        if (field === undefined) { return; }
        const new_field = { ...field };
        if (new_field.type === "lookup_table_value") {
            new_field.lookup_table_value = { value_header_idx };
            setField(field_idx, new_field);
        }
    }

    const validateJSFormula = async (formula: string, field_name: string) => {
        const result = await BackendObj.extractions.validateJsCode({ code: formula });
        if (!result.valid) {
            alert(`Error in formula for field ${field_name}: ${result.error}`);
        }
    }

    const is_object = context_type === CONTEXT_TYPES.object;
    const is_lookup_table = context_type === CONTEXT_TYPES.lookup_table;

    // prepare type dropdown items
    const type_items: IRichDropdownValue[] = [
        { id: "string", name: "Text" },
        { id: "number", name: "Number" },
        { id: "date", name: "Date" },
        { id: "enum", name: "Label", hide: is_lookup_table },
        { id: "fallback_sequence", name: "Lookup" },
        { id: "formula", name: "Formula", hide: !(is_org_admin || is_admin) },
        { id: "metadata", name: "Metadata" }
    ];
    const selected_type = (field?.type === "extract" || is_lookup_table) ? field?.datatype : field?.compute?.type;

    // prepare rules dropdown items
    const has_lookup_tables = lookup_tables.length > 0;
    const rules_items: IDropdownMenuItem[] = [
        { title: "Lookup Constraint", hide: !has_lookup_tables, onClick: addValidationRange },
        { title: "Filtered Lookup Constraint", hide: !has_lookup_tables, onClick: addValidationPivotRange },
        { title: "Field Equality Constraint", onClick: addValidationEqualsField },
        { title: "Date Range Constraint", separator: false, onClick: addValidationDateRange, hide: field?.datatype !== ("date" as t.IContextFieldDataType) },
        { title: "Number Range Constraint", separator: false, onClick: addValidationNumberRange, hide: field?.datatype !== ("number" as t.IContextFieldDataType) },
        { title: "Regex Constraint", onClick: addValidationRegex },
        { title: "Formula Constraint", separator: true, hide: !(is_org_admin || is_admin), onClick: addValidationFormula },
        { title: "OC Date Quality Control", separator: true, onClick: addValidationOCDate },
        { title: "Extract With Condition", onClick: () => { addCondition("only_with"); } },
        { title: "Skip Row on Value Match/Mismatch", separator: !has_lookup_tables, onClick: () => { addCondition("skip_if_match"); } },
        { title: "Skip Row on Lookup Match/Mismatch", hide: !has_lookup_tables, separator: true, onClick: () => { addCondition("skip_if_includes"); } },
        { title: "Postprocess Formula", hide: !(is_org_admin || is_admin), onClick: addFieldPostprocessFormula },
    ];

    // prepare range lookup dropdown values
    const lookup_tables_names: string[] = lookup_tables.map((lookup_table) => lookup_table.name);
    const lookup_tables_ids: string[] = lookup_tables.map((lookup_table) => lookup_table.uuid);

    const getLookupTableHeaderValues = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((header, idx) => `${getExcelColumnName(idx)} - ${header}`);
    };
    const getLookupTableHeaderIds = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((_header, idx) => `${idx}`);
    };

    // move steps
    const move_contexts: { uuid: string, name: string }[] = contexts
        ? contexts
            .map((context, idx) => ({ ...context, idx }))
            .filter((context) => context.type !== CONTEXT_TYPES.lookup_table)
            .filter((context) => context.uuid !== "")
            .map((context) => ({ uuid: context.uuid, name: `${context.idx + 1}. ${context.name}` }))
        : [];
    const can_move_steps = move_contexts.length > 1 && type === "edit" && !is_lookup_table && selected_context_uuid !== "";

    const all_fields = [
        ...fields.map((field) => ({ uuid: field.uuid, name: field.name })),
        ...prev_fields
            .filter((field) => field.context_type === CONTEXT_TYPES.object)
            .map((field) => ({ uuid: field.field_uuid, name: `[${field.context_name}] ${field.field_name}` }))
    ];

    const dateRangeFilterParametersExplanation = (validator: IFieldValidatorDateRange, field: t.IContextField): string | JSX.Element => {
        const ref_field_name = all_fields.find(f => f.uuid === validator.reference_date_field?.field_uuid)?.name;
        const field1 = validator.switch_reference_field ? ref_field_name : field.name;
        const field2 = validator.switch_reference_field ? field.name : ref_field_name;

        // Both empty - no interval restriction
        if (validator.days_before === undefined && validator.days_after === undefined) {
            // return "Please specify Days Before and/or Days After parameter of the validator";
            return <span className="text-torch_red-600">Please specify Days Before and/or Days After parameter of the validator</span>
        }

        // Both are 0 - exact same date case
        if (validator.days_before === 0 && validator.days_after === 0) {
            return `The ${field1} must be on exactly the same date as ${field2}.`;
        }

        // One is 0, other is empty - one-sided exact date restriction
        if (validator.days_before === 0 && validator.days_after === undefined) {
            return `The ${field1} must be on or after the same date as ${field2}.`;
        }
        if (validator.days_after === 0 && validator.days_before === undefined) {
            return `The ${field1} must be on or before the same date as ${field2}.`;
        }

        // One is 0, other has value
        if (validator.days_before === 0 && validator.days_after !== undefined) {
            return `The ${field1} must be between the same date and ${validator.days_after} days after ${field2}.`;
        }
        if (validator.days_after === 0 && validator.days_before !== undefined) {
            return `The ${field1} must be between ${validator.days_before} ${validator.days_before === 1 ? "day" : "days"} before and the same date as ${field2}.`;
        }

        // One is empty, other has value - open interval
        if (validator.days_before === undefined && validator.days_after !== undefined) {
            return `The ${field1} must be no more than ${validator.days_after} ${validator.days_before === 1 ? "day" : "days"} after ${field2}.`;
        }
        if (validator.days_after === undefined && validator.days_before !== undefined) {
            return `The ${field1} must be no more than ${validator.days_before} ${validator.days_before === 1 ? "day" : "days"} before ${field2}.`;
        }

        // Both have non-zero values - specific interval
        if (validator.days_before !== undefined && validator.days_after !== undefined) {
            return `The ${field1} must be between ${validator.days_before} ${validator.days_before === 1 ? "day" : "days"} before and ${validator.days_after} ${validator.days_before === 1 ? "day" : "days"} after ${field2}.`;
        }

        return "";
    }

    const numberRangeFilterParametersExplanation = (validator: IFieldValidatorNumberRange, field: t.IContextField): string | JSX.Element => {
        const ref_field_name = all_fields.find(f => f.uuid === validator.reference_number_field?.field_uuid)?.name;
        const field1 = validator.switch_reference_field ? ref_field_name : field.name;
        const field2 = validator.switch_reference_field ? field.name : ref_field_name;

        // Both empty - no interval restriction
        if (validator.less_than === undefined && validator.greater_than === undefined) {
            return <span className="text-torch_red-600">Please specify Less Than and/or More Than parameter of the validator</span>;
        }

        // Both are 0 - exact same number case
        if (validator.less_than === 0 && validator.greater_than === 0) {
            return `The ${field1} value must be exactly equal to ${field2}.`;
        }

        // Both have values - specific range
        if (validator.less_than !== undefined && validator.greater_than !== undefined) {
            return `The ${field1} value can be no more than ${validator.less_than} less than and up to ${validator.greater_than} more than ${field2}.`;
        }

        // One is empty - one-sided restriction
        if (validator.less_than === undefined && validator.greater_than !== undefined) {
            return `The ${field1} value can be less than ${field2} or up to ${validator.greater_than} more than ${field2}.`;
        }
        if (validator.greater_than === undefined && validator.less_than !== undefined) {
            return `The ${field1} value can be no more than ${validator.less_than} less than or more than ${field2}.`;
        }

        return "";
    }

    const updateConstantType = (type: string) => {
        if (field === undefined) { return; }
        const new_field = { ...field };
        if (new_field.type === "compute" && new_field.compute && new_field.compute.type === "metadata") {
            new_field.datatype = type as t.IContextFieldDataType;
            // Clear the constant value when switching types
            new_field.compute.raw_value_constant = new_field.datatype === "number" ? 0 : "";
            setField(field_idx, new_field);
        }
    }

    const updateConstantValue = (value: string) => {
        if (field === undefined) { return; }
        const new_field = { ...field };
        if (new_field.type === "compute" && new_field.compute && new_field.compute.type === "metadata") {
            if (new_field.datatype === "number") {
                // Validate number input
                if (value === "" || !isNaN(Number(value))) {
                    new_field.compute.raw_value_constant = value;
                }
            } else {
                new_field.compute.raw_value_constant = value;
            }
            setField(field_idx, new_field);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-lg xl:max-w-xl 2xl:max-w-2xl pt-16 lg:pt-0">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Detailed Field Settings
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    {type === "edit" && <ButtonGroup buttons={[
                                                        { text: "", icon: is_object ? ChevronDoubleUpIcon : ChevronDoubleLeftIcon, onClick: () => moveField(field_idx, -field_idx), disabled: field_idx === 0 },
                                                        { text: "", icon: is_object ? ChevronUpIcon : ChevronLeftIcon, onClick: () => moveField(field_idx, -1), disabled: field_idx === 0 },
                                                        { text: "", icon: is_object ? ChevronDownIcon : ChevronRightIcon, onClick: () => moveField(field_idx, 1), disabled: fields_count - field_idx <= 1 },
                                                        { text: "", icon: is_object ? ChevronDoubleDownIcon : ChevronDoubleRightIcon, onClick: () => moveField(field_idx, fields_count - field_idx - 1), disabled: fields_count - field_idx <= 1 },
                                                        { text: "", icon: TrashIcon, onClick: () => deleteField(field_idx) }
                                                    ]} tiny={true} />}
                                                    <button
                                                        type="button"
                                                        className="ml-4 relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-space_blue-500 focus:ring-offset-2"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative py-4 flex-1 px-4 sm:px-6">
                                            <div className="w-full flex flex-row items-center">
                                                <label htmlFor="title" className=" w-28 my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                    Name
                                                </label>
                                                <div className="flex-grow">
                                                    <Textbox
                                                        value={field?.name || ""}
                                                        onChange={(value) => updateField("name", value)}
                                                        disabled={type === "override"} />
                                                </div>
                                                {field?.extract && field?.extract?.synonyms === undefined && <Button icon={PlusIcon} text="Synonym" onClick={() => openNewFieldDialog("synonyms")} />}
                                            </div>
                                            {field?.extract && field?.extract?.synonyms && <div className="my-3 w-full flex flex-row items-center">
                                                <label htmlFor="title" className="w-28 my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                    Synonyms
                                                </label>
                                                <div className="flex-grow">
                                                    <MultiselectInputField
                                                        values={field?.extract?.synonyms ?? []}
                                                        placeholder="No synonyms"
                                                        separator="space"
                                                        onAdd={(() => openNewFieldDialog("synonyms"))}
                                                        onClick={(idx) => openNewFieldDialog("synonyms", field?.extract?.synonyms, idx)}
                                                        onRemove={(idx) => deleteFieldElement("synonyms", idx)} />
                                                </div>
                                                <Button icon={PlusIcon} onClick={() => openNewFieldDialog("synonyms")} />
                                            </div>}
                                            {can_move_steps && setFieldContext &&
                                                <div className="my-3 w-full flex flex-row items-center">
                                                    <label htmlFor="title" className="w-28 my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Step
                                                    </label>
                                                    <div className="flex-grow">
                                                        <Dropdown
                                                            ids={move_contexts.map((context) => context.uuid)}
                                                            values={move_contexts.map((context) => context.name)}
                                                            selected={selected_context_uuid || ""}
                                                            onChange={(context_uuid) => { setFieldContext(field?.uuid || "", context_uuid); setOpen(false); }}
                                                        />
                                                    </div>
                                                </div>}
                                            <div className="mt-3 w-full flex flex-row items-center">
                                                <label htmlFor="title" className="w-28 my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                    Type
                                                </label>
                                                <div className="flex-grow">
                                                    {type === "edit" && <RichDropdown
                                                        values={type_items}
                                                        selected={selected_type || ""}
                                                        onChange={(e) => updateField("datatype", e)} />}
                                                    {type !== "edit" && <Textbox
                                                        value={type_items.find((item) => item.id === selected_type)?.name || ""}
                                                        disabled={true} />}
                                                </div>
                                            </div>

                                            <div className="mt-6">
                                                <CompactTabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
                                            </div>
                                            {selected_tab === "general" && <div>
                                                {field?.datatype !== "enum" && field?.datatype !== "date" && <Fragment>
                                                    <div className="my-4">
                                                        <p className="text-sm text-gray-400">
                                                            The following settings are optional, you can use them to improve the extraction quality.
                                                        </p>
                                                        {type === "override" && <p className="text-sm text-gray-400">
                                                            These settings are used to overwrite existing field settings in the case of exception.
                                                        </p>}

                                                    </div>
                                                    {field?.datatype === "string" && <div className="my-3">
                                                        <div className="my-2 relative flex items-center sm:pt-1.5">
                                                            <label htmlFor="guidelines" className=" block text-sm font-medium leading-6 text-gray-900 ">
                                                                Format
                                                            </label>
                                                            <div className="flex-grow" />
                                                            <div className="flex h-6 items-center">
                                                                <Checkbox
                                                                    id="verbatim"
                                                                    checked={field?.extract?.verbatim ?? false}
                                                                    setChecked={(value) => updateCheckbox("verbatim", value)}
                                                                />
                                                            </div>
                                                            <div className="ml-3 text-sm leading-6">
                                                                <label htmlFor="verbatim" className="text-gray-900">
                                                                    Verbatim
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="my-2 flex w-full">
                                                            <Textbox
                                                                value={field?.extract?.format || ""}
                                                                disabled={field?.extract?.verbatim}
                                                                placeholder={field?.extract?.verbatim ? "Format cannot be changed for verbatim fields" : ""}
                                                                onChange={(value) => updateField("format", value)} />
                                                        </div>
                                                    </div>}
                                                    <div className="my-3 w-full">
                                                        <label htmlFor="title" className="mt-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Examples
                                                        </label>
                                                        <div className="w-full flex flex-row items-center">
                                                            <label htmlFor="title" className="w-28 my-2 pl-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                Good
                                                            </label>
                                                            <div className="flex-grow">
                                                                <MultiselectInputField
                                                                    values={field?.extract?.examples ?? []}
                                                                    placeholder="No examples"
                                                                    separator="space"
                                                                    color={"green"}
                                                                    onAdd={(() => openNewFieldDialog("examples"))}
                                                                    onClick={(idx) => openNewFieldDialog("examples", field?.extract?.examples, idx)}
                                                                    onRemove={(idx) => deleteFieldElement("examples", idx)} />
                                                            </div>
                                                            <div>
                                                                <Button icon={PlusIcon} onClick={() => openNewFieldDialog("examples")} />
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex flex-row items-center">
                                                            <label htmlFor="title" className="w-28 my-2 pl-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                Bad
                                                            </label>
                                                            <div className="flex-grow">
                                                                <MultiselectInputField
                                                                    values={field?.extract?.negative_examples ?? []}
                                                                    placeholder="No negative examples"
                                                                    separator="space"
                                                                    color={"red"}
                                                                    onAdd={(() => openNewFieldDialog("negative_examples"))}
                                                                    onClick={(idx) => openNewFieldDialog("negative_examples", field?.extract?.negative_examples, idx)}
                                                                    onRemove={(idx) => deleteFieldElement("negative_examples", idx)} />
                                                            </div>
                                                            <div>
                                                                <Button icon={PlusIcon} onClick={() => openNewFieldDialog("negative_examples")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="my-3 w-full flex flex-row items-center">
                                                        <label htmlFor="title" className="w-28 my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Prefixes
                                                        </label>
                                                        <div className="flex-grow">
                                                            <MultiselectInputField
                                                                values={(field?.extract?.prefixes ?? []).map((prefix) => `${prefix}...`)}
                                                                placeholder="No prefixes"
                                                                separator="space"
                                                                onAdd={(() => openNewFieldDialog("prefixes"))}
                                                                onClick={(idx) => openNewFieldDialog("prefixes", field?.extract?.prefixes, idx)}
                                                                onRemove={(idx) => deleteFieldElement("prefixes", idx)} />
                                                        </div>
                                                        <div>
                                                            <Button icon={PlusIcon} onClick={() => openNewFieldDialog("prefixes")} />
                                                        </div>
                                                    </div>
                                                    <div className="my-3 w-full flex flex-row items-center">
                                                        <label htmlFor="title" className="w-28 my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Suffixes
                                                        </label>
                                                        <div className="flex-grow">
                                                            <MultiselectInputField
                                                                values={(field?.extract?.suffixes ?? []).map((suffix) => `...${suffix}`)}
                                                                placeholder="No suffixes"
                                                                separator="space"
                                                                onAdd={(() => openNewFieldDialog("suffixes"))}
                                                                onClick={(idx) => openNewFieldDialog("suffixes", field?.extract?.suffixes, idx)}
                                                                onRemove={(idx) => deleteFieldElement("suffixes", idx)} />
                                                        </div>
                                                        <div>
                                                            <Button icon={PlusIcon} onClick={() => openNewFieldDialog("suffixes")} />
                                                        </div>
                                                    </div>
                                                </Fragment>}

                                                {field?.datatype === "date" && <div className="my-4">
                                                    <div className="text-sm font-semibold leading-6 text-gray-700 flex flex-row items-start">
                                                        <label htmlFor={"variant_names"} className="The following settings pb-4">
                                                            Support the Following Input Formats:
                                                        </label>
                                                        <div className="flex-grow" />
                                                        <QuestionMarkCircleIcon
                                                            className="w-5 h-5 ml-1 text-gray-400 mt-0.5"
                                                            data-tooltip-id="fields-table-tooltip-id"
                                                            data-tooltip-html={`<p class="pb-4 max-w-sm">Different input format variants are used to handle different ways the date is expected to be expressed in the input text.
                                                            For example, if the date is specified as a concrete date like "12.31.2024",
                                                            you should use the "Specific Date" variant. If the date might be specified as a week of
                                                            year like "Week 52 of 2024", you should use the "Week of Year" variant. You can use
                                                            multiple variants to handle different input formats.</p>`}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col gap-4">
                                                        <div className="flex flex-col gap-8">
                                                            {DATE_VARIANT_TYPES.map((variant_type, idx) => (
                                                                <div key={idx} className="flex flex-col gap-y-2">
                                                                    <div className="flex items-center">
                                                                        <Checkbox
                                                                            id={`variant_type_${variant_type.type}`}
                                                                            checked={field.extract?.date_data?.input_format_variants?.some(v => v.parse_data?.type === variant_type.type) ?? false}
                                                                            setChecked={(checked) => {
                                                                                // Don't allow unchecking if this is the last enabled variant
                                                                                if (!checked && (field.extract?.date_data?.input_format_variants?.length ?? 0) <= 1) {
                                                                                    return;
                                                                                }
                                                                                const new_field = { ...field };
                                                                                const variants = [...(field.extract?.date_data?.input_format_variants || [])];

                                                                                if (checked) {
                                                                                    // Add variant if not exists
                                                                                    if (!variants.some(v => v.parse_data?.type === variant_type.type)) {
                                                                                        const new_variant = {
                                                                                            name: variant_type.default_name,
                                                                                            format: "",
                                                                                            parse_data: {
                                                                                                type: variant_type.type,
                                                                                                day_month_year_order: field?.extract?.date_data?.day_month_year_order
                                                                                            }
                                                                                        };
                                                                                        // Find insert position to maintain order
                                                                                        const insert_idx = DATE_VARIANT_TYPES.findIndex(t => t.type === variant_type.type);
                                                                                        let actual_idx = 0;
                                                                                        for (let i = 0; i < insert_idx; i++) {
                                                                                            if (variants.some(v => v.parse_data?.type === DATE_VARIANT_TYPES[i].type)) {
                                                                                                actual_idx++;
                                                                                            }
                                                                                        }
                                                                                        variants.splice(actual_idx, 0, new_variant);
                                                                                    }
                                                                                } else {
                                                                                    // Remove variant
                                                                                    const idx = variants.findIndex(v => v.parse_data?.type === variant_type.type);
                                                                                    if (idx !== -1) {
                                                                                        variants.splice(idx, 1);
                                                                                    }
                                                                                }

                                                                                new_field.extract = {
                                                                                    ...new_field.extract,
                                                                                    date_data: {
                                                                                        ...new_field?.extract?.date_data,
                                                                                        input_format_variants: variants
                                                                                    }
                                                                                };
                                                                                setField(field_idx, new_field);
                                                                            }}
                                                                        />
                                                                        <label htmlFor={`variant_type_${variant_type.type}`} className="ml-2 text-sm text-gray-700 flex flex-row items-center gap-x-2">
                                                                            {variant_type.title} <span className="text-xs text-gray-400">(e.g. {variant_type.example})</span>
                                                                        </label>
                                                                    </div>

                                                                    {(field?.extract?.date_data?.input_format_variants ?? [])
                                                                        .map((variant, idx) => ({ variant, idx })) // remember original index
                                                                        .filter(({ variant }) => variant.parse_data?.type === variant_type.type) // remove non-matching variants
                                                                        .map(({ variant, idx }) => <div key={"date_variant_" + idx} className="flex flex-col w-full gap-y-2">
                                                                            {is_admin && <div className="w-full flex flex-row items-center">
                                                                                <label htmlFor={"variant_name_" + idx} className="w-36 my-2 block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                                                                    Short Name
                                                                                </label>
                                                                                <div className="flex-grow">
                                                                                    <Textbox
                                                                                        id={"variant_name_" + idx}
                                                                                        value={variant.name || ""}
                                                                                        placeholder="Variant Name"
                                                                                        onChange={(value) => onDateVariantUpdate({ ...variant, name: value }, idx)}
                                                                                    />
                                                                                </div>
                                                                            </div>}
                                                                            <div className="w-full flex items-center">
                                                                                <label htmlFor={"variant_guideline_" + idx} className="w-36 my-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Good Examples
                                                                                </label>
                                                                                <div className="flex-grow">
                                                                                    <Textbox
                                                                                        id={"good_examples_" + idx}
                                                                                        value={variant.examples?.join(";") || ""}
                                                                                        placeholder="Use ; to separate examples"
                                                                                        onChange={(value) => onDateVariantUpdate({ ...variant, examples: value.split(";") }, idx)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="w-full flex items-center">
                                                                                <label htmlFor={"variant_guideline_" + idx} className="w-36 my-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Bad Examples
                                                                                </label>
                                                                                <div className="flex-grow">
                                                                                    <Textbox
                                                                                        id={"bad_examples_" + idx}
                                                                                        value={variant.negative_examples?.join(";") || ""}
                                                                                        placeholder="Use ; to separate examples"
                                                                                        onChange={(value) => onDateVariantUpdate({ ...variant, negative_examples: value.split(";") }, idx)} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="w-full flex flex-row items-center">
                                                                                <label htmlFor={"variant_guideline_" + idx} className="w-36 my-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Guideline
                                                                                </label>
                                                                                <div className="flex-grow">
                                                                                    <Textbox
                                                                                        id={"variant_guideline_" + idx}
                                                                                        value={variant.guideline || ""}
                                                                                        placeholder="No guidelines"
                                                                                        onChange={(value) => onDateVariantUpdate({ ...variant, guideline: value }, idx)} />
                                                                                </div>
                                                                            </div>

                                                                            {variant.parse_data?.type === "week_year" && (
                                                                                <div className="w-full flex flex-row items-center">
                                                                                    <label htmlFor={"week_year_" + idx} className="w-36 my-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                                        Use Day of Week
                                                                                    </label>
                                                                                    <div className="flex-grow">
                                                                                        <Dropdown
                                                                                            id={"week_year_" + idx}
                                                                                            values={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]}
                                                                                            ids={["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]}
                                                                                            selected={variant.parse_data.day_in_week || "monday"}
                                                                                            onChange={(day: string) => onDateVariantUpdateDayOfWeek(day as "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday", idx)}
                                                                                        />
                                                                                    </div>
                                                                                </div>)}
                                                                            {variant.parse_data?.type === "month_year" && (
                                                                                <div className="w-full flex flex-row items-center">
                                                                                    <label htmlFor={"month_year_" + idx} className="w-36 my-2 block text-sm leading-6 text-gray-900 sm:pt-1.5">
                                                                                        Use Day in Month
                                                                                    </label>
                                                                                    <div className="flex-grow">
                                                                                        <Dropdown
                                                                                            id={"month_year_" + idx}
                                                                                            values={Array.from({ length: 31 }, (_, i) => (i + 1).toString())}
                                                                                            ids={Array.from({ length: 31 }, (_, i) => (i + 1).toString())}
                                                                                            selected={variant.parse_data.day_in_month?.toString() || "1"}
                                                                                            onChange={(day: string) => onDateVariantUpdateDayInMonth(parseInt(day, 10), idx)}
                                                                                        />
                                                                                    </div>
                                                                                </div>)}
                                                                        </div>)}
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </div>}

                                                {field?.datatype === "enum" && <div className="my-3">
                                                    <label htmlFor="title" className="mt-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Possible Labels
                                                    </label>
                                                    <div className="py-2 w-full">
                                                        <EnumTable values={field?.extract?.enum_data?.values ?? [{ value: "", guideline: "" }]} setValues={(values) => updateField("enum_data", values)} />
                                                    </div>
                                                </div>}
                                                {field?.datatype !== "date" && <div className="my-3">
                                                    <label htmlFor="guidelines" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                        Guidelines
                                                    </label>
                                                    {(field?.extract?.guidelines ?? [""]).map((guideline, idx) => <div key={idx} className="my-2 flex w-full">
                                                        <div className="flex-grow flex flex-row items-center">
                                                            <span className="text-sm pl-2 w-9">{idx + 1}.</span>
                                                            <Textbox value={guideline} placeholder="No guidelines" onChange={(value) => onGuidelineUpdate(value, idx)} />
                                                        </div>
                                                        <Button
                                                            icon={field?.extract?.guidelines !== undefined && (idx + 1) < field.extract.guidelines.length ? TrashIcon : PlusIcon}
                                                            onClick={() => onGuidelineButton(idx)} />
                                                    </div>)}
                                                </div>}

                                            </div>}

                                            {selected_tab === "rules" && <div>
                                                <div className="pt-4 flex flex-row items-center w-full">
                                                    <DropdownMenu title="Create new rule" items={rules_items} />
                                                </div>
                                                {field?.postprocess && <div className="my-6 flex flex-col gap-y-4">
                                                    {field.postprocess.map((postprocess, postprocess_idx) => {
                                                        if (postprocess.type === "formula") {
                                                            return (<div key={postprocess_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {postprocess_idx + 1}.&nbsp;
                                                                        Field Postprocess Logic
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteFieldPostprocess(postprocess_idx)} /></div>
                                                                </div>
                                                                <div className="w-full shadow border">
                                                                    <CodeMirror
                                                                        value={postprocess.formula}
                                                                        height="200px"
                                                                        theme="light"
                                                                        extensions={[javascript()]}
                                                                        readOnly={!(is_org_admin || is_admin)}
                                                                        onChange={(formula) => updateFieldPostprocessFormula(postprocess_idx, formula)}
                                                                        onBlur={async () => { await validateJSFormula(postprocess.formula ?? "", field.name) }}
                                                                    />
                                                                </div>
                                                            </div>);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>}
                                                {field?.validators && <div className="flex flex-col gap-y-4">
                                                    {field.validators.map((validator, validator_idx) =>
                                                        <div className="" key={validator_idx}>
                                                            <div className="pt-4 flex flex-row items-center">
                                                                <div className="text-sm font-semibold">
                                                                    {(field?.postprocess?.length || 0) + validator_idx + 1}.&nbsp;
                                                                    {validator.type === "range" && "Lookup Constraint"}
                                                                    {validator.type === "pivot_range" && "Filtered Lookup Constraint"}
                                                                    {validator.type === "equals_field" && "Field Equality Constraint"}
                                                                    {validator.type === "regex" && "Regex Constraint"}
                                                                    {validator.type === "oc_date_validation" && "OC Date Quality Control"}
                                                                    {validator.type === "formula" && "Field Constraint Logic"}
                                                                </div>
                                                                <div className="grow" />
                                                                <div className="mr-3">
                                                                    <Dropdown
                                                                        values={["Notify", "Require", "Reject"]}
                                                                        ids={["warn", "fail", "reject"]}
                                                                        selected={validator.level}
                                                                        onChange={(level: string) => updateValidation(validator_idx, "level", level)}
                                                                    />
                                                                </div>
                                                                <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteValidation(validator_idx)} /></div>
                                                            </div>

                                                            <div className="grid grid-cols-2 gap-x-4 pb-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Name
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Textbox
                                                                            value={validator.name}
                                                                            onChange={(name: string) => updateValidation(validator_idx, "name", name)} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Analytics Tag
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Textbox
                                                                            value={validator.analytics_tag}
                                                                            onChange={(analytics_tag: string) => updateValidation(validator_idx, "analytics_tag", analytics_tag)} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {validator.type === "range" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_names}
                                                                            ids={lookup_tables_ids}
                                                                            selected={validator.lookup_table_uuid}
                                                                            onChange={(id: string) => updateValidationRangeTable(validator_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Lookup Column
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={getLookupTableHeaderValues(validator.lookup_table_uuid)}
                                                                            ids={getLookupTableHeaderIds(validator.lookup_table_uuid)}
                                                                            selected={validator.header_idx.toString() || "0"}
                                                                            onChange={(header_idx: string) => updateValidationRange(validator_idx, "header", parseInt(header_idx, 10))} />
                                                                    </div>
                                                                </div>
                                                                <div className="py-3 grid grid-cols-3 gap-x-4">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Compensate OCR</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={validator.compensate_ocr_errors ?? false}
                                                                                        setChecked={(checked) => updateValidationRange(validator_idx, "compensate_ocr_errors", checked)}
                                                                                        id={`range_compensate_ocr_errors_${validator_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                                                                    <label htmlFor={`range_compensate_ocr_errors_${validator_idx}`} className="font-medium text-gray-900">
                                                                                        Compensate OCR
                                                                                    </label>
                                                                                    <QuestionMarkCircleIcon
                                                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                                                        data-tooltip-id="fields-table-tooltip-id"
                                                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have OCR errors like confusing 0 and O, 1 and I, etc.This will help in matching the OCR extracted value with the lookup table value.</p>`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset>
                                                                        <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={validator.compensate_leading_zeros ?? false}
                                                                                        setChecked={(checked) => updateValidationRange(validator_idx, "compensate_leading_zeros", checked)}
                                                                                        id={`range_compensate_leading_zeros_${validator_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                                                                    <label htmlFor={`range_compensate_leading_zeros_${validator_idx}`} className="font-medium text-gray-900">
                                                                                        Leading zeros
                                                                                    </label>
                                                                                    <QuestionMarkCircleIcon
                                                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                                                        data-tooltip-id="fields-table-tooltip-id"
                                                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have ID numbers with leading zeros. This will help in matching the extracted value with the lookup table value even when the number of leading zeros is different.</p>`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset>
                                                                        <legend className="sr-only">Case insensitive</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={validator.case_insensitive ?? true}
                                                                                        setChecked={(checked) => updateValidationRange(validator_idx, "case_insensitive", checked)}
                                                                                        id={`range_case_insensitive_${validator_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                                                                    <label htmlFor={`range_case_insensitive_${validator_idx}`} className="font-medium text-gray-900">
                                                                                        Ignore case
                                                                                    </label>
                                                                                    <QuestionMarkCircleIcon
                                                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                                                        data-tooltip-id="fields-table-tooltip-id"
                                                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you do not want to differentiate between uppercase and lowercase letters.</p>`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>}

                                                            {validator.type === "pivot_range" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_names}
                                                                            ids={lookup_tables_ids}
                                                                            selected={validator.lookup_table_uuid}
                                                                            onChange={(id: string) => updateValidationPivotRangeTable(validator_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-2 gap-x-4">
                                                                    <div className="">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Field Key
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={all_fields.map((field) => field.name)}
                                                                                ids={all_fields.map((field) => field.uuid)}
                                                                                selected={validator.key_field_uuid}
                                                                                onChange={(field_uuid: string) => updateValidationPivotRangeValue(validator_idx, "field", field_uuid)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                            Lookup Key
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={getLookupTableHeaderValues(validator.lookup_table_uuid)}
                                                                                ids={getLookupTableHeaderIds(validator.lookup_table_uuid)}
                                                                                selected={validator.key_header_idx.toString() || "0"}
                                                                                onChange={(key_header_idx: string) => updateValidationPivotRangeValue(validator_idx, "key", parseInt(key_header_idx, 10))} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Lookup Value
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={getLookupTableHeaderValues(validator.lookup_table_uuid)}
                                                                            ids={getLookupTableHeaderIds(validator.lookup_table_uuid)}
                                                                            selected={validator.value_header_idx.toString() || "0"}
                                                                            onChange={(value_header_idx: string) => updateValidationPivotRangeValue(validator_idx, "value", parseInt(value_header_idx, 10))} />
                                                                    </div>
                                                                </div>
                                                                <div className="py-3 grid grid-cols-3 gap-x-4">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Compensate OCR</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={validator.compensate_ocr_errors ?? false}
                                                                                        setChecked={(checked) => updateValidationPivotRangeValue(validator_idx, "compensate_ocr_errors", checked)}
                                                                                        id={`pivot_ange_compensate_ocr_errors_${validator_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                                                                    <label htmlFor={`pivot_ange_compensate_ocr_errors_${validator_idx}`} className="font-medium text-gray-900">
                                                                                        Compensate OCR
                                                                                    </label>
                                                                                    <QuestionMarkCircleIcon
                                                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                                                        data-tooltip-id="fields-table-tooltip-id"
                                                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have OCR errors like confusing 0 and O, 1 and I, etc.This will help in matching the OCR extracted value with the lookup table value.</p>`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset>
                                                                        <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={validator.compensate_leading_zeros ?? false}
                                                                                        setChecked={(checked) => updateValidationPivotRangeValue(validator_idx, "compensate_leading_zeros", checked)}
                                                                                        id={`pivot_ange_compensate_leading_zeros_${validator_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                                                                    <label htmlFor={`pivot_ange_compensate_leading_zeros_${validator_idx}`} className="font-medium text-gray-900">
                                                                                        Leading zeros
                                                                                    </label>
                                                                                    <QuestionMarkCircleIcon
                                                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                                                        data-tooltip-id="fields-table-tooltip-id"
                                                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have ID numbers with leading zeros. This will help in matching the extracted value with the lookup table value even when the number of leading zeros is different.</p>`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                    <fieldset>
                                                                        <legend className="sr-only">Case insensitive</legend>
                                                                        <div className="space-y-5">
                                                                            <div className="relative flex items-start">
                                                                                <div className="flex h-6 items-center">
                                                                                    <Checkbox
                                                                                        checked={validator.case_insensitive ?? true}
                                                                                        setChecked={(checked) => updateValidationPivotRangeValue(validator_idx, "case_insensitive", checked)}
                                                                                        id={`pivot_ange_case_insensitive_${validator_idx}`}
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                                                                    <label htmlFor={`pivot_ange_case_insensitive_${validator_idx}`} className="font-medium text-gray-900">
                                                                                        Ignore case
                                                                                    </label>
                                                                                    <QuestionMarkCircleIcon
                                                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                                                        data-tooltip-id="fields-table-tooltip-id"
                                                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you do not want to differentiate between uppercase and lowercase letters.</p>`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                            </div>}

                                                            {validator.type === "equals_field" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Field to compare
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={all_fields.map((field) => field.name)}
                                                                            ids={all_fields.map((field) => field.uuid)}
                                                                            selected={validator.field_uuid}
                                                                            onChange={(field_uuid: string) => updateValidationEqualsField(validator_idx, field_uuid)} />
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                            {validator.type === "regex" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 flex flex-row">
                                                                        <span className="text-sm text-gray-900">Pattern</span>
                                                                        <span className="flex-grow" />
                                                                        <span className="text-sm text-gray-900">
                                                                            [
                                                                            <span onClick={() => updateValidationRegex(validator_idx, "^\\d+$")} className="cursor-pointer text-space_blue-600 hover:text-space_blue-800">number</span>,
                                                                            <span onClick={() => updateValidationRegex(validator_idx, "^(\\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$")} className="cursor-pointer text-space_blue-600 hover:text-space_blue-800">date</span>,
                                                                            <span onClick={() => updateValidationRegex(validator_idx, "^.+$")} className="cursor-pointer text-space_blue-600 hover:text-space_blue-800">non-empty</span>
                                                                            ]
                                                                        </span>
                                                                    </label>
                                                                    <div className="w-full font-mono">
                                                                        <Textbox
                                                                            value={validator.pattern || ""}
                                                                            onChange={(pattern: string) => updateValidationRegex(validator_idx, pattern)} />
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                            {validator.type === "oc_date_validation" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        PO Created Date ISO Field
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={all_fields.map((field) => field.name)}
                                                                            ids={all_fields.map((field) => field.uuid)}
                                                                            selected={validator.po_created_date_ISO_field.field_uuid}
                                                                            onChange={(field_uuid: string) => updateValidationOCDate(validator_idx, "po_created_date_ISO_field", field_uuid, selected_context_uuid || "")} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        PO Requested Date ISO Field
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={all_fields.map((field) => field.name)}
                                                                            ids={all_fields.map((field) => field.uuid)}
                                                                            selected={validator.po_requested_date_ISO_field.field_uuid}
                                                                            onChange={(field_uuid: string) => updateValidationOCDate(validator_idx, "po_requested_date_ISO_field", field_uuid, selected_context_uuid || "")} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Extracted Part Number Field
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={all_fields.map((field) => field.name)}
                                                                            ids={all_fields.map((field) => field.uuid)}
                                                                            selected={validator.extracted_part_number_field.field_uuid}
                                                                            onChange={(field_uuid: string) => updateValidationOCDate(validator_idx, "extracted_part_number_field", field_uuid, selected_context_uuid || "")} />
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                            {validator.type === "date_range_validation" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="grid grid-cols-4 gap-x-4">
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="title" className="block text-sm text-gray-900">
                                                                            Reference Date Field
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={all_fields.map((field) => field.name)}
                                                                                ids={all_fields.map((field) => field.uuid)}
                                                                                selected={validator.reference_date_field && validator.reference_date_field.field_uuid}
                                                                                onChange={(field_uuid: string) => updateRangeDateField(validator_idx, "reference_date_field", field_uuid, selected_context_uuid || "")} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <label htmlFor="days_before" className="block text-sm text-gray-900">
                                                                            Days Before
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Textbox
                                                                                value={validator.days_before?.toString() || ""}
                                                                                onChange={(value) => updateRangeDateField(validator_idx, "days_before", value, selected_context_uuid || "")} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <label htmlFor="days_after" className="block text-sm text-gray-900">
                                                                            Days After
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Textbox
                                                                                value={validator.days_after?.toString() || ""}
                                                                                onChange={(value) => updateRangeDateField(validator_idx, "days_after", value, selected_context_uuid || "")} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <Checkbox
                                                                        id={`switch_reference_field_${validator_idx}`}
                                                                        checked={validator.switch_reference_field ?? false}
                                                                        setChecked={(checked) => updateRangeDateField(validator_idx, "switch_reference_field", checked, selected_context_uuid || "")}
                                                                    />
                                                                    <label htmlFor={`switch_reference_field_${validator_idx}`} className="ml-2 block text-sm text-gray-900">
                                                                        Apply the range on {field.name} field instead of {all_fields.find(f => f.uuid === validator.reference_date_field?.field_uuid)?.name || "reference date"} field
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 text-sm text-gray-600 italic">
                                                                    {field?.name && validator.reference_date_field && all_fields.find(f => f.uuid === validator.reference_date_field?.field_uuid)?.name && dateRangeFilterParametersExplanation(validator, field)}
                                                                </div>
                                                            </div>}

                                                            {validator.type === "number_range_validation" && <div className="w-full flex flex-col gap-y-4">
                                                                <div className="grid grid-cols-4 gap-x-4">
                                                                    <div className="col-span-2">
                                                                        <label htmlFor="title" className="block text-sm text-gray-900">
                                                                            Reference Number Field
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Dropdown
                                                                                values={all_fields.map((field) => field.name)}
                                                                                ids={all_fields.map((field) => field.uuid)}
                                                                                selected={validator.reference_number_field && validator.reference_number_field.field_uuid}
                                                                                onChange={(field_uuid: string) => updateRangeNumberField(validator_idx, "reference_number_field", field_uuid, selected_context_uuid || "")} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <label htmlFor="less_than" className="block text-sm text-gray-900">
                                                                            Less Than
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Textbox
                                                                                value={validator.less_than?.toString() || ""}
                                                                                onChange={(value) => updateRangeNumberField(validator_idx, "less_than", value, selected_context_uuid || "")} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <label htmlFor="greater_than" className="block text-sm text-gray-900">
                                                                            More Than
                                                                        </label>
                                                                        <div className="w-full">
                                                                            <Textbox
                                                                                value={validator.greater_than?.toString() || ""}
                                                                                onChange={(value) => updateRangeNumberField(validator_idx, "greater_than", value, selected_context_uuid || "")} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <Checkbox
                                                                        id={`switch_reference_field_${validator_idx}`}
                                                                        checked={validator.switch_reference_field ?? false}
                                                                        setChecked={(checked) => updateRangeNumberField(validator_idx, "switch_reference_field", checked, selected_context_uuid || "")}
                                                                    />
                                                                    <label htmlFor={`switch_reference_field_${validator_idx}`} className="ml-2 block text-sm text-gray-900">
                                                                        Apply the range on {field.name} field instead of {all_fields.find(f => f.uuid === validator.reference_number_field?.field_uuid)?.name || "reference number"} field
                                                                    </label>
                                                                </div>
                                                                <div className="mt-2 text-sm text-gray-600 italic">
                                                                    {field?.name && validator.reference_number_field && all_fields.find(f => f.uuid === validator.reference_number_field?.field_uuid)?.name && numberRangeFilterParametersExplanation(validator, field)}
                                                                </div>
                                                            </div>}

                                                            {validator.type === "formula" && <div className="w-full flex flex-col gap-y-2 font-mono">
                                                                <div className="w-full shadow border">
                                                                    <CodeMirror
                                                                        value={validator.formula || ""}
                                                                        height="200px"
                                                                        theme="light"
                                                                        extensions={[javascript()]}
                                                                        readOnly={!(is_org_admin || is_admin)}
                                                                        onBlur={async () => { await validateJSFormula(validator.formula ?? "", field?.name ?? "") }}
                                                                        onChange={(formula: string) => updateValidationFormula(validator_idx, formula)} />
                                                                </div>
                                                                <div className="w-full text-xs text-gray-400">
                                                                    <span className="font-bold pr-2">Output type:</span>
                                                                    <span className="font-mono">boolean | {"{ status: boolean, reason: string }"}</span>
                                                                </div>
                                                            </div>}

                                                        </div>)}
                                                </div>}

                                                {field?.conditions && <div className="my-6 flex flex-col gap-y-4">
                                                    {field.conditions.map((condition, condition_idx) => {
                                                        if (condition.type === "only_with") {
                                                            return (<div key={condition_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {(field?.postprocess?.length || 0) + (field?.validators?.length || 0) + condition_idx + 1}.&nbsp;
                                                                        Extract only when field available
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteCondition(condition_idx)} /></div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-600">
                                                                        Extract only when value of the following field is not empty
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={fields.map((field) => field.name)}
                                                                            ids={fields.map((field) => field.uuid)}
                                                                            selected={condition.field_uuid?.toString() || ""}
                                                                            onChange={(field_uuid: string) => updateCondition(condition_idx, "field_uuid", field_uuid)} />
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                        } else if (condition.type === "skip_if_match" || condition.type === "skip_if_not_match") {
                                                            return (<div key={condition_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {(field?.postprocess?.length || 0) + (field?.validators?.length || 0) + condition_idx + 1}.&nbsp;
                                                                        Skip row if value is {condition.type === "skip_if_match" ? "matched" : "not matched"}
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteCondition(condition_idx)} /></div>
                                                                </div>
                                                                <div className="w-full">
                                                                    <div className="flex items-center mb-2">
                                                                        <Checkbox
                                                                            id={`skip_if_match_toggle_${condition_idx}`}
                                                                            checked={condition.type === "skip_if_not_match"}
                                                                            setChecked={(value) => updateCondition(condition_idx, "type", value ? "skip_if_not_match" : "skip_if_match")}
                                                                        />
                                                                        <label htmlFor={`skip_if_match_toggle_${condition_idx}`} className="ml-2 block text-sm text-gray-900">
                                                                            Invert condition (skip if not matched)
                                                                        </label>
                                                                    </div>
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-600">
                                                                        Skip row if field value {condition.type === "skip_if_match" ? "matches" : "does not match"} the following value
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Textbox
                                                                            placeholder={condition.type === "skip_if_match" ? "Leave empty to skip when empty" : "Leave empty to skip when not empty"}
                                                                            value={condition.field_value || ""}
                                                                            onChange={(value: string) => updateCondition(condition_idx, "field_value", value)} />
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                        } else if (condition.type === "skip_if_includes" || condition.type === "skip_if_not_includes_any") {
                                                            return (<div key={condition_idx}>
                                                                <div className="flex flex-row items-center">
                                                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                                                        {(field?.postprocess?.length || 0) + (field?.validators?.length || 0) + condition_idx + 1}.&nbsp;
                                                                        Skip row if the field value {condition.type === "skip_if_includes" ? "includes" : "does not include"} any of the following values in the lookup table
                                                                    </div>
                                                                    <div className="grow" />
                                                                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteCondition(condition_idx)} /></div>
                                                                </div>
                                                                <div className="flex items-center mb-2">
                                                                    <Checkbox
                                                                        id={`skip_if_includes_toggle_${condition_idx}`}
                                                                        checked={condition.type === "skip_if_not_includes_any"}
                                                                        setChecked={(value) => updateCondition(condition_idx, "type", value ? "skip_if_not_includes_any" : "skip_if_includes")}
                                                                    />
                                                                    <label htmlFor={`skip_if_includes_toggle_${condition_idx}`} className="ml-2 block text-sm text-gray-900">
                                                                        Invert condition (skip if not includes any)
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                                                                        Lookup Table
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={lookup_tables_names}
                                                                            ids={lookup_tables_ids}
                                                                            selected={condition.range?.lookup_table_uuid || ""}
                                                                            onChange={(id: string) => updateConditionRangeTable(condition_idx, id)} />
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                                        Lookup Column
                                                                    </label>
                                                                    <div className="w-full">
                                                                        <Dropdown
                                                                            values={getLookupTableHeaderValues(condition.range?.lookup_table_uuid || "")}
                                                                            ids={getLookupTableHeaderIds(condition.range?.lookup_table_uuid || "")}
                                                                            selected={condition.range?.header_idx.toString() || "0"}
                                                                            onChange={(header_idx: string) => updateConditionRangeHeader(condition_idx, parseInt(header_idx, 10))} />
                                                                    </div>
                                                                </div>
                                                            </div>);
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>}
                                            </div>}

                                            {selected_tab === "fallback_sequence" && field?.compute?.fallback_sequence && <FallbackSequence
                                                field_idx={field_idx}
                                                field={field}
                                                fields={fields}
                                                prev_fields={prev_fields}
                                                lookup_tables={lookup_tables}
                                                fallback_sequence={field?.compute.fallback_sequence}
                                                setField={setField}
                                            />}

                                            {selected_tab === "formula" && <div>
                                                <div className="pt-4">
                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                        Result Datatype
                                                    </label>
                                                    <div className="w-full">
                                                        <Dropdown
                                                            values={["String", "Number", "Date"]}
                                                            ids={["string", "number", "date"]}
                                                            selected={field?.datatype || "string"}
                                                            onChange={(value) => updateFormulaFieldValue("datatype", value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="py-4">
                                                    <div className="my-2 sm:mt-0">
                                                        <div className="w-full shadow border">
                                                            <CodeMirror
                                                                value={field?.compute?.formula || ""}
                                                                height="500px"
                                                                theme="light"
                                                                extensions={[javascript()]}
                                                                readOnly={!(is_org_admin || is_admin)}
                                                                onChange={(value) => updateFormulaFieldValue("formula", value)}
                                                                onBlur={async () => { await validateJSFormula(field?.compute?.formula ?? "", field?.name ?? "") }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-2">
                                                    <label htmlFor="title" className="flex flex-col my-2 text-sm">
                                                        <span className="font-medium leading-6 text-gray-900">Available Fields</span>
                                                        <span className="text-sm text-gray-400">Can only refer to preceding fields</span>
                                                    </label>
                                                    <ul className="text-sm">
                                                        {fields.slice(0, field_idx).map((field, idx) => <Fragment key={idx}>
                                                            {field.code === undefined && <li key={idx} className="flex flex-row items-center">
                                                                <span className="text-gray-600"><code>{getExcelColumnName(idx)}</code></span>
                                                                <span className="mx-2">-</span>
                                                                <span className="text-gray-400">{field.name}</span>
                                                            </li>}
                                                            {field.code !== undefined && <li className="flex flex-row items-center">
                                                                <span className="text-gray-600"><code>${field.code}</code></span>
                                                                <span className="mx-2">-</span>
                                                                <span className="text-gray-400">{field.name}</span>
                                                            </li>}
                                                        </Fragment>)}
                                                        <li className="flex flex-row items-center">
                                                            <span className="text-gray-600"><code>INPUT_TEXT</code></span>
                                                            <span className="mx-2">-</span>
                                                            <span className="text-gray-400">Input document as string</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>}

                                            {selected_tab === "document_name" && <div>
                                                <div className="py-4 text-sm text-gray-500">
                                                    This field will be automatically populated with the document name.
                                                </div>
                                            </div>}

                                            {selected_tab === "metadata" && <div>
                                                <div className="py-4 text-sm text-gray-500">
                                                    <div className="py-2">
                                                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                            Select which metadata to populate this field with
                                                        </label>
                                                        <div className="my-2 sm:mt-0">
                                                            <div className="w-full">
                                                                <Dropdown
                                                                    values={["Document Name", "Processing Time", "Processing Date", "File Name(s)", "Number of Files", "Number of Pages", "Sender", "Constant"]}
                                                                    ids={["document_name", "extract_ts", "extract_date", "filename", "total_files", "total_pages", "sender", "constant"]}
                                                                    selected={field?.compute?.metadata?.type.toString() || "document_name"}
                                                                    onChange={(new_type: string) => updateMetadataFieldValue(new_type as ContextFieldComputeMetadataType)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {field?.compute?.metadata?.type === "constant" && (
                                                    <div className="my-2 flex gap-4">
                                                        <div className="w-1/3">
                                                            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                                                                Type
                                                            </label>
                                                            <Dropdown
                                                                values={["String", "Number"]}
                                                                ids={["string", "number"]}
                                                                selected={field?.datatype || "string"}
                                                                onChange={(type: string) => updateConstantType(type)}
                                                            />
                                                        </div>
                                                        <div className="w-2/3">
                                                            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                                                                Value
                                                            </label>
                                                            <input
                                                                type={field?.datatype === "number" ? "number" : "text"}
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                value={field?.compute?.raw_value_constant || ""}
                                                                onChange={(e) => updateConstantValue(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>}

                                            {selected_tab === "lookup_table_value" && <div>
                                                <div className="py-4 text-sm text-gray-500">
                                                    <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                                        Lookup Column
                                                    </label>
                                                    <div className="w-full">
                                                        <Dropdown
                                                            values={getLookupTableHeaderValues(extract_params?.lookup_table_filter?.lookup_table_uuid || "")}
                                                            ids={getLookupTableHeaderIds(extract_params?.lookup_table_filter?.lookup_table_uuid || "")}
                                                            selected={field?.lookup_table_value?.value_header_idx.toString() || "0"}
                                                            onChange={(header_idx: string) => updateLookupTableValue(parseInt(header_idx, 10))} />
                                                    </div>
                                                </div>
                                            </div>}

                                            {selected_tab === "details" && <div className="flex flex-col divide-y divide-gray-200">
                                                <div className="py-6 flex flex-col gap-y-2">
                                                    <label htmlFor="confirm_name" className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
                                                        Confirmation page name
                                                        <QuestionMarkCircleIcon
                                                            className="w-5 h-5 ml-1 text-gray-400"
                                                            data-tooltip-id="fields-table-tooltip-id"
                                                            data-tooltip-html={`<p class="pb-4 max-w-sm">Use different name when displaying results on the confirmation page. Useful when the field name is too technical or long.</p>`}
                                                        />
                                                    </label>
                                                    <Textbox
                                                        value={field?.confirm_name || ""}
                                                        placeholder="Leave empty to use the field name"
                                                        onChange={(value) => updateField("confirm_name", value)}
                                                    />
                                                    <div className="pt-4 flex flex-row items-center gap-x-2">
                                                        <Checkbox
                                                            id="skip_on_confirm"
                                                            checked={field?.skip_on_confirm ?? false}
                                                            setChecked={(value) => updateCheckbox("skip_on_confirm", value)}
                                                        />
                                                        <label htmlFor="skip_on_confirm" className="flex flex-row items-center font-medium text-sm text-gray-900">
                                                            Hide on confirmation page
                                                            <QuestionMarkCircleIcon
                                                                className="w-5 h-5 ml-1 text-gray-400"
                                                                data-tooltip-id="fields-table-tooltip-id"
                                                                data-tooltip-html={`<p class="pb-4 max-w-sm">Useful to hide fields that do not require confirmation on the confirmation page. By hiding the field, the user will not be distracted by fields that do not require confirmation.</p>`}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="py-6 flex flex-col gap-y-2">
                                                    <div className="flex flex-col gap-y-2">
                                                        <label htmlFor="range_filter" className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
                                                            Value Range
                                                            <QuestionMarkCircleIcon
                                                                className="w-5 h-5 ml-1 text-gray-400"
                                                                data-tooltip-id="fields-table-tooltip-id"
                                                                data-tooltip-html={`<p class="pb-4 max-w-sm">Providing value range from a lookup table will simplify UX on the confirmation page. User will have option to search and select value from the lookup table.</p>`}
                                                            />
                                                        </label>
                                                        <div className="grid grid-cols-2 gap-x-4">
                                                            <Dropdown
                                                                ids={["", ...lookup_tables_ids]}
                                                                values={["/", ...lookup_tables_names]}
                                                                selected={field?.range?.lookup_table_uuid || ""}
                                                                onChange={(value) => updateRange("lookup_table_uuid", value)}
                                                            />
                                                            <Dropdown
                                                                disabled={field?.range?.lookup_table_uuid === undefined}
                                                                ids={getLookupTableHeaderIds(field?.range?.lookup_table_uuid ?? "")}
                                                                values={getLookupTableHeaderValues(field?.range?.lookup_table_uuid ?? "")}
                                                                selected={field?.range?.header_idx.toString() || "0"}
                                                                onChange={(value) => updateRange("header_idx", value)}
                                                            />
                                                        </div>
                                                        {field?.range !== undefined && <Fragment>
                                                            <div className="pt-2 flex flex-row items-center">
                                                                <label className="flex-grow text-sm font-medium text-gray-700">Connected fields</label>
                                                                <DropdownMenu
                                                                    title="Add"
                                                                    align="right"
                                                                    items={fields.map((field) => ({
                                                                        title: field.name,
                                                                        onClick: () => { updateConnectedField("add", field.uuid, 0) }
                                                                    }))}
                                                                />
                                                            </div>
                                                            {field?.range && field.range.connected_fields.map(cf => <Fragment>
                                                                <div className="grid grid-cols-2 gap-x-4 items-center   ">
                                                                    <span className="text-sm">{fields.find(f => f.uuid === cf.field_uuid)?.name}</span>
                                                                    <div className="flex flex-row font-normal items-center gap-x-2">
                                                                        <Dropdown
                                                                            disabled={field?.range?.lookup_table_uuid === undefined}
                                                                            ids={getLookupTableHeaderIds(field?.range?.lookup_table_uuid ?? "")}
                                                                            values={getLookupTableHeaderValues(field?.range?.lookup_table_uuid ?? "")}
                                                                            selected={cf.header_idx.toString() || "0"}
                                                                            onChange={(value) => updateConnectedField("add", cf.field_uuid, parseInt(value, 10))}
                                                                        />
                                                                        <Button icon={TrashIcon} onClick={() => updateConnectedField("remove", cf.field_uuid)} />
                                                                    </div>
                                                                </div>
                                                            </Fragment>)}
                                                            <div className="pt-2 flex flex-row items-center">
                                                                <label className="flex-grow text-sm font-medium text-gray-700">Show additional columns</label>
                                                                <DropdownMenu
                                                                    title="Add"
                                                                    align="right"
                                                                    items={getRangePossibleDisplayHeaders(field?.range?.lookup_table_uuid)
                                                                        .map(({ title, idx }) => ({ title, onClick: () => { updateRange("add_display_header_idx", idx) } }))}
                                                                />
                                                            </div>
                                                            <MultiselectInputField
                                                                values={getRangeSelectedDisplayHeaders(field?.range?.lookup_table_uuid).map(({ title }) => title)}
                                                                placeholder="Select additional columns to display"
                                                                separator="space"
                                                                color="sky"
                                                                onRemove={(idx) => updateRange("remove_display_header_idx", idx)}
                                                                onClick={(idx) => updateRange("remove_display_header_idx", idx)}
                                                            />
                                                        </Fragment>}
                                                    </div>
                                                </div>
                                                {field?.type === "extract" && field?.datatype === "date" && <div className="py-6 flex flex-col gap-y-2">
                                                    <label htmlFor="day_month_year_order" className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900">
                                                        Expected Day-Month-Year Order in Input Text
                                                        <QuestionMarkCircleIcon
                                                            className="w-5 h-5 ml-1 text-gray-400"
                                                            data-tooltip-id="fields-table-tooltip-id"
                                                            data-tooltip-html={`<p class="pb-4 max-w-sm">Use this option to specify the expected day-month-year order in the input text. For example, if the input text follows the US format, such as “12/31/2024”, you should select “Month-Day-Year”. For documents using the European format, such as “31.12.2024”, you should select “Day-Month-Year. Use the “Autodetect” option to let the system automatically infer the correct order.</p>`}
                                                        />
                                                    </label>
                                                    <Dropdown
                                                        id="day_month_year_order"
                                                        values={["Day-Month-Year", "Month-Day-Year", "Year-Month-Day", "Autodetect"]}
                                                        ids={["dmy", "mdy", "ymd", "autodetect"]}
                                                        selected={field?.extract?.date_data?.day_month_year_order || "autodetect"}
                                                        onChange={(new_order: string) => updateField("date_data", {
                                                            input_format_variants: field?.extract?.date_data?.input_format_variants ?? [],
                                                            day_month_year_order: (new_order === "autodetect") ? undefined : (new_order as "dmy" | "mdy" | "ymd")
                                                        })}
                                                    />
                                                </div>}
                                                {field?.type === "extract" && <div className="py-6 flex flex-col gap-y-2">
                                                    <label htmlFor="translate" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Translate to
                                                    </label>
                                                    <Dropdown
                                                        ids={["", ...languages]}
                                                        values={["[No translation]", ...languages]}
                                                        selected={field?.extract?.translate || ""}
                                                        onChange={(value) => updateField("translate", value)}
                                                    />
                                                    <div className="pt-4 flex flex-row items-center gap-x-2">
                                                        <Checkbox
                                                            id="prioritize_handwritten"
                                                            checked={field?.extract?.prioritize_handwritten ?? false}
                                                            setChecked={(value) => updateCheckbox("prioritize_handwritten", value)}
                                                        />
                                                        <label htmlFor="prioritize_handwritten" className="flex flex-row items-center text-sm font-medium text-gray-900">
                                                            Prioritize handwritten over printed text
                                                            <QuestionMarkCircleIcon
                                                                className="w-5 h-5 ml-1 text-gray-400"
                                                                data-tooltip-id="fields-table-tooltip-id"
                                                                data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have a mix of handwritten and printed values for the same field and you want extraction of this field to prioritize handwritten text over printed text when both are present.</p>`}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>}
                                                {(is_org_admin || is_admin) && <div className="py-6 flex flex-col gap-y-2">
                                                    <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                                        Code (admin setting)
                                                    </label>
                                                    <div className="font-mono">
                                                        <Textbox
                                                            value={field?.code || ""}
                                                            placeholder="Must start with letter; only letters, numbers, and underscores allowed"
                                                            onChange={(value) => updateField("code", value)}
                                                        />
                                                    </div>
                                                </div>}
                                            </div>}
                                            {selected_tab === "admin" && <div className="py-4">
                                                <div className="w-full shadow border">
                                                    <CodeMirror
                                                        value={JSON.stringify(field, null, 2)}
                                                        height="500px"
                                                        theme="light"
                                                        extensions={[javascript()]}
                                                        onChange={(value) => {
                                                            try {
                                                                const parsed = JSON.parse(value);
                                                                setField(field_idx, parsed);
                                                                setIsValidAdminJSON(true);
                                                            } catch (e) {
                                                                console.error("Invalid JSON", e);
                                                                setIsValidAdminJSON(false);
                                                            }
                                                        }} />
                                                </div>
                                                <div className={`mt-2 text-sm ${is_valid_admin_json ? "text-mint-600" : "text-torch_red-600"}`}>
                                                    {is_valid_admin_json ? "VALID JSON" : "INVALID JSON"}
                                                </div>
                                            </div>}
                                            <div className="px-4 mt-4 py-4 border-t border-gray-200 flex justify-end">
                                                <Button text="Close" onClick={() => setOpen(false)} />
                                            </div>
                                        </div>
                                        <TextboxModal
                                            open={new_field_dialog !== undefined}
                                            title={getNewFieldDialogTitle()}
                                            init_text={new_field_dialog_init_text}
                                            cancel="Cancel"
                                            confirm={new_field_dialog_idx !== undefined ? "Update" : "Add"}
                                            validate={(text) => text.length > 0}
                                            onClose={onNewFieldDialogClose} />
                                        <Tooltip id="fields-table-tooltip-id" />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}

type FieldsTableProps = {
    fields: t.IContextField[];
    prev_fields: IPrevField[];
    context_type: t.ContextType;
    extract_params?: t.IExtractParams;
    records?: t.IRecord[];
    disabled?: boolean;
    is_editable?: boolean;
    show_delete?: boolean;
    show_settings?: boolean;
    focus_on_col?: number;
    is_demo?: boolean;
    lookup_tables?: t.ILookupTableBase[];
    contexts?: { uuid: string, name: string, type: t.ContextType }[];
    selected_context_uuid?: string;
    indicate_array?: boolean;
    template_org_uuid: string;
    setFields?: (fields: t.IContextField[]) => Promise<boolean>;
    setInvalidate?: () => void;
    setFieldContext?: (field_uuid: string, context_uuid: string) => void;
}

export function FieldsTable(props: FieldsTableProps) {
    const {
        fields: init_fields,
        prev_fields,
        context_type,
        extract_params,
        records,
        disabled,
        is_editable,
        show_delete,
        show_settings,
        focus_on_col,
        is_demo,
        lookup_tables,
        contexts,
        selected_context_uuid,
        indicate_array,
        template_org_uuid,
        setFields: setInitFields,
        setInvalidate,
        setFieldContext
    } = props;
    // add extract objects to fields of type "extract" if missing
    for (const field of init_fields) {
        if (field.type === "extract") {
            field.extract = field.extract ?? {};
        }
    }

    const [fields, setFields] = useState<t.IContextField[]>(init_fields || []);
    const [new_name, setNewName] = useState<string | undefined>(undefined);
    const [field_refs, setFieldRefs] = useState<RefObject<HTMLTableCellElement>[]>(fields.map(() => createRef()));
    const [current_field_idx, setCurrentFieldIdx] = useState<number>(-1);
    const [is_field_settings_open, setIsFieldSettingsOpen] = useState<boolean>(false);
    const [field_settings_idx, setFieldSettingsIdx] = useState<number>(-1);

    const is_array = context_type === CONTEXT_TYPES.array;
    const is_object = context_type === CONTEXT_TYPES.object;
    const is_lookup_table = context_type === CONTEXT_TYPES.lookup_table;

    useEffect(() => {
        setFields(init_fields);
    }, [init_fields]);

    useEffect(() => {
        setFieldRefs(fields.map(() => createRef()));
    }, [fields, fields.length]);

    useEffect(() => {
        if (current_field_idx !== -1 && field_refs[current_field_idx]) {
            const element = field_refs[current_field_idx]?.current;
            if (element) {
                element.focus();
                window.getSelection()?.selectAllChildren(element);
                window.getSelection()?.collapseToEnd();
            }
        }
    }, [fields, fields.length, field_refs, field_refs.length, current_field_idx]);

    useEffect(() => {
        if (focus_on_col !== undefined && focus_on_col !== -1) {
            setCurrentFieldIdx(focus_on_col);
        }
    }, [focus_on_col]);

    const update = async (new_fields: t.IContextField[]) => {
        // check if we can do the change
        if (setInitFields) {
            const can_change = await setInitFields(new_fields);
            if (!can_change) { return; }
        }
        setFields(new_fields);
        if (setInvalidate) { setInvalidate(); }
    };

    const handleBlur = (e: any, idx: number) => {
        setCurrentFieldIdx(-1);
        const new_fields = [...fields];
        if (new_name !== undefined && new_fields[idx].name !== new_name) {
            new_fields[idx].name = new_name;
            setNewName(undefined);
            update(new_fields);
        }
    };

    const handleInput = (e: any, idx: number) => {
        setNewName(e.target.innerText);
        if (setInvalidate) { setInvalidate(); }
    };

    const handleKeyDown = (e: any, idx: number) => {
        if (idx === fields.length - 1) {
            if (e.key === "Tab" && !e.shiftKey) {
                e.preventDefault();
                addField();
            } else if (e.key === "Enter") {
                e.preventDefault();
                addField();
            }
        }
    }

    const handleFocus = (e: any, idx: number) => {
        setCurrentFieldIdx(idx);
    }

    const addField = () => {
        if (!disabled) {
            const new_fields = [...fields];
            if (is_lookup_table) {
                new_fields.push({ uuid: newUuid(), name: "", datatype: "string", type: "lookup_table_value", lookup_table_value: { value_header_idx: 0 } });
            } else {
                new_fields.push({ uuid: newUuid(), name: "", datatype: "string", type: "extract" });
            }
            update(new_fields);
            setCurrentFieldIdx(new_fields.length - 1);
        }
    };

    const updateField = (field_idx: number, field: t.IContextField) => {
        const new_fields = [...fields];
        new_fields[field_idx] = field;
        update(new_fields);
    };

    const moveField = (curr_idx: number, diff_idx: number) => {
        const field = fields[curr_idx];
        const new_field_idx = curr_idx + diff_idx;
        const new_fields = [
            ...fields.slice(0, curr_idx),
            ...fields.slice(curr_idx + 1)
        ];
        new_fields.splice(new_field_idx, 0, field);
        update(new_fields);
        setFieldSettingsIdx(new_field_idx);
    }

    const deleteField = (field_idx: number) => {
        setIsFieldSettingsOpen(false);
        const new_fields = [...fields];
        new_fields.splice(field_idx, 1);
        update(new_fields);
    }

    const field_col_span = 1 + (show_settings ? 1 : 0) + (show_delete ? 1 : 0);
    const record = is_object && records && records.length > 0 ? records[0].val : undefined;

    return <Fragment>

        {(is_array || is_lookup_table) && <table className="py-4 text-xs md:text-base">
            <thead>
                <tr>
                    <th className="w-5 bg-gray-100 border-t border-b border-l border-gray-300"></th>
                    {fields.map((field, idx) =>
                        <Fragment key={idx}>
                            <th key={idx}
                                className={classNames("py-1 px-4 border-t border-b border-l border-gray-400 cursor-text hover:bg-space_blue-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-space_blue-500",
                                    (is_demo === true ? "min-w-[80px] max-w-[150px]" : "min-w-[150px] max-w-[250px]"),
                                    (is_field_settings_open && idx === field_settings_idx) ? "bg-space_blue-100 border-space_blue-500" : "bg-gray-100 border-gray-400",
                                    (field.type === "compute" ? "italic" : ""),
                                    (field.name.length === 0 && current_field_idx !== idx) ? "text-gray-400" : "text-gray-900",
                                    (!show_delete && !show_settings) ? "border-r" : ""
                                )}
                                contentEditable={disabled !== true && is_editable === true}
                                onBlur={e => handleBlur(e, idx)}
                                onInput={e => handleInput(e, idx)}
                                onFocus={e => handleFocus(e, idx)}
                                onKeyDown={e => handleKeyDown(e, idx)}
                                ref={field_refs[idx]}
                                dangerouslySetInnerHTML={{ __html: (field.name.length === 0 && current_field_idx !== idx) ? "click to edit" : field.name }}
                            />
                            {show_delete && <th key={idx + fields.length}
                                className="py-1 px-2 bg-gray-100 text-gray-400 border border-gray-400 cursor-pointer hover:bg-space_blue-300 hover:text-white w-4"
                                onClick={() => { deleteField(idx); }}
                            >
                                <MinusIcon className="h-4 w-4 " />
                            </th>}
                            {show_settings && <th key={idx + fields.length}
                                className={classNames(
                                    "py-1 px-2 bg-gray-200 text-gray-400 border-t border-b border-r border-gray-400  hover:text-white w-4",
                                    (is_field_settings_open && idx === field_settings_idx) ? "bg-sea_blue-300 border-space_blue-500 text-white" : "text-gray-400 border-gray-400",
                                    disabled ? "cursor-not-allowed" : "cursor-pointer hover:bg-sea_blue-300"
                                )}
                                onClick={() => { if (!disabled) { setFieldSettingsIdx(idx); setIsFieldSettingsOpen(true); } }}
                            >
                                <Cog6ToothIcon className="h-4 w-4 " />
                            </th>}
                        </Fragment>)}
                    {is_editable && <th className="py-1 px-4 bg-gray-50 hover:bg-sea_blue-300 border border-gray-300 cursor-pointer" onClick={addField}>+</th>}
                </tr>
            </thead>
            <tbody className="text-sm text-gray-600">
                {records && records.map(({ val: record }, idx) => <tr key={idx}>
                    <td className="w-5 py-1 px-4 bg-gray-100 border border-gray-300 text-center font-semibold align-top">{idx + 1}</td>
                    {fields.map((field, idx) => <td key={idx} colSpan={field_col_span} className="py-1 px-4 border text-left text-sm align-top">{record[field.name]}</td>)}
                    {is_editable && <td className="py-1 px-4 border border-dashed"></td>}
                </tr>)}
                {(records === undefined || indicate_array === true) && <tr>
                    <td className="w-5 py-1 px-4 bg-gray-50 border border-gray-300">...</td>
                    {fields.map((_field, idx) => <td key={idx} colSpan={field_col_span} className="h-8 py-1 px-4 border border-dashed"></td>)}
                    {is_editable && <td className="py-1 px-4 border border-dashed"></td>}
                </tr>}
            </tbody>
        </table>}

        {is_object && <table className="py-4 text-xs md:text-base">
            <tbody className="">
                {fields.map((field, idx) =>
                    <tr key={idx}>
                        <td key={idx}
                            className={classNames(
                                "py-1 px-4  border-l border-t border-b  cursor-text hover:bg-space_blue-100 text-left text-sm font-normal align-top focus:ring-1 focus:ring-space_blue-500 w-[250px]",
                                (is_field_settings_open && idx === field_settings_idx) ? "bg-space_blue-100 border-space_blue-500" : "bg-gray-100 border-gray-400",
                                (field.type === "compute" ? "italic" : ""),
                                (field.name.length === 0 && current_field_idx !== idx) ? "text-gray-400" : "text-gray-900",
                                (!show_delete && !show_settings) ? "border-r" : "")}
                            contentEditable={disabled !== true && is_editable === true}
                            onBlur={e => handleBlur(e, idx)}
                            onInput={e => handleInput(e, idx)}
                            onFocus={e => handleFocus(e, idx)}
                            onKeyDown={e => handleKeyDown(e, idx)}
                            ref={field_refs[idx]}
                            dangerouslySetInnerHTML={{ __html: (field.name.length === 0 && current_field_idx !== idx) ? "click to edit" : field.name }}
                        />
                        {show_delete && <td key={idx + fields.length}
                            className="py-1 px-2 bg-gray-100 text-gray-400 border border-gray-400 cursor-pointer hover:bg-space_blue-300 hover:text-white w-4"
                            onClick={() => { deleteField(idx); }}
                        >
                            <MinusIcon className="h-4 w-4 " />
                        </td>}
                        {show_settings && <td key={idx + fields.length}
                            className={classNames(
                                "py-1 px-2 bg-gray-200 border-t border-b border-r border-gray-400  hover:text-white w-4",
                                (is_field_settings_open && idx === field_settings_idx) ? "bg-sea_blue-300 border-space_blue-500 text-white" : "text-gray-400 border-gray-400",
                                disabled ? "cursor-not-allowed" : "cursor-pointer hover:bg-sea_blue-300"
                            )}
                            onClick={() => { if (!disabled) { setFieldSettingsIdx(idx); setIsFieldSettingsOpen(true); } }}
                        >
                            <Cog6ToothIcon className="h-4 w-4 " />
                        </td>}
                        {record && <td key={idx} colSpan={field_col_span} className="py-1 px-4 border text-left text-sm align-top">{record[field.name]}</td>}
                        {records === undefined && <td className="py-1 px-4 border border-dashed w-[200px]"></td>}
                    </tr>)}
                {is_editable && <tr><td className="py-1 px-4 bg-gray-50 hover:bg-sea_blue-300 text-center border border-gray-300 cursor-pointer" colSpan={2} onClick={addField}>+</td></tr>}
            </tbody>
        </table>}

        {is_editable && <FieldSettings
            type="edit"
            open={is_field_settings_open}
            field_idx={field_settings_idx}
            fields_count={fields.length}
            field={fields[field_settings_idx]}
            fields={fields}
            prev_fields={prev_fields}
            context_type={context_type}
            extract_params={extract_params}
            lookup_tables={lookup_tables || []}
            contexts={contexts}
            selected_context_uuid={selected_context_uuid}
            template_org_uuid={template_org_uuid}
            setOpen={setIsFieldSettingsOpen}
            setField={updateField}
            moveField={moveField}
            deleteField={deleteField}
            setFieldContext={setFieldContext} />}
    </Fragment>;
}

type HierarchicalFieldsTableProps = {
    fields: t.IContextField[];
}

export function HierarchicalFieldsTable(props: HierarchicalFieldsTableProps) {
    const { fields } = props;

    const { example } = getHierarchicalContextExample(fields);

    return <div className="">
        <div className="pb-2 text-base text-gray-900 ">
            Template Schema
        </div>
        <HierarchicalRecord val={example} input_documents={[]} show_all={true} />
    </div >;
}