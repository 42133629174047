import { Fragment, useEffect, useState, useCallback } from "react";
import { TrashIcon, ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { PageFilters } from "./PageFilters";
import { IEndpointTrigger, IContextBase, IEndpointBase, IEndpoint } from "../lib/types";
import { BackendObj } from "../lib/backend";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";
import { USER_ROLES } from "../lib/consts";

interface EndpointTriggersProps {
    triggers: IEndpointTrigger[];
    template_contexts: IContextBase[];
    onChange: (triggers: IEndpointTrigger[]) => void;
    isDisabled?: boolean;
}

export function EndpointTriggers({
    triggers,
    template_contexts, // source endpoint template
    onChange,
    isDisabled = false
}: EndpointTriggersProps) {
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [available_endpoints, set_available_endpoints] = useState<IEndpointBase[]>([]);
    const [target_endpoints, set_target_endpoints] = useState<Record<string, IEndpoint>>({});
    const [loading_endpoint_uuids, set_loading_endpoint_uuids] = useState<Set<string>>(new Set());

    useEffect(() => {
        BackendObj.extractions.listEndpoints({})
            .then(({ endpoints }) => { set_available_endpoints(endpoints); })
            .catch(() => { set_available_endpoints([]); });
    }, []);

    const loadTargetEndpoint = useCallback(async (endpoint_uuid: string) => {
        if (!endpoint_uuid || loading_endpoint_uuids.has(endpoint_uuid) || target_endpoints[endpoint_uuid]) {
            return;
        }

        set_loading_endpoint_uuids(prev => new Set([...prev, endpoint_uuid]));
        try {
            const { endpoint } = await BackendObj.extractions.getEndpoint({ endpoint_uuid });
            if (endpoint) {
                set_target_endpoints(prev => ({
                    ...prev,
                    [endpoint_uuid]: endpoint
                }));
            }
        } catch (error) {
            console.error("Failed to load target endpoint:", error);
        }
        set_loading_endpoint_uuids(prev => {
            const new_set = new Set(prev);
            new_set.delete(endpoint_uuid);
            return new_set;
        });
    }, [loading_endpoint_uuids, target_endpoints]);

    // Load all target endpoints when triggers change
    useEffect(() => {
        // Load all target endpoints that have a valid UUID
        triggers.forEach(trigger => {
            if (trigger.endpoint_uuid) {
                loadTargetEndpoint(trigger.endpoint_uuid);
            }
        });
    }, [triggers, loadTargetEndpoint]);

    const getStringFields = () => {
        return template_contexts
            .filter(ctx => ctx.type === "object")
            .flatMap(ctx => ctx.fields)
            .filter(field => field.datatype === "string");
    };

    const getObjectFields = (contexts: IContextBase[]) => {
        return contexts.filter(ctx => ctx.type === "object").flatMap(ctx => ctx.fields);
    };

    const addTrigger = (type: "simple" | "regex") => {
        const new_trigger: IEndpointTrigger = type === "simple" ? {
            endpoint_uuid: "",
            page_filters: { filters: [], invert: false },
            type,
            max_chain_length: 0,
            max_target_revisit: 0,
            field_overrides: [],
        } : {
            endpoint_uuid: "",
            page_filters: { filters: [], invert: false },
            type,
            max_chain_length: 0,
            max_target_revisit: 0,
            field_overrides: [],
            field_uuid: getStringFields()[0]?.uuid || "",
            regex: ""
        };
        onChange([...triggers, new_trigger]);
    };

    const removeTrigger = (idx: number) => {
        const new_triggers = [...triggers];
        new_triggers.splice(idx, 1);
        onChange(new_triggers);
    };

    const moveTrigger = (idx: number, direction: "up" | "down") => {
        if ((direction === "up" && idx === 0) ||
            (direction === "down" && idx === triggers.length - 1)) {
            return;
        }
        const new_triggers = [...triggers];
        const swap_idx = direction === "up" ? idx - 1 : idx + 1;
        [new_triggers[idx], new_triggers[swap_idx]] = [new_triggers[swap_idx], new_triggers[idx]];
        onChange(new_triggers);
    };

    const updateTrigger = (idx: number, updates: Partial<IEndpointTrigger>) => {
        const new_triggers = [...triggers];
        new_triggers[idx] = { ...new_triggers[idx], ...updates } as IEndpointTrigger;
        onChange(new_triggers);
    };

    return (
        <div className="space-y-4">
            {triggers.map((trigger, idx) => (
                <div key={idx} className="border rounded-lg p-4 space-y-4">
                    <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">
                            {trigger.type === "simple" ? "Trigger" : "Regex Trigger"}
                        </h3>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => moveTrigger(idx, "up")}
                                disabled={isDisabled || idx === 0}
                                className="p-1 hover:bg-gray-100 rounded"
                            >
                                <ArrowUpIcon className="h-5 w-5" />
                            </button>
                            <button
                                onClick={() => moveTrigger(idx, "down")}
                                disabled={isDisabled || idx === triggers.length - 1}
                                className="p-1 hover:bg-gray-100 rounded"
                            >
                                <ArrowDownIcon className="h-5 w-5" />
                            </button>
                            <button
                                onClick={() => removeTrigger(idx)}
                                disabled={isDisabled}
                                className="p-1 hover:bg-gray-100 rounded"
                            >
                                <TrashIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className="flex space-x-4">
                            <div className="w-1/2 space-y-2">
                                <label className="block text-sm font-medium text-gray-900">
                                    Target
                                </label>
                                <div className="flex items-center">
                                    <select
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                        value={trigger.endpoint_uuid}
                                        onChange={(e) => {
                                            const new_uuid = e.target.value;
                                            updateTrigger(idx, { endpoint_uuid: new_uuid });
                                            if (new_uuid) {
                                                loadTargetEndpoint(new_uuid);
                                            }
                                        }}
                                        disabled={isDisabled}
                                    >
                                        <option value="">Select an endpoint</option>
                                        {available_endpoints.map((endpoint) => (
                                            <option key={endpoint.uuid} value={endpoint.uuid}>
                                                {endpoint.name}
                                            </option>
                                        ))}
                                    </select>
                                    {loading_endpoint_uuids.has(trigger.endpoint_uuid) && (
                                        <div className="ml-2 animate-spin h-4 w-4 border-2 border-sea_blue-600 rounded-full border-t-transparent"></div>
                                    )}
                                </div>
                            </div>

                            {is_admin && <div className="w-1/2 space-y-2">
                                <label className="block text-sm font-medium text-gray-400">
                                    Target UUID
                                </label>
                                <input
                                    type="text"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                    value={trigger.endpoint_uuid}
                                    onChange={(e) => updateTrigger(idx, { endpoint_uuid: e.target.value })}
                                    placeholder="Or paste endpoint UUID"
                                    disabled={isDisabled}
                                />
                            </div>}
                        </div>

                        {trigger.type === "regex" && (
                            <Fragment>
                                <div className="flex space-x-4">
                                    <div className="w-1/2 space-y-2">
                                        <label className="block text-sm font-medium text-gray-900">
                                            Field for Regex
                                        </label>
                                        <select
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                            value={trigger.field_uuid}
                                            onChange={(e) => updateTrigger(idx, { field_uuid: e.target.value })}
                                            disabled={isDisabled}
                                        >
                                            <option value="">Select a field</option>
                                            {getStringFields().map((field) => (
                                                <option key={field.uuid} value={field.uuid}>
                                                    {field.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="w-1/2 space-y-2">
                                        <label className="block text-sm font-medium text-gray-900">
                                            Regex Pattern
                                        </label>
                                        <input
                                            type="text"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                            value={trigger.regex}
                                            onChange={(e) => updateTrigger(idx, { regex: e.target.value })}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        {is_admin && <div className="flex space-x-4">
                            <div className="w-1/2 space-y-2">
                                <label className="block text-sm font-medium text-gray-400">
                                    Max Chain Length
                                </label>
                                <input
                                    type="number"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                    value={trigger.max_chain_length}
                                    onChange={(e) => updateTrigger(idx, { max_chain_length: Math.max(parseInt(e.target.value) || 0, 0) })}
                                    placeholder="Unlimited chain length"
                                    disabled={isDisabled}
                                />
                            </div>

                            <div className="w-1/2 space-y-2">
                                <label className="block text-sm font-medium text-gray-400">
                                    Max Target Revisits
                                </label>
                                <input
                                    type="number"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                    value={trigger.max_target_revisit}
                                    onChange={(e) => updateTrigger(idx, { max_target_revisit: Math.max(parseInt(e.target.value) || 0, 0) })}
                                    placeholder="Revisits of target not limited"
                                    disabled={isDisabled}
                                />
                            </div>
                        </div>}

                        {template_contexts.find(ctx => ctx.type === "classifier") !== undefined && <div className="space-y-2">
                            {trigger.page_filters && (
                                <PageFilters
                                    classifiers={template_contexts.find(ctx => ctx.type === "classifier")?.extract_params.classifiers ?? []}
                                    input_page_filters={trigger.page_filters}
                                    onChange={(filters) => updateTrigger(idx, { page_filters: filters })}
                                    hide_required_nonempty={true}
                                    isDisabled={isDisabled}
                                />
                            )}
                        </div>}

                        {/* Field Overrides section */}
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-900">
                                Field Overrides
                            </label>

                            {(trigger.field_overrides?.length ?? 0) > 0 && (
                                <div className="flex space-x-4 items-center">
                                    <div className="w-1/2">
                                        <label className="block text-sm font-medium text-gray-900">
                                            Source Field
                                        </label>
                                    </div>
                                    <div className="w-1/2 flex items-center">
                                        <label className="block text-sm font-medium text-gray-900">
                                            Target Field
                                        </label>
                                    </div>
                                </div>
                            )}

                            {trigger.field_overrides?.map((override, override_idx) => (
                                <div key={override_idx} className="flex space-x-4 items-center">
                                    <div className="w-1/2">
                                        <Dropdown
                                            values={getObjectFields(template_contexts).map(f => f.name)}
                                            ids={getObjectFields(template_contexts).map(f => f.uuid)}
                                            selected={override.source_field_uuid}
                                            onChange={(value) => {
                                                const new_overrides = [...(trigger.field_overrides || [])];
                                                new_overrides[override_idx].source_field_uuid = value;
                                                updateTrigger(idx, { field_overrides: new_overrides });
                                            }}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div className="w-1/2 flex items-center">
                                        <Dropdown
                                            values={getObjectFields(target_endpoints[trigger.endpoint_uuid]?.template.contexts ?? []).map(f => f.name)}
                                            ids={getObjectFields(target_endpoints[trigger.endpoint_uuid]?.template.contexts ?? []).map(f => f.uuid)}
                                            selected={override.target_field_uuid}
                                            onChange={(value) => {
                                                const new_overrides = [...(trigger.field_overrides || [])];
                                                new_overrides[override_idx].target_field_uuid = value;
                                                updateTrigger(idx, { field_overrides: new_overrides });
                                            }}
                                            disabled={isDisabled || loading_endpoint_uuids.has(trigger.endpoint_uuid)}
                                        />
                                        {loading_endpoint_uuids.has(trigger.endpoint_uuid) && (
                                            <div className="ml-2 animate-spin h-4 w-4 border-2 border-sea_blue-600 rounded-full border-t-transparent"></div>
                                        )}
                                        <button
                                            onClick={() => {
                                                const new_overrides = [...(trigger.field_overrides || [])];
                                                new_overrides.splice(override_idx, 1);
                                                updateTrigger(idx, { field_overrides: new_overrides });
                                            }}
                                            disabled={isDisabled}
                                            className="p-1 hover:bg-gray-100 rounded"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <Button
                                text="Add Field Override"
                                onClick={() => {
                                    const new_overrides = [...(trigger.field_overrides || [])];
                                    new_overrides.push({
                                        source_field_uuid: getObjectFields(template_contexts)[0]?.uuid || "",
                                        target_field_uuid: getObjectFields(target_endpoints[trigger.endpoint_uuid]?.template.contexts ?? [])[0]?.uuid || ""
                                    });
                                    updateTrigger(idx, { field_overrides: new_overrides });
                                }}
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className="flex space-x-2 pb-4">
                <Button
                    text="Add Trigger"
                    onClick={() => addTrigger("simple")}
                    disabled={isDisabled}
                />
                <Button
                    text="Add Regex Trigger"
                    onClick={() => addTrigger("regex")}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
}