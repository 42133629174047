import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    FaceSmileIcon,
    PlusIcon
} from "@heroicons/react/24/outline";

import { classNames } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { IItemSlim } from "../lib/types";
import { BackendObj } from "../lib/backend";

import { Button } from "../components/Button";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { MultiselectInputField } from "../components/MultiselectInputField";
import { DropdownMenu, IDropdownMenuItem } from "../components/DropdownMenu";
import { TextboxModal } from "../components/TextboxModal";
import { ItemList } from "./Items";

interface IFilter {
    type: "name" | "endpoint";
    value?: string;
    uuid?: string;
}

export function Tasks() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [limit, setLimit] = useState(100);
    const [confirm_items, setConfirmItems] = useState<IItemSlim[] | undefined>(undefined);
    const [filtered_confirm_items, setFilteredConfirmItems] = useState<IItemSlim[] | undefined>(undefined);
    const [is_loading_more, setIsLoadingMore] = useState(false);
    const [no_more_items, setNoMoreItems] = useState(false);
    const [filters, setFilters] = useState<IFilter[]>([]);
    const [is_filter_dialog_open, setIsFilterDialogOpen] = useState(false);
    const [endpoint_map, setEndpointMap] = useState<Map<string, { uuid: string, name: string }>>(new Map());
    useEffect(() => {
        BackendObj.extractions.getOpenExtractConfirmations({ limit: `${limit}` }).then(({ confirm_items: new_items }) => {
            setConfirmItems(new_items);
            setNoMoreItems(new_items.length < limit);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const increaseLimit = async () => {
        const inc_limit = limit + 100;
        setIsLoadingMore(true);
        const { confirm_items: new_items } = await BackendObj.extractions.getOpenExtractConfirmations({ limit: `${inc_limit}` });
        setConfirmItems(new_items);
        setLimit(inc_limit);
        setNoMoreItems(new_items.length < inc_limit);
        setIsLoadingMore(false);
    };

    useEffect(() => {
        if (confirm_items !== undefined) {
            let new_filtered_items = filters.length > 0 ? confirm_items.filter(item => {
                // all filters must be true
                for (const filter of filters) {
                    if (filter.type === "name" && filter.value !== undefined) {
                        if (!item.name.toLowerCase().includes(filter.value.toLowerCase())) {
                            return false;
                        }
                    }
                    if (filter.type === "endpoint" && filter.uuid !== undefined) {
                        if (item.endpoint_uuid !== filter.uuid) {
                            return false;
                        }
                    }
                }
                return true;
            }) : confirm_items;
            setFilteredConfirmItems(new_filtered_items);
            // if filtered items is less than limit and there are more items, increase limit
            if (new_filtered_items.length < limit && !no_more_items) {
                increaseLimit();
            }

            // get list of endpoints, sort by name
            // NOTE: template_name is actually the name of the endpoint, should be updated in backend
            // Apply all filters except endpoint filter to get the endpoint list
            const items_for_endpoint_menu = confirm_items.filter(item => {
                for (const filter of filters) {
                    if (filter.type === "endpoint") {
                        continue; // Skip endpoint filter
                    }
                    if (filter.type === "name" && filter.value !== undefined) {
                        if (!item.name.toLowerCase().includes(filter.value.toLowerCase())) {
                            return false;
                        }
                    }
                }
                return true;
            });

            // Build endpoint map from filtered items (excluding endpoint filter)
            const new_endpoint_map = new Map<string, { uuid: string, name: string }>();
            for (const item of items_for_endpoint_menu) {
                if (item.endpoint_uuid !== undefined) {
                    new_endpoint_map.set(item.endpoint_uuid, { uuid: item.endpoint_uuid, name: item.template_name });
                }
            }
            setEndpointMap(new_endpoint_map);
        }
    }, [filters, confirm_items]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        // Check if scrolled to bottom (with a small threshold)
        if (scrollHeight - scrollTop - clientHeight < 50 && !no_more_items) {
            const inc_limit = limit + 100;
            setIsLoadingMore(true);
            const { confirm_items: new_items } = await BackendObj.extractions.getOpenExtractConfirmations({ limit: `${inc_limit}` });
            setConfirmItems(new_items);
            setLimit(inc_limit);
            setNoMoreItems(new_items.length < inc_limit);
            setIsLoadingMore(false);
        }
    };

    const addFilter = (filter: IFilter) => {
        if (filter.type === "endpoint") {
            // remove existing filter of same type and add new one in a single update
            setFilters([
                ...filters.filter((f) => f.type !== filter.type),
                filter
            ]);
        } else {
            setFilters([...filters, filter]);
        }
    }

    const removeFilter = (idx: number) => {
        setFilters(filters.filter((_, i) => i !== idx));
    }

    const onFilterDialogClose = (result: boolean, input_text?: string) => {
        if (result) {
            addFilter({ type: "name", value: input_text });
        }
        setIsFilterDialogOpen(false);
    }

    const onItemSelected = (item: IItemSlim) => {
        navigate(`/confirm/${item.uuid}`);
    }

    if (confirm_items === undefined || filtered_confirm_items === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const endpoint_menu_items: IDropdownMenuItem[] = Array.from(endpoint_map.values())
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ uuid, name }) => ({
            title: name,
            onClick: () => addFilter({ type: "endpoint", value: name, uuid }),
        }));

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}
        onScroll={handleScroll}>
        <div className="h-16 w-full bg-white border-b border-b-sea_blue-700">
            <div className="px-10 py-4 flex flex-row items-center max-w-5xl">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                    TODOs
                </h2>
                <div className="flex-grow" />
                <Button highlight={true} icon={PlusIcon} text="New Job" href="/extraction/new" />
            </div>
        </div>
        <div className="max-w-5xl">
            <div className="p-10 py-4 pb-0">
                <div className="flex flex-row items-center pb-4 gap-x-4">
                    <div className="flex-grow">
                        <MultiselectInputField
                            values={filters.map(filter => filter.value ?? "")}
                            placeholder={"Filter by name or integration"}
                            separator="space"
                            onAdd={() => setIsFilterDialogOpen(true)}
                            onClick={(idx) => removeFilter(idx)}
                            onRemove={(idx) => removeFilter(idx)} />
                    </div>
                    <DropdownMenu title="Integrations" items={endpoint_menu_items} align="right" />
                    <TextboxModal
                        open={is_filter_dialog_open}
                        title="Add filter by name"
                        init_text={""}
                        cancel="Cancel"
                        confirm="Add"
                        validate={(text) => text.length > 0}
                        onClose={onFilterDialogClose} />
                </div>
            </div>
            <div className="px-10">
                {confirm_items.length > 0 && <ItemList items={filtered_confirm_items} show_template={true} is_endpoint={true} onItemSelected={onItemSelected} />}
                {confirm_items.length === 0 && <div className="text-center py-20">
                    <FaceSmileIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">Yay, all nice and tidy!</h3>
                    <p className="mt-1 text-sm text-gray-500">No workflows that require confirmation.</p>
                </div>}
            </div>
            {is_loading_more && <div className="flex justify-center items-center h-screen w-full">
                <LoadingSpinnerLimit />
            </div>}
        </div>
    </div>;
}
