import { useSelector } from 'react-redux';

import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from '@headlessui/react'
import {
    ChevronDownIcon,
    LockClosedIcon
} from '@heroicons/react/20/solid'

import { classNames } from '../lib/utils';
import { selectUser } from '../lib/scraper.slice';
import { USER_ROLES } from '../lib/consts';

export interface IButtonMenuItem {
    title: string;
    separator?: boolean;
    admin_only?: boolean;
    hide?: boolean;
    onClick?: () => void;
    href?: string;
    open_in_new_tab?: boolean;
    disabled?: boolean;
}

type ButtonMenuProps = {
    title: string;
    items: IButtonMenuItem[];
    highlight?: boolean;
    disabled?: boolean;
};

export function ButtonMenu(props: ButtonMenuProps) {
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const { title, items, highlight, disabled } = props;

    const show_items = items
        .filter(item => !item.admin_only || is_admin)
        .filter(item => !item.hide);

    return (
        <Menu as="div" className="mx-1 relative inline-block text-left ">
            <div>
                <MenuButton disabled={disabled} className={classNames(
                    "inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset whitespace-nowrap",
                    disabled ? "bg-gray-100 text-gray-500 ring-gray-300 cursor-not-allowed" :
                        highlight ? "bg-space_blue-600 text-white ring-space_blue-600 hover:bg-space_blue-500" :
                            "bg-white text-gray-900 ring-gray-300 hover:bg-gray-50"
                )}>
                    {title}
                    <ChevronDownIcon aria-hidden="true" className={classNames("-mr-1 w-5 h-5", highlight ? "text-white" : "text-gray-400")} />
                </MenuButton>
            </div>

            <MenuItems
                portal={true}
                anchor="bottom end"
                transition
                className="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1 flex flex-col">
                    {show_items.map(({ title, separator, admin_only, onClick, disabled, href, open_in_new_tab }, idx) => (
                        <MenuItem key={idx}>
                            <a // eslint-disable-line
                                href={href}
                                className={classNames(
                                    "px-4 py-2 text-sm truncate",
                                    separator ? "border-t border-gray-200" : "",
                                    admin_only ? "text-gray-400" : (disabled ? "text-gray-400" : "text-gray-900"),
                                    disabled ? "cursor-not-allowed" : "data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none cursor-pointer"
                                )}
                                target={open_in_new_tab ? "_blank" : "_self"}
                                onClick={disabled ? undefined : onClick}
                                title={title}
                            >
                                {admin_only && <LockClosedIcon className="inline-block align-middle w-4 h-4 mr-2" />}
                                {title}
                            </a>
                        </MenuItem>
                    ))}
                </div>
            </MenuItems>
        </Menu>
    )
}
