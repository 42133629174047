import {
    Fragment,
    MouseEvent,
    useCallback,
    useEffect,
    useState
} from "react";

import { useResizeObserver } from "@wojtekmaj/react-hooks";

import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { FullScreen } from "./FullScreen";
import { Checkbox } from "./Checkbox";
import { classNames } from "../lib/utils";
import { TimeoutErrorMessageBar } from "./ErrorMessageBar";
import { SelectPagesMode } from "../lib/types";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";


type PdfViewerHorizontalProps = {
    display_name: string;
    file: string;
    select_pages?: boolean;
    split_document?: boolean;
    max_width?: number;
    select_pages_mode?: SelectPagesMode,
    notifyNumPages?: (num_pages: number,) => void;
    notifySelectedPages?: (selected_pages: number[], page_delimiters: number[]) => void;
}

export function PdfViewerHorizontal(props: PdfViewerHorizontalProps) {
    const { display_name, file, select_pages, split_document, max_width, select_pages_mode, notifyNumPages, notifySelectedPages } = props;

    const [num_pages, setNumPages] = useState<number>(0);
    const [selected_pages, setSelectedPages] = useState<number[]>([]);
    const [page_delimiters, setPageDelimiters] = useState<number[]>([]);
    const [container_ref, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [container_width, setContainerWidth] = useState<number>(100);
    const [full_screen, setFullScreen] = useState<number>(-1);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            const { width } = entry.contentRect;
            setContainerWidth(width);
        }
    }, []);

    useResizeObserver(container_ref, {}, onResize);

    useEffect(() => {
        if (select_pages_mode === "all") {
            const new_selected_pages = Array.from(new Array(num_pages), (_el, index) => index);
            setSelectedPages(new_selected_pages);
            if (notifySelectedPages) { notifySelectedPages(new_selected_pages, page_delimiters); }
        } else if (select_pages_mode === "first_page") {
            const new_selected_pages = [0];
            setSelectedPages(new_selected_pages);
            if (notifySelectedPages) { notifySelectedPages(new_selected_pages, page_delimiters); }
        } else if (select_pages_mode === "last_page") {
            const new_selected_pages = [num_pages - 1];
            setSelectedPages(new_selected_pages);
            if (notifySelectedPages) { notifySelectedPages(new_selected_pages, page_delimiters); }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [select_pages_mode]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        const new_selected_pages = Array.from(new Array(numPages), (el, index) => index);
        const new_page_delimiters = Array.from(new Array(numPages), (el, index) => index).slice(1);
        setNumPages(numPages);
        setSelectedPages(new_selected_pages);
        setPageDelimiters(new_page_delimiters);
        if (notifyNumPages) { notifyNumPages(numPages); }
        if (notifySelectedPages) { notifySelectedPages(new_selected_pages, new_page_delimiters); }
    }

    const togglePage = (index: number, checked: boolean) => {
        if (checked && !selected_pages.includes(index)) {
            const new_selected_pages = [...selected_pages, index].sort((a, b) => a - b);
            setSelectedPages(new_selected_pages);
            if (notifySelectedPages) { notifySelectedPages(new_selected_pages, page_delimiters); }
        } else {
            if (selected_pages.length > 1) {
                const new_selected_pages = selected_pages.filter((page) => page !== index);
                setSelectedPages(new_selected_pages);
                if (notifySelectedPages) { notifySelectedPages(new_selected_pages, page_delimiters); }
            } else {
                setErrorMessage("At least one page must be selected");
            }
        }
    }

    const toggleDelimiter = (index: number, checked: boolean) => {
        if (checked && !page_delimiters.includes(index)) {
            const new_page_delimiters = [...page_delimiters, index].sort((a, b) => a - b);
            setPageDelimiters(new_page_delimiters);
            if (notifySelectedPages) { notifySelectedPages(selected_pages, new_page_delimiters); }
        } else {
            const new_page_delimiters = page_delimiters.filter((page) => page !== index);
            setPageDelimiters(new_page_delimiters);
            if (notifySelectedPages) { notifySelectedPages(selected_pages, new_page_delimiters); }
        }
    }

    const onPageClick = (index: number) => {
        if (!select_pages) {
            // show full screen
            setFullScreen(index);
        } else {
            // toggle page
            togglePage(index, !selected_pages.includes(index));
        }
    }

    const onSplitterClick = (index: number, event: MouseEvent<HTMLDivElement>) => {
        // check if click on left border
        const rect = event.currentTarget.getBoundingClientRect();
        const click_x = event.clientX - rect.left;
        if (click_x <= 9) {
            toggleDelimiter(index, !page_delimiters.includes(index));
        }
    }

    const show_select_pages = num_pages > 1 && select_pages;
    const show_split_document = num_pages > 1 && split_document;

    const width = max_width !== undefined ? Math.min(container_width, max_width) : container_width;

    return <div ref={setContainerRef}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <div className="flex flex-row items-end">
                {Array.from(new Array(num_pages), (el, index) => (
                    <Fragment key={index}>
                        <div className="flex flex-col">
                            <div className={classNames(
                                "",
                                show_split_document && index > 0 && selected_pages.includes(index) ? (page_delimiters.includes(index) ? "ml-3 pl-3 border-l-8" : "ml-2 pl-2") : "",
                                show_split_document ? (page_delimiters.includes(index) ? "border-space_blue-300" : "") : ""
                            )} onClick={(event) => onSplitterClick(index, event)}>
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    className={classNames(
                                        `m-2 border border-gray-200 shadow max-w-[${width}px]`,
                                        (select_pages && !selected_pages.includes(index)) ? "opacity-20" : "",
                                        show_split_document ? (selected_pages.includes(index) ? "border-space_blue-300" : "") : ""
                                    )}
                                    width={max_width !== undefined ? Math.min(container_width, max_width) : container_width}
                                    onClick={() => { onPageClick(index) }}
                                />
                            </div>
                            {(show_split_document || show_select_pages) && <div className="flex flex-row pt-1">
                                {show_split_document && index > 0 && selected_pages.includes(index) && <div className={classNames(
                                    "flex items-center",
                                    page_delimiters.includes(index) ? "px-2" : "pr-3"
                                )}>
                                    <Checkbox checked={page_delimiters.includes(index)} setChecked={(checked) => { toggleDelimiter(index, checked) }} />
                                </div>}
                                {show_select_pages && <div className="p-1 flex-grow flex justify-center items-center ">
                                    <Checkbox checked={selected_pages.includes(index)} setChecked={(checked) => { togglePage(index, checked) }} />
                                </div>}
                            </div>}
                        </div>
                    </Fragment>
                ))}
            </div>
        </Document>
        {/* Show error if defined, make sure always visible even if div scrolled horizontally */}
        <TimeoutErrorMessageBar message={error_message} clearMessage={() => setErrorMessage(undefined)} />

        <FullScreen show={full_screen !== -1} onClose={() => setFullScreen(-1)} opacity={false}>
            {!show_select_pages && <PdfViewerFullScreen display_name={display_name} file={file} />}
            {show_select_pages && <PdfViewerFullScreenPage page_number={full_screen} display_name={display_name} file={file} />}
        </FullScreen>
    </div >;
}

type PdfViewerVerticalProps = {
    file: string;
}

export function PdfViewerVertical(props: PdfViewerVerticalProps) {
    const { file } = props;

    const [num_pages, setNumPages] = useState<number>(0);
    const [container_ref, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [container_width, setContainerWidth] = useState<{ width: number, last_update_ts: number }>({ width: 100, last_update_ts: 0 });

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            const { width } = entry.contentRect;
            // only update if width changed by more than 20px or if it has been more than 100ms
            // this is due to jittering look that happens when resizing the div and it's on the
            // edge between scrollbar width and non-scrollbar width:
            //  - if no scrollbar, then PDF till the edge of the div, making it longer and causing scrollbar to appear
            //  - if scrollbar, then div is bit narrower, so PDF is bit narrower, causing scrollbar to disappear
            // we only update if at least 100ms has passed since last update to avoid too much re-rendering and
            const size_diff = width - container_width.width;
            const time_diff = Date.now() - container_width.last_update_ts;
            if ((size_diff > 20 || size_diff < -20) && time_diff > 100) {
                setContainerWidth({ width, last_update_ts: Date.now() });
            }
        }
    }, [container_width]);
    useResizeObserver(container_ref, {}, onResize);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    return <div ref={setContainerRef}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <div className="flex flex-col items-center">
                {Array.from(new Array(num_pages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className={`m-2 border border-gray-200 shadow`}
                        width={container_width.width}
                    />
                ))}
            </div>
        </Document>
    </div >;
}

type PdfViewerFullScreenProps = {
    display_name: string;
    file: string;
}

function PdfViewerFullScreen(props: PdfViewerFullScreenProps) {
    const { display_name, file } = props;

    const [container_ref, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [container_width, setContainerWidth] = useState<number>(100);
    const [num_pages, setNumPages] = useState<number>(0);

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            const { width } = entry.contentRect;
            setContainerWidth(width);
        }
    }, []);
    useResizeObserver(container_ref, {}, onResize);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
    }

    return <div ref={setContainerRef}>
        <div className="py-2">{display_name}</div>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <div className="flex flex-col items-center">
                {Array.from(new Array(num_pages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className={`m-2 border border-gray-200 shadow`}
                        width={container_width}
                    />
                ))}
            </div>
        </Document>
    </div>;
}

type PdfViewerFullScreenPageProps = {
    page_number: number;
    display_name: string;
    file: string;
}

function PdfViewerFullScreenPage(props: PdfViewerFullScreenPageProps) {
    const { page_number, display_name, file } = props;

    const [container_ref, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [container_width, setContainerWidth] = useState<number>(100);

    const onResize = useCallback<ResizeObserverCallback>((entries) => {
        const [entry] = entries;

        if (entry) {
            const { width } = entry.contentRect;
            setContainerWidth(width);
        }
    }, []);
    useResizeObserver(container_ref, {}, onResize);

    return <div ref={setContainerRef}>
        <div className="py-2">{display_name}</div>
        <Document file={file}>
            <div className="flex flex-col items-center">
                <Page
                    key={`page_${page_number + 1}`}
                    pageNumber={page_number + 1}
                    className={`m-2 border border-gray-200 shadow`}
                    width={container_width}
                />
            </div>
        </Document>
    </div>;
}