import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import {
    ArrowDownTrayIcon,
    BookOpenIcon,
    CurrencyDollarIcon,
    MinusIcon,
    PencilIcon,
    PlusIcon
} from "@heroicons/react/24/outline";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild
} from "@headlessui/react";

import * as st from "../lib/types";
import * as t from "../lib/backend/extractions.types.generated";
import {
    scraperUser,
    selectIsSidebarLarge,
    selectUser
} from "../lib/scraper.slice";
import {
    ORG_ROLES,
    ORG_TYPES,
    TRANSACTION_TYPE,
    extract_job_types,
    onboarding_steps,
    org_roles,
    user_roles
} from "../lib/consts";
import {
    OrgRole,
    UserRole,
} from "../lib/backend/extractions.types.generated";
import { Backend, BackendObj } from "../lib/backend";
import {
    downloadObjectAsJson,
    sanitizeFileName,
    classNames,
    validateEmail,
    isValidInteger,
    hashEmail
} from "../lib/utils";
import {
    IOrganization,
    OrgType,
    TransactionType
} from "../lib/backend/auth.types.generated";

import { CopyTextbox } from "../components/CopyTextbox";
import { UserDetails } from "./UserDetails";
import {
    LoadingSpinner,
    LoadingSpinnerLimit
} from "../components/LoadingSpinner";
import { ReadOnlyTextbox } from "../components/ReadOnlyTextbox";
import { Dropdown } from "../components/Dropdown";
import { TransactionHistory } from "../components/TransactionHistory";
import { Button } from "../components/Button";
import { ExtractJobs } from "../components/ExtractJobs";
import { UserAuditLog } from "../components/UserAuditLog";
import { Tabs } from "../components/Tabs";
import { OrgPillSimple } from "../components/OrgPill";
import { Pagination } from "../components/Pagination";
import { TextboxModal } from "../components/TextboxModal";
import { SidePanel } from "../components/SidePanel";
import { AppDispatch } from "../store";
import { DateTextbox, Textbox } from "../components/Textbox";
import { Checkbox } from "../components/Checkbox";
import { TimeoutErrorMessageBar } from "../components/ErrorMessageBar";

type AddUserDialogProps = {
    memberships: st.IMembership[] | undefined,
    addUserToOrg: (org_uuid: string, role: string) => void,
    open: boolean;
    setOpen: (open: boolean) => void;
};

function AddOrgDialog(props: AddUserDialogProps) {
    const { memberships, open, setOpen } = props;

    const [orgs, setOrgs] = useState<IOrganization[] | undefined>(undefined);
    const [selected_org_uuid, setSelectedOrgUuid] = useState<string>("");
    const [org_role, setOrgRole] = useState<string>(ORG_ROLES.member);

    useEffect(() => {
        if (!memberships) { return; }
        const current_org_uuids = memberships.map((membership) => membership.org.uuid);
        BackendObj.auth.getOrganizations({})
            .then(res => {
                const filtered_orgs = res.orgs.filter((org) => !current_org_uuids.includes(org.uuid)) ?? [];
                setOrgs(filtered_orgs);
                if (filtered_orgs.length > 0) {
                    setSelectedOrgUuid(filtered_orgs[0].uuid);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [memberships]);

    const addUserToOrg = (org_uuid: string, role: string) => {
        setOpen(false);
        props.addUserToOrg(org_uuid, role);
        setOrgRole(ORG_ROLES.member);
    }

    const is_invalid = (selected_org_uuid === "");

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-mint-100">
                                        <CurrencyDollarIcon className="h-6 w-6 text-mint-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base text-center font-semibold leading-6 text-gray-900">
                                            Add user to organization
                                        </Dialog.Title>
                                        <div className="mt-2 text-left">
                                            {orgs && <form className="">
                                                <div className="space-y-4">
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm w-20 font-medium text-gray-700">
                                                            Organization
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Dropdown values={orgs.map((org) => org.name)} ids={orgs.map((org) => org.uuid)} selected={selected_org_uuid} onChange={setSelectedOrgUuid} />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm w-20 font-medium text-gray-700">
                                                            Type
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Dropdown values={Object.values(ORG_ROLES)} selected={org_role + ""} onChange={setOrgRole} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>}
                                            {!orgs && <div className="text-center p-2"><LoadingSpinnerLimit /></div>}
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <Button onClick={() => setOpen(false)} text={"Cancel"} highlight={false} />
                                    <Button onClick={() => addUserToOrg(selected_org_uuid, org_role)} text={"Add"} highlight={true} disabled={is_invalid} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

type CreateOrgDialogProps = {
    createOrg: (name: string, type: OrgType) => void,
    open: boolean;
    setOpen: (open: boolean) => void;
};

function CreateOrgDialog(props: CreateOrgDialogProps) {
    const { open, setOpen } = props;

    const [org_name, setOrgName] = useState<string>("");
    const [org_type, setOrgType] = useState<OrgType>(ORG_TYPES.business);

    const is_invalid = (org_name === "");

    const createOrg = (name: string, type: OrgType) => {
        setOpen(false);
        props.createOrg(name, type);
        setOrgName("");
        setOrgType(ORG_TYPES.business);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-mint-100">
                                        <CurrencyDollarIcon className="h-6 w-6 text-mint-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base text-center font-semibold leading-6 text-gray-900">
                                            Create new organization
                                        </Dialog.Title>
                                        <div className="mt-2 text-left">
                                            <form className="">
                                                <div className="space-y-4">
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm font-medium text-gray-700">
                                                            Name
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Textbox
                                                                placeholder="Organization name"
                                                                value={org_name}
                                                                onChange={(value) => setOrgName(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm w-20 font-medium text-gray-700">
                                                            Type
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Dropdown values={Object.values(ORG_TYPES)} selected={org_type + ""} onChange={s => setOrgType(s as OrgType)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <Button onClick={() => setOpen(false)} text={"Cancel"} highlight={false} />
                                    <Button onClick={() => createOrg(org_name, org_type)} text={"Create"} highlight={true} disabled={is_invalid} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

type AddCreditDialogProps = {
    org: IOrganization;
    addOrgCredit: (org_uuid: string, type: TransactionType, amount: number, description_text: string, expiration_date?: Date) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
};

function AddCreditDialog(props: AddCreditDialogProps) {
    const { org, open, setOpen, addOrgCredit } = props;

    const [type, setType] = useState<TransactionType>(TRANSACTION_TYPE.purchase);
    const [amount, setAmount] = useState<string>("0");
    const [details_text, setDetailsText] = useState<string>("");
    const [expiration_date, setExpirationDate] = useState<Date | undefined>(undefined);
    const [is_expiration_date_valid, setIsExpirationDateValid] = useState<boolean>(true);

    // check with regex if amount is a number (integer, could be negative)
    const is_amount_valid = isValidInteger(amount);
    const is_invalid = !is_amount_valid || (details_text === "") || !is_expiration_date_valid;

    const setExpirationDateCheckbox = (value: boolean) => {
        if (value) {
            const new_expiration_date = new Date();
            // add a month
            new_expiration_date.setMonth(new_expiration_date.getMonth() + 1);
            setExpirationDate(new_expiration_date);
        } else {
            setExpirationDate(undefined);
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-mint-100">
                                        <CurrencyDollarIcon className="h-6 w-6 text-mint-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 sm:mt-5">
                                        <DialogTitle as="h3" className="text-base text-center font-semibold leading-6 text-gray-900">
                                            Add credits to <i>{org.name}</i>
                                        </DialogTitle>
                                        <div className="mt-2 text-left">
                                            <form className="">
                                                <div className="space-y-4">
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm w-20 font-medium text-gray-700">
                                                            Type
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Dropdown values={Object.values(TRANSACTION_TYPE)} selected={type + ""} onChange={s => setType(s as TransactionType)} />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm font-medium text-gray-700">
                                                            Amount of credits
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Textbox
                                                                placeholder="0"
                                                                value={amount}
                                                                onChange={(value) => setAmount(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <label htmlFor="" className="block text-sm font-medium text-gray-700">
                                                            Description
                                                        </label>
                                                        <div className="mt-1 col-span-2">
                                                            <Textbox
                                                                placeholder="Description"
                                                                value={details_text}
                                                                onChange={(value) => setDetailsText(value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3 gap-4 items-center">
                                                        <div className="flex flex-row gap-x-2">
                                                            <Checkbox
                                                                id="expiration_date"
                                                                checked={expiration_date !== undefined}
                                                                setChecked={setExpirationDateCheckbox}
                                                            />
                                                            <label htmlFor="expiration_date" className="block text-sm font-medium text-gray-700">
                                                                Expiration date
                                                            </label>
                                                        </div>
                                                        <div className="mt-1 col-span-2">
                                                            {expiration_date !== undefined && <DateTextbox
                                                                date={expiration_date}
                                                                onChange={(date) => setExpirationDate(date)}
                                                                reportIsValid={setIsExpirationDateValid}
                                                            />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <Button onClick={() => setOpen(false)} text={"Cancel"} highlight={false} />
                                    <Button onClick={() => addOrgCredit(org.uuid, type, parseInt(amount), details_text, expiration_date)} text={"Add Credits"} highlight={true} disabled={is_invalid} />
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

function UserStats(props: { stats: st.IUserStats }) {
    const { stats } = props;

    return <div className="grid sm:grid-cols-2 gap-x-4">
        <div className="pb-4">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Count</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">Scrapes</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{stats.scrape_count}</td>
                    </tr>
                    <tr>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">Extractions</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{stats.item_count}</td>
                    </tr>
                    <tr>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">Templates</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{stats.template_count}</td>
                    </tr>
                    <tr>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">Integrations</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{stats.endpoint_count}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Extract Job Type</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total in last 7 days</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total in last 30 days</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {extract_job_types.map((job_type, i) => (<tr key={i}>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">{job_type}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {stats.extract_jobs_7_days.find((count) => count.extract_job_type === job_type)?.count ?? 0}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {stats.extract_jobs_30_days.find((count) => count.extract_job_type === job_type)?.count ?? 0}
                        </td>
                    </tr>))}
                </tbody>
            </table>
        </div>
    </div>;
}

function UserTemplates(props: { user_uuid: string }) {
    const { user_uuid } = props;

    const [orgs, setOrgs] = useState<IOrganization[] | undefined>(undefined);
    const [templates, setTemplates] = useState<st.ITemplateBase[] | undefined>(undefined);

    useEffect(() => {
        BackendObj.extractions.getUserTemplates({ user_uuid })
            .then(({ templates }) => {
                setTemplates(templates);
            })
            .catch((err) => {
                console.log(err);
            });
        BackendObj.auth.getOrganizations({})
            .then((res) => {
                setOrgs(res.orgs);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [user_uuid]);

    const handleDownload = async (template_uuid: string) => {
        // mark in audit log the download
        await BackendObj.extractions.logTemplateDefDownload({ template_uuid });
        // prepare def for download
        const { template } = await BackendObj.extractions.getUserTemplate({ user_uuid, template_uuid });
        if (template === undefined) { return; }
        const template_def: t.ICreateTemplateReq = {
            template: {
                org_uuid: template.org_uuid,
                name: template.name,
                details: template.details,
                facts: template.facts
            },
            contexts: template.contexts.map((context) => ({
                name: context.name,
                code: context.code,
                org_uuid: context.org_uuid,
                facts: context.facts,
                fields: context.fields,
                postprocess: context.postprocess,
                type: context.type,
                extract_params: context.extract_params,
                overrides: context.overrides,
                row_validators: context.row_validators,
                context_validators: context.context_validators,
                weight_score: context.weight_score
            }))
        };
        downloadObjectAsJson(template_def, `template_def_${template_uuid}.json`);
    };

    if (templates === undefined) {
        return <div className="p-8">
            <LoadingSpinnerLimit />
        </div>;
    }

    return <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
            <tr>
                <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Name</th>
                <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Organization</th>
                <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900 w-40"></th>
            </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {templates.map((template, i) => (<tr key={i}>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray text-gray-500">{template.name}</td>
                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray text-gray-500">
                    <OrgPillSimple org={orgs && orgs.find((org) => org.uuid === template.org_uuid)} />
                </td>
                <td className="px-3 py-2 text-sm text-gray text-right text-gray-500">
                    <Button icon={ArrowDownTrayIcon} text="Definition" highlight={false} onClick={() => handleDownload(template.uuid)} />
                </td>
            </tr>))}
        </tbody>
    </table>;
}

type ItemDownload = {
    title: string,
    url?: string,
    handler?: () => void
};
function UserItems(props: { user_uuid: string }) {
    const { user_uuid } = props;

    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState<st.IItemSlim[] | undefined>(undefined);
    const [show_possible_downloads, setShowPossibleDownloads] = useState<boolean>(false);
    const [downloads, setDownloads] = useState<ItemDownload[]>([]);

    const LIMIT = 10;

    useEffect(() => {
        Backend.getAdminUserItems({ user_uuid, offset, limit: LIMIT })
            .then(({ items, total }) => {
                setItems(items);
                setTotal(total);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [user_uuid, offset]);

    const handleDownloadAttachment = (item_uuid: string) => {
        setShowPossibleDownloads(true);
        setDownloads([]);
        Backend.getAdminUserItem({ user_uuid, item_uuid })
            .then((item) => {
                if (item === undefined) { return; }
                const downloads: ItemDownload[] = [];
                // download item
                downloads.push({
                    handler: () => {
                        downloadObjectAsJson(item, `item_def_${item.uuid}.json`);
                    },
                    title: "Item definition"
                });
                // download template
                const template_def: t.ICreateTemplateReq = {
                    template: {
                        org_uuid: item.template.org_uuid,
                        name: item.template.name,
                        details: item.template.details,
                        facts: item.template.facts
                    },
                    contexts: item.template.contexts.map((context) => ({
                        name: context.name,
                        code: context.code,
                        org_uuid: context.org_uuid,
                        facts: context.facts,
                        fields: context.fields,
                        postprocess: context.postprocess,
                        row_validators: context.row_validators,
                        context_validators: context.context_validators,
                        type: context.type,
                        extract_params: context.extract_params,
                        overrides: context.overrides,
                        weight_score: context.weight_score
                    }))
                };
                downloads.push({
                    handler: () => {
                        const filename = sanitizeFileName(item.template.uuid);
                        downloadObjectAsJson(template_def, filename);
                    },
                    title: "Template - " + item.template.name
                });
                // download individual attachment
                for (const att of item.attachments) {
                    downloads.push({
                        url: `/api/admin/attachment/get?uuid=` + encodeURIComponent(att.uuid),
                        title: att.filename
                    });
                }

                setDownloads(downloads);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    if (items === undefined) {
        return <div className="p-8">
            <LoadingSpinnerLimit />
        </div>;
    }

    return <Fragment>
        <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
                <tr>
                    <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900"></th>
                    <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Name</th>
                    <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900 w-40"></th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {items.map((item, i) => (<tr key={i}>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray text-gray-500">{i + offset + 1}.</td>
                    <td className="whitespace-nowrap px-3 py-2 text-sm text-gray text-gray-500">{item.name}</td>
                    <td className="px-3 py-2 text-sm text-gray text-right text-gray-500">
                        <Button icon={ArrowDownTrayIcon} text="Download" highlight={false} onClick={() => handleDownloadAttachment(item.uuid)} />
                    </td>
                </tr>))}
            </tbody>
        </table>
        <Pagination offset={offset} limit={LIMIT} total={total} setOffset={setOffset} />
        <SidePanel
            title="Downloads for item" size="lg"
            open={show_possible_downloads}
            onClose={() => setShowPossibleDownloads(false)}
        >
            <div className="space-y-4">
                {downloads.filter(x => x.handler !== undefined).map((download, i) => (
                    <Button key={i} text={download.title} highlight={true} onClick={download.handler} />
                ))}

                {downloads.filter(x => x.url !== undefined).map((x, i) => (
                    <a
                        className="text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
                        href={x.url} target="_blank" rel="noreferrer">
                        {x.title}
                    </a>
                ))}
            </div>
        </SidePanel>
    </Fragment>;
}

export function AdminUser() {
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();

    // Get tab and page from URL query params, defaulting to "orgs" and 0
    const query_params = new URLSearchParams(location.search);
    const initial_tab = query_params.get("tab") || "orgs";
    const initial_page = parseInt(query_params.get("page") || "0");

    const { uuid } = useParams<{ uuid: string }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const logged_in_user = useSelector(selectUser);

    const [user, setUser] = useState<st.IUser | undefined>(undefined);
    const [email_hash, setEmailHash] = useState<string>("");
    const [is_multi_region_enabled, setIsMultiRegionEnabled] = useState<boolean>(false);
    const [is_new_email_open, setIsNewEmailOpen] = useState<boolean>(false);
    const [memberships, setMemberships] = useState<st.IMembership[] | undefined>(undefined);
    const [selected_tab_key, setSelectedTab] = useState<string>(initial_tab);
    const [open_add_org, setOpenAddOrg] = useState<boolean>(false);
    const [open_create_org, setOpenCreateOrg] = useState<boolean>(false);
    const [balances, setBalances] = useState<st.IOrgBalance[] | undefined>(undefined);
    const [history_org, setHistoryOrg] = useState<IOrganization | undefined>(undefined);
    const [open_transaction_history, setOpenTransactionHistory] = useState<boolean>(false);
    const [add_credit_org, setAddCreditOrg] = useState<IOrganization | undefined>(undefined);
    const [open_add_org_credit, setOpenAddOrgCredit] = useState<boolean>(false);
    const [onboarding_status, setOnboardingStatus] = useState<st.IOnboardingStatus | undefined>(undefined);
    const [onboarding_events, setOnboardingEvents] = useState<st.IOnboardingEvent[] | undefined>(undefined);
    const [stats, setStats] = useState<st.IUserStats | undefined>(undefined);
    const [is_updating, setIsUpdating] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [history_refresh_ticker, setHistoryRefreshTicker] = useState<number>(0);

    useEffect(() => {
        if (!uuid) { return; }
        Backend.getUserByUuid({ uuid })
            .then(async ({ user, memberships, balances, onboarding_status, onboarding_events }) => {
                setUser(user);
                setEmailHash(await hashEmail(user?.email ?? ""));
                setMemberships(memberships);
                setBalances(balances);
                setOnboardingStatus(onboarding_status);
                setOnboardingEvents(onboarding_events);
            })
            .catch((err) => {
                console.log(err);
            });
        Backend.getUserStats({ uuid })
            .then((stats) => {
                setStats(stats);
            })
            .catch((err) => {
                console.log(err);
            });
        Backend.getUserMultiRegionStatus({ uuid })
            .then((data) => {
                setIsMultiRegionEnabled(data.multi_region_enabled);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [uuid]);

    const updateEmail = async (result: boolean, new_email?: string) => {
        setErrorMessage(undefined);
        if (result && new_email && user) {
            setIsUpdating(true);
            try {
                await Backend.setUserEmail({ uuid: user.uuid, email: new_email });
                setUser({ ...user, email: new_email });
            } catch (err) {
                setErrorMessage("Error changing email address, check if user with such email already exists.");
                console.log(err);
            }
            setIsUpdating(false);
        }
        setIsNewEmailOpen(false);
    }

    const updateRole = async (new_role: UserRole) => {
        if (!user) { return; }
        setErrorMessage(undefined);
        setIsUpdating(true);
        try {
            await Backend.setUserRole({ uuid: user.uuid, role: new_role })
            setUser({ ...user, role: new_role });
        } catch (err: any) {
            setErrorMessage("Error changing role.");
            console.log(err);
        }
        setIsUpdating(false);
    };

    const updateDebug = async (new_debug: string) => {
        if (!user) { return; }
        setIsUpdating(true);
        await Backend.setAdminUserDebug({ uuid: user.uuid, debug: new_debug === "Enabled" })
        setUser({ ...user, debug: new_debug === "Enabled" });
        setIsUpdating(false);
    };

    const updateIsEnabled = async (new_is_enabled: string) => {
        if (!user) { return; }
        setIsUpdating(true);
        await BackendObj.auth.setUserEnabled({ uuid: user.uuid, is_enabled: new_is_enabled === "Yes" })
        setUser({ ...user, is_enabled: new_is_enabled === "Yes" });
        setIsUpdating(false);
    };

    const updateNotifyAboutChanges = async (new_notify_about_changes: string) => {
        if (!user) { return; }
        setIsUpdating(true);
        await BackendObj.extractions.setNotifyAboutChangesForUser({ user_uuid: user.uuid, notify_about_changes: new_notify_about_changes === "Enabled" })
        setUser({ ...user, notify_about_changes: new_notify_about_changes === "Enabled" });
        setIsUpdating(false);
    };

    const updateIsMultiRegionEnabled = async (new_is_multi_region_enabled: string) => {
        if (!user) { return; }
        setIsUpdating(true);
        const new_val = new_is_multi_region_enabled === "Enabled";
        await Backend.setUserMultiRegionStatus({ uuid: user.uuid, multi_region_enabled: new_val })
        setIsMultiRegionEnabled(new_val);
        setIsUpdating(false);
    };

    const updateOnboardingStep = async (new_step: string) => {
        if (!user) { return; }
        if (!onboarding_status) { return; }
        setIsUpdating(true);
        await Backend.setUserOnboardingStep({ uuid: user.uuid, step: new_step })
        setOnboardingStatus({ ...onboarding_status, step: new_step });
        setIsUpdating(false);
    };

    const createOrg = async (org_name: string, org_type: OrgType) => {
        if (!uuid) { return; }
        setMemberships(undefined);
        setBalances(undefined);

        await Backend.createOrg({ org_name, org_type, admin_user_uuid: uuid });

        Backend.getUserByUuid({ uuid })
            .then(({ memberships, balances }) => {
                setMemberships(memberships);
                setBalances(balances);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updateOrgRole = async (org_uuid: string, new_role: OrgRole) => {
        if (!uuid) { return; }
        setMemberships(undefined);
        setBalances(undefined);
        setIsUpdating(true);

        await Backend.adminAddUserToOrg({ org_uuid, user_uuid: uuid, role: new_role as st.OrgRole });
        if (uuid === logged_in_user?.uuid) { dispatch(scraperUser()); }

        Backend.getUserByUuid({ uuid })
            .then(({ memberships, balances }) => {
                setMemberships(memberships);
                setBalances(balances);
                setIsUpdating(false);
            })
            .catch((err) => {
                setIsUpdating(false);
                console.log(err);
            });
    };

    const removeUserFromOrg = async (org_uuid: string) => {
        if (!uuid) { return; }
        setMemberships(undefined);
        setBalances(undefined);

        await Backend.adminRemoveUserFromOrg({ org_uuid, user_uuid: uuid });
        if (uuid === logged_in_user?.uuid) { dispatch(scraperUser()); }

        Backend.getUserByUuid({ uuid })
            .then(({ memberships, balances }) => {
                setMemberships(memberships);
                setBalances(balances);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const showTransactionHistory = (org: IOrganization) => {
        setHistoryOrg(org);
        setOpenTransactionHistory(true);
    };

    const showAddOrgCredit = (org: IOrganization) => {
        setAddCreditOrg(org);
        setOpenAddOrgCredit(true);
    };

    const addOrgCredit = async (org_uuid: string, type: TransactionType, amount: number, details_text: string, expiration_date?: Date) => {
        if (!uuid) { return; }
        setOpenAddOrgCredit(false);
        setBalances(undefined);

        const expiration_ts = expiration_date ? expiration_date.getTime() : undefined;
        await BackendObj.auth.addAdminOrgCredit({ org_uuid, type, amount, details_text, expiration_ts });
        const { balances } = await Backend.getUserByUuid({ uuid });
        setBalances(balances);
    };

    const refreshCredits = async () => {
        if (!uuid) { return; }
        const { balances } = await Backend.getUserByUuid({ uuid });
        setBalances(balances);
        setHistoryRefreshTicker(history_refresh_ticker + 1);
    };

    const tabs = [
        { name: "Organizations", key: "orgs" },
        { name: "Extract Jobs", key: "extract_jobs" },
        { name: "Audit Log", key: "audit_log" },
        { name: "Onboarding", key: "onboarding" }
    ];
    if (user?.debug) {
        tabs.push({ name: "Templates", key: "templates" });
        tabs.push({ name: "Items", key: "items" });
    }

    if (!user) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const is_self = logged_in_user?.uuid === user.uuid;

    // Update URL when tab changes
    const handleTabChange = (tab: string) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("tab", tab);
        // Use navigate without replace to create history entry
        navigate({ search: new_params.toString() });
        setSelectedTab(tab);
    };

    const handlePageChange = (page: number) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("page", page.toString());
        // Use navigate without replace to create history entry
        navigate({ search: new_params.toString() });
    };

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    User "{user?.first_name} {user?.last_name}"
                </h2>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>

            <div className="px-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 text-sm">
                    <div className="py-2">
                        <div className="flex flex-row items-center">
                            <h3 className="text-md font-semibold text-gray-800">Email:</h3>
                            <div className="flex-grow" />
                            <span className="text-gray-600 text-sm">
                                &nbsp;[
                                <span className="cursor-pointer" onClick={() => setIsNewEmailOpen(true)}>edit</span>
                                ]&nbsp;
                                <TextboxModal
                                    open={is_new_email_open}
                                    title="Enter new email"
                                    init_text={user?.email ?? ""}
                                    validate={validateEmail}
                                    onClose={updateEmail} />
                            </span>
                        </div>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={user.email} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">UUID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={user.uuid} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Email hash:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={email_hash} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Enabled:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={user.is_enabled ? "Yes" : "No"}
                                disabled={is_updating}
                                onChange={updateIsEnabled} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Authorization source:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={user.auth_source} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Picture URL:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={user.picture_url} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Role:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={user_roles} selected={`${user.role}`} disabled={is_self || is_updating}
                                onChange={s => updateRole(s as UserRole)} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Debug:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Enabled", "Disabled"]}
                                selected={user.debug ? "Enabled" : "Disabled"}
                                disabled={is_updating}
                                onChange={updateDebug} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Notify about changes:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Enabled", "Disabled"]}
                                selected={user.notify_about_changes ? "Enabled" : "Disabled"}
                                disabled={is_updating}
                                onChange={updateNotifyAboutChanges} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Multi-region:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Enabled", "Disabled"]}
                                selected={is_multi_region_enabled ? "Enabled" : "Disabled"}
                                disabled={is_updating}
                                onChange={updateIsMultiRegionEnabled} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Created at:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={new Date(user.created_at).toLocaleString()} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">IP Country:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={user.details.geoip_info?.country ?? "/"} />
                        </div>
                    </div>
                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">IP City:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={user.details.geoip_info?.city ?? "/"} />
                        </div>
                    </div>
                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">IP Org:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={user.details.geoip_info?.organization ?? "/"} />
                        </div>
                    </div>

                    {onboarding_status && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Onboarding status:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown values={onboarding_steps} selected={onboarding_status.step} disabled={is_updating} onChange={updateOnboardingStep} />
                        </div>
                    </div>}
                    {onboarding_status && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Onboarding start:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={new Date(onboarding_status.start_ts).toLocaleString()} />
                        </div>
                    </div>}
                    {onboarding_status && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Onboarding end:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={onboarding_status.end_ts ? new Date(onboarding_status.end_ts).toLocaleString() : "/"} />
                        </div>
                    </div>}
                </div>
            </div>

            <div className="p-10">
                {stats && <UserStats stats={stats} />}
            </div>

            <div className="px-10">
                <Tabs tabs={tabs} selected_tab_key={selected_tab_key} setSelectedTab={handleTabChange} />

                {selected_tab_key === "orgs" && <div>
                    <div className="p-8 flex flex-col ">
                        <table className="w-full divide-y divide-gray-300 border-gray-300 border">
                            <thead>
                                <tr>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Organization</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {memberships && memberships.map((membership, i) => (<tr key={i}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <Link to={`/admin/org/${membership.org.uuid}`} className="underline">{membership.org.name}</Link>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{membership.org.type}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-40">
                                        <Dropdown values={org_roles} selected={`${membership.role}`}
                                            disabled={is_updating || (membership.org.type === ORG_TYPES.personal)}
                                            onChange={(new_role: string) => updateOrgRole(membership.org.uuid, new_role as OrgRole)} />
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-10">
                                        {membership.org.type !== ORG_TYPES.personal &&
                                            <Button onClick={() => removeUserFromOrg(membership.org.uuid)} icon={MinusIcon} highlight={false} />}
                                    </td>
                                </tr>))}
                                {!memberships && <tr><td className="text-center p-2" colSpan={4}><LoadingSpinnerLimit /></td></tr>}
                            </tbody>
                        </table>
                        {(user.role === "admin" || (memberships?.length ?? 0) < 2) && <div className="pt-4 text-right">
                            <Button onClick={() => setOpenCreateOrg(true)} text="Create" icon={PencilIcon} highlight={false} />
                            <Button onClick={() => setOpenAddOrg(true)} text="Add" icon={PlusIcon} highlight={false} />
                        </div>}
                    </div>
                    <div className="p-8 flex flex-col">
                        <table className="w-full divide-y divide-gray-300 border-gray-300 border">
                            <thead>
                                <tr>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Organization</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Credit Balance</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Transactions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {balances && balances.map((balance, i) => (<tr key={i}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{balance.org.name}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{balance.org.type}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{balance.balance}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right w-10">
                                        <Button onClick={() => showTransactionHistory(balance.org)} text="History" icon={BookOpenIcon} highlight={false} />
                                        <Button onClick={() => showAddOrgCredit(balance.org)} text="Add Credits" icon={CurrencyDollarIcon} highlight={false} />
                                    </td>
                                </tr>))}
                                {!balances && <tr><td className="text-center p-2" colSpan={4}><LoadingSpinnerLimit /></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>}

                {selected_tab_key === "extract_jobs" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <ExtractJobs
                        type="admin_user"
                        user_uuid={user.uuid}
                        initial_page={initial_page}
                        onPageChange={handlePageChange}
                    />
                </div>}

                {selected_tab_key === "audit_log" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <UserAuditLog user_uuid={user.uuid} is_admin={true} />
                </div>}

                {selected_tab_key === "onboarding" && <div>

                    <div className="p-8">
                        <UserDetails user={user} is_onboarding={false} is_admin={true} />
                    </div>

                    {onboarding_events && <div className="p-8 flex flex-col items-center">
                        <table className="w-full divide-y divide-gray-300 border-gray-300 border">
                            <thead>
                                <tr>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Onboarding Event</th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {onboarding_events.map((event, i) => (<tr key={i}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{event.event}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(event.ts).toLocaleString()}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>}
                </div>}

                {user && selected_tab_key === "templates" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <UserTemplates user_uuid={user.uuid} />
                </div>}

                {user && selected_tab_key === "items" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <UserItems user_uuid={user.uuid} />
                </div>}
            </div>
        </div>
        {history_org && <TransactionHistory
            is_admin={true}
            org={history_org}
            open={open_transaction_history}
            setOpen={setOpenTransactionHistory}
            refreshCredits={refreshCredits}
            ticker={history_refresh_ticker} />}
        {add_credit_org && <AddCreditDialog
            org={add_credit_org}
            open={open_add_org_credit}
            setOpen={setOpenAddOrgCredit}
            addOrgCredit={addOrgCredit} />}
        {<CreateOrgDialog
            createOrg={createOrg}
            open={open_create_org}
            setOpen={setOpenCreateOrg} />}
        {<AddOrgDialog
            addUserToOrg={(org_uuid, new_role) => updateOrgRole(org_uuid, new_role as OrgRole)}
            open={open_add_org}
            setOpen={setOpenAddOrg}
            memberships={memberships} />}
        <TimeoutErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)} />
    </Fragment >;
};
