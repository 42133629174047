import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    ChartBarIcon,
    ChevronUpIcon,
    LightBulbIcon,
    PencilSquareIcon,
    PlusIcon,
    RocketLaunchIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import {
    PinIcon,
    PinSlashIcon
} from "@primer/octicons-react";

import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";
import {
    IWidget,
    IChart,
    IChartRange,
    ILookupTableBase,
    IDynamicFilter,
    IPinboardElement,
    WidgetType
} from "../lib/backend/extractions.types.generated";
import {
    Backend,
    BackendObj
} from "../lib/backend";

import {
    Chart,
    Indicator
} from "../components/Chart";
import {
    Button,
    ButtonGroup
} from "../components/Button";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { IconButton } from "../components/Button";
import { Dropdown } from "../components/Dropdown";

export const INDICATOR_WIDGET_TYPES = ["sum_number"];

function EmptyList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <RocketLaunchIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No insights</h3>
                <p className="mt-1 text-sm text-gray-500">You can create insights on top of your lookup tables.</p>
                <div className="mt-6">
                    <Button icon={PlusIcon} text="Go to lookup tables" href="/endpoints" />
                </div>
            </div>
        </div>
    </div >;
}

function isWidgetWide(widget: IWidget): boolean {
    return widget.type === "date_tags_chart";
}

function WidgetIcon({ widget_type }: { widget_type: WidgetType }) {
    return ["date_count_chart", "date_distribution_chart", "date_tags_chart", "tags_chart", "date_line_chart"].includes(widget_type) ?
        <ChartBarIcon className="h-5 w-5 text-gray-400" /> :
        <LightBulbIcon className="h-5 w-5 text-gray-400" />;
}

type IndicatorWidgetProps = {
    widget: IWidget;
    range: IChartRange;
    is_pinned?: boolean;
    is_editable?: boolean;
    onPinToggle?: () => void;
};

export function IndicatorWidget(props: IndicatorWidgetProps) {
    const { widget, range, is_pinned, is_editable, onPinToggle } = props;

    const [chart, setChart] = useState<IChart | undefined>(undefined);

    useEffect(() => {
        const loadWidget = async () => {
            const { chart } = await BackendObj.extractions.previewWidget({ widget_uuid: widget.uuid, range, additional_filters: [] });
            setChart(chart);
        };
        loadWidget();
    }, [widget.uuid, range]);

    return chart !== undefined ? <Indicator
        chart={chart}
        is_pinned={is_pinned}
        is_editable={is_editable}
        onTogglePin={onPinToggle}
    /> : null;
}

type WidgetProps = {
    widget: IWidget;
    is_pinned?: boolean;
    is_editable?: boolean;
    lookup_tables?: ILookupTableBase[];
    hide_range?: boolean;
    onClose?: () => void;
    onPinToggle?: () => void;
};

export function Widget(props: WidgetProps) {
    const { widget, is_pinned, is_editable, lookup_tables, hide_range, onClose, onPinToggle } = props;

    const navigate = useNavigate();

    const [range, _setRange] = useState<IChartRange>((localStorage.getItem(`widget_range_${widget.uuid}`) as IChartRange) ?? "7d");
    const [chart, setChart] = useState<IChart | undefined>(undefined);
    const [additional_filters, setAdditionalFilters] = useState<IDynamicFilter[]>([]);

    useEffect(() => {
        const loadWidget = async () => {
            const { chart } = await BackendObj.extractions.previewWidget({ widget_uuid: widget.uuid, range, additional_filters });
            setChart(chart);
        };
        loadWidget();
    }, [widget.uuid, range, additional_filters]);

    const setRange = (range: IChartRange) => {
        // remember the range
        _setRange(range);
        localStorage.setItem(`widget_range_${widget.uuid}`, range);
    };

    const changeFilter = (column_idx: number, value: string | undefined) => {
        let new_additional_filters: IDynamicFilter[] = [];
        if (value === undefined) {
            // delete filter
            new_additional_filters = additional_filters.filter(f => f.column_idx !== column_idx);
        } else {
            // update or add filter
            const new_filter: IDynamicFilter = { column_idx, possible_values: [value] };
            if (additional_filters.some(f => f.column_idx === column_idx)) {
                new_additional_filters = additional_filters.map(f => f.column_idx === column_idx ? new_filter : f);
            } else {
                new_additional_filters = [...additional_filters, new_filter];
            }
        }
        setAdditionalFilters([...new_additional_filters]);
    }

    const getSelectedFilterValue = (column_idx: number): string => {
        if (chart === undefined) { return ""; }
        // first find dynamic filter
        const dynamic_filter = chart.dynamic_filters.find(f => f.column_idx === column_idx);
        if (dynamic_filter === undefined) { return ""; }
        // then find selected value
        const selected_filter = additional_filters.find(f => f.column_idx === column_idx);
        if (selected_filter && selected_filter.possible_values.length > 0) {
            const selected_value = selected_filter.possible_values[0];
            const idx = dynamic_filter.possible_values.findIndex(v => v === selected_value);
            if (idx !== -1) { return `${idx}`; }
        }
        return "";
    }

    const getWidgetLookupTableName = (widget: IWidget): string => {
        return lookup_tables?.find(lookup_table => lookup_table.uuid === widget.lookup_table_uuid)?.name ?? "/";
    };

    const navigateToLookupTable = (lookup_table_uuid: string) => {
        if (lookup_table_uuid) {
            navigate(`/lookup_table/${lookup_table_uuid}?tab=insights`);
        }
    };

    return <div key={widget.uuid} className={classNames("bg-white rounded-lg shadow overflow-hidden border border-gray-200 flex flex-col", isWidgetWide(widget) ? "xl:col-span-2" : "")}>
        <div className="px-4 py-3 flex justify-between items-center border-b border-gray-200">
            <div className="flex flex-row items-center gap-x-2 truncate" title={widget.title}>
                <WidgetIcon widget_type={widget.type} />
                <h3 className="text-lg font-medium text-gray-700 truncate">{widget.title}</h3>
            </div>
            <div className="flex items-center gap-x-4">
                {(hide_range === undefined || !hide_range) && <ButtonGroup
                    buttons={[
                        { text: "7", onClick: () => setRange("7d"), selected: range === "7d" },
                        { text: "30", onClick: () => setRange("30d"), selected: range === "30d" }
                    ]}
                />}
                {hide_range === true && <div className="text-xs text-gray-400 whitespace-nowrap">{range === "7d" ? "7 days" : "30 days"}</div>}
                {onClose && <XMarkIcon
                    className="h-5 w-5 text-gray-400 cursor-pointer"
                    onClick={onClose}
                />}
            </div>
        </div>

        <div className="p-4 bg-white flex-grow">
            {chart === undefined && <div className="h-80 flex items-center justify-center text-gray-500">
                <LoadingSpinnerLimit />
            </div>}
            {chart !== undefined && <Fragment>
                <div className="w-full">
                    <Chart widget={widget} chart={chart} is_wide={isWidgetWide(widget)} />
                </div>
                {chart.dynamic_filters.length > 0 && <div className="pt-2 flex flex-row gap-x-2 h-11">
                    {chart.dynamic_filters.map(({ column_idx, column_name, possible_values }, idx) => <Dropdown
                        key={idx}
                        values={[`All [${column_name?.replace(/^tag /, "") ?? "All"}]`, ...possible_values]}
                        ids={["", ...possible_values.map((_, idx) => `${idx}`)]}
                        onChange={(value) => changeFilter(column_idx, value === "" ? undefined : possible_values[parseInt(value)])}
                        selected={getSelectedFilterValue(column_idx)}
                    />)}
                </div>}
            </Fragment>}
        </div>

        {lookup_tables !== undefined && is_pinned !== undefined && is_editable !== undefined && onPinToggle !== undefined &&
            <div className="px-4 py-2 bg-gray-50 border-t border-gray-200 text-xs text-gray-500 mt-auto">
                <div className="flex flex-row items-center gap-x-2">
                    <span>Source: {getWidgetLookupTableName(widget)}</span>
                    <div className="flex-grow" />
                    <div
                        className="flex flex-row items-center gap-x-2"
                        onClick={() => onPinToggle()}
                        title={is_pinned ? "Pin to dashboard" : "Unpin from dashboard"}
                    >
                        {is_editable && is_pinned && <PinIcon className="h-3 w-3 text-gray-400 hover:text-gray-600 cursor-pointer" />}
                        {!is_editable && is_pinned && <PinIcon className="h-3 w-3 text-gray-400 hover:text-gray-600 cursor-not-allowed" />}
                        {!is_pinned && <PinSlashIcon className="h-3 w-3 text-gray-400 hover:text-gray-600 cursor-pointer" />}
                    </div>
                    {widget.lookup_table_uuid && (
                        <IconButton
                            icon={PencilSquareIcon}
                            onClick={() => navigateToLookupTable(widget.lookup_table_uuid)}
                            title="Edit lookup table"
                            size="small"
                        />
                    )}
                </div>
            </div>
        }
    </div>;
}

export function Insights() {
    const navigate = useNavigate();
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // loaded state from backend
    const [widgets, setWidgets] = useState<IWidget[] | undefined>(undefined);
    const [indicator_widgets, setIndicatorWidgets] = useState<IWidget[] | undefined>(undefined);
    const [lookup_tables, setLookupTables] = useState<ILookupTableBase[] | undefined>(undefined);
    // loaded state from localStorage
    const [expanded_widget_ids, _setExpandedWidgetIds] = useState<Set<string>>(new Set<string>(JSON.parse(localStorage.getItem("expanded_widget_ids") ?? "[]")));
    const [indicator_widget_range, _setIndicatorWidgetRange] = useState<IChartRange>(localStorage.getItem("indicator_widget_range") as IChartRange ?? "7d");
    // pinboard status
    const [pinned_widgets, setPinnedWidgets] = useState<IPinboardElement[] | undefined>(undefined);

    useEffect(() => {
        const fetchWidgets = async () => {
            const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({});
            setWidgets(new_widgets.filter(widget => !INDICATOR_WIDGET_TYPES.includes(widget.type)));
            setIndicatorWidgets(new_widgets.filter(widget => INDICATOR_WIDGET_TYPES.includes(widget.type)));
        };
        fetchWidgets();
        const fetchLookupTables = async () => {
            const new_lookup_tables = await Backend.getLookupTables();
            setLookupTables(new_lookup_tables);
        };
        fetchLookupTables();
        const fetchPinnedWidgets = async () => {
            const { elements } = await BackendObj.extractions.listPinboardElements({ pinned_type: "widget" });
            setPinnedWidgets(elements);
        };
        fetchPinnedWidgets();
    }, []);

    if (widgets === undefined || indicator_widgets === undefined || lookup_tables === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:h-16 lg:flex lg:flex-row border-b-sea_blue-700 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const setExpandedWidgetIds = (new_expanded_widget_ids: Set<string>) => {
        localStorage.setItem("expanded_widget_ids", JSON.stringify(Array.from(new_expanded_widget_ids)));
        _setExpandedWidgetIds(new_expanded_widget_ids);
    };

    const setIndicatorWidgetRange = (range: IChartRange) => {
        _setIndicatorWidgetRange(range);
        localStorage.setItem("indicator_widget_range", range);
    };

    const toggleWidget = async (widget_uuid: string) => {
        const new_expanded_widget_ids = new Set(expanded_widget_ids);
        if (expanded_widget_ids.has(widget_uuid)) {
            new_expanded_widget_ids.delete(widget_uuid);
        } else {
            new_expanded_widget_ids.add(widget_uuid);
        }
        setExpandedWidgetIds(new_expanded_widget_ids);
    };

    const getWidgetLookupTableName = (widget: IWidget): string => {
        return lookup_tables?.find(lookup_table => lookup_table.uuid === widget.lookup_table_uuid)?.name ?? "/";
    };

    const navigateToLookupTable = (lookup_table_uuid: string) => {
        if (lookup_table_uuid) {
            navigate(`/lookup_table/${lookup_table_uuid}?tab=insights`);
        }
    };

    const isWidgetPinned = (widget_uuid: string): boolean => {
        if (pinned_widgets === undefined) { return false; }
        return pinned_widgets.some(widget => widget.pinned_uuid === widget_uuid);
    };

    const isWidgetPinEditable = (widget_uuid: string): boolean => {
        if (pinned_widgets === undefined) { return false; }
        // if widget not pinned then it is editable
        if (pinned_widgets.find(widget => widget.pinned_uuid === widget_uuid) === undefined) { return true; }
        // if widget is pinned by user then it is editable
        if (pinned_widgets.some(widget => widget.pinned_uuid === widget_uuid && widget.level === "user")) { return true; }
        // if widget is pinned by org then it is not editable
        if (pinned_widgets.some(widget => widget.pinned_uuid === widget_uuid && widget.level === "org")) { return false; }
        // default to not editable
        return false;
    };

    const toggleWidgetPin = async (widget_uuid: string) => {
        if (pinned_widgets === undefined) { return; }
        if (!isWidgetPinEditable(widget_uuid)) { return; }
        // do the toggle
        if (pinned_widgets.some(widget => widget.pinned_uuid === widget_uuid)) {
            await BackendObj.extractions.removeElementFromPinboard({ pinned_uuid: widget_uuid });
        } else {
            await BackendObj.extractions.addElementToPinboard({ pinned_uuid: widget_uuid, pinned_type: "widget" });
        }
        // update the pinned widgets
        const { elements: new_pinned_widgets } = await BackendObj.extractions.listPinboardElements({ pinned_type: "widget" });
        setPinnedWidgets(new_pinned_widgets);
    };

    // Separate expanded and collapsed widgets
    const expanded_widgets = widgets.filter(widget => expanded_widget_ids.has(widget.uuid));
    const collapsed_widgets = widgets.filter(widget => !expanded_widget_ids.has(widget.uuid));

    // sort expanded widgets by so that wide widgets are only on even positions
    // for that we go from start of the array and swap wide widgets with the next one
    // wide widgets count as two positions
    let position = 0;
    for (let i = 0; i < expanded_widgets.length; i += 1) {
        if (isWidgetWide(expanded_widgets[i]) && position % 2 === 1 && i + 1 < expanded_widgets.length) {
            const temp = expanded_widgets[i];
            expanded_widgets[i] = expanded_widgets[i + 1];
            expanded_widgets[i + 1] = temp;
            position += 1;
        } else if (isWidgetWide(expanded_widgets[i])) {
            position += 2;
        } else {
            position += 1;
        }
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-scroll", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-16 w-full bg-white border-b border-b-sea_blue-700">
            <div className="px-10 py-4 flex flex-row items-center">
                <h2 className="flex-grow text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                    Insights
                </h2>
                {indicator_widgets.length > 0 && (<div className="flex flex-row items-center gap-x-4">
                    <div className="text-sm text-gray-600">Indicator range</div>
                    <div className="flex items-center gap-x-4">
                        <ButtonGroup
                            buttons={[
                                { text: "7", onClick: () => setIndicatorWidgetRange("7d"), selected: indicator_widget_range === "7d" },
                                { text: "30", onClick: () => setIndicatorWidgetRange("30d"), selected: indicator_widget_range === "30d" }
                            ]}
                        />
                    </div>
                </div>)}
            </div>
        </div>

        {widgets.length === 0 ?
            <EmptyList /> :
            <div className="px-10 py-6">
                <div className="space-y-6">
                    {indicator_widgets.length > 0 && (<div className="py-4 flex flex-col w-full ">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
                            {indicator_widgets.map(widget => <IndicatorWidget
                                key={widget.uuid}
                                widget={widget}
                                range={indicator_widget_range}
                                is_pinned={isWidgetPinned(widget.uuid)}
                                is_editable={isWidgetPinEditable(widget.uuid)}
                                onPinToggle={() => toggleWidgetPin(widget.uuid)}
                            />)}
                        </div>
                    </div>)}

                    {expanded_widgets.length > 0 && (<div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                        {expanded_widgets.map((widget) => (<Widget
                            key={widget.uuid}
                            widget={widget}
                            is_pinned={isWidgetPinned(widget.uuid)}
                            is_editable={isWidgetPinEditable(widget.uuid)}
                            lookup_tables={lookup_tables}
                            onClose={() => toggleWidget(widget.uuid)}
                            onPinToggle={() => toggleWidgetPin(widget.uuid)}
                        />))}
                    </div>)}

                    <div className="pt-6 text-lg font-medium text-gray-700">Other Insight Widgets</div>
                    {collapsed_widgets.length > 0 && (<div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
                        {collapsed_widgets.map((widget) => (
                            <div key={widget.uuid} className="bg-white rounded-lg shadow overflow-hidden border border-gray-200 hover:border-gray-300 transition-colors">
                                <div className="px-4 py-3 flex justify-between items-center cursor-pointer hover:bg-sea_blue-50" onClick={() => toggleWidget(widget.uuid)}>
                                    <div className="flex flex-row items-center gap-x-2">
                                        <WidgetIcon widget_type={widget.type} />
                                        <h3 className="text-md font-medium text-gray-700 truncate">{widget.title}</h3>
                                    </div>
                                    <ChevronUpIcon className="h-5 w-5 text-gray-400 flex-shrink-0" />
                                </div>

                                <div className="px-4 py-2 bg-gray-50 border-t border-gray-200 text-xs text-gray-500">
                                    <div className="flex items-center justify-between">
                                        <span className="truncate">Source: {getWidgetLookupTableName(widget)}</span>
                                        {widget.lookup_table_uuid && (
                                            <IconButton
                                                icon={PencilSquareIcon}
                                                onClick={() => navigateToLookupTable(widget.lookup_table_uuid)}
                                                title="Edit lookup table"
                                                size="small"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>)}
                </div>
            </div>
        }
    </div>;
}