import { v4 as uuidv4 } from "uuid";
import {
    unzipSync,
    Zippable,
    zipSync
} from "fflate";

import {
    DEFAULT_PREPROCESS_PARAMS,
    Env,
    FILE_EXTENSIONS_TO_MIME_TYPES,
    ONBOARDING_STEPS,
    onboarding_steps
} from "./consts";
import {
    IContextFieldDataType,
    IPrimitiveType,
    OnboardingStep
} from "../lib/backend/extractions.types.generated";
import {
    IContextField,
    IRecordRaw,
    IScrapeDocument
} from "./types";

export async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
}

export function deepCopyTyped<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function newUuid(): string {
    return uuidv4().replace(/-/g, "");
}

export function isValidInteger(val: string): boolean {
    return /^-?\d+$/.test(val);
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export function prettyNumber(val: number, min_digits: number, max_digits?: number, user_locale?: string): string {
    return new Intl.NumberFormat(user_locale ?? undefined, {
        minimumFractionDigits: min_digits,
        maximumFractionDigits: max_digits !== undefined ? max_digits : min_digits
    }).format(val);
}

export function prettyDateTime(ts: number): string {
    return new Date(ts).toLocaleString();
}

export function prettyDate(ts: number, user_locale?: string): string {
    return new Date(ts).toLocaleDateString(user_locale ?? undefined);
}

export function prettySmartDateTime(ts: number): string {
    const now = Date.now();
    const now_date = prettyDate(now);
    const date = prettyDate(ts);

    // if same day, show time
    if (now_date === date) { return prettyTime(ts); }
    // if yesterday, show "Yesterday"
    if (prettyDate(now - 24 * 3600 * 1000) === date) { return "Yesterday"; }
    // else show date
    return date;
}

export function prettyTime(ts: number): string {
    return new Date(ts).toLocaleTimeString();
}

export function prettyDuration(ts_seconds: number): string {
    const days = Math.floor(ts_seconds / 86400);
    const hours = Math.floor((ts_seconds % 86400) / 3600);
    const minutes = Math.floor((ts_seconds % 3600) / 60);
    const seconds = Math.floor(ts_seconds % 60);

    const parts = [];
    if (days > 0) { parts.push(`${days}d`); }
    if (hours > 0) { parts.push(`${hours}h`); }
    if (minutes > 0 || (days === 0 && hours === 0)) { parts.push(`${minutes}m`); }
    if (seconds > 0 || (days === 0 && hours === 0 && minutes === 0)) { parts.push(`${seconds}s`); }

    return parts.join(" ");
}

export function validateEmail(email: string): boolean {
    // https://stackoverflow.com/a/46181/1780891
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function validatePassword(password: string): boolean {
    // Regular expressions to check for lowercase, uppercase, and digit
    const has_lowercase = /[a-z]/.test(password);
    const has_uppercase = /[A-Z]/.test(password);
    const has_digit = /[0-9]/.test(password);
    const is_long_enough = password.length > 7;

    // Check if all conditions are met
    return has_lowercase && has_uppercase && has_digit && is_long_enough;
}

export function isValidIpAddress(ip: string): boolean {
    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (ipv4Regex.test(ip)) {
        return ip.split(".").every(part => parseInt(part) <= 255);
    }
    const ipv6Regex = /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i;
    if (ipv6Regex.test(ip)) {
        return ip.split(":").every(part => part.length <= 4);
    }
    if (ip.startsWith("::ffff:")) {
        return isValidIpAddress(ip.slice(7));
    }
    return false;
}

export function setDocumentTitle(title: string, env: Env) {
    const prefix = env === "prod" ? "" : `[${env.toUpperCase()}] `;

    if (title.length > 0) {
        document.title = `${prefix}Nordoon - ${title}`;
    } else {
        document.title = `${prefix}Nordoon`;
    }
}

/** Create safe file name from input */
export function sanitizeFileName(s: string): string {
    return s.replace(/[^a-z0-9.-]/gi, "_").toLowerCase();
}

/** This function downloads a object as a JSON file. */
export function downloadObjectAsJson(data: any, filename: string): void {
    const item_str = JSON.stringify(data, null, 2);
    const item_uri = "data:application/json;charset=utf-8," + encodeURIComponent(item_str);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", item_uri);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

// onaboarding step
export function getOnboardingStepIdx(step: OnboardingStep): number {
    return onboarding_steps.indexOf(`${step}`);
}

export function getNextOnboardingStep(step: OnboardingStep): OnboardingStep {
    const idx = getOnboardingStepIdx(step);
    // if not found, return first step
    if (idx === -1) { return ONBOARDING_STEPS.new_account; }
    // if last step, return last step
    if (idx === onboarding_steps.length - 1) { return onboarding_steps[idx] as OnboardingStep; }
    // else, return next step
    return onboarding_steps[idx + 1] as OnboardingStep;
}

/** This function performs hard-reload of the page. It also clears caches. */
export async function hardReloadPage(): Promise<void> {
    const caches_local = caches ?? window?.caches;
    if (caches_local !== undefined) {
        const cache_keys = await caches_local.keys();
        for (const cache_key of cache_keys) {
            await caches.delete(cache_key);
        }
    }
    window.location.reload();
}

/** This function redirects to external page, bypassing or more accurately, escaping React mechanism. */
export function redirectToExternalPage(url: string, new_tab?: boolean): void {
    window.open(url, new_tab ? "_blank" : "_self");
};

export function redirectToExternalPageWithPostData(url: string, new_tab?: boolean, post_data?: any): void {
    if (!post_data) {
        window.open(url, new_tab ? "_blank" : "_self");
        return;
    }

    // Create a hidden form
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;
    form.target = new_tab ? "_blank" : "_self";
    form.setAttribute("novalidate", ""); // Prevent browser validation
    try {
        // Add JSON data as a single hidden field
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "json_data";
        input.value = JSON.stringify(post_data);
        form.appendChild(input);
        // Add form to document, submit it, and remove it
        document.body.appendChild(form);
        form.submit();
    } catch (error) {
        console.error("Failed to redirect with POST data:", error);
    } finally {
        form.remove();
    }
};

export function getExcelColumnName(col_num: number): string {
    let dividend = col_num + 1;
    let column_name = "";
    let modulo: number;

    while (dividend > 0) {
        modulo = (dividend - 1) % 26;
        column_name = String.fromCharCode(65 + modulo) + column_name;
        dividend = Math.floor((dividend - modulo) / 26);
    }

    return column_name;
}

export function getHierarchicalContextExample(fields: IContextField[]): { example: any } {
    // go over context fields and prepare example object with empty values
    const example: any = {};
    for (const field of fields) {
        // break field name into parts
        const parts = field.name.split("÷");
        let pointer = example;
        for (let part_n = 0; part_n < parts.length; part_n++) {
            const is_array = parts[part_n].endsWith("≤≥");
            const part = parts[part_n].replace("≤≥", "");

            if (part_n + 1 === parts.length) {
                // on the last level we set value to empty string
                pointer[part] = is_array ? [] : "";
            } else if (pointer[part] === undefined) {
                // part is not yet defined, we create an object or array
                if (is_array) {
                    pointer[part] = [{}];
                } else {
                    pointer[part] = {};
                }
            } else {
                // we already have an object, we continue with it
            }
            pointer = is_array ? pointer[part][0] : pointer[part];
        }
    }
    return { example };
}

export function getHierarchicalContextSchema(fields: IContextField[]): {
    context_schema: any, array_placeholders: { [key: string]: any }
} {
    // go over context fields and prepare placeholder object
    // in case some fields are arrays, we keep it empty array and create a separate placeholder object for each array type
    const context_schema: any = {};
    const array_placeholders: { [key: string]: any } = {};
    for (const field of fields) {
        // break field name into parts
        const parts = field.name.split("÷");
        let pointer = context_schema;
        for (let part_n = 0; part_n < parts.length; part_n++) {
            const part_path = parts.slice(0, part_n + 1).join("÷");
            const is_array = parts[part_n].endsWith("≤≥");
            const part = parts[part_n].replace("≤≥", "");

            if (part_n + 1 === parts.length) {
                // on the last level we set value to empty string
                pointer[part] = is_array ? [] : "";
                if (is_array) {
                    // this is an array of primitive values, add a placeholder for it
                    array_placeholders[part_path] = "";
                }
            } else if (pointer[part] === undefined) {
                // part is not yet defined, we create an object or array
                if (is_array) {
                    pointer[part] = [];
                    // we also create a placeholder object for prototype object of this array
                    array_placeholders[part_path] = {};
                } else {
                    pointer[part] = {};
                }
            } else {
                // we already have an object, we continue with it
            }
            pointer = is_array ? array_placeholders[part_path] : pointer[part];
        }
    }

    return { context_schema, array_placeholders };
}

// rec is field array if it ends with "≤NUMBER≥"
const is_rec_field_array = (part: string) => /≤(\d+)≥$/.test(part);
const get_rec_field_name = (part: string) => part.replace(/≤\d+≥$/, "");
const get_ref_field_idx = (part: string) => parseInt(part.match(/≤(\d+)≥$/)?.[1] || "0");

export function getHierarchicalRecord(
    flat_record_val: IRecordRaw,
    context_schema: any,
    array_placeholders: { [key: string]: any }
): { record: any } {
    const record = deepCopy(context_schema);
    for (const field in flat_record_val) {
        const parts = field.split("÷");
        let pointer = record;
        for (let part_n = 0; part_n < parts.length; part_n++) {
            const is_array = is_rec_field_array(parts[part_n]);
            const part = get_rec_field_name(parts[part_n]);
            // convert to context-field path (no array index)
            const part_path = is_array ?
                [...parts.slice(0, part_n), `${part}≤≥`].join("÷") :
                parts.slice(0, part_n + 1).join("÷");

            if (pointer[part] === undefined) {
                // unexpected field, we skip it
                break;
            } else if (part_n + 1 === parts.length) {
                if (is_array) {
                    const field_idx = get_ref_field_idx(parts[part_n]);
                    const placeholder_name = part_path.replace(/≤\d+≥/g, "≤≥");
                    const placeholder = array_placeholders[placeholder_name] || {};
                    while (pointer[part].length <= field_idx) {
                        pointer[part].push(deepCopy(placeholder));
                    }
                    pointer[part][field_idx] = flat_record_val[field];
                } else {
                    pointer[part] = flat_record_val[field];
                }
            } else if (is_array) {
                const field_idx = get_ref_field_idx(parts[part_n]);
                // remove indices from path to get placeholder name (replace ≤num≥ with ≤≥)
                const placeholder_name = part_path.replace(/≤\d+≥/g, "≤≥");
                const placeholder = array_placeholders[placeholder_name] || {};
                while (pointer[part].length <= field_idx) {
                    pointer[part].push(deepCopy(placeholder));
                }
                pointer = pointer[part][field_idx];
            } else {
                pointer = pointer[part];
            }
        }
    }

    return { record };
}

export function getTopHierarchicalFields(fields: IContextField[]): string[] {
    const { context_schema } = getHierarchicalContextSchema(fields);
    if (Object.keys(context_schema).length > 1) {
        return Object.keys(context_schema);
    } else if (Object.keys(context_schema).length === 1) {
        const top_key = Object.keys(context_schema)[0];
        return Object.keys(context_schema[top_key]);
    }
    return [];
}

export function isFlatField(field: IContextField): boolean {
    return field.name.indexOf("÷") === -1;
}

export function flattenScrapeDocuments(documents: IScrapeDocument[]): string {
    if (documents === undefined) { return ""; }
    return documents.flatMap(d => d.pages).flatMap(p => p.text).join("\n");
}

export function stringToScrapeDocuments(text: string): IScrapeDocument[] {
    return [{ type: "document", name: "", pages: [{ name: "", text, preprocess_params: DEFAULT_PREPROCESS_PARAMS }] }];
}

export function getUtcHour(local_hour: number): number {
    const offset_hours = new Date().getTimezoneOffset() / 60;
    return (local_hour + offset_hours) % 24;
}

export function getLocalHour(utc_hour: number): number {
    const offset_hours = new Date().getTimezoneOffset() / 60;
    return Math.round(utc_hour - offset_hours) % 24;
}

export function removeFileNameExtension(filename: string): string {
    return filename.replace(/\.[^/.]+$/, "");
}

export function getFileNameExtension(filename: string): string {
    return filename.split('.').pop() || "";
}

export async function unzipFile(file: File): Promise<File[]> {
    const filename_lc = file.name.toLowerCase();
    if (filename_lc.endsWith(".zip")) {
        try {
            console.log("Loading zip file:", file.name);
            const arrayBuffer = await file.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);
            const unzipped = unzipSync(uint8Array);

            const files: File[] = [];
            for (const [name, content] of Object.entries<Uint8Array>(unzipped)) {
                console.log("Unzipping file:", name);
                if (name.endsWith("/")) { continue; }  // Skip directories
                if (name.startsWith("__MACOSX/")) { continue; }  // Skip macOS metadata files
                const file_ext = getFileNameExtension(name);
                const type = FILE_EXTENSIONS_TO_MIME_TYPES[file_ext] || "application/octet-stream";
                const blob = new Blob([content], { type });
                files.push(new File([blob], name, { type }));
            }
            return files;
        } catch (error: any) {
            console.error("Error loading or unzipping file:", error.message);
            throw error;
        }
    }
    return [file];
}

export async function downloadAndZipFiles(urls: string[]): Promise<Blob> {
    // download all urls and zip them
    const responses: Zippable = {};
    for (const url of urls) {
        const response = await fetch(url);
        // get filename from disposition header
        const content_disposition = response.headers.get("Content-Disposition");
        const filename = content_disposition?.split("filename=")[1]?.replace(/^"(.*)"$/, "$1");
        // make filename unique (does not appear in response already)
        if (filename) {
            let unique_filename = filename;
            let n = 1;
            while (responses[unique_filename]) {
                // put n at the end of filename before extension
                const ext = getFileNameExtension(filename);
                unique_filename = filename.replace(`.${ext}`, ``) + `_${n}.${ext}`;
                n++;
            }
            // get response content as Uint8Array
            const arrayBuffer = await response.arrayBuffer();
            const uint8array = new Uint8Array(arrayBuffer);
            responses[unique_filename] = uint8array;
        }
    }
    const zip_uint8array = zipSync(responses);
    return new Blob([zip_uint8array], { type: "application/zip" });
}

export function isValidCodeName(code: string): boolean {
    // must start with letter, can only contain letters, numbers, and underscores
    return /^[a-zA-Z][a-zA-Z0-9_]*$/.test(code);
}

// escape field for CSV and wrap with quotes if needed
export function escapeCsvField(field: string): string {
    if (/[,"\n\r]/.test(field)) {
        return `"${field.replace(/"/g, '""')}"`;
    }
    return field;
}

export function primitiveToStr(value: IPrimitiveType, datatype: IContextFieldDataType, user_locale?: string): string {
    if (datatype === "number") {
        if (value === null || value === "") { return ""; }
        // if string return as is
        if (typeof value === "string") { return value; }
        return prettyNumber(value as number, 0, 4, user_locale);
    } else if (datatype === "date") {
        if (value === null || value === "") { return ""; }
        try {
            // user regex to make sure we have a valid date string
            const date_regex = /^\d{4}-\d{2}-\d{2}$/;
            if (!date_regex.test(value as string)) {
                // strange value, just return what we have
                return value as string;
            }
            // parse the date string and return it as locale date string
            const date_value = new Date(value as string);
            return prettyDate(date_value.getTime(), user_locale);
        } catch (error) {
            return "";
        }
    }
    return `${value}`;
}

export function strToPrimitive(str: string, datatype: IContextFieldDataType, user_locale?: string): IPrimitiveType | undefined {
    if (datatype === "string") {
        return str;
    } else if (datatype === "number") {
        if (str === "") { return null; }
        // first check if it looks like a number
        if (!isLocalNumber(str, user_locale)) { return undefined; }
        // we need to parse the number using browsers locale
        const num = parseLocalNumber(str, user_locale);
        return isNaN(num) ? undefined : num;
    } else if (datatype === "date") {
        if (str === "") { return ""; }
        // try to parse the date using browsers locale and return it as yyyy-mm-dd string
        try {
            const date_str = parseLocalDate(str, user_locale);
            // make sure we have a valid date string
            const date_regex = /^\d{4}-\d{2}-\d{2}$/;
            if (!date_regex.test(date_str)) {
                return undefined;
            }
            return date_str;
        } catch (error) {
            return undefined;
        }
    } else if (datatype === "enum") {
        return str;
    }
}

// Utility function for hashing email addresses
export const hashEmail = async (email: string): Promise<string> => {
    const hash = await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(email.toLowerCase()));
    return Array.from(new Uint8Array(hash))
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
};

function isLocalNumber(_value: string, user_locale?: string): boolean {
    // Get locale-specific number formatting information
    const number_format = new Intl.NumberFormat(user_locale);
    const parts = number_format.formatToParts(1234.5);
    const decimal_separator = parts.find(part => part.type === "decimal")?.value || ".";
    const _group_separator = parts.find(part => part.type === "group")?.value || ",";

    // compensate for difference between ’ and ' in case of Swiss numbers
    const { group_separator, value } = compensate(_group_separator, _value);

    // Escape special regex characters if they exist in the separators
    const escaped_decimal = decimal_separator.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const escaped_group = group_separator.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // Create regex that allows:
    // - Optional leading minus or plus sign
    // - Digits with optional thousands separators in correct positions
    // - Optional decimal part
    const regex = new RegExp(
        `^[-+]?` + // Optional sign
        `(\\d{1,3}(${escaped_group}\\d{3})*|\\d+)` + // Integer part with optional thousands separators
        `(${escaped_decimal}\\d+)?$` // Optional decimal part
    );

    return regex.test(value);
}

/**
 * Parses a string as a number accounting for locale-specific decimal and thousands separators
 * @param value The string value to parse
 * @returns The parsed number or NaN if parsing fails
 */
function parseLocalNumber(_value: string, user_locale?: string): number {
    // Get the locale-specific separators
    const number_format = new Intl.NumberFormat(user_locale ?? undefined);
    const parts = number_format.formatToParts(1234.5);
    const decimal_separator = parts.find(part => part.type === "decimal")?.value || ".";
    const _group_separator = parts.find(part => part.type === "group")?.value || ",";

    // compensate for special regional characters
    const { group_separator, value } = compensate(_group_separator, _value);

    // Remove all group separators and replace decimal separator with standard period
    let normalized_value = value;
    if (group_separator) {
        // Use a global regex to remove all group separators
        const group_regex = new RegExp(group_separator.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g");
        normalized_value = normalized_value.replace(group_regex, "");
    }

    if (decimal_separator !== ".") {
        normalized_value = normalized_value.replace(decimal_separator, ".");
    }

    // Parse the normalized string
    return parseFloat(normalized_value);
}

function compensate(group_separator: string, value: string): { group_separator: string, value: string } {
    // compensate for difference between ’ and ' and ` in case of Swiss numbers
    if (group_separator === "’") {
        group_separator = "'";
        value = value.replaceAll("’", "'").replaceAll("`", "'");
    }
    // compensate for difference between ' ' and ' ' in case of French numbers
    if (group_separator === " ") {
        group_separator = " ";
        value = value.replaceAll(" ", " ");
    }
    return { group_separator, value };
}

export function niceRound(num: number): number {
    let str = num.toString();

    // Check if the number has excessive decimal places
    if (str.includes('.')) {
        let decimals = str.split('.')[1];

        // Find the first significant decimal place difference
        let precision = 0;
        for (let i = 0; i < decimals.length; i++) {
            if (decimals[i] !== '0') {
                precision = i + 1; // Use first non-zero digit as precision
                break;
            }
        }

        // Enforce minimum of 2 decimal places for readability
        precision = Math.max(precision, 5);

        return Number(num.toFixed(precision));
    }

    return num;
}

// convert three numbers to YYYY-MM-DD string
function getISODateFromNumbers(year: number, month: number, day: number): string {
    // make sure if day or month is single digit, we add a leading zero
    const month_str = month < 10 ? `0${month}` : month;
    const day_str = day < 10 ? `0${day}` : day;
    return `${year}-${month_str}-${day_str}`;
}

function getDateOrder(user_locale?: string): "day-month" | "month-day" {
    if (user_locale === undefined) {
        // figure out date order from browser locale
        const date_formatter = new Intl.DateTimeFormat();
        const date_parts = date_formatter.formatToParts(new Date());
        const day_part_idx = date_parts.findIndex(part => part.type === "day");
        const month_part_idx = date_parts.findIndex(part => part.type === "month");
        if (day_part_idx === -1 || month_part_idx === -1) { return "day-month"; }
        return day_part_idx < month_part_idx ? "day-month" : "month-day";
    }
    return user_locale.startsWith("en-US") ? "month-day" : "day-month";
}

export function parseLocalDate(date_str: string, user_locale?: string): string {
    // check if we have us or non-us locale
    const is_us_locale = getDateOrder(user_locale) === "month-day";

    // check if we have only two numbers and interpret them as month and day
    // depending on US locale, we interpret it as mm/dd or dd/mm
    // numbers can be separated by / or . or - or , or space
    const short_date_regex = /^\d{1,2}[/.\- ,]\d{1,2}$/;
    if (short_date_regex.test(date_str)) {
        const parts = date_str.split(/[/.\- ,]/);
        const first_num = parseInt(parts[0]);
        const second_num = parseInt(parts[1]);
        const month = is_us_locale ? first_num : second_num;
        const day = is_us_locale ? second_num : first_num;
        // make sure month and day are in the correct range
        if (month < 1 || month > 12) {
            throw new Error("Invalid month");
        }
        if (day < 1 || day > 31) {
            throw new Error("Invalid day");
        }
        // we use current year if month and day are in the future, otherwise we use next year
        const now = new Date();
        const year = month > now.getMonth() + 1 || (month === now.getMonth() + 1 && day >= now.getDate()) ? now.getFullYear() : now.getFullYear() + 1;
        return getISODateFromNumbers(year, month, day);
    }

    // check if we have three numbers and we can interpret them as mm/dd/yy or dd/mm/yy or mm/dd/yyyy or dd/mm/yyyy
    const long_date_regex = /^\d{1,2}[/.\- ,]\d{1,2}[/.\- ,]\d{2,4}$/;
    if (long_date_regex.test(date_str)) {
        const parts = date_str.split(/[/.\- ,]/);
        const first_num = parseInt(parts[0]);
        const second_num = parseInt(parts[1]);
        const third_num = parseInt(parts[2]);
        const month = is_us_locale ? first_num : second_num;
        const day = is_us_locale ? second_num : first_num;
        const year = third_num < 100 ? third_num + 2000 : third_num;
        // make sure month and day are in the correct range
        if (month < 1 || month > 12) {
            throw new Error("Invalid month");
        }
        if (day < 1 || day > 31) {
            throw new Error("Invalid day");
        }
        return getISODateFromNumbers(year, month, day);
    }

    // try if we can do it with the default date parser
    const date = new Date(date_str);
    return getISODateFromNumbers(date.getFullYear(), date.getMonth() + 1, date.getDate());
}
