import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    CheckIcon,
    EyeIcon,
    PencilSquareIcon,
    PlusIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";

import {
    selectMemberships
} from "../lib/scraper.slice";
import {
    ILookupTable
} from "../lib/types";
import {
    BackendObj
} from "../lib/backend";
import {
    IChart,
    IWidget,
    IWidgetDateCountChartDetails,
    IWidgetDateDistributionChartDetails,
    IWidgetDateDistributionChartValuesType,
    IWidgetDateSumChartDetails,
    IWidgetDateTagsChartDetails,
    IWidgetDetails,
    IWidgetFilter,
    IWidgetSumNumberDetails,
    IWidgetTagsChartDetails,
    WidgetType
} from "../lib/backend/extractions.types.generated";

import { Button, ButtonGroup } from "../components/Button";
import { Chart } from "../components/Chart";
import { ConfirmModal } from "../components/ConfirmModal";
import { Textbox } from "../components/Textbox";
import { Dropdown } from "../components/Dropdown";
import { SidePanelRaw } from "../components/SidePanel";
import { LongText } from "../components/LongText";
import { ButtonMenu } from "./ButtonMenu";

function getWidgetDetails(type: WidgetType): IWidgetDetails {
    switch (type) {
        case "date_count_chart": return { date_header_idx: 0 };
        case "date_distribution_chart": return { date_header_idx: 0, values_header_idx: 1 };
        case "date_tags_chart": return { date_header_idx: 0, tags_headers_idx: [1], tags_header_names: ["Tag"] };
        case "tags_chart": return { date_header_idx: 0, tags_headers_idx: [1], tags_header_names: ["Tag"] };
        case "date_sum_chart": return { date_header_idx: 0, values_headers_idx: [1], values_header_names: ["Sum"] };
        case "sum_number": return { date_header_idx: 0, values_headers_idx: [1], values_header_names: ["Sum"] };
    }
}

type WidgetListProps = {
    lookup_table: ILookupTable;
}

export function WidgetList(props: WidgetListProps) {
    const { lookup_table } = props;

    const memberships = useSelector(selectMemberships);
    const is_org_admin = memberships.filter((m) => m.org.uuid === lookup_table?.org_uuid && m.role === "admin").length > 0;

    const [widgets, setWidgets] = useState<IWidget[]>([]);
    const [show_side_panel, setShowSidePanel] = useState<"new_widget" | "edit_widget" | undefined>(undefined);
    const [edit_widget_uuid, setEditWidgetUuid] = useState<string | undefined>(undefined);
    const [delete_widget_uuid, setDeleteWidgetUuid] = useState<string | undefined>(undefined);
    const [new_widget_name, setNewWidgetName] = useState("");
    const [new_widget_filters, setNewWidgetFilters] = useState<IWidgetFilter[]>([]);
    const [new_widget_type, setNewWidgetType] = useState<WidgetType>("date_count_chart");
    const [new_widget_details, setNewWidgetDetails] = useState<IWidgetDetails>(getWidgetDetails("date_count_chart"));
    const [widget_chart, setWidgetChart] = useState<{ widget: IWidget, chart: IChart } | undefined>(undefined);
    const [is_saving_widget, setIsSavingWidget] = useState(false);

    useEffect(() => {
        BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid })
            .then(({ widgets }) => { setWidgets(widgets); })
            .catch((error) => { setWidgets([]); console.error(error); });
    }, [lookup_table]);

    const openNewWidget = () => {
        setShowSidePanel("new_widget");
        setNewWidgetName("");
        setNewWidgetFilters([]);
        setNewWidgetType("date_count_chart");
        setNewWidgetDetails(getWidgetDetails("date_count_chart"));
    }

    const openEditWidget = (widget: IWidget) => {
        setShowSidePanel("edit_widget");
        setEditWidgetUuid(widget.uuid);
        setNewWidgetName(widget.title);
        setNewWidgetFilters(widget.filters);
        setNewWidgetType(widget.type);
        setNewWidgetDetails(widget.details);
    }

    const openDeleteWidget = (widget: IWidget) => {
        setDeleteWidgetUuid(widget.uuid);
    }

    const addNewWidgetFilter = (type: "fixed" | "dynamic") => {
        setNewWidgetFilters([...new_widget_filters, { column_idx: 0, values: [""], type }]);
    }

    const updateNewWidgetFilterColumnIdx = (idx: number, value: number) => {
        const old_filter = new_widget_filters[idx];
        if (old_filter === undefined) { return; }
        old_filter.column_idx = value;
        setNewWidgetFilters([...new_widget_filters.slice(0, idx), old_filter, ...new_widget_filters.slice(idx + 1)]);
    }

    const updateNewWidgetFilterValue = (idx: number, value_idx: number, value: string) => {
        const old_filter = new_widget_filters[idx];
        if (old_filter === undefined) { return; }
        old_filter.values[value_idx] = value;
        // remove empty values
        old_filter.values = old_filter.values.filter((value) => value.trim() !== "");
        // ensure at least one value
        if (old_filter.values.length === 0) { old_filter.values.push(""); }
        setNewWidgetFilters([...new_widget_filters.slice(0, idx), old_filter, ...new_widget_filters.slice(idx + 1)]);
    }

    const addNewWidgetFilterValue = (idx: number) => {
        const old_filter = new_widget_filters[idx];
        if (old_filter === undefined) { return; }
        old_filter.values.push("");
        setNewWidgetFilters([...new_widget_filters.slice(0, idx), old_filter, ...new_widget_filters.slice(idx + 1)]);
    }

    const removeNewWidgetFilter = (idx: number) => {
        setNewWidgetFilters(new_widget_filters.filter((_, i) => i !== idx));
    }

    const updateNewWidgetType = (type: WidgetType) => {
        setNewWidgetType(type);
        setNewWidgetDetails(getWidgetDetails(type));
    }

    const updateNewWidgetTagsHeadersIdx = (tag_idx: number, new_value: string) => {
        console.log({ tag_idx, new_value });
        if (new_value === "/") {
            // remove tag header
            setNewWidgetDetails({
                ...new_widget_details,
                tags_headers_idx: (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails))
                    .tags_headers_idx.filter((_, idx) => idx !== tag_idx)
            });
        } else {
            // update tag value
            setNewWidgetDetails({
                ...new_widget_details,
                tags_headers_idx: (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails))
                    .tags_headers_idx.map((idx, idx_idx) => (idx_idx === tag_idx) ? parseInt(new_value) : idx)
            });
        }
    }

    const updateNewWidgetTagsHeaderName = (tag_idx: number, value: string) => {
        setNewWidgetDetails({
            ...new_widget_details,
            tags_header_names: (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails)).tags_header_names.map((name, idx) => (idx === tag_idx) ? value : name)
        });
    }

    const addNewWidgetTagHeaderIdx = () => {
        setNewWidgetDetails({
            ...new_widget_details,
            tags_headers_idx: [...(new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails)).tags_headers_idx, 0],
            tags_header_names: [...(new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails)).tags_header_names, ""]
        });
    }

    const updateNewWidgetValuesHeadersIdx = (values_idx: number, new_value: string) => {
        console.log({ values_idx, new_value });
        if (new_value === "/") {
            // remove tag header
            setNewWidgetDetails({
                ...new_widget_details,
                values_headers_idx: (new_widget_details as (IWidgetDateSumChartDetails | IWidgetSumNumberDetails))
                    .values_headers_idx.filter((_, idx) => idx !== values_idx)
            });
        } else {
            // update tag value
            setNewWidgetDetails({
                ...new_widget_details,
                values_headers_idx: (new_widget_details as (IWidgetDateSumChartDetails | IWidgetSumNumberDetails))
                    .values_headers_idx.map((idx, idx_idx) => (idx_idx === values_idx) ? parseInt(new_value) : idx)
            });
        }
    }

    const updateNewWidgetValuesHeaderName = (values_idx: number, value: string) => {
        setNewWidgetDetails({
            ...new_widget_details,
            values_header_names: (new_widget_details as (IWidgetDateSumChartDetails | IWidgetSumNumberDetails))
                .values_header_names.map((name, idx) => (idx === values_idx) ? value : name)
        });
    }

    const addNewWidgetValuesHeaderIdx = () => {
        setNewWidgetDetails({
            ...new_widget_details,
            values_headers_idx: [...(new_widget_details as (IWidgetDateSumChartDetails | IWidgetSumNumberDetails)).values_headers_idx, 0],
            values_header_names: [...(new_widget_details as (IWidgetDateSumChartDetails | IWidgetSumNumberDetails)).values_header_names, ""]
        });
    }

    const cancelNewWidget = () => {
        setShowSidePanel(undefined);
        setNewWidgetName("");
        setNewWidgetFilters([]);
        setNewWidgetType("date_count_chart");
        setNewWidgetDetails(getWidgetDetails("date_count_chart"));
    }

    const createNewWidget = async () => {
        setIsSavingWidget(true);
        setShowSidePanel(undefined);
        try {
            await BackendObj.extractions.createWidget({
                lookup_table_uuid: lookup_table.uuid,
                title: new_widget_name,
                type: new_widget_type,
                filters: new_widget_filters,
                details: new_widget_details
            });
            // refresh widgets
            const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid });
            setWidgets(new_widgets);
        } catch (error) {
            console.error(error);
        }
        setIsSavingWidget(false);
    }

    const saveEditWidget = async () => {
        if (edit_widget_uuid === undefined) { return; }
        setIsSavingWidget(true);
        setShowSidePanel(undefined);
        try {
            await BackendObj.extractions.updateWidget({
                widget_uuid: edit_widget_uuid,
                title: new_widget_name,
                filters: new_widget_filters,
                details: new_widget_details
            });
            // refresh widgets
            const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid });
            setWidgets(new_widgets);
        } catch (error) {
            console.error(error);
        }
        setIsSavingWidget(false);
    }

    const previewEditWidget = async (widget: IWidget) => {
        const { chart } = await BackendObj.extractions.previewWidget({ widget_uuid: widget.uuid, range: "7d", additional_filters: [] });
        setWidgetChart({ widget, chart });
    }

    const removeWidget = async (result: boolean) => {
        if (delete_widget_uuid === undefined) { return; }
        if (result) {
            setIsSavingWidget(true);
            try {
                await BackendObj.extractions.deleteWidget({ widget_uuid: delete_widget_uuid });
                const { widgets: new_widgets } = await BackendObj.extractions.listWidgets({ lookup_table_uuid: lookup_table.uuid });
                setWidgets(new_widgets);
            } catch (error) {
                console.error(error);
            }
            setIsSavingWidget(false);
        }
        setDeleteWidgetUuid(undefined);
    }

    // prepare list of tags headers for dropdown
    const possible_tags_headers_ids = lookup_table.headers.map((_, idx) => `${idx}`);
    const possible_tags_headers_values = lookup_table.headers.map((header) => header);
    // if there is only one tag header, we don't need to show the "/" separator
    if (["date_tags_chart", "tags_chart"].includes(new_widget_type) && (new_widget_details as (IWidgetDateTagsChartDetails | IWidgetTagsChartDetails)).tags_headers_idx.length > 1) {
        possible_tags_headers_ids.unshift("/");
        possible_tags_headers_values.unshift("/");
    }

    // prepare list of value headers for sums
    const possible_values_headers_ids = lookup_table.headers.map((_, idx) => `${idx}`);
    const possible_values_headers_values = lookup_table.headers.map((header) => header);
    if (["date_sum_chart", "sum_number"].includes(new_widget_type) && (new_widget_details as (IWidgetDateSumChartDetails | IWidgetSumNumberDetails)).values_headers_idx.length > 1) {
        possible_values_headers_ids.unshift("/");
        possible_values_headers_values.unshift("/");
    }

    return <div className="w-full">
        {widgets.length > 0 && <div className="py-3 flex flex-row items-center w-full">
            <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5 w-full">
                <table className="w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">Title</th>
                            <th className="text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">Type</th>
                            <th className="w-1 text-right px-3 text-sm cursor-pointer font-semibold text-gray-900">
                                {is_org_admin && <Button icon={PlusIcon} onClick={openNewWidget} disabled={is_saving_widget} loading={is_saving_widget} />}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {widgets.map((widget) => <tr key={widget.uuid}>
                            <td className="p-3 text-sm text-gray-900">
                                <LongText text={widget.title} line_limit={1} />
                            </td>
                            <td className="p-3 text-sm text-gray-900">
                                <LongText text={widget.type} line_limit={1} />
                            </td>
                            <td className="w-32 text-right px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900">
                                {is_org_admin && <ButtonGroup buttons={[
                                    { icon: PencilSquareIcon, tooltip: "Edit", text: "", onClick: () => openEditWidget(widget) },
                                    { icon: EyeIcon, tooltip: "Preview", text: "", onClick: () => previewEditWidget(widget) },
                                    { icon: TrashIcon, tooltip: "Delete", text: "", onClick: () => openDeleteWidget(widget) }
                                ]} disabled={is_saving_widget} />}
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>}
        {widgets.length === 0 && <div className="py-3 flex flex-row items-center w-full">
            <div className="text-sm text-gray-900">No visualizations found</div>
            <div className="flex-grow" />
            {is_org_admin && <Button icon={PlusIcon} onClick={openNewWidget} disabled={is_saving_widget} loading={is_saving_widget} />}
        </div>}

        <SidePanelRaw open={show_side_panel !== undefined} size="2xl" onClose={cancelNewWidget}>
            <div className="grid grid-cols-3 gap-4 px-4 py-6 items-center">
                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Visualization Title
                </label>
                <div className="col-span-2">
                    <Textbox
                        value={new_widget_name || ""}
                        onChange={(value) => setNewWidgetName(value)}
                    />
                </div>
                {new_widget_filters.map((filter, filter_idx) => <Fragment key={filter_idx}>
                    {filter.type === "fixed" && <Fragment>
                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Filter {filter_idx + 1} (fixed values)
                        </label>
                        <div className="flex flex-col items-start">
                            <Dropdown
                                values={lookup_table.headers.map((header) => header)}
                                ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                                selected={`${filter.column_idx}`}
                                onChange={(value) => updateNewWidgetFilterColumnIdx(filter_idx, parseInt(value))}
                            />
                        </div>
                        {filter.values.map((value, value_idx) => <Fragment key={value_idx}>
                            {value_idx > 0 && <div className="flex flex-col w-full gap-y-2" />}
                            {value_idx > 0 && <div className="flex flex-col w-full gap-y-2" />}
                            <div className="flex flex-col w-full gap-y-2">
                                <Textbox
                                    key={value_idx}
                                    value={value}
                                    onChange={(value) => updateNewWidgetFilterValue(filter_idx, value_idx, value)}
                                />
                            </div>
                        </Fragment>)}
                        {filter.values.length > 0 && <div className="flex flex-col w-full gap-y-2" />}
                        <Button icon={TrashIcon} text="Remove Filter" onClick={() => removeNewWidgetFilter(filter_idx)} />
                        <Button icon={PlusIcon} text="Add Value" onClick={() => addNewWidgetFilterValue(filter_idx)} />
                    </Fragment>}
                    {filter.type === "dynamic" && <Fragment>
                        <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            Filter {filter_idx + 1} (dynamic values)
                        </label>
                        <div className="flex flex-col items-start">
                            <Dropdown
                                values={lookup_table.headers.map((header) => header)}
                                ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                                selected={`${filter.column_idx}`}
                                onChange={(value) => updateNewWidgetFilterColumnIdx(filter_idx, parseInt(value))}
                            />
                        </div>
                        <Button icon={TrashIcon} text="Remove Filter" onClick={() => removeNewWidgetFilter(filter_idx)} />
                    </Fragment>}
                </Fragment>)}
                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Visualization Type
                </label>
                <div className="col-span-2">
                    <Dropdown
                        values={["date_count_chart", "date_distribution_chart", "date_tags_chart", "tags_chart", "date_sum_chart", "sum_number"]}
                        ids={["date_count_chart", "date_distribution_chart", "date_tags_chart", "tags_chart", "date_sum_chart", "sum_number"]}
                        selected={new_widget_type}
                        disabled={show_side_panel === "edit_widget"}
                        onChange={(value) => updateNewWidgetType(value as WidgetType)}
                    />
                </div>

                {new_widget_type === "date_count_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateCountChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                </Fragment>}

                {new_widget_type === "date_distribution_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateDistributionChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Values Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateDistributionChartDetails).values_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, values_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Values Type
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={["Validation Results", "Yes/No", "All Values"]}
                            ids={["validation", "yes_no", "all_values"]}
                            selected={`${(new_widget_details as IWidgetDateDistributionChartDetails).type}`}
                            onChange={(id) => setNewWidgetDetails({ ...new_widget_details, type: id as IWidgetDateDistributionChartValuesType })}
                        />
                    </div>
                </Fragment>}

                {new_widget_type === "date_tags_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateTagsChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Tags Headers
                    </label>
                    {(new_widget_details as IWidgetDateTagsChartDetails).tags_headers_idx.map((tag_headers_idx, tag_idx) => <Fragment key={tag_idx}>
                        {tag_idx > 0 && <div />}
                        <div className="flex flex-row w-full gap-x-2 col-span-2">
                            <Dropdown
                                values={possible_tags_headers_values}
                                ids={possible_tags_headers_ids}
                                selected={`${tag_headers_idx}`}
                                onChange={(value) => updateNewWidgetTagsHeadersIdx(tag_idx, value)}
                            />
                            <Textbox
                                value={`${(new_widget_details as IWidgetDateTagsChartDetails).tags_header_names[tag_idx]}`}
                                onChange={(value) => updateNewWidgetTagsHeaderName(tag_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    <div className="col-span-2" />
                    <Button icon={PlusIcon} text="Add Tag" onClick={addNewWidgetTagHeaderIdx} />
                </Fragment>}

                {new_widget_type === "tags_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetTagsChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Tags Headers
                    </label>
                    {(new_widget_details as IWidgetTagsChartDetails).tags_headers_idx.map((tag_headers_idx, tag_idx) => <Fragment key={tag_idx}>
                        {tag_idx > 0 && <div />}
                        <div className="flex flex-row w-full gap-x-2 col-span-2">
                            <Dropdown
                                values={possible_tags_headers_values}
                                ids={possible_tags_headers_ids}
                                selected={`${tag_headers_idx}`}
                                onChange={(value) => updateNewWidgetTagsHeadersIdx(tag_idx, value)}
                            />
                            <Textbox
                                value={`${(new_widget_details as IWidgetDateTagsChartDetails).tags_header_names[tag_idx]}`}
                                onChange={(value) => updateNewWidgetTagsHeaderName(tag_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    <div className="col-span-2" />
                    <Button icon={PlusIcon} text="Add Tag" onClick={addNewWidgetTagHeaderIdx} />
                </Fragment>}

                {new_widget_type === "date_sum_chart" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetDateSumChartDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Values Headers
                    </label>
                    {(new_widget_details as IWidgetDateSumChartDetails).values_headers_idx.map((values_headers_idx, values_idx) => <Fragment key={values_idx}>
                        {values_idx > 0 && <div />}
                        <div className="flex flex-row w-full gap-x-2 col-span-2">
                            <Dropdown
                                values={possible_values_headers_values}
                                ids={possible_values_headers_ids}
                                selected={`${values_headers_idx}`}
                                onChange={(value) => updateNewWidgetValuesHeadersIdx(values_idx, value)}
                            />
                            <Textbox
                                value={`${(new_widget_details as IWidgetDateSumChartDetails).values_header_names[values_idx]}`}
                                onChange={(value) => updateNewWidgetValuesHeaderName(values_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    <div className="col-span-2" />
                    <Button icon={PlusIcon} text="Add Value" onClick={addNewWidgetValuesHeaderIdx} />
                </Fragment>}

                {new_widget_type === "sum_number" && <Fragment>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Date Header
                    </label>
                    <div className="flex flex-col w-full gap-y-2 col-span-2">
                        <Dropdown
                            values={lookup_table.headers.map((header) => header)}
                            ids={lookup_table.headers.map((_, idx) => `${idx}`)}
                            selected={`${(new_widget_details as IWidgetSumNumberDetails).date_header_idx}`}
                            onChange={(value) => setNewWidgetDetails({ ...new_widget_details, date_header_idx: parseInt(value) })}
                        />
                    </div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Values Headers
                    </label>
                    {(new_widget_details as IWidgetSumNumberDetails).values_headers_idx.map((values_headers_idx, values_idx) => <Fragment key={values_idx}>
                        {values_idx > 0 && <div />}
                        <div className="flex flex-row w-full gap-x-2 col-span-2">
                            <Dropdown
                                values={possible_values_headers_values}
                                ids={possible_values_headers_ids}
                                selected={`${values_headers_idx}`}
                                onChange={(value) => updateNewWidgetValuesHeadersIdx(values_idx, value)}
                            />
                            <Textbox
                                value={`${(new_widget_details as IWidgetSumNumberDetails).values_header_names[values_idx]}`}
                                onChange={(value) => updateNewWidgetValuesHeaderName(values_idx, value)}
                            />
                        </div>
                    </Fragment>)}
                    <div className="col-span-2" />
                    <Button icon={PlusIcon} text="Add Value" onClick={addNewWidgetValuesHeaderIdx} />
                </Fragment>}

            </div>
            <div className="flex flex-row items-center justify-end gap-x-2 p-4">
                <ButtonMenu
                    title="Add Filter"
                    items={[
                        { title: "Fixed Values", onClick: () => addNewWidgetFilter("fixed") },
                        { title: "Dynamic Values", onClick: () => addNewWidgetFilter("dynamic") }
                    ]}
                />
                <Button icon={XMarkIcon} text="Cancel" onClick={cancelNewWidget} />
                {show_side_panel === "new_widget" && <Button icon={PlusIcon} text="Create" onClick={createNewWidget} />}
                {show_side_panel === "edit_widget" && <Button icon={CheckIcon} text="Save" onClick={saveEditWidget} />}
            </div>
        </SidePanelRaw>

        {widget_chart !== undefined && <ConfirmModal
            open={widget_chart !== undefined}
            title="Preview"
            confirm="Close"
            hide_cancel={true}
            hide_icon={true}
            size="3xl"
            onClose={() => { setWidgetChart(undefined); }}
        >
            <div className="py-4"><Chart widget={widget_chart.widget} chart={widget_chart.chart} /></div>
        </ConfirmModal>}

        <ConfirmModal
            open={delete_widget_uuid !== undefined}
            title="Remove Visualization"
            message={["Are you sure you want to remove this visualization?"]}
            confirm="Remove"
            onClose={removeWidget} />
    </div>;
}