import { Fragment, useEffect, useState } from "react";

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import {
    ArrowPathIcon,
    CheckIcon,
    PencilIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";

import { classNames, isValidInteger, prettyDate, prettyDateTime } from "../lib/utils";
import { BackendObj } from "../lib/backend";
import { IOrganization, ITransaction, TransactionType } from "../lib/backend/auth.types.generated";

import { Pagination } from "./Pagination";
import { LoadingSpinnerLimit } from "./LoadingSpinner";
import { Button } from "./Button";
import { DateTextbox, Textbox } from "./Textbox";
import { Checkbox } from "./Checkbox";
import { ConfirmModal } from "./ConfirmModal";

function prettyTransactionType(type: TransactionType): string {
    switch (type) {
        case "purchase": return "Purchase";
        case "refund": return "Refund";
        case "usage": return "Usage";
        case "subscription": return "Subscription";
        case "expiration": return "Expiration";
        case "free": return "Free";
        case "referral": return "Referral";
        case "automatic_expiration": return "Subscription Expired";
        default: return type;
    }
}

type TransactionHistoryProps = {
    is_admin: boolean;
    org: IOrganization;
    open: boolean;
    ticker?: number;
    setOpen: (open: boolean) => void;
    refreshCredits?: () => void;
}

export function TransactionHistory(props: TransactionHistoryProps) {
    const { is_admin, org, open, setOpen, refreshCredits, ticker } = props;

    const [transaction_list, setTransactionList] = useState<{ total: number, transactions: ITransaction[] } | undefined>(undefined);
    const [offset, setOffset] = useState(0);
    const [show_only_positive, setShowOnlyPositive] = useState(false);
    const [edit_uuid, setEditUuid] = useState<string | undefined>(undefined);
    const [edit_amount, setEditAmount] = useState<string | undefined>(undefined);
    const [edit_text, setEditText] = useState<string | undefined>("");
    const [edit_expiration_date, setEditExpirationDate] = useState<Date | undefined>(undefined);
    const [is_expiration_date_valid, setIsExpirationDateValid] = useState<boolean>(true);
    const [is_saving, setIsSaving] = useState(false);
    const [confirm_delete, setConfirmDelete] = useState<string | undefined>(undefined);

    const limit = 15;

    useEffect(() => {
        setTransactionList(undefined);
        if (is_admin) {
            BackendObj.auth.getAdminOrgTransactionHistory({ org_uuid: org.uuid, offset, limit, show_only_positive })
                .then(({ total, transactions }) => { setTransactionList({ total, transactions }); });
        } else {
            BackendObj.auth.getOrgTransactionHistory({ org_uuid: org.uuid, offset, limit, show_only_positive })
                .then(({ total, transactions }) => { setTransactionList({ total, transactions }); });
        }
    }, [is_admin, offset, org, show_only_positive, ticker]);

    const startEdit = (transaction: ITransaction) => {
        setEditUuid(transaction.uuid);
        setEditAmount(`${transaction.amount}`);
        setEditText(transaction.details.text);
        setEditExpirationDate(transaction.expiration_ts ? new Date(transaction.expiration_ts) : undefined);
        setIsExpirationDateValid(true);
    };

    const saveEdit = async () => {
        if (!is_admin) { return; }
        // make sure we have valid data
        if (edit_uuid === undefined || edit_amount === undefined || edit_text === undefined || !isValidInteger(edit_amount)) { return; }
        // find the transaction
        const old_transaction = transaction_list?.transactions.find(transaction => transaction.uuid === edit_uuid);
        if (old_transaction !== undefined) {
            // save
            setIsSaving(true);
            try {
                const expiration_ts = edit_expiration_date ? edit_expiration_date.getTime() : undefined;
                await BackendObj.auth.editAdminOrgCredit({
                    org_uuid: old_transaction.org_uuid,
                    transaction_uuid: edit_uuid,
                    amount: Number(edit_amount),
                    details_text: edit_text,
                    expiration_ts
                });
                // update the transaction list
                const new_transaction_list = await BackendObj.auth.getAdminOrgTransactionHistory({
                    org_uuid: org.uuid,
                    offset,
                    limit,
                    show_only_positive
                });
                setTransactionList(new_transaction_list);
                if (refreshCredits) { refreshCredits(); }
            } catch (error) {
                console.error(error);
            }
        }
        setEditUuid(undefined);
        setEditAmount(undefined);
        setEditText("");
        setIsSaving(false);
    }

    const refreshHistory = () => {
        if (!is_admin) { return; }
        setTransactionList(undefined);
        BackendObj.auth.getAdminOrgTransactionHistory({ org_uuid: org.uuid, offset, limit, show_only_positive })
            .then(({ total, transactions }) => { setTransactionList({ total, transactions }); });
    }

    const onConfirmDelete = (result: boolean) => {
        if (!is_admin) { return; }
        if (result && confirm_delete !== undefined) {
            BackendObj.auth.deleteAdminOrgCreditTransaction({ org_uuid: org.uuid, transaction_uuid: confirm_delete });
        }
        setConfirmDelete(undefined);
        refreshHistory();
        if (refreshCredits) { refreshCredits(); }
    }

    // check if edit_amount is valid number
    const is_valid = edit_amount !== undefined && isValidInteger(edit_amount) && is_expiration_date_valid;

    return <Fragment>
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <TransitionChild
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <DialogPanel className="pointer-events-auto w-screen max-w-2xl">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex flex-row items-center justify-between">
                                                <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                                                    Transaction History for {org.name}
                                                </DialogTitle>
                                                <div className="flex-grow" />
                                                <div className="flex flex-row gap-x-2 items-center text-xs px-4">
                                                    <Checkbox
                                                        id="show_only_positive"
                                                        checked={show_only_positive}
                                                        setChecked={setShowOnlyPositive}
                                                    />
                                                    <label htmlFor="show_only_positive" className="text-gray-700">
                                                        Only positive
                                                    </label>
                                                </div>
                                                {is_admin && <Button onClick={refreshHistory} icon={ArrowPathIcon} highlight={false} />}
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">

                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" />
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Details</th>
                                                        {is_admin && < th />}
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {transaction_list && transaction_list.transactions.map((transaction, idx) => (
                                                        <Fragment key={idx}>
                                                            <tr className={classNames("text-sm text-gray-500", edit_uuid === transaction.uuid ? "font-semibold text-gray-900" : "")}>
                                                                <td className="whitespace-nowrap py-4 pl-2 font-semibold sm:pl-3 align-top">{idx + offset + 1}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 align-top">{prettyDateTime(transaction.created_at)}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 align-top">{transaction.amount}</td>
                                                                <td className="px-3 py-4 align-top">{prettyTransactionType(transaction.type)}</td>
                                                                <td className="px-3 py-4 flex flex-col gap-y-1 align-top">
                                                                    <span>{transaction.details.text}</span>
                                                                    {transaction.expiration_ts !== undefined && <span className="text-gray-500">Expires: {prettyDate(transaction.expiration_ts)}</span>}
                                                                </td>
                                                                {is_admin && <td className="align-top py-4">
                                                                    {edit_uuid === undefined && (
                                                                        <div className="flex flex-row space-x-2">
                                                                            <PencilIcon className="h-4 w-4 text-gray-400 cursor-pointer" onClick={() => startEdit(transaction)} />
                                                                            <TrashIcon className="h-4 w-4 text-gray-400 cursor-pointer" onClick={() => setConfirmDelete(transaction.uuid)} />
                                                                        </div>
                                                                    )}
                                                                </td>}
                                                            </tr>
                                                            {edit_uuid === transaction.uuid && <tr className="bg-gray-50">
                                                                <td></td>
                                                                <td colSpan={5}>
                                                                    <div className="flex flex-col gap-y-2 py-2 items-end">
                                                                        <div className="w-full flex flex-row items-start gap-x-2">
                                                                            {edit_amount !== undefined && <Textbox value={edit_amount} onChange={setEditAmount} />}
                                                                            {edit_text !== undefined && <Textbox value={edit_text} onChange={setEditText} />}
                                                                            {edit_expiration_date !== undefined && <DateTextbox
                                                                                date={edit_expiration_date}
                                                                                onChange={setEditExpirationDate}
                                                                                reportIsValid={setIsExpirationDateValid}
                                                                            />}
                                                                        </div>
                                                                        <div className="flex flex-row">
                                                                            <Button icon={CheckIcon} loading={is_saving} disabled={!is_valid} onClick={() => saveEdit()} />
                                                                            <Button icon={XMarkIcon} loading={is_saving} onClick={() => setEditUuid(undefined)} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>}
                                                        </Fragment>
                                                    ))}
                                                    {!transaction_list && <tr>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={is_admin ? 6 : 5}>
                                                            <LoadingSpinnerLimit />
                                                        </td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                            <Pagination offset={offset} limit={limit} total={transaction_list?.total || 0} setOffset={setOffset} />
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
        <ConfirmModal
            open={confirm_delete !== undefined}
            title="Delete Transaction"
            message={["Are you sure you want to delete this transaction?"]}
            cancel="Cancel"
            confirm="Delete"
            onClose={onConfirmDelete}
        />
    </Fragment>;
}