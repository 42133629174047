import { useState } from "react";

import {
    CheckIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

import { IExcelArraySheet } from "../lib/types";
import {
    classNames,
    getExcelColumnName
} from "../lib/utils";

import { Dropdown } from "./Dropdown";

type SheetProps = {
    data: string[][],
    show_header?: boolean
};

export function Sheet(props: SheetProps) {
    const { data, show_header } = props;

    let number_of_cols = 0;
    for (const row of data) {
        if (row.length > number_of_cols) {
            number_of_cols = row.length;
        }
    }

    if (data.length === 0) {
        return <span>This worksheet is empty.</span>;
    }

    const cols = Array.from({ length: number_of_cols }).map((_, idx) => idx);
    const first_rows = data.length <= 21 ? data.slice(show_header ? 1: 0) : data.slice(show_header ? 1 : 0, 10);
    const last_rows = data.length <= 21 ? [] : data.slice(-10);

    return <table className="">
        <thead>
            <tr>
                <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                {cols.map((idx) =>
                    <th key={idx} className="font-normal min-w-[50px] max-w-[200px] bg-gray-100 border border-gray-200 text-xs">
                        {getExcelColumnName(idx)}
                    </th>
                )}
            </tr>
            {show_header && <tr>
                <th className="min-w-[20px] bg-sea_blue-100 border border-gray-200 font-normal text-xs px-1 text-center">Header</th>
                {cols.map((idx) =>
                    <th key={idx} className="min-w-[50px] max-w-[200px] bg-sea_blue-100 border border-gray-200 font-normal text-xs px-1 truncate tracking-tighter">
                        {data[0][idx] || ""}
                    </th>
                )}
            </tr>}
        </thead>
        <tbody>
            {first_rows.map((row, idx) =>
                <tr key={idx}>
                    <td className="min-w-[20px] bg-gray-100 border border-gray-200 font-normal text-xs px-1 text-center">{idx + (show_header ? 2 : 1)}</td>
                    {cols.map((col, idx) =>
                        <td key={idx} className="min-w-[50px] max-w-[200px] bg-white border border-gray-200 font-normal text-xs px-1 truncate tracking-tighter">
                            {row[col] || ""}
                        </td>
                    )}
                </tr>
            )}
            {last_rows.length > 0 && <tr>
                <td className="min-w-[20px] bg-gray-100 border border-gray-200 font-normal text-xs px-1 text-center text-">...</td>
                <td colSpan={number_of_cols} className="min-w-[20px] bg-gray-50 border border-gray-200 px-1"></td>
            </tr>}
            {last_rows.map((row, idx) =>
                <tr key={idx}>
                    <td className="min-w-[20px] bg-gray-100 border border-gray-200 font-normal text-xs px-1 text-center">{data.length - last_rows.length + idx + (show_header ? 2 : 1)}</td>
                    {cols.map((col, idx) =>
                        <td key={idx} className="min-w-[50px] max-w-[200px] bg-white border border-gray-200 font-normal text-xs px-1 truncate tracking-tighter">
                            {row[col] || ""}
                        </td>
                    )}
                </tr>
            )}
        </tbody>
    </table>;
}

type SheetsProps = {
    sheets: IExcelArraySheet[],
    selected_sheet_idx: number,
    setSelectedSheetIdx: (idx: number) => void
    show_header?: boolean
};

export function Sheets(props: SheetsProps) {
    const { sheets, selected_sheet_idx, setSelectedSheetIdx, show_header } = props;

    const selected_sheet = sheets[selected_sheet_idx];

    return <div className="flex flex-col gap-2">
        <div className="grid grid-cols-4 items-center w-full">
            <div className="text-sm text-gray-500">Sheet</div>
            <div className="col-span-3">
                <Dropdown
                    values={sheets.map((sheet) => sheet.name)}
                    ids={sheets.map((_, idx) => `${idx}`)}
                    selected={`${selected_sheet_idx}`}
                    onChange={(idx) => setSelectedSheetIdx(parseInt(idx, 10))} />
            </div>
        </div>
        <div className="w-full">
            <div className="outer-div">
                {selected_sheet && <Sheet data={selected_sheet.data} show_header={show_header} />}
            </div>
        </div>
    </div>;
}

type SheetsViewerProps = {
    sheets: IExcelArraySheet[],
    show_header?: boolean,
};

export function SheetsViewer(props: SheetsViewerProps) {
    const { sheets, show_header } = props;

    const [selected_sheet_idx, setSelectedSheetIdx] = useState<number>(0);

    return <Sheets
        sheets={sheets}
        selected_sheet_idx={selected_sheet_idx}
        setSelectedSheetIdx={setSelectedSheetIdx}
        show_header={show_header} />;
}

type SheetRow = {
    uuid: string,
    row: string[]
}

type SheetsEditorProps = {
    headers: string[],
    rows: SheetRow[],
    onAddRow: (row: string[]) => Promise<void>,
    onDeleteRow: (uuid: string) => Promise<void>,
    onUpdateRow: (uuid: string, row: string[]) => Promise<void>
}

export function SheetEditor(props: SheetsEditorProps) {
    const { headers, rows, onAddRow, onDeleteRow, onUpdateRow } = props;

    const [row_changes, setRowChanges] = useState<{ row_idx: number, data: string[] }[]>([]);
    const [new_row, setNewRow] = useState<string[]>(Array.from({ length: headers.length }).map(() => ""));
    const [is_new_row_changed, setIsNewRowChanged] = useState<boolean>(false);

    const handleOldRowInput = (row_idx: number) => {
        if (row_changes.find((change) => change.row_idx === row_idx)) {
            return;
        }
        const row_copy = [...rows[row_idx].row];
        setRowChanges([...row_changes, { row_idx, data: row_copy }]);
    };

    const handleOldRowBlur = (e: any, row_idx: number, col_idx: number) => {
        const value = e.target.innerText.trim();
        const row_copy = row_changes.find((change) => change.row_idx === row_idx)?.data || rows[row_idx].row;
        row_copy[col_idx] = value;
        setRowChanges(row_changes.map((change) => change.row_idx === row_idx ? { row_idx, data: row_copy } : change));
    };

    const handleOldRowUpdate = (row_idx: number) => {
        const row = row_changes.find((change) => change.row_idx === row_idx)?.data || rows[row_idx].row;
        onUpdateRow(rows[row_idx].uuid, row);
        setRowChanges(row_changes.filter((change) => change.row_idx !== row_idx));
    };

    const handleNewRowInput = () => {
        setIsNewRowChanged(true);
    };

    const handleNewRowBlur = (e: any, col_idx: number) => {
        const value = e.target.innerText.trim();
        const new_row_copy = [...new_row];
        new_row_copy[col_idx] = value;
        setNewRow(new_row_copy);
    };

    const handleNewRowAdd = () => {
        onAddRow(new_row);
        setNewRow(Array.from({ length: headers.length }).map(() => ""));
        setIsNewRowChanged(false);
    }

    const edit_row_idx_set = new Set(row_changes.map((change) => change.row_idx));

    return <div>
        <table>
            <thead>
                <tr>
                    <th className="min-w-[20px] bg-gray-50 border border-gray-200"></th>
                    {headers.map((_header, idx) =>
                        <th key={idx} className="font-normal min-w-[50px] max-w-[200px] bg-gray-50 border border-gray-200 px-2 py-1 text-xs">
                            {getExcelColumnName(idx)}
                        </th>
                    )}
                </tr>
                <tr>
                    <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {headers.map((header, idx) =>
                        <th key={idx} title={header} className="truncate font-normal min-w-[50px] max-w-[200px] bg-gray-100 border border-gray-200 px-2 py-1 text-sm">
                            {header}
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, row_idx) =>
                    <tr key={row_idx}>
                        <td className="min-w-[20px] bg-gray-100 border border-gray-200 font-normal px-2 py-1 text-sm text-center">{row_idx + 1}</td>
                        {row.row.map((col, col_idx) =>
                            <td key={col_idx}
                                className={classNames(
                                    "min-w-[50px] max-w-[200px] bg-white border border-gray-200 font-normal px-2 py-1 text-sm truncate tracking-tighter",
                                    edit_row_idx_set.has(row_idx) ? "font-semibold" : "font-normal"
                                )}
                                contentEditable={true}
                                onInput={() => handleOldRowInput(row_idx)}
                                onBlur={(e) => handleOldRowBlur(e, row_idx, col_idx)}
                                dangerouslySetInnerHTML={{ __html: col }}
                            />
                        )}
                        {!edit_row_idx_set.has(row_idx) && <td
                            className="p-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                            onClick={() => onDeleteRow(row.uuid)}
                        >
                            <TrashIcon className="w-4 h-4" />
                        </td>}
                        {edit_row_idx_set.has(row_idx) && <td
                            className="py-1 px-2 bg-gray-100 border border-gray-200 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-sea_blue-300"
                            onClick={() => handleOldRowUpdate(row_idx)}
                        >
                            <CheckIcon className="w-4 h-4" />
                        </td>}
                    </tr>
                )}
                <tr>
                    <td className="min-w-[20px] bg-gray-100 border border-gray-200 font-normal px-2 py-1 text-sm text-center h-7" />
                    {new_row.map((col, col_idx) =>
                        <td
                            key={col_idx} className="min-w-[50px] max-w-[200px] bg-white border border-gray-200 font-normal px-2 py-1 text-sm truncate tracking-tighter"
                            contentEditable={true}
                            onInput={(e) => handleNewRowInput()}
                            onBlur={(e) => handleNewRowBlur(e, col_idx)}
                            dangerouslySetInnerHTML={{ __html: col }} />
                    )}
                    {is_new_row_changed && <td
                        className="py-1 px-2 bg-gray-100 border border-gray-200 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-sea_blue-300"
                        onClick={() => handleNewRowAdd()}
                    >
                        <CheckIcon className="w-4 h-4" />
                    </td>}
                </tr>

            </tbody>
        </table>
    </div>

}