import { Fragment, useEffect, useRef, useState } from "react"

import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react"
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import { BackendObj } from "../lib/backend";
import { TEMPLATE_EXAMPLE_TAGS, template_example_tags } from "../lib/consts";
import { ITemplateExampleComment } from "../lib/backend/extractions.types.generated";
import { prettySmartDateTime } from "../lib/utils";

import { Button } from "./Button";
import { Textbox } from "./Textbox";
import { TagPill } from "./TemplateEval";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";

type NewExampleModalProps = {
    open: boolean;
    item_uuid: string;
    template_uuid: string;
    onUpdateExample: (example_uuid: string) => void;
    onClose: () => void;
}

export function NewExampleModal(props: NewExampleModalProps) {
    const { open, item_uuid, template_uuid, onUpdateExample, onClose } = props;

    const nameRef = useRef(null);

    const [example_title, setExampleTitle] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [is_creating, setIsCreating] = useState<boolean>(false);
    const [template_tags, setTemplateTags] = useState<string[]>([]);
    const [selected_tags, setSelectedTags] = useState<string[]>([TEMPLATE_EXAMPLE_TAGS.new_example]);
    const [new_tag, setNewTag] = useState<string>("");

    useEffect(() => {
        setComment("");
        setExampleTitle("");
        setSelectedTags([TEMPLATE_EXAMPLE_TAGS.new_example]);
        setNewTag("");
    }, [item_uuid]);

    useEffect(() => {
        const fetchTags = async () => {
            const { tags } = await BackendObj.extractions.getExampleTagsForTemplate({ template_uuid });
            setTemplateTags(tags);
        };
        fetchTags();
    }, [template_uuid]);

    const createExample = async () => {
        setIsCreating(true);
        // if there are new tags, add them
        const update_tags = [...selected_tags];
        if (new_tag.trim().length > 0) {
            update_tags.push(new_tag.trim());
            setTemplateTags(ts => ts.includes(new_tag.trim()) ? ts : [...ts, new_tag.trim()]);
            setSelectedTags(update_tags);
            setNewTag("");
        }
        const { example } = await BackendObj.extractions.createExampleFromItem({
            item_uuid,
            comment,
            tags: update_tags,
            example_name: example_title.trim() !== "" ? example_title.trim() : undefined // Only send if not empty
        });
        setIsCreating(false);
        if (example) {
            onUpdateExample(example.item.uuid);
        } else {
            console.error("Failed to create example");
        }
    }

    const toggleTag = (tag: string) => {
        setSelectedTags(ts => {
            if (ts.includes(tag)) {
                return ts.filter(t => t !== tag);
            } else {
                return [...ts, tag];
            }
        });
    }

    const addNewTag = () => {
        const trim_new_tag = new_tag.trim();
        if (trim_new_tag.length > 0) {
            setTemplateTags(ts => ts.includes(trim_new_tag) ? ts : [...ts, trim_new_tag]);
            setSelectedTags(ts => ts.includes(trim_new_tag) ? ts : [...ts, trim_new_tag]);
            setNewTag("");
        }
    }

    const possible_tags = template_example_tags;
    for (const tag of template_tags) {
        if (!possible_tags.includes(tag)) {
            possible_tags.push(tag);
        }
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={nameRef} onClose={() => onClose()}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Add to Template Examples
                                        </DialogTitle>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Title</div>
                                            <div className="col-span-3">
                                                <Textbox
                                                    value={example_title}
                                                    onChange={setExampleTitle}
                                                    placeholder="Example title (optional)"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Comment</div>
                                            <div className="col-span-3">
                                                <Textbox value={comment} onChange={setComment} />
                                            </div>
                                        </div>
                                        <div className="mt-5 grid grid-cols-4 gap-y-4 items-center w-full">
                                            <div className="text-sm text-gray-500 self-start pt-0.5">Tags</div>
                                            <div className="col-span-3 flex flex-wrap gap-2">
                                                {possible_tags.map((tag, idx) => <TagPill
                                                    key={idx}
                                                    tag={tag}
                                                    disabled={!selected_tags.includes(tag)}
                                                    onClick={() => toggleTag(tag)}
                                                />)}
                                            </div>
                                            <div className="text-sm text-gray-500">Add custom tag</div>
                                            <div className="col-span-3">
                                                <div className="flex flex-row items-center gap-2">
                                                    <Textbox placeholder="e.g. 'wrong supplier'" value={new_tag} onChange={setNewTag} />
                                                    <Button icon={PlusIcon} onClick={addNewTag} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 sm:flex justify-center">
                                    <Button highlight={true} text="Add" onClick={createExample} loading={is_creating} disabled={is_creating} />
                                    <Button text="Cancel" onClick={() => onClose()} disabled={is_creating} />
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

type EditExampleModalProps = {
    open: boolean;
    example_item_uuid: string;
    template_uuid: string;
    onClose: () => void;
    onExampleUpdated?: (updated_example: any) => void;
}

export function EditExampleModal(props: EditExampleModalProps) {
    const { open, example_item_uuid, template_uuid, onClose, onExampleUpdated } = props;

    const user = useSelector(selectUser);

    const [comments, setComments] = useState<ITemplateExampleComment[]>([]);
    const [template_tags, setTemplateTags] = useState<string[]>([]);
    const [init_tags, setInitTags] = useState<string[]>([]);
    const [selected_tags, setSelectedTags] = useState<string[]>([]);
    const [new_comment, setNewComment] = useState<string>("");
    const [new_tag, setNewTag] = useState<string>("");
    const [is_updating, setIsUpdating] = useState<boolean>(false);
    const [example_title, setExampleTitle] = useState<string>("");
    const [initial_title, setInitialTitle] = useState<string>("");

    const nameRef = useRef(null);

    useEffect(() => {
        const fetchExample = async () => {
            const { example } = await BackendObj.extractions.getTemplateExample({ item_uuid: example_item_uuid, load_content: "no" });
            if (example) {
                setComments(example.comments);
                setSelectedTags(example.tags);
                setInitTags(example.tags);
                setExampleTitle(example.item.name);
                setInitialTitle(example.item.name);
            }
        };
        setNewComment("");
        setNewTag("");
        fetchExample();
    }, [example_item_uuid]);

    useEffect(() => {
        const fetchTags = async () => {
            const { tags } = await BackendObj.extractions.getExampleTagsForTemplate({ template_uuid });
            setTemplateTags(tags);
        };
        fetchTags();
    }, [template_uuid]);

    const updateExample = async () => {
        setIsUpdating(true);
        // if there are new comments, add them
        const update_comments = [...comments];
        if (new_comment.trim().length > 0) {
            update_comments.push({ comment: new_comment.trim(), created_at: new Date().toISOString() });
            setComments(update_comments);
            setNewComment("");
        }
        // if there are new tags, add them
        const update_tags = [...selected_tags];
        if (new_tag.trim().length > 0) {
            update_tags.push(new_tag.trim());
            setTemplateTags(ts => ts.includes(new_tag.trim()) ? ts : [...ts, new_tag.trim()]);
            setSelectedTags(update_tags);
            setNewTag("");
        }

        // check difference between init_tags and selected_tags
        const tags_to_remove = init_tags.filter(t => !update_tags.includes(t));
        const tags_to_add = update_tags.filter(t => !init_tags.includes(t));
        if (tags_to_remove.length > 0) {
            update_comments.push({ comment: `Removed tags: ${tags_to_remove.join(", ")} [${user.email}]`, created_at: new Date().toISOString() });
        }
        if (tags_to_add.length > 0) {
            update_comments.push({ comment: `Added tags: ${tags_to_add.join(", ")} [${user.email}]`, created_at: new Date().toISOString() });
        }

        // Add comment for title change if it was modified
        if (example_title !== initial_title) {
            update_comments.push({
                comment: `Changed title from "${initial_title}" to "${example_title}" [${user.email}]`,
                created_at: new Date().toISOString()
            });
        }

        if (tags_to_remove.length > 0 || tags_to_add.length > 0 || example_title !== initial_title) {
            setComments(update_comments);
        }

        // update the example
        const { example } = await BackendObj.extractions.updateExample({
            item_uuid: example_item_uuid,
            comments: update_comments,
            tags: update_tags,
            example_name: example_title
        });
        if (example) {
            if (onExampleUpdated) {
                onExampleUpdated(example);
            }
            onClose();
        } else {
            console.error("Failed to update example");
        }
        setIsUpdating(false);
    }

    const onDeleteComment = (comment_idx: number) => {
        setComments(cs => cs.filter((_, idx) => idx !== comment_idx));
    }

    const addNewComment = () => {
        if (new_comment.trim().length > 0) {
            setComments(cs => [...cs, { comment: new_comment.trim(), created_at: new Date().toISOString() }]);
            setNewComment("");
        }
    }

    const toggleTag = (tag: string) => {
        setSelectedTags(ts => {
            if (ts.includes(tag)) {
                return ts.filter(t => t !== tag);
            } else {
                return [...ts, tag];
            }
        });
    }

    const addNewTag = () => {
        const trim_new_tag = new_tag.trim();
        if (trim_new_tag.length > 0) {
            setTemplateTags(ts => ts.includes(trim_new_tag) ? ts : [...ts, trim_new_tag]);
            setSelectedTags(ts => ts.includes(trim_new_tag) ? ts : [...ts, trim_new_tag]);
            setNewTag("");
        }
    }

    const possible_tags = template_example_tags;
    for (const tag of template_tags) {
        if (!possible_tags.includes(tag)) {
            possible_tags.push(tag);
        }
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={nameRef} onClose={() => onClose()}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Edit Example Metadata
                                        </DialogTitle>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Title</div>
                                            <div className="col-span-3">
                                                <Textbox
                                                    value={example_title}
                                                    onChange={setExampleTitle}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500 self-start pt-0.5">Existing comments</div>
                                            <div className="col-span-3 flex flex-wrap gap-2">
                                                {comments.map((comment, comment_idx) => <div key={comment_idx} className="flex flex-row items-start gap-4 w-full">
                                                    <div className="flex-grow flex flex-col items-start gap-y-1">
                                                        <div className="whitespace-nowrap text-xs text-gray-400">[{prettySmartDateTime(new Date(comment.created_at).getTime())}]</div>
                                                        <div className="text-sm text-gray-900 text-left">{comment.comment !== "" ? comment.comment : "(empty)"}</div>
                                                    </div>
                                                    <Button icon={TrashIcon} onClick={() => onDeleteComment(comment_idx)} />
                                                </div>)}
                                                {comments.length === 0 && <div className="text-sm text-gray-400">
                                                    No comments yet.
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="mt-5 grid grid-cols-4 items-center w-full">
                                            <div className="text-sm text-gray-500">Add comment</div>
                                            <div className="col-span-3">
                                                <div className="flex flex-row items-center gap-2">
                                                    <Textbox value={new_comment} onChange={setNewComment} />
                                                    <Button icon={PlusIcon} onClick={addNewComment} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-8 grid grid-cols-4 gap-y-4 items-center w-full">
                                            <div className="text-sm text-gray-500 self-start pt-0.5">Tags</div>
                                            <div className="col-span-3 flex flex-wrap gap-2">
                                                {possible_tags.map((tag, idx) => <TagPill
                                                    key={idx}
                                                    tag={tag}
                                                    disabled={!selected_tags.includes(tag)}
                                                    onClick={() => toggleTag(tag)}
                                                />)}
                                            </div>
                                            <div className="text-sm text-gray-500">Add custom tag</div>
                                            <div className="col-span-3">
                                                <div className="flex flex-row items-center gap-2">
                                                    <Textbox placeholder="e.g. 'wrong supplier'" value={new_tag} onChange={setNewTag} />
                                                    <Button icon={PlusIcon} onClick={addNewTag} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-8 sm:flex justify-center">
                                    <Button highlight={true} text="Update" onClick={updateExample} loading={is_updating} disabled={is_updating} />
                                    <Button text="Cancel" onClick={() => onClose()} disabled={is_updating} />
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}