import { IOrganization, OrgType } from "../lib/backend/auth.types.generated";
import { ORG_TYPES } from "../lib/consts";
import { classNames } from "../lib/utils";

type OrgPillProps = {
    name: string;
    type: OrgType;
    selected?: boolean;
    small?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

export function OrgPill(props: OrgPillProps) {
    const { name, type, selected, small, disabled, onClick } = props;
    return <span
        className={classNames(
            "items-center rounded-full px-2 text-xs truncate",
            disabled === true ? "bg-gray-100 text-gray-500 font-medium" :
                type === ORG_TYPES.personal ?
                    selected ? "bg-candy_corn-100 text-candy_corn-800 font-bold border-2 border-candy_corn-800" : "bg-candy_corn-100 text-candy_corn-800 font-medium" :
                    selected ? "bg-space_blue-100 text-space_blue-800 font-bold border-2 border-space_blue-800" : "bg-space_blue-100 text-space_blue-800 font-medium",
            onClick !== undefined ? (disabled ? "cursor-not-allowed" : "cursor-pointer") : "cursor-default",
            small === true ? "py-0" : "py-1"
        )}
        onClick={() => { if (onClick && !disabled) { onClick(); } }}
        title={disabled ? "Only admins can select this organization" : undefined}>
        {name}
    </span>
}

type OrgPillSimpleProps = {
    org?: IOrganization;
}

export function OrgPillSimple(props: OrgPillSimpleProps) {
    const { org } = props;
    if (org === undefined) {
        return <OrgPill name="Personal" type={ORG_TYPES.personal} />;
    } else {
        return <OrgPill name={org.name} type={org.type} />;
    }
}