import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import { selectEnv } from "../lib/scraper.slice";
import { ISubscription } from "../lib/backend/auth.types.generated";
import { IStripeInvoice } from "../lib/backend/stripe.types.generated";
import { BackendObj } from "../lib/backend";
import { prettyDateTime } from "../lib/utils";

import { ReadOnlyTextbox } from "./ReadOnlyTextbox";
import { Button } from "./Button";
import { FullScreenText } from "./FullScreen";
import { ConfirmModal } from "./ConfirmModal";
import { Dropdown } from "./Dropdown";
import { Textbox } from "./Textbox";
import { Checkbox } from "./Checkbox";
import { LoadingSpinnerLimit } from "./LoadingSpinner";

const DAY_OF_MONTH_OPTIONS = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th"];
const DAY_OF_MONTH_IDS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28"];
const EXPIRATION_PERIOD_OPTIONS = ["1 Month", "2 Months", "3 Months", "4 Months", "5 Months", "6 Months", "7 Months", "8 Months", "9 Months", "10 Months", "11 Months", "12 Months"];
const EXPIRATION_PERIOD_IDS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

function StripeInvoice(props: { invoice: IStripeInvoice }) {
    const { created_at, amount, url } = props.invoice;

    return <div className="text-sm text-gray-600 mt-2 flex flex-row items-center gap-4">
        <div className="flex-grow text-gray-400 text-xs">{prettyDateTime(created_at)}</div>
        <div>${(amount).toFixed(2)} USD</div>
        {url &&
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-space_blue-600 hover:text-space_blue-800"
            >
                View
            </a>
        }
    </div>
}

type CreateManualSubscriptionModalProps = {
    org_uuid: string;
    onClose: () => void;
}

function CreateManualSubscriptionModal(props: CreateManualSubscriptionModalProps) {
    const { org_uuid, onClose } = props;

    const [price, setPrice] = useState<number>(0);
    const [credits, setCredits] = useState<number>(0);
    const [day_of_month, setDayOfMonth] = useState<number>(Math.min(new Date().getDate(), 28));
    const [expiration_period_months, setExpirationPeriodMonths] = useState<number>(1);
    const [insert_initial_credits, setInsertInitialCredits] = useState<boolean>(false);
    const [is_saving, setIsSaving] = useState<boolean>(false);

    const onCreateSubscription = async (result: boolean) => {
        if (result) {
            setIsSaving(true);
            await BackendObj.auth.createNewManualSubscription({
                org_uuid,
                price: price,
                credits,
                day_of_month,
                expiration_period_months,
                insert_initial_credits
            });
        }
        onClose();
    }

    return <ConfirmModal
        open={true}
        title="Create Subscription"
        hide_icon={true}
        confirm="Create"
        disable_confirm={is_saving}
        onClose={onCreateSubscription}>
        <div className="py-3 flex flex-col gap-2">
            <div className="pt-2 text-sm leading-6 text-gray-900">
                Price (USD):
            </div>
            <div className="flex flex-row items-center gap-2">
                <Textbox value={price.toString()} onChange={(value) => setPrice(parseInt(value))} />
            </div>
            <div className="pt-2 text-sm leading-6 text-gray-900">
                Credits:
            </div>
            <div className="flex flex-row items-center gap-2">
                <Textbox value={credits.toString()} onChange={(value) => setCredits(parseInt(value))} />
            </div>
            <div className="pt-2 text-sm leading-6 text-gray-900">
                Day of Month:
            </div>
            <div className="flex flex-row items-center gap-2">
                <Dropdown
                    values={DAY_OF_MONTH_OPTIONS}
                    ids={DAY_OF_MONTH_IDS}
                    selected={day_of_month.toString()}
                    onChange={(id) => setDayOfMonth(parseInt(id))}
                />
            </div>
            <div className="pt-2 text-sm leading-6 text-gray-900">
                Expiration Period:
            </div>
            <div className="flex flex-row items-center gap-2">
                <Dropdown
                    values={EXPIRATION_PERIOD_OPTIONS}
                    ids={EXPIRATION_PERIOD_IDS}
                    selected={expiration_period_months.toString()}
                    onChange={(id) => setExpirationPeriodMonths(parseInt(id))}
                />
            </div>
            <div className="pt-2 text-sm leading-6 text-gray-900 flex flex-row items-center gap-2">
                <Checkbox id="insert_initial_credits" checked={insert_initial_credits} setChecked={(checked) => setInsertInitialCredits(checked)} />
                <label htmlFor="insert_initial_credits">Insert Initial Credits</label>
            </div>
        </div>
    </ConfirmModal>;
}

type ConnectStripeModalProps = {
    org_uuid: string;
    onClose: () => void;
}

function ConnectStripeModal(props: ConnectStripeModalProps) {
    const { org_uuid, onClose } = props;

    const [stripe_subscription_id, setStripeSubscriptionId] = useState<string>("");
    const [verify_state, setVerifyState] = useState<"init" | "verifying" | "verified" | "error" | "connecting">("init");
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [credits_num, setCreditsNum] = useState<number>(0);

    const onVerify = async () => {
        setVerifyState("verifying");
        const { message, credits_num } = await BackendObj.stripe.verifySubscriptionsSync({
            org_uuid,
            stripe_subscription_id
        });
        if (message === undefined) {
            setVerifyState("verified");
            setMessage(undefined);
            setCreditsNum(credits_num ?? 0);
        } else {
            setVerifyState("error");
            setMessage(message);
        }
    }

    const onConnect = async (result: boolean) => {
        if (result) {
            setVerifyState("connecting");
            await BackendObj.stripe.associateSubscriptionsSync({
                org_uuid,
                stripe_subscription_id
            });
        }
        onClose();
    }

    return <ConfirmModal
        open={true}
        title="Connect Stripe"
        hide_icon={true}
        confirm="Connect"
        disable_confirm={verify_state !== "verified"}
        onClose={onConnect}
    >
        <div className="py-3 flex flex-col gap-2">
            <div className="pt-2 text-sm leading-6 text-gray-900">
                Stripe Subscription ID:
            </div>
            <div className="flex flex-row items-center gap-2">
                <Textbox value={stripe_subscription_id} onChange={(value) => setStripeSubscriptionId(value)} />
                <Button text="Verify" onClick={onVerify} />
            </div>
            {(verify_state === "verifying" || verify_state === "connecting") && <div className="pt-2 text-sm leading-6 text-gray-900">
                <LoadingSpinnerLimit />
            </div>}
            {verify_state === "verified" && <Fragment>
                <div className="pt-2 text-sm leading-6 text-gray-900">
                    Credits:
                </div>
                <div className="w-full">
                    <ReadOnlyTextbox text={credits_num.toString()} />
                </div>
            </Fragment>}
            {verify_state === "error" && <div className="pt-2 text-sm leading-6 text-torch_red-500">
                <div>{message}</div>
            </div>}
        </div>
    </ConfirmModal>
}

type EditSubscriptionModalProps = {
    subscription: ISubscription;
    onClose: () => void;
}

function EditSubscriptionModal(props: EditSubscriptionModalProps) {
    const { subscription, onClose } = props;
    const { provider } = subscription;

    const [expiration_period_months, setExpirationPeriodMonths] = useState<number>(subscription.expiration_period_months ?? 1);
    const [price, setPrice] = useState<number>(subscription.price ?? 0);
    const [credits, setCredits] = useState<number>(subscription.details.quantity ?? 0);
    const [day_of_month, setDayOfMonth] = useState<number>(subscription.details.manual_subscription_day_of_month ?? 1);
    const [is_saving, setIsSaving] = useState<boolean>(false);

    const onEditSubscription = async (result: boolean) => {
        if (result) {
            setIsSaving(true);
            if (provider === "manual") {
                await BackendObj.auth.updateSubscription({
                    subscription_uuid: subscription.uuid,
                    price,
                    credits,
                    day_of_month,
                    expiration_period_months
                });
            } else if (provider === "stripe") {
                // for stripe, we only update the expiration period
                await BackendObj.auth.updateSubscription({
                    subscription_uuid: subscription.uuid,
                    expiration_period_months
                });
            }
            setIsSaving(false);
        }
        onClose();
    }

    return <ConfirmModal
        open={true}
        title="Edit Subscription"
        hide_icon={true}
        confirm="Update"
        disable_confirm={is_saving}
        onClose={onEditSubscription}>
        <div className="py-3 flex flex-col gap-2">
            {provider === "manual" && <Fragment>
                <div className="pt-2 text-sm leading-6 text-gray-900">
                    Price:
                </div>
                <div className="flex flex-row items-center gap-2">
                    <Textbox value={price.toString()} onChange={(value) => setPrice(parseInt(value))} />
                </div>
                <div className="pt-2 text-sm leading-6 text-gray-900">
                    Credits:
                </div>
                <div className="flex flex-row items-center gap-2">
                    <Textbox value={credits.toString()} onChange={(value) => setCredits(parseInt(value))} />
                </div>
                <div className="pt-2 text-sm leading-6 text-gray-900">
                    Day of Month:
                </div>
                <div className="flex flex-row items-center gap-2">
                    <Dropdown
                        values={DAY_OF_MONTH_OPTIONS}
                        ids={DAY_OF_MONTH_IDS}
                        selected={day_of_month.toString()}
                        onChange={(id) => setDayOfMonth(parseInt(id))}
                    />
                </div>
            </Fragment>}
            <div className="pt-2 text-sm leading-6 text-gray-900">
                Expiration Period:
            </div>
            <div className="flex flex-row items-center gap-2">
                <Dropdown
                    values={EXPIRATION_PERIOD_OPTIONS}
                    ids={EXPIRATION_PERIOD_IDS}
                    selected={expiration_period_months.toString()}
                    onChange={(id) => setExpirationPeriodMonths(parseInt(id))}
                />
            </div>
        </div>
    </ConfirmModal>;
}

type AdminSubscriptionProps = {
    org_uuid: string;
    refreshCredits: () => void;
}

export const STRIPE_PROD_URL = "https://dashboard.stripe.com/";
export const STRIPE_TEST_URL = "https://dashboard.stripe.com/test/";

export function AdminSubscriptions(props: AdminSubscriptionProps) {
    const { org_uuid, refreshCredits } = props;

    const env = useSelector(selectEnv);
    const STRIPE_URL = env === "prod" ? STRIPE_PROD_URL : STRIPE_TEST_URL;

    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const [subscriptions_invoices, setSubscriptionsInvoices] = useState<(IStripeInvoice[] | null)[]>([]);
    const [stripe_details, setStripeDetails] = useState<string | undefined>(undefined);
    const [is_create_modal_open, setIsCreateModalOpen] = useState<boolean>(false);
    const [is_connect_stripe_modal_open, setIsConnectStripeModalOpen] = useState<boolean>(false);
    const [is_edit_modal_open, setIsEditModalOpen] = useState<number | undefined>(undefined);
    const [is_cancel_modal_open, setIsCancelModalOpen] = useState<number | undefined>(undefined);

    useEffect(() => {
        BackendObj.auth.getSubscriptions({ active: "true", org_uuid }).then(res => {
            setSubscriptions(res.subscriptions);

            const load_invoices = async () => {
                const new_subscriptions_invoices: (IStripeInvoice[] | null)[] = [];
                for (const subscription of res.subscriptions) {
                    if (subscription.provider === "stripe") {
                        const { invoices } = await BackendObj.stripe.getStripeInvoices({ org_uuid });
                        new_subscriptions_invoices.push(invoices);
                    } else {
                        new_subscriptions_invoices.push(null);
                    }
                }
                setSubscriptionsInvoices(new_subscriptions_invoices);
            }

            load_invoices();
        });
    }, [org_uuid]);

    const createSubscription = async () => {
        BackendObj.auth.getSubscriptions({ active: "true", org_uuid }).then(res => {
            setSubscriptions(res.subscriptions);
        });
        setIsCreateModalOpen(false);
        refreshCredits();
    }

    const connectStripe = async () => {
        BackendObj.auth.getSubscriptions({ active: "true", org_uuid }).then(res => {
            setSubscriptions(res.subscriptions);
        });
        setIsConnectStripeModalOpen(false);
    }

    const updateSubscriptions = async () => {
        BackendObj.auth.getSubscriptions({ active: "true", org_uuid }).then(res => {
            setSubscriptions(res.subscriptions);
        });
        setIsEditModalOpen(undefined);
    }

    const cancelSubscription = async (result: boolean) => {
        if (is_cancel_modal_open === undefined) { return; }
        const delete_subscription_uuid = subscriptions[is_cancel_modal_open].uuid;
        setIsCancelModalOpen(undefined);
        if (result) {
            setSubscriptions([]);
            await BackendObj.auth.cancelSubscription({ subscription_uuid: delete_subscription_uuid });
            const { subscriptions: new_subscriptions } = await BackendObj.auth.getSubscriptions({ active: "true", org_uuid });
            setSubscriptions(new_subscriptions);
        }
    }

    return <div className="p-4">
        {subscriptions.length === 0 && <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 text-sm">
            <div className="py-2 text-md font-semibold text-gray-800 md:col-span-2 lg:col-span-3 flex justify-end">
                <Button text="Create Subscription" onClick={() => setIsCreateModalOpen(true)} />
                <Button text="Connect Stripe" onClick={() => setIsConnectStripeModalOpen(true)} />
            </div>
        </div>}

        {subscriptions.map((subscription, idx) => (
            <div key={subscription.uuid}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 text-sm">
                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Provider:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.provider} />
                        </div>
                    </div>
                    <div className="lg:col-span-2 flex justify-end">
                        {subscription.provider === "stripe" && <Button
                            onClick={() => setStripeDetails(subscription.details.stripe_subscription_details)}
                            text="Stripe Details"
                        />}
                        <Button text="Edit" onClick={() => setIsEditModalOpen(idx)} />
                        <Button text="Cancel" onClick={() => setIsCancelModalOpen(idx)} />
                    </div>
                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Price:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={`${subscription.price} ${subscription.plan_currency.toUpperCase()}`} />
                        </div>
                    </div>
                    {subscription.provider === "manual" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Credits:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.details.quantity?.toString() ?? "/"} />
                        </div>
                    </div>}
                    {subscription.provider === "manual" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Day of Month:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.details.manual_subscription_day_of_month?.toString() ?? "/"} />
                        </div>
                    </div>}
                    {subscription.expiration_period_months !== undefined && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Expiration Period:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.expiration_period_months.toString()} />
                        </div>
                    </div>}
                    {subscription.provider === "stripe" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Subscription ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.external_id} href={`${STRIPE_URL}subscriptions/${subscription.external_id}`} />
                        </div>
                    </div>}
                    {subscription.provider === "stripe" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Product ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.plan_name} href={`${STRIPE_URL}products/${subscription.plan_name}`} />
                        </div>
                    </div>}
                    {subscription.provider === "stripe" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Customer ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.customer_external_id} href={`${STRIPE_URL}customers/${subscription.customer_external_id}`} />
                        </div>
                    </div>}
                    {subscription.provider === "stripe" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Price ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={subscription.plan_id} href={`${STRIPE_URL}prices/${subscription.plan_id}`} />
                        </div>
                    </div>}
                    {subscription.provider === "stripe" && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Invoices:</h3>
                        <div className="text-sm text-gray-600 mt-2 flex flex-col gap-2">
                            {subscriptions_invoices[idx]?.map(x => <StripeInvoice key={x.id} invoice={x} />)}
                            {(subscriptions_invoices[idx] === null || subscriptions_invoices[idx]?.length === 0) && <div className="text-sm text-gray-600 mt-2">
                                No invoices found
                            </div>}
                        </div>
                    </div>}
                </div>
            </div>
        ))}

        <FullScreenText
            show={stripe_details !== undefined}
            text={stripe_details ?? ""}
            onClose={() => setStripeDetails(undefined)}
        />
        {is_create_modal_open && <CreateManualSubscriptionModal
            org_uuid={org_uuid}
            onClose={createSubscription}
        />}
        {is_connect_stripe_modal_open && <ConnectStripeModal
            org_uuid={org_uuid}
            onClose={connectStripe}
        />}
        {is_edit_modal_open !== undefined && <EditSubscriptionModal
            subscription={subscriptions[is_edit_modal_open]}
            onClose={updateSubscriptions}
        />}
        <ConfirmModal
            open={is_cancel_modal_open !== undefined}
            title="Cancel Subscription"
            message={["Are you sure you want to cancel this subscription?"]}
            confirm="Yes"
            cancel="No"
            onClose={cancelSubscription}
        />
    </div>
}