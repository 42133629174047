import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { classNames } from "../lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";

type CancelableErrorMessageBarProps = {
    message?: string;
    clearMessage?: () => void;
    is_sidebar_large_override?: boolean;
};

export function CancelableErrorMessageBar(props: CancelableErrorMessageBarProps) {
    const { message, clearMessage, is_sidebar_large_override } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    if (!props.message) {
        return null;
    }

    return (
        <div className={classNames(
            "z-50 fixed flex flex-row items-center w-full bottom-0 right-0 bg-torch_red-500 p-4",
            (is_sidebar_large_override ?? is_sidebar_large) ? "lg:pl-64" : "lg:pl-20"
        )} style={{ width: "100vw" }}>
            <div className="text-gray-100 text-sm font-semibold px-4">
                {message}
            </div>
            <div className="flex-grow"></div>
            {clearMessage && <button
                className="ml-2 text-sm focus:outline-none focus:underline text-gray-300"
                onClick={() => { clearMessage(); }}>
                <XMarkIcon className="h-6 w-6" />
            </button>}
        </div>
    );
}


type TimeoutErrorMessageBarProps = {
    color?: "red" | "green";
    message?: string;
    clearMessage: () => void;
    is_sidebar_large_override?: boolean;
};

export function TimeoutErrorMessageBar(props: TimeoutErrorMessageBarProps) {
    const { message, clearMessage, is_sidebar_large_override, color = "red" } = props;
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    // Store the timeout ID in a ref so it persists between renders
    const timeout_id_ref = useRef<NodeJS.Timeout | undefined>(undefined);
    // Add state for progress tracking
    const [progress, setProgress] = useState(100);
    // Add state to track if animation should be applied
    const [animate_progress, setAnimateProgress] = useState(false);
    // Define timeout duration constant
    const TIMEOUT_DURATION = 5000;

    useEffect(() => {
        if (message) {
            // Clear any existing timeout to reset the timer
            if (timeout_id_ref.current) {
                clearTimeout(timeout_id_ref.current);
            }

            // Reset progress to 100% without animation
            setAnimateProgress(false);
            setProgress(100);

            // Small delay to ensure the non-animated 100% is applied first
            setTimeout(() => {
                setAnimateProgress(true);
            }, 50);

            // Set up animation interval to update progress
            const start_time = Date.now();
            const interval = setInterval(() => {
                const elapsed = Date.now() - start_time;
                const remaining_percent = Math.max(0, 100 - (elapsed / TIMEOUT_DURATION * 100));
                setProgress(remaining_percent);

                if (remaining_percent <= 0) {
                    clearInterval(interval);
                }
            }, 50);

            // Set a new timeout to clear the message after 5 seconds
            timeout_id_ref.current = setTimeout(() => {
                clearMessage();
                clearInterval(interval);
            }, TIMEOUT_DURATION);

            // Cleanup function to clear the timeout and interval when component unmounts or message changes
            return () => {
                clearInterval(interval);
                if (timeout_id_ref.current) {
                    clearTimeout(timeout_id_ref.current);
                    timeout_id_ref.current = undefined;
                }
            };
        }
    }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!props.message) {
        return null;
    }

    return (
        <div className={classNames(
            "z-50 fixed flex flex-col items-center w-full bottom-0 right-0",
            color === "green" ? "bg-mint-500" : "bg-torch_red-500",
            (is_sidebar_large_override ?? is_sidebar_large) ? "lg:pl-64" : "lg:pl-20"
        )} style={{ width: "100vw" }}>
            <div className="w-full flex flex-row items-center pt-4 pb-2 px-4">
                <div className={classNames("text-sm font-semibold", color === "green" ? "text-gray-900" : "text-gray-100")}>
                    {message}
                </div>
                <div className="flex-grow"></div>
                {clearMessage && <button
                    className="ml-2 text-sm focus:outline-none focus:underline text-gray-300"
                    onClick={() => { clearMessage(); }}>
                    <XMarkIcon className="h-6 w-6" />
                </button>}
            </div>

            {/* Progress bar */}
            <div className={classNames("w-full h-2", color === "green" ? "bg-mint-500" : "bg-torch_red-500")}>
                <div
                    className={classNames(
                        "h-2",
                        animate_progress ? "transition-all duration-100 ease-linear" : "",
                        color === "green" ? "bg-mint-700" : "bg-torch_red-700"
                    )}
                    style={{ width: `${progress}%` }}
                />
            </div>
        </div>
    );
}