import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { BackendObj } from "../lib/backend";
import {
    classNames,
    prettySmartDateTime
} from "../lib/utils";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectUser
} from "../lib/scraper.slice";
import {
    IGetStripeOptionsRes,
    ISyncSubscriptionsRes
} from "../lib/backend/stripe.types.generated";
import { ISubscription } from "../lib/backend/auth.types.generated";
import { STRIPE_TEST_URL, STRIPE_PROD_URL } from "../components/AdminSubscriptions";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { Button } from "../components/Button";
import { TimeoutErrorMessageBar } from "../components/ErrorMessageBar";

function StripeSubLink(props: { subscription_id: string }) {
    const env = useSelector(selectEnv);
    const STRIPE_URL = env === "prod" ? STRIPE_PROD_URL : STRIPE_TEST_URL;

    return <a href={`${STRIPE_URL}subscriptions/${props.subscription_id}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sea_blue-600 hover:text-sea_blue-800 hover:underline flex items-center">
        {props.subscription_id}
        <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
    </a>
}

export function StripeAdministration() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);

    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [sync_result, setSyncResult] = useState<ISyncSubscriptionsRes | undefined>(undefined);
    const [settings, setSettings] = useState<IGetStripeOptionsRes | undefined>(undefined);
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        BackendObj.stripe.getStripeOptions({})
            .then(res => {
                setSettings(res);
            })
            .catch(err => {
                console.error(err);
                setErrorMessage("An error occurred while loading Stripe options.");
            });

        BackendObj.auth.getSubscriptions({ active: "true" })
            .then(({ subscriptions: new_subscriptions }) => {
                setSubscriptions(new_subscriptions);
            })
            .catch(err => {
                console.error(err);
                setErrorMessage("An error occurred while syncing with Stripe.");
            });
    }, []);

    if (user.role !== "admin") {
        navigate("/");
    }

    const onRunSync = async () => {
        setIsLoading(true);
        setErrorMessage(undefined);
        try {
            const res = await BackendObj.stripe.syncSubscriptions({});
            setSyncResult(res);
            setErrorMessage(undefined);
        } catch (err) {
            console.error(err);
            setErrorMessage("An error occurred while syncing with Stripe.");
        }
        setIsLoading(false);
    }

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Subscriptions
                </h2>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>

            <div className="flex flex-col px-10 py-5">
                <div className="w-full max-w-5xl bg-gray-50 border rounded-lg p-4">

                    <div className="mt-4">
                        <div className="m-4 flex flex-row">
                            <span className="text-xl font-semibold">Stripe settings</span>
                            <div className="flex-grow" />
                            <Button text="Run sync"
                                highlight={true}
                                loading={is_loading}
                                tooltip={"Sync with Stripe to update local data. This may take a few minutes."}
                                onClick={onRunSync} />
                        </div>

                        {settings && <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <div className="space-y-6">
                                <div>
                                    <div className="mt-2 text-sm text-gray-600 flex items-center">
                                        <span className="font-medium mr-2">Stripe Portal URL:</span>
                                        <a href={settings.stripe_portal_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 underline">{settings.stripe_portal_url}</a>
                                    </div>
                                </div>

                                <div>
                                    <h3 className="text-lg font-medium text-gray-700 border-b pb-2">Professional Tier</h3>
                                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="bg-gray-50 p-3 rounded-md">
                                            <h4 className="font-medium text-gray-700">Subscription</h4>
                                            <div className="mt-1 text-sm">
                                                <div className="flex justify-between"><span>Lookup Key:</span><span>{settings.tier1.lookup_key}</span></div>
                                                <div className="flex justify-between"><span>Credits:</span><span>{settings.tier1.credits}</span></div>
                                                <div className="flex justify-between"><span>Price:</span><span>${settings.tier1.price}</span></div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 p-3 rounded-md">
                                            <h4 className="font-medium text-gray-700">One-time Payment</h4>
                                            <div className="mt-1 text-sm">
                                                <div className="flex justify-between"><span>Lookup Key:</span><span>{settings.tier1.onetime_lookup_key}</span></div>
                                                <div className="flex justify-between"><span>Credits:</span><span>{settings.tier1.onetime_credits}</span></div>
                                                <div className="flex justify-between"><span>Price:</span><span>${settings.tier1.onetime_price}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className="text-lg font-medium text-gray-700 border-b pb-2">Enterprise Tier</h3>
                                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="bg-gray-50 p-3 rounded-md">
                                            <h4 className="font-medium text-gray-700">Subscription</h4>
                                            <div className="mt-1 text-sm">
                                                <div className="flex justify-between"><span>Lookup Key:</span><span>{settings.tier2.lookup_key}</span></div>
                                                <div className="flex justify-between"><span>Credits:</span><span>{settings.tier2.credits}</span></div>
                                                <div className="flex justify-between"><span>Price:</span><span>${settings.tier2.price}</span></div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 p-3 rounded-md">
                                            <h4 className="font-medium text-gray-700">One-time Payment</h4>
                                            <div className="mt-1 text-sm">
                                                <div className="flex justify-between"><span>Lookup Key:</span><span>{settings.tier2.onetime_lookup_key}</span></div>
                                                <div className="flex justify-between"><span>Credits:</span><span>{settings.tier2.onetime_credits}</span></div>
                                                <div className="flex justify-between"><span>Price:</span><span>${settings.tier2.onetime_price}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {sync_result && <div className="mt-6 bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <h3 className="text-lg font-medium text-gray-700 border-b pb-2 mb-4">Sync Results</h3>

                            <div className="mb-6">
                                <h4 className="font-medium text-gray-700 mb-2">Statistics</h4>
                                <div className="bg-gray-50 p-3 rounded-md">
                                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">All</div>
                                            <div className="text-xl font-semibold">{sync_result.subscriptions_all}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Active</div>
                                            <div className="text-xl font-semibold text-green-600">{sync_result.subscriptions_active}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Inactive</div>
                                            <div className="text-xl font-semibold text-gray-600">{sync_result.subscriptions_inactive}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Skipped</div>
                                            <div className="text-xl font-semibold">{sync_result.subscriptions_skipped}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Unknown</div>
                                            <div className="text-xl font-semibold text-candy_corn-600">{sync_result.subscriptions_unknown}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Updated</div>
                                            <div className="text-xl font-semibold text-blue-600">{sync_result.subscriptions_updated}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Created</div>
                                            <div className="text-xl font-semibold text-blue-600">{sync_result.subscriptions_created}</div>
                                        </div>
                                        <div className="text-center">
                                            <div className="text-sm text-gray-500">Errored</div>
                                            <div className="text-xl font-semibold text-torch_red-600">{sync_result.subscriptions_errored}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {sync_result.subscriptions_errored > 0 && (
                                <div>
                                    <h4 className="font-medium text-gray-700 mb-2">Errors</h4>
                                    <div className="bg-torch_red-50 p-3 rounded-md">
                                        {sync_result.subscriptions_errored_details.map(x => (
                                            <div key={x.id} className="text-sm text-torch_red-700 mb-1">
                                                <span className="font-medium"><StripeSubLink subscription_id={x.id} />:</span> {x.error}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>}
                    </div>
                </div>
            </div>

            <div className="flex flex-col px-10 py-5 overflow-y-auto">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg border border-gray-200 max-w-5xl">
                    <div className="px-4 py-5 sm:px-6 bg-gray-50">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Active Subscriptions</h3>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Org</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Provider</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Stripe Subscription Id</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {subscriptions.length > 0 && subscriptions.map((subscription, idx) => (
                                    <tr key={idx} className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            <Link to={`/admin/org/${subscription.org_uuid}?tab=subscription`} className="text-sea_blue-600 hover:text-sea_blue-800 hover:underline">{subscription.org_title}</Link>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{subscription.provider}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{subscription.type}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${subscription.status === "active" ? "bg-green-100 text-green-800" :
                                                subscription.status === "canceled" ? "bg-torch_red-100 text-torch_red-800" :
                                                    "bg-candy_corn-100 text-candy_corn-800"
                                                }`}>
                                                {subscription.status}
                                            </span>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{prettySmartDateTime(subscription.created_at)}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {subscription.provider === "stripe" && <StripeSubLink subscription_id={subscription.external_id} />}
                                        </td>
                                    </tr>
                                ))}
                                {subscriptions.length === 0 &&
                                    <tr>
                                        <td className="px-6 py-10 text-center text-sm text-gray-500" colSpan={6}>
                                            <div className="flex flex-col items-center justify-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                </svg>
                                                <p className="mt-2 font-medium">No active subscriptions found</p>
                                                <p className="mt-1 text-gray-400">Run a sync to update subscription data</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <TimeoutErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)}
        />
    </Fragment >;
};
