import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import {
    Link,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

import { saveAs } from "file-saver";

import {
    ArrowUpTrayIcon,
    PencilSquareIcon,
    PlusCircleIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import {
    BrowserIcon,
    FileCodeIcon,
    MarkGithubIcon
} from "@primer/octicons-react";
import { TableCellsIcon } from "@heroicons/react/20/solid";

import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectSelfUrl,
    selectUser
} from "../lib/scraper.slice";
import {
    IEndpointToLookupTable,
    ILookupTable,
    ILookupTableRowSlim
} from "../lib/types";
import {
    Backend,
    BackendObj
} from "../lib/backend";
import {
    classNames,
    escapeCsvField,
    getExcelColumnName,
    prettyDateTime,
    setDocumentTitle
} from "../lib/utils";

import { ITab, Tabs } from "../components/Tabs";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ORG_TYPES } from "../lib/consts";
import { Button, ButtonGroup } from "../components/Button";
import { CopyTextbox } from "../components/CopyTextbox";
import { Sheet, SheetEditor } from "../components/Sheets";
import { Pill } from "../components/Pill";
import { ConfirmModal } from "../components/ConfirmModal";
import { EndpointToLookupTable } from "../components/EndpointToLookupTable";
import { TwoRowHeader } from "../components/Header";
import { WidgetList } from "../components/WidgetList";
import { ListSourceIPs } from "../components/ListSourceIPs";


export function LookupTableDetail() {
    const navigate = useNavigate();
    const location = useLocation();
    const { lookup_table_uuid } = useParams<{ lookup_table_uuid: string | undefined }>();

    // Get tab and page from URL query params, defaulting to "extract_jobs" and 0
    const query_params = new URLSearchParams(location.search);
    const selected_tab = query_params.get("tab") || "observed_values";

    const env = useSelector(selectEnv);
    const self_url = useSelector(selectSelfUrl);
    const user = useSelector(selectUser);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    const [lookup_table, setLookupTable] = useState<ILookupTable | undefined>(undefined);
    const [lookup_table_rows, setLookupTableRows] = useState<ILookupTableRowSlim[] | undefined>(undefined);
    const [has_endpoints, setHasEndpoints] = useState(false);
    const [endpoint_to_lookup_table_mappings, setEndpointToLookupTableMappings] = useState<IEndpointToLookupTable[] | undefined>(undefined);
    const [is_deleting, setIsDeleting] = useState(false);
    const [is_activating, setIsActivating] = useState(false);
    const [show_confirm, setShowConfirm] = useState(false);

    const is_org_admin = memberships.filter((m) => m.org.uuid === lookup_table?.org_uuid && m.role === "admin").length > 0;

    useEffect(() => {
        if (lookup_table_uuid === undefined) { return; }
        Backend.getLookupTable({ lookup_table_uuid })
            .then((lookup_table) => {
                if (lookup_table !== undefined) {
                    setLookupTable(lookup_table);
                    BackendObj.extractions.listLookupTableRows({ lookup_table_uuid, header_length: lookup_table.headers.length })
                        .then(({ rows }) => { setLookupTableRows(rows); })
                        .catch((error) => { console.error(error); setLookupTableRows(undefined); });
                    BackendObj.extractions.listEndpointToLookupTableMappings({ lookup_table_uuid })
                        .then(({ mappings }) => { setEndpointToLookupTableMappings(mappings); })
                        .catch((error) => { console.error(error); setEndpointToLookupTableMappings(undefined); });
                    // check if we have any endpoints so we can show the connected endpoints tab
                    BackendObj.extractions.listEndpoints({}).then(({ endpoints }) => {
                        setHasEndpoints(endpoints.filter((endpoint) => endpoint.org_uuid === lookup_table.org_uuid).length > 0);
                    });
                }
            });
    }, [lookup_table_uuid]);

    useEffect(() => {
        if (lookup_table === undefined) {
            setDocumentTitle("Lookup Table", env);
        } else {
            setDocumentTitle(`Lookup Table - ${lookup_table.name}`, env);
        }
    }, [lookup_table, env]);

    const is_rest_api_enabled = lookup_table?.details.rest_api_update || lookup_table?.details.rest_api_get;

    const tabs: ITab[] = [
        { name: "Observed Values", key: "observed_values" },
        { name: "Uploaded Data", key: "upload_data" },
        { name: "API Docs", key: "api_docs", hide: !(is_rest_api_enabled && is_org_admin) },
        { name: "Insights", key: "insights" },
        { name: "Connected Endpoints", key: "connected_endpoints" }
    ];

    const onDownload = async (type: "rows" | "upload") => {
        if (lookup_table === undefined) { return; }
        // prepare data
        const data: string[][] = type === "rows" ?
            [lookup_table.headers, ...lookup_table_rows?.map((row) => row.row) || []] :
            lookup_table.active_version?.sheet || [];

        // determine delimiter based on org tag
        const use_semicolon = lookup_table_org?.org.tags?.csv_use_semicolon ?? false;
        console.log(lookup_table_org?.org.tags);
        const delimiter = use_semicolon ? ";" : ",";

        // format numbers with german locale for semicolon CSV
        const format_value = (value: any): string => {
            if (use_semicolon && typeof value === "number") {
                return value.toLocaleString("de-DE", { useGrouping: false });
            }
            return String(value);
        };

        // create CSV content with appropriate delimiter
        const csv_content = data.map((row) =>
            row.map((value) => escapeCsvField(format_value(value))).join(delimiter)
        ).join("\n");

        // add BOM for semicolon CSV
        const bom = use_semicolon ? "\uFEFF" : "";
        const blob = new Blob([bom + csv_content], { type: "text/csv" });

        saveAs(blob, `lookup_table${lookup_table.uuid}.csv`);
    };

    const deleteMapping = async (endpoint_to_lookup_table_uuid: string) => {
        setIsDeleting(true);
        await BackendObj.extractions.deleteEndpointToLookupTableMapping({ endpoint_to_lookup_table_uuid });
        if (endpoint_to_lookup_table_mappings !== undefined) {
            const new_endpoint_to_lookup_table_mappings = endpoint_to_lookup_table_mappings.filter((mapping) => mapping.uuid !== endpoint_to_lookup_table_uuid);
            setEndpointToLookupTableMappings(new_endpoint_to_lookup_table_mappings);
        }
        setIsDeleting(false);
    }

    const handleActivate = async (version_uuid: string) => {
        if (lookup_table === undefined) { return; }
        setIsActivating(true);
        // update version
        await BackendObj.extractions.setLookupTableActiveVersion({ lookup_table_uuid: lookup_table.uuid, version_uuid });
        // refresh lookup table
        const updated_lookup_table = await Backend.getLookupTable({ lookup_table_uuid: lookup_table.uuid });
        if (updated_lookup_table !== undefined) {
            setLookupTable(updated_lookup_table);
        }
        setIsActivating(false);
    }

    const onAddRow = async (row: string[]) => {
        if (lookup_table === undefined) { return; }
        const { row: new_row } = await BackendObj.extractions.addLookupTableRow({ lookup_table_uuid: lookup_table.uuid, row });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = [...lookup_table_rows, new_row];
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onDeleteRow = async (row_uuid: string) => {
        await BackendObj.extractions.deleteLookupTableRow({ row_uuid });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = lookup_table_rows.filter((row) => row.uuid !== row_uuid);
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onUpdateRow = async (row_uuid: string, row: string[]) => {
        await BackendObj.extractions.updateLookupTableRow({ row_uuid, row });
        if (lookup_table_rows !== undefined) {
            const new_lookup_table_rows = lookup_table_rows.map((r) => (r.uuid === row_uuid) ? { ...r, row } : r);
            setLookupTableRows(new_lookup_table_rows);
        }
    }

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (is_remove && lookup_table_uuid !== undefined) {
            setIsDeleting(true);
            // delete from backend
            await Backend.deleteLookupTable({ lookup_table_uuid });
            // navigate back to list
            navigate("/endpoints");
        }
    };

    if (lookup_table === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const is_email_enabled = lookup_table.details.email_update !== undefined && lookup_table.details.email_update !== "disabled";
    const is_personal = memberships.some((m) => m.org.uuid === lookup_table.org_uuid && m.org.type === ORG_TYPES.personal);

    const hash_keys = lookup_table.details.merge_key_column_indices?.map((idx) => lookup_table.headers[idx]) || [];
    const compensate_leading_zeros_keys = lookup_table.details.compensate_leading_zeros_column_indices?.map((idx) => lookup_table.headers[idx]) || [];

    const lookup_table_org = memberships.find(({ org }) => org.uuid === lookup_table.org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <TwoRowHeader
            title={lookup_table.name}
            subtitle="Lookup Table"
            org_name={lookup_table_org?.org.name}
            buttons={<Fragment>
                {lookup_table && is_org_admin && <ButtonGroup disabled={is_deleting}
                    buttons={[
                        { icon: ArrowUpTrayIcon, text: "", tooltip: "Upload", href: `/lookup_table/upload/${lookup_table.uuid}` },
                        { icon: PencilSquareIcon, text: "", tooltip: "Edit", href: `/lookup_table/edit/${lookup_table.uuid}` },
                        { icon: TrashIcon, text: "", tooltip: "Remove", onClick: () => setShowConfirm(true) }
                    ]} />}
            </Fragment>}
            onBack={() => navigate("/endpoints")}
        />

        <div className="px-10 py-7 max-w-5xl">
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Type</dt>
                <dd className="flex fle-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    <TableCellsIcon className="h-4 w-4 mr-2 text-slate-300" />Lookup Table
                </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Headers</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0 outer-div">
                    <table className="py-4 text-xs md:text-base">
                        <thead>
                            <tr>
                                {lookup_table.headers.map((_header, idx) => <th key={idx}
                                    className="py-1 px-4 bg-gray-50 border border-gray-300 text-gray-900 text-xs font-normal align-top w-32 focus:ring-1 focus:ring-space_blue-500 min-w-[150px] max-w-[300px]"
                                >
                                    {getExcelColumnName(idx)}
                                </th>)}
                            </tr>
                            <tr>
                                {lookup_table.headers.map((header, idx) => <th key={idx}
                                    title={header}
                                    className="truncate py-1 px-4 bg-gray-100 border border-gray-300 text-gray-900 cursor-text hover:bg-sea_blue-100 text-left text-sm font-normal align-top w-32 focus:ring-1 focus:ring-space_blue-500 min-w-[150px] max-w-[300px]"
                                >
                                    {header}
                                </th>)}
                            </tr>
                        </thead>
                    </table>
                </dd>
            </div>
            <div className="pt-3 pb-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Merge keys</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-8 sm:mt-0">
                    {hash_keys.length === 0 && "/"}
                    {hash_keys.length > 0 && hash_keys.join(", ")}
                </dd>
            </div>

            <div className="pt-3 pb-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Compensate leading zeros</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-8 sm:mt-0">
                    {compensate_leading_zeros_keys.length === 0 && "/"}
                    {compensate_leading_zeros_keys.length > 0 && compensate_leading_zeros_keys.join(", ")}
                </dd>
            </div>

            {is_email_enabled && <div className="py-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Email connector</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-8 sm:mt-0">
                    <CopyTextbox text={lookup_table.email_address} email_pretty_name={`Update lookup table '${lookup_table.name}'`} is_email={true} />
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Upload via Email</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {[
                        { key: "replace", name: "Upload" },
                        { key: "append", name: "Append" },
                        { key: "merge_incoming_wins", name: "Merge incoming wins" },
                        { key: "merge_existing_wins", name: "Merge existing wins" }
                    ].find((f) => f.key === lookup_table.details.email_update)?.name || "/"}
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Attachment format</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                    {[
                        { key: "tsv", name: "TSV" }
                    ].find((f) => f.key === lookup_table.details.email_attachment_format)?.name || "TSV"}
                </dd>
            </div>}

            {is_rest_api_enabled && <div className="py-6 sm:grid sm:grid-cols-10 sm:gap-x-4 sm:gap-y-6 sm:px-0 items-center border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-2">REST API Endpoint</dt>
                <dd className="pr-4 text-sm leading-6 sm:col-span-8 sm:mt-0 text-space_blue-600">
                    <CopyTextbox text={lookup_table.url} is_email={false} />
                </dd>
            </div>}
        </div>

        <div className="px-10 max-w-5xl">
            <div className="pt-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={(tab) => navigate(`/lookup_table/${lookup_table_uuid}?tab=${tab}`)} />
            </div>

            {selected_tab === "observed_values" && lookup_table_rows &&
                <div className="px-4 py-3 pb-6">
                    <div>
                        <span
                            className="p-2 text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
                            onClick={() => onDownload("rows")}>
                            [download]
                        </span>
                    </div>
                    <div className="pb-4">
                        <SheetEditor
                            headers={lookup_table.headers}
                            rows={lookup_table_rows.map((row) => ({ uuid: row.uuid, row: row.row }))}
                            onAddRow={onAddRow}
                            onDeleteRow={onDeleteRow}
                            onUpdateRow={onUpdateRow} />
                    </div>
                </div>}

            {selected_tab === "upload_data" &&
                <div className="px-4 py-3 sm:px-0">
                    {lookup_table.active_version?.sheet !== undefined && lookup_table.active_version?.no_of_rows > 0 &&
                        <div className="pl-4 outer-div">
                            <span
                                className="p-2 text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
                                onClick={() => onDownload("upload")}>
                                [download]
                            </span>
                            <Sheet data={lookup_table.active_version.sheet} />
                        </div>}
                    {lookup_table.active_version?.sheet !== undefined && lookup_table.active_version?.no_of_rows === 0 &&
                        <div className="pl-4 text-sm text-gray-900">No uploaded data</div>}
                    {lookup_table.active_version?.sheet === undefined &&
                        <div className="pl-4 text-sm text-gray-900">Sheet not available</div>}

                    <div className="pl-4 pt-4 text-sm font-medium leading-6 text-gray-900">Upload History</div>
                    <div className="p-4">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Created</th>
                                    <th className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Rows</th>
                                    <th className="w-32 px-3 py-2 text-center text-sm font-semibold text-gray-900">Status</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {lookup_table.versions.slice(0, 100).map((version, idx) => (
                                    <tr key={idx}>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-600 align-top">{prettyDateTime(version.created_at)}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-600 align-top">{version.no_of_rows}</td>
                                        <td className="whitespace-nowrap px-3 py-2 text-sm align-top text-center">
                                            {version.uuid === lookup_table.active_version_uuid &&
                                                <Pill text="Active" type="info" disabled={is_activating} />}
                                            {version.uuid !== lookup_table.active_version_uuid &&
                                                <Pill text="Activate" type="default" disabled={is_activating} onClick={() => handleActivate(version.uuid)} />}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}

            {selected_tab === "api_docs" && <Fragment>
                <h3 className="px-4 pt-4 leading-7 text-lg font-medium">API Keys</h3>
                <div className="text-sm text-gray-500 flex-grow py-4">
                    {!is_personal && <span>You can manage API keys in the <Link to={`/org/${lookup_table.org_uuid}`} className="text-space_blue-600 underline">organization settings</Link>.</span>}
                    {is_personal && <span>You can manage API keys in the <Link to={`/user/${user.uuid}`} className="text-space_blue-600 underline">user settings</Link>.</span>}
                </div>
                <h3 className="px-4 pt-4 leading-7 text-lg font-medium">REST API</h3>
                <div className="my-4 flex flex-row gap-2 items-center">
                    <Button
                        icon={FileCodeIcon}
                        text="OpenAPI"
                        href={`${self_url}/public/open-api.json`}
                        open_in_new_tab={true} />
                    <Button
                        icon={BrowserIcon}
                        text="Swagger"
                        href={`${self_url}/public/swagger`}
                        open_in_new_tab={true} />
                    <ListSourceIPs />
                    <Button
                        icon={MarkGithubIcon}
                        href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                        text="Node.JS example"
                        open_in_new_tab={true} />
                </div>
            </Fragment>}

            {selected_tab === "insights" && <div className="p-4 w-full">
                <WidgetList lookup_table={lookup_table} />
            </div>}

            {selected_tab === "connected_endpoints" && <div className="px-4 py-3 pb-6">
                <div className="py-3 flex flex-row items-center w-full">
                    <div className="flex-1 text-sm font-medium leading-6 text-gray-900">Connected Endpoints</div>
                    {has_endpoints && is_org_admin && <Button icon={PlusCircleIcon} text="Connect" href={`/endpoint-lookup-table-mapping/create/lookup_table/${lookup_table.uuid}`} />}
                </div>
                <EndpointToLookupTable
                    can_edit={is_org_admin}
                    source_type="lookup_table"
                    source_uuid={lookup_table.uuid}
                    endpoint_to_lookup_table_mappings={endpoint_to_lookup_table_mappings}
                    is_deleting={is_deleting}
                    deleteMapping={deleteMapping} />
            </div>}

        </div>
        <ConfirmModal open={show_confirm}
            title="Remove Lookup Table"
            message={["Are you sure you want to remove this lookup table?"]}
            confirm="Remove"
            onClose={onRemoveClose} />
    </div>;
}
