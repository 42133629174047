import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate
} from "react-router-dom";
import {
    useSelector,
    useDispatch
} from "react-redux";

import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild
} from "@headlessui/react";
import * as hi from "@heroicons/react/24/outline";
import {
    TbTable,
    TbTablePlus
} from "react-icons/tb";

import {
    classNames,
    getNextOnboardingStep,
    setDocumentTitle
} from "./lib/utils";
import { AppDispatch } from "./store";
import {
    selectInit,
    selectIsSidebarLarge,
    selectUser,
    selectOnboardingStatus,
    selectMemberships,
    scraperUser,
    toggleSidebar,
    selectEnv,
    scraperOrgBalances,
    selectOrgBalances,
    selectSelfUrl
} from "./lib/scraper.slice";
import { USER_ROLES, ONBOARDING_STEPS, ORG_TYPES, ORG_ROLES, Env, ENDPOINT_TYPE } from "./lib/consts";
import {
    UserRole
} from "./lib/backend/extractions.types.generated";
import { IUser } from "./lib/types";

import { LoadingSpinner } from "./components/LoadingSpinner";
import { Demo } from "./screens/Demo";
import { LOGIN_STATES, Login } from "./screens/Login";
import { Home } from "./screens/Home";
import { Templates } from "./screens/Templates";
import { NewTemplate, NewTemplateInit } from "./screens/NewTemplate";
import { User } from "./screens/User";
import { NewExtraction } from "./screens/NewExtraction";
import { Items } from "./screens/Items";
import { ItemDetail } from "./screens/ItemDetail";
import { AdminUsers } from "./screens/AdminUsers";
import { AdminUser } from "./screens/AdminUser";
import { AdminBackgroundJobs } from "./screens/AdminBackgroundJobs";
import { Backend } from "./lib/backend";
import { AdminSettings } from "./screens/AdminSettings";
import { AdminDemoUsage } from "./screens/AdminDemoUsage";
import { AdminPromptLog } from "./screens/AdminPromptLog";
import { AdminUnitEconomics } from "./screens/AdminUnitEconomics";
import { PASSWORD_STATES, Password } from "./screens/Password";
import { Endpoints } from "./screens/Endpoints";
import { NewEndpoint, NewEndpointInit } from "./screens/NewEndpoint";
import { AdminImportTemplate } from "./screens/AdminImportTemplate";
import { AdminOrgs } from "./screens/AdminOrgs";
import { AdminOrg } from "./screens/AdminOrg";
import { AdminExtractionJobs } from "./screens/AdminExtractionJobs";
import { VersionCheck } from "./components/VersionCheck";
import { Stripe } from "./screens/Stripe";
import { StripeAdministration } from "./screens/StripeAdministration";
import { ReferralsAdministration } from "./screens/ReferralsAdministration";
import { StripeSubscribe } from "./screens/StripeSubscribe";
import { ItemConfirm } from "./screens/ItemConfirm";
import { NewLookupTable } from "./screens/NewLookupTable";
import { OneTimePaymentRedirect } from "./components/OneTimePaymentRedirect";
import { AdminEmailUsers } from "./screens/AdminEmailUsers";
import { NewTemplateWizard } from "./screens/NewTemplateWizard";
import { Organization } from "./screens/Organization";
import { NewEndpointToLookupTableMapping } from "./screens/NewEndpointToLookupTableMapping";
import { UploadLookupTable } from "./screens/UploadLookupTable";
import { AdminDashboards } from "./screens/AdminDashboards";
import { EndpointDetail } from "./screens/EndpointDetail";
import { LookupTableDetail } from "./screens/LookupTableDetail";
import { JobDetail } from "./screens/JobDetail";
import { Tasks } from "./screens/Tasks";
import { ChangeModal } from "./components/ChangeModal";
import { AdminDB } from "./screens/AdminDB";
import { Insights } from "./screens/Insights";

interface INavigationItem {
    name: string;
    href: string;
    icon: any;
    current: boolean;
    roles?: UserRole[];
    envs?: Env[];
}

function InitPage() {
    return <LoadingSpinner />;
}

function TitleWrapper({ title, children }: { title: string, children: any }) {
    const env = useSelector(selectEnv);

    useEffect(() => { setDocumentTitle(title, env); }, [title, env]);
    return children;
}

function InitNewUser(props: { user: IUser }) {
    const dispatch = useDispatch<AppDispatch>();
    const user = props.user;

    useEffect(() => {
        // we start with initializing empty details since this call also triggers on
        // backend a call to check geoip and update user details
        Backend.updateUserDetails({
            details: {
                company_name: "",
                company_size: "",
                team_size: "",
                industry: "",
                job_role: "",
                country_code: ""
            }
        }).then(() => {
            Backend.updateOnboardingStep({
                step: `${getNextOnboardingStep(ONBOARDING_STEPS.welcome)}`
            }).then(() => {
                dispatch(scraperUser());
            });
        });
    }, [dispatch, user.email]);

    return <LoadingSpinner />;
}

function App() {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const init = useSelector(selectInit);
    const env = useSelector(selectEnv);
    const self_url = useSelector(selectSelfUrl);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);
    const onboarding_status = useSelector(selectOnboardingStatus);
    const org_balances = useSelector(selectOrgBalances);

    useEffect(() => {
        dispatch(scraperUser());
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            dispatch(scraperOrgBalances());
        }
    }, [dispatch, user]);

    const [sidebar_open, setSidebarOpen] = useState(false);
    const [is_admin_open, setIsAdminOpen] = useState(localStorage.getItem("is_sidebar_admin_open") === "true");

    const toggleAdminOpen = () => {
        const new_is_admin_open = !is_admin_open;
        setIsAdminOpen(new_is_admin_open);
        localStorage.setItem("is_sidebar_admin_open", new_is_admin_open ? "true" : "false");
    }

    const pathname = useLocation().pathname;
    const is_demo = pathname === "/demo";
    const is_login = pathname === "/login";
    const is_register = pathname === "/register";
    const is_subscribe = pathname === "/subscribe";

    // check if we are on a different domain than the self_url and redirect to the self_url
    const current_url = window.location.href.toLowerCase();
    if (!current_url.startsWith(self_url.toLowerCase()) && env !== "dev") {
        // replace the domain and port (if any) with the self_url
        const new_url = current_url.replace(window.location.host, new URL(self_url).host);
        window.location.href = new_url;
    }

    // check if demo mode, used embedded on the website
    if (is_demo) { return <TitleWrapper title="Demo"><Demo /></TitleWrapper>; }
    // we did not check yet if user is logged in or we are redirecting to login page
    if (init) { return <TitleWrapper title=""><InitPage /></TitleWrapper>; }
    // we are not logged in
    if (!user) {
        // if at register link, show registration
        if (is_register) {
            return <TitleWrapper title="Register"><Login init_login_state={LOGIN_STATES.register} /></TitleWrapper>;
        }
        // if at subscribe link, show registration page
        if (is_subscribe) {
            navigate("/register");
            return null;
        }
        // else show login page
        return <TitleWrapper title="Login"><Login init_login_state={LOGIN_STATES.login_email} /></TitleWrapper>;
    }
    // if logged-in user comes to login or register page, redirect to home
    if (is_login || is_register) {
        navigate("/");
        return null;
    }
    // if logged-in user comes to subscribe, redirect to stripe-subscribe
    if (is_subscribe) {
        for (const org of memberships) {
            if (org.org.type === ORG_TYPES.personal) {
                // show subscription options for personal org
                navigate(`/stripe-subscribe/${org.org.uuid}`);
                return null;
            }
        };
        navigate("/"); // we couldn't find personal org for this user, go to home
        return null;
    }

    // direct link to onboarding screens for testing
    if (pathname === "/onboard/01") { return <TitleWrapper title="Initializing new account"><InitNewUser user={user} /></TitleWrapper>; }

    if (user.reset_password) {
        return <TitleWrapper title="Reset Password"><Password user={user} init_password_state={PASSWORD_STATES.new} /></TitleWrapper>;
    }

    if (pathname === "/user/change_password") {
        return <TitleWrapper title="Change Password"><Password user={user} init_password_state={PASSWORD_STATES.change} /></TitleWrapper>;
    }

    // check onboarding status and show onboarding if needed
    if (onboarding_status && onboarding_status.step === ONBOARDING_STEPS.new_account) {
        // fire and forget update onboarding status
        const onboarding_status_update = async () => {
            await Backend.updateOnboardingStep({
                step: `${getNextOnboardingStep(ONBOARDING_STEPS.new_account)}`
            });
            dispatch(scraperUser());
        }
        onboarding_status_update();

        return <TitleWrapper title=""><InitPage /></TitleWrapper>;
    }
    if (onboarding_status && onboarding_status.step === ONBOARDING_STEPS.welcome) {
        return <TitleWrapper title="Initializing new account"><InitNewUser user={user} /></TitleWrapper>;
    }

    // check if we need to redirect to pre-login url
    const pre_login_url = localStorage.getItem("pre_login_url");
    if (pre_login_url) {
        localStorage.removeItem("pre_login_url");
        if (pre_login_url !== pathname) {
            navigate(pre_login_url);
        }
        return null;
    }

    // no special page, render the main app
    const is_admin = user.role === USER_ROLES.admin;

    // check if we need to show the upgrade button
    const is_free = user.role === USER_ROLES.free;
    const personal_org = memberships.find((membership) => membership.org.type === ORG_TYPES.personal);
    const admin_orgs = memberships.filter((membership) => membership.org.type === ORG_TYPES.business && membership.role === ORG_ROLES.admin);
    const subscribe_url = personal_org ? `/stripe-subscribe/${personal_org.org.uuid}` : "/";

    // when in confirm screen we always show small sidebar
    const alway_small_sidebar = pathname.startsWith("/confirm/");
    const show_sidebar_large = is_sidebar_large && !alway_small_sidebar;

    // check if we need to show low on credits button
    const self_serve_roles = [USER_ROLES.professional, USER_ROLES.professional_plus];
    const is_low_on_credits =
        // user must be in self-serve plan and we must have org balances
        self_serve_roles.includes(user.role) && org_balances &&
        // user must have personal org with low balance
        org_balances.some((balance) => balance.org.type === ORG_TYPES.personal && balance.balance < 100);

    // we are logged and in the main app
    const check_if_current = (item: INavigationItem): INavigationItem => {
        // special case for home page
        if (item.href === "/") { return { ...item, current: pathname === "/" }; }
        // check if current page is a sub page of the navigation item
        const current = pathname.startsWith(item.href);
        return { ...item, current };
    }

    const navigation: INavigationItem[] = [
        { name: "Dashboard", href: "/", icon: hi.HomeIcon, current: false },
        { name: "TODOs", href: "/tasks", icon: hi.InboxIcon, current: false },
        { name: "Insights", href: "/insights", icon: hi.RocketLaunchIcon, current: false },
        { name: "Results", href: "/items", icon: hi.CheckCircleIcon, current: false },
        { name: "Templates", href: "/templates", icon: TbTable, current: false },
        { name: "Integrations", href: "/endpoints", icon: hi.PuzzlePieceIcon, current: false, roles: [USER_ROLES.admin, USER_ROLES.enterprise, ...self_serve_roles] },
        { name: "Integrations", href: "/endpoint/new", icon: hi.PuzzlePieceIcon, current: false, roles: [USER_ROLES.free] }
    ].map(check_if_current).filter((item) => !item.roles || item.roles.includes(user.role)).filter((item) => !item.envs || item.envs.includes(env));

    const actions: INavigationItem[] = [
        { name: "New Job", href: "/extraction/new", icon: hi.PlusIcon, current: false },
        { name: "New Template", href: "/template/new", icon: TbTablePlus, current: false }
    ].map(check_if_current);

    const admin: INavigationItem[] = [
        { name: "Users", href: "/admin/users", icon: hi.UsersIcon, current: false },
        { name: "Organizations", href: "/admin/orgs", icon: hi.BuildingOfficeIcon, current: false },
        { name: "Settings", href: "/admin/settings", icon: hi.CogIcon, current: false }
    ].map(check_if_current);

    const selected_item = [...navigation, ...actions].find((item: INavigationItem) => item.current);

    return <div>
        {/* Static sidebar for mobile */}
        <Transition show={sidebar_open} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                <TransitionChild
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div className="fixed inset-0 flex">
                    <TransitionChild
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                        <span className="sr-only">Close sidebar</span>
                                        <hi.XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </TransitionChild>
                            {/* Sidebar component */}
                            <div className={classNames("flex grow flex-col gap-y-5 overflow-y-auto px-4 pb-2", "bg-space_blue-950")}>
                                <div className="flex h-16 shrink-0 items-center text-space_blue-100 text-xl font-semibold justify-center">
                                    <Link to="/"><img src="/logo_white.svg" alt="nordoon" className="h-10" /></Link>
                                </div>
                                <nav className="flex flex-1 flex-col px-5">
                                    <ul className="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul className="-mx-2 space-y-1">
                                                {navigation.map((item) => (
                                                    <li key={item.name}>
                                                        <Link
                                                            to={item.href}
                                                            onClick={() => setSidebarOpen(false)}
                                                            className={classNames(
                                                                item.current
                                                                    ? "bg-sidebarButtonSelected text-white"
                                                                    : "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? "text-white" : "text-space_blue-100 group-hover:text-white",
                                                                    "h-6 w-6 shrink-0"
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        <li>
                                            <div className="text-xs font-semibold leading-6 text-space_blue-100">Quick actions</div>
                                            <ul className="-mx-2 mt-2 space-y-1">
                                                {actions.map((item) => (
                                                    <li key={item.name}>
                                                        <Link
                                                            to={item.href}
                                                            onClick={() => setSidebarOpen(false)}
                                                            className={classNames(
                                                                item.current
                                                                    ? "bg-sidebarButtonSelected text-white"
                                                                    : "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? "text-white" : "text-space_blue-100 group-hover:text-white",
                                                                    "h-6 w-6 shrink-0"
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                        {is_admin && <li>
                                            <div className="flex flex-row items-center cursor-pointer" onClick={toggleAdminOpen}>
                                                <div className="text-xs font-semibold leading-6 text-space_blue-100">Administration</div>
                                                <div className="flex-grow" />
                                                <div className="text-sm font-semibold leading-6 text-space_blue-100">{is_admin_open ? "−" : "+"}</div>
                                            </div>
                                            {is_admin_open && <ul className="-mx-2 mt-2 space-y-1">
                                                {admin.map((item) => (
                                                    <li key={item.name}>
                                                        <Link
                                                            to={item.href}
                                                            onClick={() => setSidebarOpen(false)}
                                                            className={classNames(
                                                                item.current
                                                                    ? "bg-sidebarButtonSelected text-white"
                                                                    : "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                            )}
                                                        >
                                                            <item.icon
                                                                className={classNames(
                                                                    item.current ? "text-white" : "text-space_blue-100 group-hover:text-white",
                                                                    "h-6 w-6 shrink-0"
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                            {item.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>}
                                        </li>}
                                        <div className="flex-grow" />
                                        {is_free && <div className="mx-6 my-4 px-6 py-2 text-center text-white bg-sea_blue-700 text-sidebarButtonSelected rounded-lg cursor-pointer"
                                            onClick={() => navigate(subscribe_url)}>Upgrade</div>}
                                        {admin_orgs.length > 0 && <li>
                                            <ul className="-mx-2 mt-2 space-y-1">
                                                {admin_orgs.map((admin_org, idx) => (<li key={idx}>
                                                    <Link
                                                        to={`/org/${admin_org.org.uuid}`}
                                                        className="text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                    >
                                                        <hi.BuildingOfficeIcon className="text-space_blue-100 group-hover:text-white h-6 w-6 shrink-0" />
                                                        <span className="sr-only">Your organization</span>
                                                        <span aria-hidden="true">{admin_org.org.name}</span>
                                                    </Link>
                                                </li>))}
                                            </ul>
                                        </li>}
                                    </ul>
                                </nav>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-space_blue-950 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
            <button type="button" className="-m-2.5 p-2.5 text-space_blue-200 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <hi.Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 text-sm font-semibold leading-6 text-white">
                {selected_item ? selected_item.name : ""}
            </div>
            <Link to="/user">
                <span className="sr-only">Your profile</span>
                <img
                    className="h-8 w-8 rounded-full bg-white"
                    src={user.picture_url}
                    alt={`${user.first_name} ${user.last_name}`}
                />
            </Link>
        </div>

        {/* Static sidebar for desktop */}
        {show_sidebar_large && <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-64 lg:flex-col lg:overflow-y-auto">
            {/* Sidebar component */}
            <div className={classNames("flex grow flex-col gap-y-5 px-4", "bg-space_blue-950")}>
                <div className="ml-5 flex h-16 shrink-0 items-center text-space_blue-100 text-xl font-semibold mt-3">
                    <Link to="/"><img src="/logo_white.svg" alt="nordoon" className="h-10" /></Link>
                    <div className="flex-grow" />
                    <div className="hover:bg-sidebarButtonHover rounded-md cursor-pointer" onClick={() => dispatch(toggleSidebar())}>
                        <hi.ChevronDoubleLeftIcon className="h-6 w-6 text-white" />
                    </div>
                </div>
                <nav className="flex flex-1 flex-col px-5">
                    <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-sidebarButtonSelected text-white"
                                                    : "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? "text-white" : "text-space_blue-100 group-hover:text-white",
                                                    "h-6 w-6 shrink-0"
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li>
                            <div className="text-xs font-semibold leading-6 text-space_blue-100">Quick actions</div>
                            <ul className="-mx-2 mt-2 space-y-1">
                                {actions.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-sidebarButtonSelected text-white"
                                                    : "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? "text-white" : "text-space_blue-100 group-hover:text-white",
                                                    "h-6 w-6 shrink-0"
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        {is_admin && <li>
                            <div className="flex flex-row items-center cursor-pointer" onClick={toggleAdminOpen}>
                                <div className="text-xs font-semibold leading-6 text-space_blue-100">Administration</div>
                                <div className="flex-grow" />
                                <div className="text-sm font-semibold leading-6 text-space_blue-100">{is_admin_open ? "−" : "+"}</div>
                            </div>
                            {is_admin_open && <ul className="-mx-2 mt-2 space-y-1">
                                {admin.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-sidebarButtonSelected text-white"
                                                    : "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? "text-white" : "text-space_blue-100 group-hover:text-white",
                                                    "h-6 w-6 shrink-0"
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>}
                        </li>}
                        <div className="flex-grow" />
                        <li className="-mx-9">
                            {is_free && <div
                                className="mx-6 my-2 px-6 py-2 text-center text-white bg-sea_blue-700 text-sidebarButtonSelected rounded-lg cursor-pointer"
                                onClick={() => navigate(subscribe_url)}>Upgrade</div>}
                            {is_low_on_credits && <div
                                className="mx-6 my-2 px-6 py-2 text-center text-white bg-sea_blue-700 text-sidebarButtonSelected rounded-lg cursor-pointer"
                                onClick={() => navigate("/stripe-one-time-payment")}>Buy extra credits</div>}

                            {admin_orgs.map((admin_org, idx) => <Link key={idx} to={`/org/${admin_org.org.uuid}`}
                                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover">
                                <hi.BuildingOfficeIcon className="m-1 h-6 w-6 shrink-0 text-space_blue-200 group-hover:text-white" />
                                <span className="sr-only">Your organization</span>
                                <span aria-hidden="true">{admin_org.org.name}</span>
                            </Link>)}

                            <Link
                                to="/user"
                                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:text-white hover:bg-sidebarButtonHover"
                            >
                                <img
                                    className="h-8 w-8 rounded-full bg-white"
                                    src={user.picture_url}
                                    alt={`${user.first_name} ${user.last_name}`}
                                />
                                <span className="sr-only">Your profile</span>
                                <span aria-hidden="true">{`${user.first_name} ${user.last_name}`}</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>}

        {!show_sidebar_large && <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-30 lg:block lg:w-20 lg:overflow-y-auto lg:overflow-x-hidden lg:bg-space_blue-950 lg:pb-0">
            <div className="flex grow flex-col h-full">
                {!alway_small_sidebar && <div className="flex flex-col h-28 shrink-0 items-center justify-center cursor-pointer">
                    <img src="/logo_small_white.svg" alt="nordoon" className="h-10 w-10 mb-2" />
                    <div className="flex mt-2 h-6 w-6 shrink-0 items-center justify-center hover:bg-sidebarButtonHover rounded-md cursor-pointer" onClick={() => dispatch(toggleSidebar())}>
                        <hi.ChevronDoubleRightIcon className="h-6 w-6 text-white" />
                    </div>
                </div>}
                {alway_small_sidebar && <div className="flex flex-col h-28 shrink-0 items-center justify-center cursor-pointer">
                    <img src="/logo_small_white.svg" alt="nordoon" className="h-10 w-10 mb-2" />
                    <div className="h-8" />
                </div>}
                <nav className="mt-2 flex grow flex-col gap-y-7">
                    <ul className="flex flex-col items-center space-y-1">
                        {navigation.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.href}
                                    className={classNames(
                                        item.current ?
                                            "bg-sidebarButtonSelected text-white" :
                                            "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                        "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
                                    )}
                                    title={item.name}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    <span className="sr-only">{item.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="flex flex-col items-center space-y-1">
                        {actions.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.href}
                                    className={classNames(
                                        item.current ?
                                            "bg-sidebarButtonSelected text-white" :
                                            "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                        "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
                                    )}
                                    title={item.name}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    <span className="sr-only">{item.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    {is_admin && is_admin_open && <ul className="flex flex-col items-center space-y-1">
                        {admin.map((item) => (
                            <li key={item.name}>
                                <Link
                                    to={item.href}
                                    className={classNames(
                                        item.current ?
                                            "bg-sidebarButtonSelected text-white" :
                                            "text-space_blue-200 hover:text-white hover:bg-sidebarButtonHover",
                                        "group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold"
                                    )}
                                    title={item.name}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    <span className="sr-only">{item.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>}
                    <div className="flex-grow" />
                    <ul className="flex flex-col items-center space-y-1">
                        {is_free && <div className="mx-6 my-4 px-3 py-2 text-center text-white bg-sea_blue-700 text-sidebarButtonSelected rounded-lg cursor-pointer"
                            onClick={() => navigate(subscribe_url)}><hi.ArrowUpIcon className="h-6 w-6 shrink-0" title="Upgrade account" /></div>}
                        {is_low_on_credits && <div className="mx-6 my-4 px-3 py-2 text-center text-white bg-sea_blue-700 text-sidebarButtonSelected rounded-lg cursor-pointer"
                            onClick={() => navigate("/stripe-one-time-payment")}><hi.ArrowUpIcon className="h-6 w-6 shrink-0" title="Buy extra credits" /></div>}

                        <li>
                            {admin_orgs.map((admin_org, idx) => <Link key={idx} to={`/org/${admin_org.org.uuid}`}
                                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:text-white hover:bg-sidebarButtonHover"
                                title={`Your profile ${user.first_name} ${user.last_name}`}
                            >
                                <hi.BuildingOfficeIcon className="m-1 h-6 w-6 shrink-0 text-space_blue-100 group-hover:text-white" />
                                <span className="sr-only">Your organization {admin_org.org.name}</span>
                            </Link>)}

                            <Link
                                to="/user"
                                className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:text-white hover:bg-sidebarButtonHover"
                                title={`Your profile ${user.first_name} ${user.last_name}`}
                            >
                                <img
                                    className="h-8 w-8 rounded-full bg-white"
                                    src={user.picture_url}
                                    alt={`${user.first_name} ${user.last_name}`}
                                />
                                <span className="sr-only">Your profile {`${user.first_name} ${user.last_name}`}</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>}

        <main className="lg:fixed lg:inset-y-0 lg:pl-64">
            <Routes>
                <Route path="/" element={<TitleWrapper title="Dashboard"><Home /></TitleWrapper>} />

                <Route path="/job/:job_uuid" element={<JobDetail />} />
                <Route path="/items" element={<TitleWrapper title="Results"><Items /></TitleWrapper>} />
                <Route path="/item/:item_uuid" element={<ItemDetail />} />
                <Route path="/tasks" element={<TitleWrapper title="TODOs"><Tasks /></TitleWrapper>} />
                <Route path="/confirm/:item_uuid" element={<ItemConfirm />} />
                <Route path="/insights" element={<TitleWrapper title="Insights"><Insights /></TitleWrapper>} />

                <Route path="/templates" element={<TitleWrapper title="Templates"><Templates /></TitleWrapper>} />
                <Route path="/template/:template_uuid" element={<NewTemplate />} />
                <Route path="/template/new" element={<NewTemplateInit />} />
                <Route path="/template/new/:init_template_type" element={<NewTemplate />} />
                <Route path="/template/wizard" element={<TitleWrapper title="New Template - Upload"><NewTemplateWizard /></TitleWrapper>} />

                <Route path="/extraction/new" element={<TitleWrapper title="New Job"><NewExtraction /></TitleWrapper>} />
                <Route path="/extraction/new/:selected_uuid" element={<TitleWrapper title="New Job"><NewExtraction /></TitleWrapper>} />
                <Route path="/extraction/new/:selected_uuid/:item_uuid" element={<TitleWrapper title="Retry Job"><NewExtraction /></TitleWrapper>} />

                <Route path="/endpoints" element={<TitleWrapper title="Integrations"><Endpoints /></TitleWrapper>} />
                <Route path="/endpoint/:endpoint_uuid" element={<EndpointDetail />} />
                <Route path="/endpoint/new" element={<NewEndpointInit />} />
                <Route path="/endpoint/new/email" element={<NewEndpoint init_endpoint_type={ENDPOINT_TYPE.email} />} />
                <Route path="/endpoint/new/rest_api" element={<NewEndpoint init_endpoint_type={ENDPOINT_TYPE.rest_api} />} />
                <Route path="/endpoint/:endpoint_uuid/edit" element={<NewEndpoint />} />
                <Route path="/lookup_table/:lookup_table_uuid" element={<LookupTableDetail />} />
                <Route path="/lookup_table/new" element={<NewLookupTable />} />
                <Route path="/lookup_table/edit/:lookup_table_uuid" element={<NewLookupTable />} />
                <Route path="/lookup_table/upload/:lookup_table_uuid" element={<UploadLookupTable />} />
                <Route path="/endpoint-lookup-table-mapping/create/:source_type/:source_uuid" element={<NewEndpointToLookupTableMapping />} />
                <Route path="/endpoint-lookup-table-mapping/edit/:source_type/:source_uuid/:mapping_uuid" element={<NewEndpointToLookupTableMapping />} />

                <Route path="/user" element={<TitleWrapper title="User Settings"><User /></TitleWrapper>} />
                <Route path="/org/:org_uuid" element={<Organization />} />
                <Route path="/stripe" element={<TitleWrapper title="Stripe"><Stripe /></TitleWrapper>} />
                <Route path="/stripe-subscribe/:org_uuid" element={<TitleWrapper title="Processing Payment"><StripeSubscribe /></TitleWrapper>} />
                <Route path="/stripe-one-time-payment" element={<TitleWrapper title="Buy Extra Credits"><OneTimePaymentRedirect /></TitleWrapper>} />
                {is_admin && <Route path="/admin/subscriptions" element={<TitleWrapper title="Subscriptions"><StripeAdministration /></TitleWrapper>} />}

                {is_admin && <Route path="/admin/users" element={<TitleWrapper title="Admin - Users"><AdminUsers /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/user/:uuid" element={<TitleWrapper title="Admin - User"><AdminUser /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/orgs" element={<TitleWrapper title="Admin - Organizations"><AdminOrgs /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/org/:org_uuid" element={<TitleWrapper title="Admin - Organization"><AdminOrg /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/email_users" element={<TitleWrapper title="Admin - Email Users"><AdminEmailUsers /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/settings" element={<TitleWrapper title="Admin - Settings"><AdminSettings /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/demo-usage" element={<TitleWrapper title="Admin - Demo Usage"><AdminDemoUsage /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/prompt-log" element={<TitleWrapper title="Admin - Prompt Log"><AdminPromptLog /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/prompt-log/:job_uuid" element={<TitleWrapper title="Admin - Prompt Log"><AdminPromptLog /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/extraction-jobs/:status" element={<TitleWrapper title="Admin - Extract Jobs"><AdminExtractionJobs /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/unit-economics" element={<TitleWrapper title="Admin - Unit Economics"><AdminUnitEconomics /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/background-jobs" element={<TitleWrapper title="Admin - Background Jobs"><AdminBackgroundJobs /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/referrals" element={<TitleWrapper title="Admin - Referrals"><ReferralsAdministration /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/import-template" element={<TitleWrapper title="Admin - Import Template"><AdminImportTemplate /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/dashboards" element={<TitleWrapper title="Admin - Dashboards"><AdminDashboards /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/dashboard/:dashboard_uuid" element={<TitleWrapper title="Admin - Dashboards"><AdminDashboards /></TitleWrapper>} />}
                {is_admin && <Route path="/admin/db" element={<TitleWrapper title="Admin - DB"><AdminDB /></TitleWrapper>} />}
            </Routes>
        </main>
        <VersionCheck />
        <ChangeModal />
    </div >;
}

export default App;
