import {
    Fragment,
    useEffect,
    useRef,
    useState
} from "react";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
    classNames,
    prettySmartDateTime,
    setDocumentTitle
} from "../lib/utils";
import { IItem } from "../lib/types";
import { BackendObj } from "../lib/backend";
import { selectEnv, selectUser } from "../lib/scraper.slice";
import { USER_ROLES } from "../lib/consts";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { ItemTableHandles, ItemTables } from "../components/ItemTables";
import { Toggle } from "../components/Toggle";
import { Button } from "../components/Button";
import { IContextFieldRange, IItemRangeValues, IItemValidationSummary } from "../lib/backend/extractions.types.generated";
import { ItemContent } from "../components/ItemContent";
import { TwoRowHeader } from "../components/Header";
import { ItemBreadcrumb } from "../components/ItemBreadcrumb";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@primer/octicons-react";

export function ItemConfirm() {
    const navigate = useNavigate();
    const { item_uuid } = useParams<{ item_uuid: string | undefined }>();

    const user = useSelector(selectUser);
    const env = useSelector(selectEnv);
    const is_admin = user.role === USER_ROLES.admin

    const [item, setItem] = useState<IItem | undefined>(undefined);
    const [range_values, setRangeValues] = useState<IItemRangeValues[]>([]);
    const [validation_summary, setValidationSummary] = useState<IItemValidationSummary | undefined>(undefined);
    const [invalid_uuid, setInvalidUuid] = useState(false);
    const [show_split, _setShowSplit] = useState(localStorage.getItem("show_confirm_split") ? localStorage.getItem("show_confirm_split") === "true" : true);
    const [left_width, setLeftWidth] = useState(localStorage.getItem("confirm_split_width") ? parseInt(localStorage.getItem("confirm_split_width") as string) : 50);
    const [is_dragging, setIsDragging] = useState(false);
    const [is_confirming, setIsConfirming] = useState(false);
    const [is_rejecting, setIsRejecting] = useState(false);

    useEffect(() => {
        if (item_uuid === undefined) { return; }
        BackendObj.extractions.getItem({ item_uuid })
            .then(({ item, validation_summary, message }) => {
                if (item) { setItem(item); }
                else if (message) { setInvalidUuid(true); }
                if (validation_summary) { setValidationSummary(validation_summary); }
            })
            .catch(() => setInvalidUuid(true));
    }, [item_uuid]);

    useEffect(() => {
        if (item === undefined) { return; }
        // collect ranges
        const ranges: (IContextFieldRange & { field_uuid: string })[] = [];
        for (const context of item.template.contexts) {
            for (const field of context.fields) {
                if (field.range !== undefined) {
                    ranges.push({
                        ...field.range,
                        field_uuid: field.uuid
                    });
                }
            }
        }
        if (ranges.length > 0) {
            BackendObj.extractions.getItemRangeValues({ ranges })
                .then(({ values }) => setRangeValues(values))
                .catch((err) => console.error(err));
        }
    }, [item])

    useEffect(() => {
        if (item === undefined) {
            setDocumentTitle("TODO - loading...", env);
        } else {
            setDocumentTitle(`TODO - ${item.name.length > 0 ? item.name : "[no subject]"}`, env);
        }
    }, [env, item]);

    const setShowSplit = (show: boolean) => {
        _setShowSplit(show);
        localStorage.setItem("show_confirm_split", show.toString());
    }

    /// CALLS TO CHILDREN

    const item_tables_ref = useRef<ItemTableHandles>(null);

    const handleConfirm = async () => {
        setIsConfirming(true);
        await item_tables_ref.current?.confirm();
        setIsConfirming(false);
    }

    const handleReject = async () => {
        setIsRejecting(true);
        await item_tables_ref.current?.reject();
        setIsRejecting(false);
    }

    /// COLUMN DRAGGING

    const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault(); // Prevent text selection
        setIsDragging(true);
    };

    const stopDragging = () => {
        if (is_dragging) { setIsDragging(false); }
    };

    const drag = (e: MouseEvent) => {
        if (is_dragging) {
            // offset all coordinates for the size of the sidebar (256px or 80px)
            const client_x = e.clientX - 80;
            const window_width = window.innerWidth - 80;
            // compute the new width as a percentage of the window width
            const new_width = (client_x / window_width) * 100;
            setLeftWidth(new_width);
            localStorage.setItem("confirm_split_width", new_width.toString());
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', drag);
        window.addEventListener('mouseup', stopDragging);

        return () => {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', stopDragging);
        };
    });

    const onItemUpdate = async () => {
        if (item === undefined) { return; }
        const { confirm_items } = await BackendObj.extractions.getOpenExtractConfirmations({});
        if (confirm_items.length > 0) {
            setItem(undefined);
            navigate(`/confirm/${confirm_items[0].uuid}`);
        } else {
            navigate(`/tasks`);
        }
    }

    const changeDecimalSeparator = async (decimal_separator: "," | ".") => {
        if (item === undefined) { return; }
        const { item: new_item } = await BackendObj.extractions.executeItemPostprocessing({ item_uuid: item.uuid, overrides: { decimal_separator } });
        setItem(new_item);
    }

    /// RENDER

    if (item_uuid === undefined || invalid_uuid) {
        return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto m-10 lg:left-20")}>
            Item not found
        </div>;
    }

    if (item === undefined) {
        return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (item.extract_confirmations_status !== "pending") {
        return <div className={classNames("flex lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto lg:left-20")}>
            <div className="p-10 w-full">
                <div className="max-w-5xl  flex flex-row items-center">
                    <div className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight whitespace-nowrap truncate">
                        This item has already been processed
                    </div>
                    <div className="flex-grow" />
                    <Button text="Next Confirmation" highlight={true} onClick={onItemUpdate} />
                </div>
            </div>
        </div >;
    }

    return <div className={classNames("lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto lg:left-20")}>
        <div className="h-full w-full overflow-auto">
            <TwoRowHeader
                title={item.name.length > 0 ? item.name : "[no subject]"}
                subtitle={`${item.template.name} [${prettySmartDateTime(item.created_at)}]`}
                buttons={<Fragment>
                    <Button
                        icon={XMarkIcon}
                        text="Reject"
                        highlight={validation_summary?.final_status === "reject"}
                        disabled={is_confirming || is_rejecting}
                        onClick={handleReject}
                        loading={is_rejecting} />
                    <Button
                        icon={CheckIcon}
                        text="Confirm"
                        highlight={validation_summary?.final_status !== "reject"}
                        disabled={is_confirming || is_rejecting}
                        onClick={handleConfirm}
                        loading={is_confirming} />
                </Fragment>}
                subbuttons={<div className="flex flex-row items-center gap-2">
                    <div className="text-xs text-gray-400 cursor-pointer" onClick={() => setShowSplit(!show_split)}>Show Input</div>
                    <Toggle enabled={show_split} setEnabled={setShowSplit} />
                </div>}
                onBack={() => navigate("/tasks")}
            />
            {is_admin ? <div className="flex items-center gap-2 bg-gray-50 pl-5">
                <span className="text-xs font-medium rounded-md">Admin</span>
                <ItemBreadcrumb
                    template_uuid={item.template_uuid}
                    template_name={item.template.name}
                    endpoint_uuid={item.endpoint?.uuid}
                    endpoint_name={item.endpoint?.name}
                />
            </div> : null}
            <div className="flex flex-row h-[calc(100%-5rem)]">
                {/* Left Column */}
                <div style={show_split ? { width: `${left_width}%` } : {}} className={classNames("h-full overflow-auto", show_split ? "" : "w-full")}>
                    <div className="py-4 px-5">
                        <ItemTables
                            ref={item_tables_ref}
                            item={item}
                            range_values={range_values}
                            validation_summary={validation_summary}
                            onItemUpdate={onItemUpdate}
                            changeDecimalSeparator={changeDecimalSeparator}
                        />
                    </div>
                </div>
                {/* Separator */}
                {show_split && <div onMouseDown={startDragging} className="cursor-col-resize bg-gray-400 hover:bg-sea_blue-700 w-1 h-full" />}
                {/* Right Column */}
                {show_split && <div style={{ width: `${100 - left_width}%` }} className="h-full overflow-auto" >
                    <div className="p-5">
                        <ItemContent item={item} attachments={item.attachments} contexts={item.template.contexts} />
                    </div>
                </div>}
            </div>
        </div>
    </div >;
}