import { classNames, redirectToExternalPage } from "../lib/utils";

type ReadOnlyTextboxProps = {
    text: string;
    href?: string;
}

export function ReadOnlyTextbox(props: ReadOnlyTextboxProps) {
    const { text, href } = props;

    const onClick = () => {
        if (href) {
            redirectToExternalPage(href, true);
        }
    }

    return <div className="flex">
        <input
            type="text"
            value={text}
            readOnly
            className={classNames(
                "flex-grow px-2 py-1 rounded-md border-gray-300 outline-none text-sm h-9",
                href ? "cursor-pointer text-space_blue-600 underline" : ""
            )}
            onClick={onClick}
        />
    </div>;
}