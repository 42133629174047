import * as api from "./rest_client.js";
import * as t from "./auth.types.generated";

export interface IAuth {

    /** Get organizations given some filters */
    getOrganizations(req: t.IGetOrganizationsReq): Promise<t.IGetOrganizationsRes>;


    /** Get organization transaction history */
    getOrgTransactionHistory(req: t.IGetOrgTransactionHistoryReq): Promise<t.IGetOrgTransactionHistoryRes>;


    /** Get organization transaction history */
    getAdminOrgTransactionHistory(req: t.IGetAdminOrgTransactionHistoryReq): Promise<t.IGetAdminOrgTransactionHistoryRes>;


    /** Add credit to given org */
    addAdminOrgCredit(req: t.IAddAdminOrgCreditReq): Promise<t.IAddAdminOrgCreditRes>;


    /** Edit credit for given org */
    editAdminOrgCredit(req: t.IEditAdminOrgCreditReq): Promise<t.IEditAdminOrgCreditRes>;


    /** Delete transaction for given org */
    deleteAdminOrgCreditTransaction(req: t.IDeleteAdminOrgCreditTransactionReq): Promise<t.IDeleteAdminOrgCreditTransactionRes>;


    /** Expire credits for given org */
    expireAdminOrgCredits(req: t.IExpireAdminOrgCreditsReq): Promise<t.IExpireAdminOrgCreditsRes>;


    /** Get subscriptions given some filters */
    getSubscriptions(req: t.IGetSubscriptionsReq): Promise<t.IGetSubscriptionsRes>;


    /** Create a new manual subscription */
    createNewManualSubscription(req: t.ICreateNewManualSubscriptionReq): Promise<t.ICreateNewManualSubscriptionRes>;


    /** Get subscription details */
    getSubscriptionDetails(req: t.IGetSubscriptionDetailsReq): Promise<t.IGetSubscriptionDetailsRes>;


    /** Update existing subscription */
    updateSubscription(req: t.IUpdateSubscriptionReq): Promise<t.IUpdateSubscriptionRes>;


    /** Delete/cancel existing subscription */
    cancelSubscription(req: t.ICancelSubscriptionReq): Promise<t.ICancelSubscriptionRes>;


    /** Set organization enabled status */
    setOrgEnabled(req: t.ISetOrgEnabledReq): Promise<t.ISetOrgEnabledRes>;


    /** Set user enabled status */
    setUserEnabled(req: t.ISetUserEnabledReq): Promise<t.ISetUserEnabledRes>;

}


function enc(s: any): string { return encodeURIComponent("" + s); }

export class Auth implements IAuth {

    client: api.IApiClient;

    constructor(client: api.IApiClient) { this.client = client; }

    /** Get organizations given some filters */
    async getOrganizations(req: t.IGetOrganizationsReq): Promise<t.IGetOrganizationsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/org`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Get organization transaction history */
    async getOrgTransactionHistory(req: t.IGetOrgTransactionHistoryReq): Promise<t.IGetOrgTransactionHistoryRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/org_transaction_history/${enc(req.org_uuid)}`;
        delete reqx.org_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get organization transaction history */
    async getAdminOrgTransactionHistory(req: t.IGetAdminOrgTransactionHistoryReq): Promise<t.IGetAdminOrgTransactionHistoryRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/org_transaction_history/${enc(req.org_uuid)}`;
        delete reqx.org_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Add credit to given org */
    async addAdminOrgCredit(req: t.IAddAdminOrgCreditReq): Promise<t.IAddAdminOrgCreditRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/org_credit/add/${enc(req.org_uuid)}`;
        delete reqx.org_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Edit credit for given org */
    async editAdminOrgCredit(req: t.IEditAdminOrgCreditReq): Promise<t.IEditAdminOrgCreditRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/org_credit/edit/${enc(req.org_uuid)}/${enc(req.transaction_uuid)}`;
        delete reqx.org_uuid;
        delete reqx.transaction_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Delete transaction for given org */
    async deleteAdminOrgCreditTransaction(req: t.IDeleteAdminOrgCreditTransactionReq): Promise<t.IDeleteAdminOrgCreditTransactionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/org_credit/delete/${enc(req.org_uuid)}/${enc(req.transaction_uuid)}`;
        delete reqx.org_uuid;
        delete reqx.transaction_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Expire credits for given org */
    async expireAdminOrgCredits(req: t.IExpireAdminOrgCreditsReq): Promise<t.IExpireAdminOrgCreditsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/org_credit/expire/${enc(req.org_uuid)}`;
        delete reqx.org_uuid;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get subscriptions given some filters */
    async getSubscriptions(req: t.IGetSubscriptionsReq): Promise<t.IGetSubscriptionsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/subscription`;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Create a new manual subscription */
    async createNewManualSubscription(req: t.ICreateNewManualSubscriptionReq): Promise<t.ICreateNewManualSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/subscription`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Get subscription details */
    async getSubscriptionDetails(req: t.IGetSubscriptionDetailsReq): Promise<t.IGetSubscriptionDetailsRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/subscription/${enc(req.subscription_uuid)}`;
        delete reqx.subscription_uuid;
        const res = await this.client.get(url, { params: reqx });
        return res.data;
    }


    /** Update existing subscription */
    async updateSubscription(req: t.IUpdateSubscriptionReq): Promise<t.IUpdateSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/subscription/${enc(req.subscription_uuid)}`;
        delete reqx.subscription_uuid;
        const res = await this.client.put(url, reqx);
        return res.data;
    }


    /** Delete/cancel existing subscription */
    async cancelSubscription(req: t.ICancelSubscriptionReq): Promise<t.ICancelSubscriptionRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/subscription/${enc(req.subscription_uuid)}`;
        delete reqx.subscription_uuid;
        const res = await this.client.delete(url, { params: reqx });
        return res.data;
    }


    /** Set organization enabled status */
    async setOrgEnabled(req: t.ISetOrgEnabledReq): Promise<t.ISetOrgEnabledRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/set_org_enabled`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }


    /** Set user enabled status */
    async setUserEnabled(req: t.ISetUserEnabledReq): Promise<t.ISetUserEnabledRes> {
        const reqx: any = req;
        const url = `/api/v1.0/auth/admin/set_user_enabled`;
        const res = await this.client.post(url, reqx);
        return res.data;
    }

}
