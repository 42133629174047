import {
    Fragment,
    useEffect,
    useState
} from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    Dialog,
    Transition
} from "@headlessui/react";
import { saveAs } from "file-saver";

import {
    ArrowPathIcon,
    BookOpenIcon,
    CheckIcon,
    ClockIcon,
    Cog6ToothIcon,
    ExclamationTriangleIcon,
    XMarkIcon,
    BoltIcon,
    CommandLineIcon,
    EnvelopeIcon,
    GlobeAltIcon,
    ScaleIcon
} from "@heroicons/react/24/outline";
import { TbTablePlus } from "react-icons/tb";

import {
    IExtractJobEventSimple,
    IExtractJobList,
    IExtractJobSimple,
    IExtractJobWithEvents
} from "../lib/types";
import {
    EXTRACT_JOB_EVENT_TYPE,
    EXTRACT_JOB_STATUS,
    EXTRACT_JOB_TYPE,
    USER_ROLES
} from "../lib/consts";
import {
    ExtractJobEventType,
    ExtractJobStatus,
    ExtractJobType
} from "../lib/backend/extractions.types.generated";
import {
    downloadAndZipFiles,
    prettyDate,
    prettyDateTime,
    prettyTime
} from "../lib/utils";
import {
    Backend,
    BackendObj
} from "../lib/backend";
import { selectMemberships, selectUser } from "../lib/scraper.slice";
import { IOrganization } from "../lib/backend/auth.types.generated";

import { Pagination } from "./Pagination";
import { LoadingSpinnerLimit } from "./LoadingSpinner";
import { Button } from "./Button";
import { OrgPillSimple } from "./OrgPill";

type JobIconProps = {
    job_type: ExtractJobType;
    job_status?: ExtractJobStatus
    fallback: "text" | "icon";
};

export function JobIcon(props: JobIconProps) {
    const { job_type, job_status, fallback } = props;

    return job_status === "running" ? <ClockIcon className="h-5 w-5" aria-hidden="true" title={"running"} /> :
        job_type === EXTRACT_JOB_TYPE.email ? <EnvelopeIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
            job_type === EXTRACT_JOB_TYPE.api ? <CommandLineIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                job_type === EXTRACT_JOB_TYPE.web ? <GlobeAltIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                    job_type === EXTRACT_JOB_TYPE.eval ? <ScaleIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                        job_type === EXTRACT_JOB_TYPE.suggest ? <TbTablePlus className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                            job_type === EXTRACT_JOB_TYPE.lookup_table_update ? <CommandLineIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                                job_type === EXTRACT_JOB_TYPE.lookup_table_get ? <CommandLineIcon className="h-5 w-5" aria-hidden="true" title={job_type} /> :
                                    fallback === "text" ? <span>job_type</span> : <BoltIcon className="h-5 w-5" aria-hidden="true" title={job_type} />;
}

export function prettyJobEventType(type: ExtractJobEventType): string {
    if (type === EXTRACT_JOB_EVENT_TYPE.attachment) {
        return "Attachment";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.to_email) {
        return "Routing";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.email_process) {
        return "Email Extraction";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.document_process) {
        return "Document Extraction";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.verify) {
        return "Verification";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.eval_scrape) {
        return "Template Evaluation";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.suggest_ct) {
        return "Suggesting template";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.detect_sep) {
        return "Detecting decimal separator";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.detect_wizard_template) {
        return "Detecting wizard template";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.classify) {
        return "Classification";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.reply) {
        return "Reply";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.passthrough) {
        return "Passthrough";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.webhook) {
        return "Webhook";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.ip_check) {
        return "IP Check";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.api_key_check) {
        return "API Key Check";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.error) {
        return "Error";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.lookup_table_update) {
        return "Lookup Table Update";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.lookup_table_get) {
        return "Lookup Table Get";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.confirmation) {
        return "Confirmation";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.validation_log) {
        return "Validation Log";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.ai_match) {
        return "AI Lookup Table Matching";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.automatic_example) {
        return "Automatic Example";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.table_splt) {
        return "Table Splitting";
    } else if (type === EXTRACT_JOB_EVENT_TYPE.cancelled) {
        return "Cancelled";
    }

    return `${type}`;
}

export function isEventWebhook(event: IExtractJobEventSimple) {
    return event.type === EXTRACT_JOB_EVENT_TYPE.webhook && event.message.includes("/api/download/webhook_log")
}

type ExtractJobEventsProps = {
    user_uuid?: string;
    org_uuid?: string;
    job_uuid?: string;
    open: boolean;
    type: "user" | "org" | "admin";
    setOpen: (open: boolean) => void;
}

function ExtractJobEvents(props: ExtractJobEventsProps) {
    const { user_uuid, org_uuid, job_uuid, open, type, setOpen } = props;

    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const is_admin = user.role === "admin";
    const is_org_admin = memberships.some((membership) => membership.org.uuid === org_uuid && membership.role === "admin");

    const [is_loading, setIsLoading] = useState<boolean>(true);
    const [is_resending_uuid, setIsResendingUuid] = useState<string | undefined>(undefined);
    const [job, setJob] = useState<IExtractJobSimple | undefined>(undefined);
    const [events, setEvents] = useState<IExtractJobEventSimple[] | undefined>(undefined);
    const [ticker, setTicker] = useState<number>(0);
    const [last_refresh, setLastRefresh] = useState<number | undefined>(undefined);
    const [is_user_debug, setIsUserDebug] = useState<boolean>(false);

    const isEventWebhook = (event: IExtractJobEventSimple) => event.type === EXTRACT_JOB_EVENT_TYPE.webhook && event.message.includes("/api/download/webhook_log")
    useEffect(() => {
        if (!job_uuid) { return; }

        setIsLoading(true);
        const handleEvents = (res: IExtractJobWithEvents) => {
            const { job, events } = res;
            setJob(job);
            setEvents(events);
            setLastRefresh(Date.now());
            setIsLoading(false);

            if (job?.end_ts === undefined && ticker < 3600) {
                setTimeout(() => { setTicker(ticker + 1); }, 2000);
            }
        };

        if (type === "admin" && user_uuid) {
            Backend.getUserByUuid({ uuid: user_uuid })
                .then(({ user }) => {
                    setIsUserDebug(user?.debug ?? false);
                });
            Backend.getAdminExtractJob({ user_uuid, job_uuid }).then(handleEvents);
        } else if (type === "org" && org_uuid) {
            setIsUserDebug(false);
            Backend.getOrgExtractJob({ org_uuid, job_uuid }).then(handleEvents);
        } else {
            setIsUserDebug(false);
            Backend.getExtractJob({ job_uuid }).then(handleEvents);
        }
    }, [type, user_uuid, org_uuid, job_uuid, ticker]);

    const resendWebHook = (event_uuid: string) => {
        if (job_uuid) {
            setIsResendingUuid(event_uuid);
            BackendObj.extractions.resendWebhookData({ id: job_uuid, event_uuid })
                .then((res) => {
                    setIsResendingUuid(undefined);
                    setIsLoading(true);
                    setTicker(ticker + 1);
                });
        }
    }

    const downloadAttachments = async () => {
        // we have a list of attachment uuids, from which we can download attachments and zip them
        if (!job?.attachment_uuids) { return; }
        const attachment_urls = job.attachment_uuids.map((uuid) => `/api/admin/attachment/get?uuid=${uuid}`);
        const zip_filename = `attachments_${job.uuid}.zip`;
        const zip_uint8array = await downloadAndZipFiles(attachment_urls);
        saveAs(zip_uint8array, zip_filename);
    }

    const show_download_attachments = type === "admin" && is_user_debug && job?.attachment_uuids && job?.attachment_uuids.length > 0;

    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start">
                                            <div className="mr-3 flex h-7 items-center">
                                                {job && job.status === EXTRACT_JOB_STATUS.done && <CheckIcon className="h-5 w-5 text-mint-500" aria-hidden="true" />}
                                                {job && job.status === EXTRACT_JOB_STATUS.running && <ClockIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
                                                {job && job.status === EXTRACT_JOB_STATUS.error && <XMarkIcon className="h-5 w-5 text-torch_red-500" aria-hidden="true" />}
                                                {job && job.status === EXTRACT_JOB_STATUS.cancelled && <XMarkIcon className="h-5 w-5 text-torch_red-500" aria-hidden="true" />}
                                            </div>
                                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 flex-row">
                                                Job events
                                            </Dialog.Title>
                                            <div className="grow" />
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                        Time
                                                    </th>
                                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                        Event
                                                    </th>
                                                    <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">
                                                        Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {events?.map((event) => (
                                                    <tr key={event.uuid} className={event.status === "error" ? "text-torch_red-500" : "text-gray-500"}>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs align-top">{prettyTime(event.ts)}</td>
                                                        <td className="whitespace-nowrap px-3 py-2 text-xs align-top">{prettyJobEventType(event.type)}</td>
                                                        <td className="whitespace-normal px-3 py-2 text-xs align-top">
                                                            <span dangerouslySetInnerHTML={{ __html: event.message }}></span>
                                                            {isEventWebhook(event) && (is_admin || is_org_admin) && <div className="mt-2">
                                                                <Button
                                                                    loading={is_resending_uuid === event.uuid}
                                                                    disabled={is_resending_uuid === event.uuid}
                                                                    text={"Resend webhook data"}
                                                                    onClick={() => resendWebHook(event.uuid)}
                                                                />
                                                            </div>}
                                                        </td>
                                                    </tr>))}
                                            </tbody>
                                        </table>
                                        <div className="flex items-center pt-4 text-gray-400 text-xs gap-x-4">
                                            <span>Last update: {last_refresh ? prettyTime(last_refresh) : "/"}</span>
                                            {job?.credits_used !== undefined && <span>Credits used: {job.credits_used}</span>}
                                            <div className="grow" />
                                            {show_download_attachments && <Button text={"Download attachments"} onClick={downloadAttachments} />}
                                            <Button icon={is_loading ? undefined : ArrowPathIcon} loading={is_loading} disabled={is_loading} text={"Refresh"} onClick={() => setTicker(ticker + 1)} />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Dialog>
    </Transition.Root>;
};

function UserLink(props: { user?: IExtractJobUser }) {
    const { user } = props;
    return user ? <Link to={`/admin/user/${user.uuid}`} className="underline">{user.email}</Link> : <span>?</span>;
}

interface IExtractJobUser {
    uuid: string;
    email: string;
}

type ExtractJobsProps = {
    type: "user" | "admin_user" | "org" | "admin_org" | "endpoint" | "status";
    user_uuid?: string;
    org_uuid?: string;
    endpoint_uuid?: string;
    statuses?: ExtractJobStatus[];
    org_users?: IExtractJobUser[];
    limit?: number;
    initial_page?: number;
    onPageChange?: (page: number) => void;
}

export function ExtractJobs(props: ExtractJobsProps) {
    const user = useSelector(selectUser);
    const is_viewer_admin = user.role === USER_ROLES.admin;
    const navigate = useNavigate();

    const { type, user_uuid, org_uuid, endpoint_uuid, statuses, org_users } = props;

    const [jobs, setJobs] = useState<IExtractJobList | undefined>(undefined);
    const [offset, setOffset] = useState(props.initial_page ? props.initial_page * (props.limit || 10) : 0);
    const [is_events_open, setIsEventsOpen] = useState(false);
    const [selected_job_uuid, setSelectedJobUuid] = useState<string | undefined>(undefined);
    const [ticker, setTicker] = useState<number>(0);
    const [users, setUsers] = useState<IExtractJobUser[] | undefined>(org_users);
    const [organizations, setOrganizations] = useState<IOrganization[] | undefined>(undefined);
    const [events_type, setEventsType] = useState<"user" | "org" | "admin">("user");

    const location = useLocation();
    const query_params = new URLSearchParams(location.search);
    const highlighted_job_uuid = query_params.get("highlighted_job");

    const limit = props.limit || 10;

    const handleOffsetChange = (new_offset: number) => {
        setOffset(new_offset);
        if (props.onPageChange) {
            props.onPageChange(Math.floor(new_offset / (props.limit || 10)));
        }
    };

    useEffect(() => {
        if (type === "admin_user" && user_uuid) {
            Backend.getAdminExtractJobs({ user_uuid, offset, limit })
                .then((jobs) => { setJobs(jobs); });
            setEventsType("admin");
        } else if (type === "admin_org" && org_uuid) {
            Backend.getAdminExtractJobs({ org_uuid, offset, limit })
                .then((jobs) => { setJobs(jobs); });
            setEventsType("admin");
        } else if (type === "org" && org_uuid) {
            Backend.getOrgExtractJobs({ org_uuid, offset, limit })
                .then((jobs) => { setJobs(jobs); });
            setEventsType("org");
        } else if (type === "endpoint" && endpoint_uuid) {
            Backend.getExtractJobs({ offset, limit, endpoint_uuid })
                .then((jobs) => { setJobs(jobs); });
            setEventsType("org");
        } else if (type === "user") {
            Backend.getExtractJobs({ offset, limit })
                .then((jobs) => { setJobs(jobs); });
            setEventsType("user");
        } else if (type === "status" && statuses) {
            Backend.getAdminStatusExtractJobs({ statuses, offset, limit })
                .then((jobs) => {
                    setJobs(jobs);
                    const user_uuids = jobs.jobs.map((job) => job.user_uuid);
                    Backend.getUsersByUuid({ uuids: user_uuids })
                        .then((users) => { setUsers(users); });
                    BackendObj.auth.getOrganizations({})
                        .then(res => { setOrganizations(res.orgs); });
                });
            setEventsType("admin");
        }

        if (ticker < 3600) {
            setTimeout(() => { setTicker(ticker + 1); }, 4000);
        }
    }, [type, user_uuid, org_uuid, endpoint_uuid, statuses, limit, offset, ticker]);

    const refreshJobs = () => {
        setJobs(undefined);
        if (type === "admin_user" && user_uuid) {
            Backend.getAdminExtractJobs({ user_uuid, offset, limit })
                .then((jobs) => { setJobs(jobs); });
        } else if (type === "admin_org" && org_uuid) {
            Backend.getAdminExtractJobs({ org_uuid, offset, limit })
                .then((jobs) => { setJobs(jobs); });
        } else if (type === "org" && org_uuid) {
            Backend.getOrgExtractJobs({ org_uuid, offset, limit })
                .then((jobs) => { setJobs(jobs); });
        } else if (type === "endpoint" && endpoint_uuid) {
            Backend.getExtractJobs({ offset, limit, endpoint_uuid })
                .then((jobs) => { setJobs(jobs); });
        } else if (type === "user") {
            Backend.getExtractJobs({ offset, limit })
                .then((jobs) => { setJobs(jobs); });
        } else if (type === "status" && statuses) {
            Backend.getAdminStatusExtractJobs({ statuses, offset, limit })
                .then((jobs) => {
                    setJobs(jobs);
                    const user_uuids = jobs.jobs.map((job) => job.user_uuid);
                    Backend.getUsersByUuid({ uuids: user_uuids })
                        .then((users) => { setUsers(users); });
                });
        }
    };

    const selectJob = (job_uuid: string) => {
        setSelectedJobUuid(job_uuid);
        setIsEventsOpen(true);
    };

    const handlePromptLogClick = (job_uuid: string) => {
        // Create new search params with the highlighted job
        const newParams = new URLSearchParams(location.search);
        newParams.set("highlighted_job", job_uuid);
        // current page url search params are modified, no extra history entry made
        navigate({
            pathname: location.pathname,
            search: newParams.toString(),
        }, { replace: true });

        // Navigate to the prompt log page without modifying the query params for the current page
        navigate({
            pathname: `/admin/prompt-log/${job_uuid}`,
        });
    };

    if (jobs === undefined) {
        return <div className="w-full">
            <LoadingSpinnerLimit />
        </div>;
    }

    if (jobs.jobs.length === 0) {
        return <div className="w-full py-5">
            <p className="text-gray-500 text-center">No {statuses ? `"${statuses.join(", ")}"` : ""} jobs found</p>
        </div>;
    }

    // if we are on user admin page, we have user uuid
    // if we are on org admin page, we have get user from the job
    // else we are on user page, we do not need user uuid since its current user
    const selected_job_user_uuid = (type === "admin_user" && user_uuid) ? user_uuid :
        (type === "admin_org" && org_uuid) ? jobs.jobs.find((job) => job.uuid === selected_job_uuid)?.user_uuid :
            (type === "org" && org_uuid) ? jobs.jobs.find((job) => job.uuid === selected_job_uuid)?.user_uuid :
                (type === "status" && statuses) ? jobs.jobs.find((job) => job.uuid === selected_job_uuid)?.user_uuid :
                    undefined;

    const is_admin = type === "status";

    return <div className="w-full">
        <div className="md:hidden overflow-auto">
            <div className="flex items-center justify-end px-4 py-2 sm:px-6">
                <Button icon={ArrowPathIcon} text={"Refresh"} onClick={refreshJobs} />
            </div>
            <div className="flex flex-col border-t border-gray-200 divide-y divide-gray-200 bg-white">
                {jobs.jobs.map((job, idx) => (
                    <ul className="p-2" key={job.uuid}>
                        <li className="flex flex-row items-center gap-x-2 text-gray-500">
                            {idx + offset + 1}
                            {job && job.status === EXTRACT_JOB_STATUS.done && !job.error && <CheckIcon className="h-5 w-5 text-mint-500" aria-hidden="true" />}
                            {job && job.status === EXTRACT_JOB_STATUS.done && job.error && <ExclamationTriangleIcon className="h-5 w-5 text-candy_corn-500" aria-hidden="true" />}
                            {job && job.status === EXTRACT_JOB_STATUS.running && <ClockIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
                            {job && job.status === EXTRACT_JOB_STATUS.error && <XMarkIcon className="h-5 w-5 text-torch_red-500" aria-hidden="true" />}
                            {job && job.status === EXTRACT_JOB_STATUS.cancelled && <XMarkIcon className="h-5 w-5 text-torch_red-500" aria-hidden="true" />}
                            <JobIcon job_type={job.type} fallback={"text"} />
                            <div className="flex-grow" />
                            <span className="text-xs text-gray-400">
                                {prettyDate(job.start_ts)} {prettyTime(job.start_ts)} - {job.end_ts && prettyTime(job.end_ts)}
                            </span>
                        </li>
                        <li className="text-sm text-gray-500 py-2">{job.message}</li>
                        <li className="flex flex-row items-center gap-x-2">
                            {is_admin && users && <span className="text-sm text-gray-500"><UserLink user={users.find((user) => user.uuid === job.user_uuid)} /></span>}
                            {is_admin && !users && <span className="text-sm text-gray-500 justify-center"><i className="fas fa-spinner fa-spin" /></span>}
                            <div className="flex-grow" />
                            <span className="text-sm text-gray-500">
                                {is_viewer_admin && (
                                    <Button
                                        icon={Cog6ToothIcon}
                                        text={""}
                                        highlight={highlighted_job_uuid === job.uuid}
                                        onClick={() => handlePromptLogClick(job.uuid)}
                                    />
                                )}
                                <Button icon={BookOpenIcon} text={""} onClick={() => selectJob(job.uuid)} />
                            </span>
                        </li>
                    </ul>))}
                <Pagination offset={offset} setOffset={handleOffsetChange} limit={limit} total={jobs?.total || 0} />
            </div>
        </div>

        <div className="hidden md:flex flex-col overflow-auto">
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-5"></th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-5"></th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-5"></th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-52">Start</th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-52">End</th>
                        {is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User</th>}
                        {is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Org</th>}
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Message</th>
                        <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-36">
                            <Button icon={ArrowPathIcon} text={""} onClick={refreshJobs} />
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {jobs.jobs.map((job, idx) => (
                        <tr key={job.uuid}>
                            <td className="whitespace-nowrap py-2 pl-2 text-sm font-semibold text-gray-500 sm:pl-3">{idx + offset + 1}</td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                {job && job.status === EXTRACT_JOB_STATUS.done && !job.error && <CheckIcon className="h-5 w-5 text-mint-500" aria-hidden="true" />}
                                {job && job.status === EXTRACT_JOB_STATUS.done && job.error && <ExclamationTriangleIcon className="h-5 w-5 text-candy_corn-500" aria-hidden="true" />}
                                {job && job.status === EXTRACT_JOB_STATUS.running && <ClockIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
                                {job && job.status === EXTRACT_JOB_STATUS.error && <XMarkIcon className="h-5 w-5 text-torch_red-500" aria-hidden="true" />}
                                {job && job.status === EXTRACT_JOB_STATUS.cancelled && <XMarkIcon className="h-5 w-5 text-torch_red-500" aria-hidden="true" />}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                                <JobIcon job_type={job.type} fallback={"text"} />
                            </td>
                            <td className="px-3 py-2 text-sm text-gray-500">{prettyDateTime(job.start_ts)}</td>
                            <td className="px-3 py-2 text-sm text-gray-500">{job.end_ts && prettyTime(job.end_ts)}</td>
                            {is_admin && users && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500"><UserLink user={users.find((user) => user.uuid === job.user_uuid)} /></td>}
                            {is_admin && !users && <td className="w-20 px-3 py-2 text-sm text-gray-500 justify-center"><i className="fas fa-spinner fa-spin" /></td>}
                            {is_admin && <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500"><OrgPillSimple org={organizations?.find((org) => org.uuid === job.org_uuid)} /></td>}
                            <td className="whitespace-normal px-3 py-2 text-sm text-gray-500 ">{job.message}</td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-right">
                                {is_viewer_admin && (
                                    <Button
                                        icon={Cog6ToothIcon}
                                        text={""}
                                        highlight={highlighted_job_uuid === job.uuid}
                                        onClick={() => handlePromptLogClick(job.uuid)}
                                    />
                                )}
                                <Button icon={BookOpenIcon} text={""} onClick={() => selectJob(job.uuid)} />
                            </td>
                        </tr>))}
                </tbody>
            </table>
            <Pagination offset={offset} setOffset={handleOffsetChange} limit={limit} total={jobs?.total || 0} />
        </div>

        <ExtractJobEvents
            user_uuid={selected_job_user_uuid}
            org_uuid={org_uuid}
            job_uuid={selected_job_uuid}
            open={is_events_open}
            type={events_type}
            setOpen={setIsEventsOpen} />
    </div >;
}