import { ILookupMapFieldFilter } from "./backend/extractions.types.generated";
import { ILookupMapFilter } from "./backend/extractions.types.generated";
import { CONTEXT_TYPES } from "./consts";
import { IContextNoUUID } from "./types";

type IContextWithUUID = IContextNoUUID & { uuid: string };
// return true if first_context can be moved before rest_contexts
// if no, return error message, if yes, return undefined
export function canMoveContextBeforeRest(first_context: IContextWithUUID, rest_contexts: IContextWithUUID[]): string | undefined {
    // we translate the problem by confirming each rest context separately
    for (const rest_context of rest_contexts) {
        const message = canMoveContextBeforeSingle(first_context, rest_context);
        if (message) { return message; }
    }
    return undefined;
}

function canMoveContextBeforeSingle(first_context: IContextWithUUID, next_context: IContextWithUUID): string | undefined {
    const next_context_uuid = next_context.uuid;
    const next_fields_uuids = next_context.fields.map(field => field.uuid);

    // check context level
    if (first_context.type === CONTEXT_TYPES.lookup_table) {
        // we are moving lookup table before next context, check if any key in the lookup table mentions the next context
        const { lookup_table_filter } = first_context.extract_params;
        if (lookup_table_filter?.keys) {
            for (const key of lookup_table_filter.keys) {
                // check if field in the next context
                if (key.type === "field" && next_fields_uuids.includes(key.field_uuid)) {
                    return "Lookup table key in the step you are moving references the previous step.";
                }
            }
        }
    }

    // check override triggers
    if (first_context.overrides) {
        for (const override of first_context.overrides) {
            if (override.override_trigger.type === "context_field_equals") {
                // check if next context field uuid is referenced
                if (next_fields_uuids.includes(override.override_trigger.data.field_uuid)) {
                    return "Override trigger in the step you are moving references the previous step.";
                }
                if (next_context_uuid === override.override_trigger.data.context_uuid) {
                    return "Override trigger in the step you are moving references the previous step.";
                }
            }
        }
    }

    // check fields
    for (const field of first_context.fields) {
        // check overrides
        if (field.overrides) {
            for (const override of field.overrides) {
                if (override.override_trigger.type === "context_field_equals") {
                    // check if next context field uuid is referenced
                    if (next_fields_uuids.includes(override.override_trigger.data.field_uuid)) {
                        return "Override trigger in the step you are moving references the previous step.";
                    }
                    if (next_context_uuid === override.override_trigger.data.context_uuid) {
                        return "Override trigger in the step you are moving references the previous step.";
                    }
                }
            }
        }
        // check lookups
        if (field.type === "compute" && field.compute?.type === "fallback_sequence") {
            // check if next context field uuid is referenced
            // - final_fallback_field_uuid
            // - sequence[i].filters[j].field_uuid for fallback_lookup_map
            // - sequence[i].field_uuid for fallback_field
            const key_field_uuids: string[] = [];
            if (field.compute?.fallback_sequence?.final_fallback_field_uuid) {
                key_field_uuids.push(field.compute.fallback_sequence.final_fallback_field_uuid);
            }

            // A type guard function for field filters
            const isFieldFilter = (filter: ILookupMapFilter): filter is ILookupMapFieldFilter => filter.type === "field" && "field_uuid" in filter;
            for (const sequence_item of field.compute?.fallback_sequence?.sequence ?? []) {
                if (sequence_item.type === "fallback_lookup_map") {
                    key_field_uuids.push(...sequence_item.filters.filter(isFieldFilter).map(filter => filter.field_uuid));
                } else if (sequence_item.type === "fallback_field") {
                    key_field_uuids.push(sequence_item.field_uuid);
                }
            }
            if (key_field_uuids.some(key_field_uuid => next_fields_uuids.includes(key_field_uuid))) {
                return "Lookup map key field in the step you are moving references a field in the previous step.";
            }
        }
    }

    // all fine so far
    return undefined;
}