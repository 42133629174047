import { Fragment, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    BookOpenIcon,
    BuildingOfficeIcon,
    MinusIcon,
    PlusIcon
} from "@heroicons/react/24/outline";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild
} from "@headlessui/react";
import {
    BrowserIcon,
    FileCodeIcon,
    MarkGithubIcon,
    ShieldCheckIcon
} from "@primer/octicons-react";

import {
    selectEnv,
    selectIsSidebarLarge,
    selectSelfUrl,
    selectUser
} from "../lib/scraper.slice";
import {
    classNames,
    setDocumentTitle,
    prettyDate,
    prettyNumber,
    validateEmail,
    redirectToExternalPage
} from "../lib/utils";
import { IRegionInfo } from "../lib/types";
import * as stripe from "../lib/backend/stripe.types.generated";
import { Backend, BackendObj } from "../lib/backend";
import {
    org_roles,
    USER_ROLES
} from "../lib/consts";
import {
    IOrgUser,
    OrgRole
} from "../lib/backend/extractions.types.generated";
import { IOrganization } from "../lib/backend/auth.types.generated";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { TransactionHistory } from "../components/TransactionHistory";
import { Button } from "../components/Button";
import { ITab, Tabs } from "../components/Tabs";
import { ExtractJobs } from "../components/ExtractJobs";
import { OrgAuditLog } from "../components/OrgAuditLog";
import { SecurityDocs } from "../components/SecurityDocs";
import { Dropdown } from "../components/Dropdown";
import { Textbox } from "../components/Textbox";
import { CopyTextbox } from "../components/CopyTextbox";
import { ConfirmModal } from "../components/ConfirmModal";
import { WebApiKeyList } from "../components/WebApiKeyList";
import { ButtonMenu } from "../components/ButtonMenu";
import { wizard_new_rest_api_roles } from "./NewEndpoint";
import { TimeoutErrorMessageBar } from "../components/ErrorMessageBar";
import { ListSourceIPs } from "../components/ListSourceIPs";
type SingleSignOnProps = {
    org: IOrganization;
}

function SingleSignOn(props: SingleSignOnProps) {
    const user = useSelector(selectUser);

    const { org } = props;

    const [state, setState] = useState<"loading" | "none" | "view" | "create" | "edit">("loading");
    const [provider, setProvider] = useState<"microsoft">("microsoft");
    const [email_suffix, setEmailSuffix] = useState<string>("");
    const [tenant_id, setTenantId] = useState<string>("");
    const [client_id, setClientId] = useState<string>("");
    const [client_secret, setClientSecret] = useState<string>("");
    const [callback_url, setCallbackUrl] = useState<string>("");
    const [is_saving, setIsSaving] = useState<boolean>(false);
    const [is_deleting, setIsDeleting] = useState<boolean>(false);
    const [show_confirm, setShowConfirm] = useState<boolean>(false);
    const [message, setMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        Backend.getOrgSingleSignOn({ org_uuid: org.uuid }).then(({ sso, callback_url }) => {
            if (sso === undefined) {
                setState("none");
                return;
            }
            setState("view");
            setEmailSuffix(sso.email_suffix);
            setProvider(sso.provider);
            setTenantId(sso.tenant_id);
            setClientId(sso.client_id);
            setClientSecret(sso.client_secret);
            setCallbackUrl(callback_url);
        });
    }, [org.uuid]);

    useEffect(() => {
        if (state === "create") {
            Backend.getOrgSingleSignOnCallbackUrl({ org_uuid: org.uuid, provider }).then(setCallbackUrl);
        }
    }, [org, state, provider]);

    const initSso = async () => {
        setEmailSuffix(user.email.split("@")[1]);
        setState("create");
    }

    const upsertSso = async () => {
        setIsSaving(true);
        setMessage(undefined);
        try {
            await Backend.insertOrgSingleSignOn({
                org_uuid: org.uuid,
                email_suffix,
                provider,
                tenant_id,
                client_id,
                client_secret
            });
            const { callback_url } = await Backend.getOrgSingleSignOn({ org_uuid: org.uuid });
            setCallbackUrl(callback_url);
            setIsSaving(false);
            setState("view");
        } catch (e) {
            setIsSaving(false);
            setMessage("Failed to save Single Sign-on configuration. Check if the values are correct and try again.");
        }
    };

    const startEditing = () => {
        setClientId("");
        setClientSecret("");
        setState("edit");
    }

    const deleteSso = async (is_delete: boolean = true) => {
        if (is_delete === true) {
            setShowConfirm(false);
            setIsDeleting(true);
            await Backend.deleteOrgSingleSignOn({ org_uuid: org.uuid });
            setProvider("microsoft");
            setEmailSuffix("");
            setTenantId("");
            setClientId("");
            setClientSecret("");
            setIsDeleting(false);
            setState("none");
        } else {
            setShowConfirm(false);
            return;
        }
    }

    if (state === "loading") {
        return <LoadingSpinnerLimit />;
    }

    if (state === "none") {
        return <div className="flex flex-row items-center">
            <div className="mt-4 text-sm text-gray-600">
                No Single Sign-on configuration found for {org.name}.
            </div>
            <div className="flex-grow" />
            <div className="mt-4">
                <Button text="Configure SSO" highlight={true} onClick={initSso} />
            </div>
        </div >;
    }

    const can_edit = state === "edit" || state === "create";

    return <div className="flex flex-col gap-y-4">
        {message && <div className="col-span-full">
            <div className="text-sm text-torch_red-600">{message}</div>
        </div>}
        <div className="col-span-full">
            <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                Provider:
            </label>
            <div className="mt-2">
                <Dropdown values={["Microsoft Active Directory"]} ids={["microsoft"]} selected={provider}
                    disabled={is_saving || !can_edit} onChange={(id: string) => { setProvider(id as "microsoft"); }} />
            </div>
        </div>
        <div className="col-span-full">
            <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                Email domain (e.g. example.com):
            </label>
            <div className="mt-2">
                <Textbox value={email_suffix} disabled={is_saving || !can_edit} onChange={setEmailSuffix} />
            </div>
        </div>
        <div className="col-span-full">
            <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                Directory (tenant) ID:
            </label>
            <div className="mt-2">
                <Textbox value={tenant_id} disabled={is_saving || !can_edit} onChange={setTenantId} />
            </div>
        </div>
        <div className="col-span-full">
            <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                Application (client) ID:
            </label>
            <div className="mt-2">
                <Textbox value={client_id} disabled={is_saving || !can_edit} onChange={setClientId} />
            </div>
        </div>
        <div className="col-span-full">
            <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                Client Secret:
            </label>
            <div className="mt-2">
                <Textbox value={client_secret} disabled={is_saving || !can_edit} onChange={setClientSecret} />
            </div>
        </div>
        <div className="col-span-full">
            <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                Callback URL:
            </label>
            <div className="mt-2">
                <CopyTextbox text={callback_url} />
            </div>
        </div>
        <div className="mt-4">
            {state === "create" && <Button text="Create SSO" highlight={true} disabled={is_saving || is_deleting} loading={is_saving} onClick={upsertSso} />}
            {(state === "view" || state === "edit") && <Button text="Delete SSO" disabled={is_saving || is_deleting} loading={is_deleting} onClick={() => setShowConfirm(true)} />}
            {state === "view" && <Button text="Edit SSO" highlight={true} disabled={is_saving || is_deleting} onClick={startEditing} />}
            {state === "edit" && <Button text="Save SSO" highlight={true} disabled={is_saving || is_deleting} loading={is_saving} onClick={upsertSso} />}
        </div>
        <ConfirmModal open={show_confirm}
            title="Delete SSO"
            message={["Are you sure you want to delete the Single Sign-on configuration for this organization?"]}
            confirm="Delete"
            onClose={deleteSso} />

    </div>;
}

type InviteUserProps = {
    open: boolean;
    onClose: (result: boolean, email?: string) => void;
}

function InviteUser(props: InviteUserProps) {
    const { open, onClose } = props;

    const [email, setEmail] = useState<string>("");

    useEffect(() => {
        if (open === false) {
            setEmail("");
        }
    }, [open]);

    const is_valid = validateEmail(email);

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => onClose(false)}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Invite user
                                        </DialogTitle>
                                        <div className="mt-2 w-full">
                                            <Textbox placeholder="Email" value={email} onChange={setEmail} />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:flex justify-center">
                                    <Button highlight={true} text="Invite" disabled={!is_valid}
                                        onClick={() => onClose(true, email)} />
                                    <Button text="Cancel" onClick={() => onClose(false)} />
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export function Organization() {
    const { org_uuid } = useParams<{ org_uuid: string }>();
    const location = useLocation();
    const navigate = useNavigate();

    const query_params = new URLSearchParams(location.search);
    const initial_tab = query_params.get("tab") || "users";
    const initial_page = parseInt(query_params.get("page") || "0");

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const self_url = useSelector(selectSelfUrl);

    const [org, setOrg] = useState<IOrganization | undefined>(undefined);
    const [users, _setUsers] = useState<IOrgUser[] | undefined>(undefined);
    const [balance, setBalance] = useState<number | undefined>(undefined);
    const [region_info, setRegionInfo] = useState<IRegionInfo | undefined>(undefined);
    const [selected_tab, setSelectedTab] = useState<string>(initial_tab);
    const [open_invite_user, setOpenInviteUser] = useState<boolean>(false);
    const [is_inviting, setIsInviting] = useState<boolean>(false);
    const [invite_message, setInviteMessage] = useState<string | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [open_transaction_history, setOpenTransactionHistory] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<stripe.IStripeInvoice[] | undefined>(undefined);
    const [transaction_report_months, setTransactionReportMonths] = useState<{ month: number, year: number }[] | undefined>(undefined);
    const [selected_transaction_report_month, setSelectedTransactionReportMonth] = useState<{ month: number, year: number } | undefined>(undefined);

    const tabs: ITab[] = [];
    tabs.push({ name: "Users", key: "users" });
    tabs.push({ name: "Processed jobs", key: "extract_jobs" });
    tabs.push({ name: "REST API", key: "rest_api", hide: !wizard_new_rest_api_roles.includes(user.role) });
    tabs.push({ name: "Audit trail", key: "audit_trail" });
    if (org && org.tags.sso_enabled === true) { tabs.push({ name: "Single Sign-on", key: "sso" }); }
    tabs.push({ name: "Trust Center", key: "security_docs" });
    tabs.push({ name: "Invoices", key: "invoices" });

    useEffect(() => {
        if (!org_uuid) { return; }
        setOrg(undefined);
        Backend.getOrg({ org_uuid })
            .then(({ org, users, balance, region_info }) => {
                setOrg(org);
                // for non-admin orgs we only show non-admin users
                // this is so when we attach to customer org for support we are not listed among users
                // however the fact we are attached is visible in the audit log
                _setUsers(org.tags.admin_org === true ? users : users.filter(u => u.user_role !== USER_ROLES.admin));
                setBalance(balance);
                setRegionInfo(region_info);
                BackendObj.stripe.getStripeInvoices({ org_uuid }).then(({ invoices }) => {
                    setInvoices(invoices);
                });
            });
    }, [org_uuid]);

    useEffect(() => {
        if (org === undefined) {
            setDocumentTitle("Organization Settings", env);
        } else {
            setDocumentTitle(`${org.name} Settings`, env);
        }
    }, [org, env]);

    useEffect(() => {
        if (org === undefined) {
            setTransactionReportMonths(undefined);
            setSelectedTransactionReportMonth(undefined);
        } else {
            const init_date = new Date(org.created_at);
            const now_date = new Date();
            const months = [];
            for (let year = init_date.getFullYear(); year <= now_date.getFullYear(); year++) {
                for (let month = 0; month < 12; month++) {
                    // check if before init date
                    if (year < init_date.getFullYear() || (year === init_date.getFullYear() && month < init_date.getMonth())) {
                        continue;
                    }
                    // check if after now date
                    if (year > now_date.getFullYear() || (year === now_date.getFullYear() && month > now_date.getMonth())) {
                        continue;
                    }
                    months.push({ month: month + 1, year });
                }
            }
            setTransactionReportMonths(months);
            setSelectedTransactionReportMonth(months[months.length - 1]);
        }
    }, [org]);

    const setUsers = (users: IOrgUser[] | undefined) => {
        if (org === undefined) { return; }
        if (users === undefined) {
            _setUsers(undefined);
        } else {
            _setUsers(org.tags.admin_org === true ? users : users.filter(u => u.user_role !== USER_ROLES.admin));
        }
    }

    const inviteUser = async (is_invite: boolean, email?: string) => {
        setOpenInviteUser(false);
        if (is_invite === true && email !== undefined) {
            if (org === undefined) { return; }
            setIsInviting(true);
            try {
                const { status } = await Backend.inviteUser({ org_uuid: org.uuid, email });
                if (status === "added") {
                    setInviteMessage(`User ${email} has been added to the organization.`);
                } else if (status === "sso") {
                    setInviteMessage(`User ${email} has been added to the organization. They will need to sign in using Single Sign-on.`);
                } else if (status === "limit_reached") {
                    const max_users = org.tags.max_users;
                    setErrorMessage(`The max user limit for this organization has been reached. Please contact support to increase the limit. Current limit: ${max_users}.`);
                } else {
                    setErrorMessage(`No user with email ${email} found. Please ask them to sign up first and try again.`);
                }
                setUsers(undefined);
                const { users } = await Backend.getOrg({ org_uuid: org.uuid });
                setUsers(users);
            } catch (e) {
                setErrorMessage("Failed to invite user. Please try again.");
            }
            setIsInviting(false);
        }
    }

    const changeRole = async (user_uuid: string, role: OrgRole) => {
        if (org === undefined) { return; }
        setUsers(undefined);
        try {
            await Backend.addUserToOrg({ org_uuid: org.uuid, user_uuid, role: role as OrgRole });
        } catch (e) {
            setInviteMessage("Failed to change user role. Please try again.");
        }
        const { users } = await Backend.getOrg({ org_uuid: org.uuid });
        setUsers(users);
    }

    const removeUser = async (user_uuid: string) => {
        if (org === undefined) { return; }
        setUsers(undefined);
        try {
            await Backend.removeUserFromOrg({ org_uuid: org.uuid, user_uuid });
        } catch (e) {
            setInviteMessage("Failed to remove user. Please try again.");
        }
        const { users } = await Backend.getOrg({ org_uuid: org.uuid });
        setUsers(users);
    }

    const handleTabChange = (tab: string) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("tab", tab);
        navigate({ search: new_params.toString() });
        setSelectedTab(tab);
    };

    const handlePageChange = (page: number) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("page", page.toString());
        navigate({ search: new_params.toString() });
    };

    if (org === undefined || balance === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinnerLimit />
        </div>;
    }

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="flex flex-col p-10">
                <div className="flex items-center space-x-4">
                    <BuildingOfficeIcon className="h-12 w-12 text-space_blue-600" />
                    <div className="flex-1">
                        <h2 className="text-2xl font-semibold text-gray-800">{org.name}</h2>
                    </div>
                </div>

                <div className="w-full mt-6 max-w-5xl bg-gray-50 border rounded-lg p-4">
                    <div className="flex flex-row items-center space-x-4">
                        <h3 className="text-md font-semibold text-gray-800">Available credits:</h3>
                        <div className="text-md text-gray-800">{balance}</div>
                        <div className="flex-grow" />
                        <div className="w-28">
                            <Dropdown
                                values={transaction_report_months?.map(m => `${m.year} / ${m.month}`) ?? []}
                                selected={`${selected_transaction_report_month?.year} / ${selected_transaction_report_month?.month}`}
                                onChange={(month_year: string) => {
                                    const [year, month] = month_year.split(" / ");
                                    setSelectedTransactionReportMonth({ month: parseInt(month), year: parseInt(year) });
                                }} />
                        </div>
                        <ButtonMenu
                            title="Report"
                            items={[
                                {
                                    title: "PDF",
                                    onClick: () => {
                                        if (selected_transaction_report_month === undefined) { return; }
                                        const { month, year } = selected_transaction_report_month;
                                        redirectToExternalPage(`/api/org_transaction_history_report?org_uuid=${org.uuid}&month=${month}&year=${year}&type=pdf`, true);
                                    }
                                },
                                {
                                    title: "Excel",
                                    onClick: () => {
                                        if (selected_transaction_report_month === undefined) { return; }
                                        const { month, year } = selected_transaction_report_month;
                                        redirectToExternalPage(`/api/org_transaction_history_report?org_uuid=${org.uuid}&month=${month}&year=${year}&type=excel`, true);
                                    }
                                }
                            ]}
                        />
                        <Button onClick={() => setOpenTransactionHistory(true)} text="History" icon={BookOpenIcon} highlight={false} />
                    </div>
                </div>

                <div className="w-full max-w-5xl mt-10">
                    <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={handleTabChange} />
                    <div className="p-4">
                        {selected_tab === "users" && <div className="my-4">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                {users && <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                Name
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex flex-row items-center">
                                                <span>Role</span>
                                                <div className="flex-grow" />
                                                <Button disabled={is_inviting} loading={is_inviting} onClick={() => setOpenInviteUser(true)} icon={PlusIcon} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {users.map((person) => (
                                            <tr key={person.email}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    <div className="flex items-center">
                                                        <div className="h-11 w-11 flex-shrink-0">
                                                            <img className="h-11 w-11 rounded-full" src={person.picture_url} alt="" />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900">{person.first_name + " " + person.last_name}</div>
                                                            <div className="mt-1 text-gray-500">{person.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 flex flex-row items-center">
                                                    {person.uuid !== user.uuid && <Dropdown
                                                        values={org_roles} selected={person.org_role as string}
                                                        onChange={(role: string) => changeRole(person.uuid, role as OrgRole)} />}
                                                    {person.uuid === user.uuid && person.org_role}
                                                    <Button icon={MinusIcon} onClick={() => removeUser(person.uuid)} disabled={person.uuid === user.uuid} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}
                                {!users && <div className="p-5"><LoadingSpinnerLimit /></div>}
                            </div>
                            <InviteUser open={open_invite_user} onClose={inviteUser} />
                        </div>}
                        {selected_tab === "extract_jobs" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <ExtractJobs
                                type="org"
                                org_uuid={org.uuid}
                                initial_page={initial_page}
                                onPageChange={handlePageChange}
                            />
                        </div>}
                        {selected_tab === "rest_api" && <div>
                            <h3 className="px-4 pt-4 leading-7 text-lg font-medium">
                                Documentation
                            </h3>
                            <div className="my-4 flex flex-row gap-2 items-center">
                                <Button
                                    icon={FileCodeIcon}
                                    text="OpenAPI"
                                    href={`${self_url}/public/open-api.json`}
                                    open_in_new_tab={true} />
                                <Button
                                    icon={BrowserIcon}
                                    text="Swagger"
                                    href={`${self_url}/public/swagger`}
                                    open_in_new_tab={true} />
                                <ListSourceIPs />
                                <Button
                                    icon={MarkGithubIcon}
                                    href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                                    text="Node.JS example"
                                    open_in_new_tab={true} />
                            </div>
                            <h3 className="px-4 pt-4 leading-7 text-lg font-medium">
                                Organization API keys
                            </h3>
                            <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                <WebApiKeyList org_uuid={org.uuid} />
                            </div>
                        </div>}
                        {selected_tab === "audit_trail" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <OrgAuditLog org_uuid={org.uuid} is_admin={false} />
                        </div>}
                        {selected_tab === "sso" && <SingleSignOn org={org} />}
                        {selected_tab === "security_docs" && <SecurityDocs />}
                        {selected_tab === "invoices" && <div>
                            {(invoices === undefined || invoices.length === 0) && <div className="p-5">No data</div>}
                            {invoices !== undefined && invoices.length > 0 && <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Date
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Invoice Number
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {invoices.map((invoice) => (
                                        <tr key={invoice.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {prettyDate(invoice.created_at)}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                {prettyNumber(invoice.amount, 2, 2, user.number_format_locale)}
                                            </td>
                                            <td>
                                                {invoice.number}
                                            </td>
                                            <td>
                                                {invoice.url && <a href={invoice.url} target="_blank" rel="noreferrer">View</a>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>}
                        </div>}
                    </div>
                </div>

                {/* Data residency - should probably be pushed to the bottom of the page (TODO) */}
                <div className="w-full max-w-5xl flex items-center justify-center space-x-2 text-xs text-gray-400 border-t border-gray-100 pt-4 mt-auto">
                    <ShieldCheckIcon className="w-3.5 h-3.5" />
                    <span>Data residency: <span className="font-medium">{region_info?.logical_region || "N/A"}</span></span>
                </div>

            </div>
        </div>
        <TransactionHistory
            is_admin={false}
            org={org}
            open={open_transaction_history}
            setOpen={setOpenTransactionHistory} />
        <TimeoutErrorMessageBar
            color="green"
            message={invite_message}
            clearMessage={() => setInviteMessage(undefined)}
        />
        <TimeoutErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)}
        />

    </Fragment >;
};
