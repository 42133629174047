import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectMemberships, selectUser } from "../lib/scraper.slice";
import { ORG_ROLES, USER_ROLES } from "../lib/consts";
import { BackendObj } from "../lib/backend";

import { Button } from "./Button";
import { ButtonMenu } from "./ButtonMenu";


type ExtractButtonProps = {
    org_uuid: string;
    template_uuid?: string;
    endpoint_uuid?: string;
}

export function ExtractButton({ org_uuid, template_uuid, endpoint_uuid }: ExtractButtonProps) {
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const navigate = useNavigate();

    const [menu_items, setMenuItems] = useState<{ name: string, uuid: string, separator?: boolean }[]>([]);

    useEffect(() => {
        if (endpoint_uuid !== undefined) {
            setMenuItems([{ name: "Endpoint only", uuid: endpoint_uuid }]);
        } else if (template_uuid === undefined) {
            setMenuItems([]);
        } else {
            // check if user is admin or org admin
            const is_admin = (user?.role === USER_ROLES.admin);
            const is_org_admin = memberships.find((m) => m.org.uuid === org_uuid)?.role === ORG_ROLES.admin;
            BackendObj.extractions.getExtractEndpointsForTemplate({ template_uuid })
                .then(({ endpoints }) => {
                    if (endpoints.length === 0) {
                        // we only have template, use template uuid
                        setMenuItems([{ name: "Template only", uuid: template_uuid }]);
                    } else if (is_admin || is_org_admin) {
                        // we have endpoints and are admin, so we merge them
                        setMenuItems([
                            ...endpoints.map(({ uuid, name }) => ({ name, uuid })),
                            { name: "Template only", uuid: template_uuid, separator: endpoints.length > 0 }
                        ]);
                    } else {
                        // we have multiple endpoints, show menu
                        setMenuItems(endpoints.map(({ uuid, name }) => ({ name, uuid })));
                    }
                })
                .catch(err => {
                    setMenuItems([{ name: "Template only", uuid: template_uuid }]);
                    console.error(err);
                });
        }
    }, [user, memberships, org_uuid, template_uuid, endpoint_uuid]);

    return (menu_items.length === 0) ? <Button text="New Job" href={"/extraction/new/"} highlight={true} /> :
        (menu_items.length === 1) ? <Button text="New Job" href={`/extraction/new/${menu_items[0].uuid}`} highlight={true} /> :
            <ButtonMenu title="New Job" highlight={true}
                items={menu_items.map(({ name, uuid, separator }) =>
                    ({ title: name, onClick: () => navigate(`/extraction/new/${uuid}`), separator, admin_only: false }))
                } />;
}
