import {
    useEffect,
    useState
} from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";

import {
    IUserAuditDetails,
    IUserAuditList
} from "../lib/types";
import {
    USER_AUDIT_TYPE
} from "../lib/consts";
import {
    UserAuditType
} from "../lib/backend/extractions.types.generated";
import { Backend } from "../lib/backend";
import { prettyDateTime } from "../lib/utils";

import { Pagination } from "./Pagination";
import { Button } from "./Button";
import { LoadingSpinnerLimit } from "./LoadingSpinner";

function getCleanType(type: UserAuditType) {
    if (type === USER_AUDIT_TYPE.register) {
        return "Registered";
    }
    if (type === USER_AUDIT_TYPE.password_reset) {
        return "Password Reset";
    }
    if (type === USER_AUDIT_TYPE.password_change) {
        return "Password Change";
    }
    if (type === USER_AUDIT_TYPE.role_change) {
        return "Subscription Change";
    }
    if (type === USER_AUDIT_TYPE.org_add) {
        return "Added to Organization or Role Change";
    }
    if (type === USER_AUDIT_TYPE.org_remove) {
        return "Removed from Organization";
    }
    if (type === USER_AUDIT_TYPE.context_download) {
        return "Template Definition Download";
    }
    if (type === USER_AUDIT_TYPE.item_download) {
        return "Extraction Download";
    }
    if (type === USER_AUDIT_TYPE.api_key_create) {
        return "API Key Create";
    }
    if (type === USER_AUDIT_TYPE.api_key_delete) {
        return "API Key Delete";
    }
    if (type === USER_AUDIT_TYPE.api_key_view) {
        return "API Key View";
    }
    if (type === USER_AUDIT_TYPE.debug_mode) {
        return "Debug Mode";
    }
    if (type === USER_AUDIT_TYPE.enabled_change) {
        return "Enabled Change";
    }
    return `${type}`;
}

function getCleanDetails(type: UserAuditType, details: IUserAuditDetails) {
    if (type === USER_AUDIT_TYPE.role_change) {
        return `New subscription type: ${details.user_role || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.org_add) {
        return `Organization: ${details.org_name || "Unknown"}, Role: ${details.org_role || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.org_remove) {
        return `Organization: ${details.org_name || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.context_download) {
        return `Organization: ${details.org_name || "Unknown"}: Template: ${details.context_name || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.item_download) {
        return `Organization: ${details.org_name || "Unknown"}: Extraction: ${details.item_name || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.api_key_create) {
        return `Organization: ${details.org_name || "Unknown"}, Endpoint: ${details.endpoint_name || "Unknown"}, API key: ${details.api_key_name || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.api_key_delete) {
        return `Organization: ${details.org_name || "Unknown"}, Endpoint: ${details.endpoint_name || "Unknown"}, API key: ${details.api_key_name || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.api_key_view) {
        return `Organization: ${details.org_name || "Unknown"}, Endpoint: ${details.endpoint_name || "Unknown"}, API key: ${details.api_key_name || "Unknown"}`;
    }
    if (type === USER_AUDIT_TYPE.debug_mode) {
        return `Debug mode: ${details.debug_mode === undefined ? "Unknown" : details.debug_mode === "true" ? "On" : "Off"}`;
    }
    if (type === USER_AUDIT_TYPE.enabled_change) {
        return `Enabled: ${details.is_enabled === undefined ? "Unknown" : details.is_enabled ? "Yes" : "No"}`;
    }
    return "/";
}

type UserAuditLogProps = {
    user_uuid: string;
    is_admin: boolean;
}

export function UserAuditLog(props: UserAuditLogProps) {
    const { user_uuid, is_admin } = props;

    const [user_audit_list, setUserAuditList] = useState<IUserAuditList | undefined>(undefined);
    const [offset, setOffset] = useState(0);
    const LIMIT = 10;

    useEffect(() => {
        if (is_admin) {
            Backend.getAdminUserAuditLog({ user_uuid, offset, limit: LIMIT })
                .then((user_audit_list) => { setUserAuditList(user_audit_list); });
        } else {
            Backend.getUserAuditLog({ offset, limit: LIMIT })
                .then((user_audit_list) => { setUserAuditList(user_audit_list); });
        }
    }, [user_uuid, is_admin, offset]);

    const refresh = () => {
        if (is_admin) {
            Backend.getAdminUserAuditLog({ user_uuid, offset, limit: LIMIT })
                .then((user_audit_list) => { setUserAuditList(user_audit_list); });
        } else {
            Backend.getUserAuditLog({ offset, limit: LIMIT })
                .then((user_audit_list) => { setUserAuditList(user_audit_list); });
        }
    };

    return (
        user_audit_list && user_audit_list.total > 0 ?
            <div className="min-w-full">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Details</th>
                            <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 w-36">
                                <Button icon={ArrowPathIcon} text={""} onClick={refresh} />
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {user_audit_list && user_audit_list.audits.map((audit, idx) => (
                            <tr key={idx + offset}>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 align-top">{prettyDateTime(audit.created_at)}</td>
                                <td className="px-3 py-2 text-sm text-gray-500 align-top">{getCleanType(audit.type)}</td>
                                <td className="px-3 py-2 text-sm text-gray-500 align-top" colSpan={2}>{getCleanDetails(audit.type, audit.details)}</td>
                            </tr>))}
                    </tbody>
                </table>
                <Pagination offset={offset} limit={LIMIT} total={user_audit_list?.total || 0} setOffset={setOffset} />
            </div> :
            user_audit_list ?
                <div className="w-full text-center text-sm">
                    /
                </div> :
                <div className="w-full text-center text-sm">
                    <LoadingSpinnerLimit />
                </div>

    );
}
