import { useState, KeyboardEvent } from "react";
import { classNames } from "../lib/utils";

type ValidKey =
    | "Enter"
    | "Escape"
    | "Tab"
    | "Backspace"
    | "Delete"
    | "ArrowUp"
    | "ArrowDown"
    | "ArrowLeft"
    | "ArrowRight"
    | "Home"
    | "End"
    | "PageUp"
    | "PageDown"
    | "Space";

type KeyHandlers = {
    [K in ValidKey]?: () => void;
}

type TextboxProps = {
    placeholder?: string;
    disabled?: boolean;
    value: string;
    id?: string;
    onChange?: (value: string) => void;
    keyHandlers?: KeyHandlers;
}

function handleKeyDown(e: KeyboardEvent<HTMLInputElement>, keyHandlers?: KeyHandlers) {
    if (keyHandlers && e.key in keyHandlers) {
        keyHandlers[e.key as ValidKey]?.();
    }
}

export function Textbox(props: TextboxProps) {
    const { placeholder, disabled, value, id, onChange, keyHandlers } = props;

    return <div className={classNames(
        "flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-space_blue-600",
        disabled ? "bg-gray-100 cursor-not-allowed text-gray-500" : "bg-white text-gray-900")}
    >
        <input
            id={id}
            type="text"
            className={classNames(
                "block flex-1 min-w-0 border-0 bg-transparent p-2  placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6",
                disabled ? "cursor-not-allowed text-gray-500" : "text-gray-900")}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={e => onChange && onChange(e.target.value)}
            onKeyDown={e => handleKeyDown(e, keyHandlers)}
        />
    </div>;
}

export function InvisibleTextbox(props: TextboxProps) {
    const { placeholder, value, disabled, id, onChange, keyHandlers } = props;

    return <div className="bg-white flex w-full focus-within:ring-2 focus-within:ring-inset focus-within:ring-space_blue-600">
        <input
            id={id}
            type="text"
            className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={e => onChange && onChange(e.target.value)}
            onKeyDown={e => handleKeyDown(e, keyHandlers)}
        />
    </div>;
}

////////////////////////////////////////////////////////////
// DateTextbox only returns a valid date back when entered date is valid

type DateTextboxProps = {
    date: Date;
    onChange: (date: Date) => void;
    reportIsValid: (isValid: boolean) => void;
}

// return date as local timezone YYYY-MM-DD
function getDateString(date: Date) {
    return date.toLocaleDateString();
}

export function DateTextbox(props: DateTextboxProps) {
    const { date, onChange, reportIsValid } = props;

    const [value, setValue] = useState<string | undefined>(getDateString(date));
    const [is_valid, setIsValid] = useState<boolean>(true);

    const handleChange = (value: string) => {
        setValue(value);
        const date = new Date(value);
        if (isNaN(date.getTime())) {
            setIsValid(false);
            reportIsValid(false);
        } else {
            setIsValid(true);
            reportIsValid(true);
            onChange(date);
        }
    }

    return <div className="flex flex-col gap-x-2">
        <Textbox
            value={value || ""}
            onChange={handleChange}
        />
        {!is_valid && <div className="text-torch_red-500 text-xs">Invalid date</div>}
    </div>;
}