import { useSelector } from "react-redux";
import * as Recharts from "recharts"

import {
    PinIcon,
    PinSlashIcon
} from "@primer/octicons-react";

import {
    IBarDateChartData,
    IBarTagChartData,
    IChart,
    ILineDateChartData,
    INumberChartData,
    IWidget,
    IWidgetDateDistributionChartValuesType,
} from "../lib/backend/extractions.types.generated";
import {
    niceRound,
    prettyNumber
} from "../lib/utils";
import { selectUser } from "../lib/scraper.slice";
import { Fragment } from "react";

const FONT_COLOR = "#4b5563";
const BAR_COLOR = "#001565";
const VERTICAL_BAR_COLOR = "#CCD8FF";
const BAR_COLORS = ["#001565", "#004AF5", "#74A3FF", "#7584BD", "#CCD8FF", "#00E3B0", "#3FB9AF"];

const VALIDATION_KEYS = ["pass", "fail"];
const VALIDATION_COLORS = ["#001565", "#FF1534"];
const YES_NO_KEYS = ["yes", "no"];
const YES_NO_COLORS = ["#001565", "#FF1534"];

function fixLegendValue(value: string) {
    // capitalize first letter and remove underscores and lowercase the rest
    const no_underscores = value.replace(/_/g, " ");
    return no_underscores.charAt(0).toUpperCase() + no_underscores.slice(1).toLowerCase();
}

type StackedBarChartProps = {
    data: IBarDateChartData[];
    aspect: number;
    values_type: IWidgetDateDistributionChartValuesType;
}

function StackedBarChart(props: StackedBarChartProps) {
    const { data, aspect, values_type } = props;

    const values = values_type === "validation" ? VALIDATION_KEYS :
        values_type === "yes_no" ? YES_NO_KEYS :
            Object.keys(data[0]).filter(key => key !== "date");
    const colors = values_type === "validation" ? VALIDATION_COLORS :
        values_type === "yes_no" ? YES_NO_COLORS :
            BAR_COLORS;

    return (
        <div className="w-full h-full text-sm">
            <Recharts.ResponsiveContainer width="100%" aspect={aspect}>
                <Recharts.BarChart data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke={FONT_COLOR} />
                    <Recharts.YAxis fontSize={12} width={40} stroke={FONT_COLOR} />
                    <Recharts.Tooltip contentStyle={{ color: FONT_COLOR, fontSize: "12px" }} />
                    <Recharts.Legend formatter={(value) => <span style={{ color: FONT_COLOR, fontSize: "12px" }}>{fixLegendValue(value)}</span>} />
                    {values.map((key, idx) => (<Recharts.Bar key={idx} dataKey={key} fill={colors[idx % colors.length]} stackId="a" />))}
                </Recharts.BarChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type BarChartProps = {
    data: IBarDateChartData[];
    aspect: number;
}

function BarChart(props: BarChartProps) {
    const { data, aspect } = props;
    const tags = Object.keys(data[0]).filter(key => key !== "date");

    return (
        <div className="w-full h-full text-sm">
            <Recharts.ResponsiveContainer width="100%" aspect={aspect}>
                <Recharts.ComposedChart data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke={FONT_COLOR} />
                    <Recharts.YAxis fontSize={12} width={40} stroke={FONT_COLOR} />
                    <Recharts.Tooltip contentStyle={{ color: FONT_COLOR, fontSize: "12px" }} />
                    <Recharts.Legend formatter={(value) => <span style={{ color: FONT_COLOR, fontSize: "12px" }}>{fixLegendValue(value)}</span>} />
                    {tags.map((tag, index) => (
                        <Recharts.Bar
                            key={tag}
                            dataKey={tag}
                            name={tag}
                            fill={BAR_COLORS[index % BAR_COLORS.length]}
                        />
                    ))}
                </Recharts.ComposedChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type BarTagChartProps = {
    data: IBarTagChartData[];
    aspect: number;
}

function BarTagChart(props: BarTagChartProps) {
    const { data, aspect } = props;

    return (
        <div className="w-full h-full text-sm">
            <Recharts.ResponsiveContainer width="100%" aspect={aspect}>
                <Recharts.BarChart data={data} margin={{ top: 10, right: 20, left: 40, bottom: 0 }} layout="vertical">
                    <Recharts.YAxis dataKey="tag" type="category" fontSize={12} width={120} stroke={FONT_COLOR} />
                    <Recharts.XAxis type="number" fontSize={12} stroke={FONT_COLOR} />
                    <Recharts.Tooltip contentStyle={{ color: FONT_COLOR, fontSize: "12px" }} />
                    <Recharts.Legend formatter={(value) => <span style={{ color: FONT_COLOR, fontSize: "12px" }}>{fixLegendValue(value)}</span>} />
                    <Recharts.Bar dataKey="count" fill={VERTICAL_BAR_COLOR} label={{ position: "right", fontSize: 12, fill: FONT_COLOR }} />
                </Recharts.BarChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type SingleBarChartProps = {
    data: IBarDateChartData[];
    aspect: number;
}

function SingleBarChart(props: SingleBarChartProps) {
    const { data, aspect } = props;

    return (
        <div className="w-full h-full text-sm">
            <Recharts.ResponsiveContainer width="100%" aspect={aspect}>
                <Recharts.ComposedChart data={data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke={FONT_COLOR} />
                    <Recharts.YAxis fontSize={12} width={40} stroke={FONT_COLOR} />
                    <Recharts.Tooltip contentStyle={{ color: FONT_COLOR, fontSize: "12px" }} />
                    <Recharts.Legend formatter={(value) => <span style={{ color: FONT_COLOR, fontSize: "12px" }}>{fixLegendValue(value)}</span>} />
                    <Recharts.Bar dataKey="count" fill={BAR_COLOR} />
                </Recharts.ComposedChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type LinesProps = {
    data: ILineDateChartData[];
    aspect: number;
}

function Lines(props: LinesProps) {
    const { data, aspect } = props;

    // user niceRound to round the values
    const rounded_data = data.map(row => ({
        ...row,
        ...Object.fromEntries(Object.entries(row).map(([key, value]) => [key, key === "date" ? value : niceRound(Number(value))])),
    }));

    return (
        <div className="w-full h-full text-sm">
            <Recharts.ResponsiveContainer width="100%" aspect={aspect}>
                <Recharts.LineChart data={rounded_data} margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
                    <Recharts.XAxis dataKey="date" fontSize={12} stroke={FONT_COLOR} />
                    <Recharts.YAxis fontSize={12} width={40} stroke={FONT_COLOR} />
                    <Recharts.Tooltip contentStyle={{ color: FONT_COLOR, fontSize: "12px" }} />
                    <Recharts.Legend formatter={(value) => <span style={{ color: FONT_COLOR, fontSize: "12px" }}>{fixLegendValue(value)}</span>} />
                    {Object.keys(data[0]).filter(key => key !== "date").map((key, index) => (
                        <Recharts.Line key={key} dataKey={key} stroke={BAR_COLORS[index % BAR_COLORS.length]} />
                    ))}
                </Recharts.LineChart>
            </Recharts.ResponsiveContainer>
        </div>
    );
}

type ChartProps = {
    widget: IWidget;
    chart: IChart;
    is_wide?: boolean;
}

export function Chart({ widget, chart, is_wide = false }: ChartProps) {
    const aspect = is_wide ? 4 : 2;

    if (chart.chart_type === "stacked_bar" && chart.data_type === "bar_date") {
        const values_type = (widget.type === "date_distribution_chart") ? widget.details.type : "all_values";
        return <StackedBarChart data={chart.data} aspect={aspect} values_type={values_type} />;
    }
    if (chart.chart_type === "bar" && chart.data_type === "bar_date") {
        return <BarChart data={chart.data} aspect={aspect} />;
    }
    if (chart.chart_type === "single_bar" && chart.data_type === "bar_date") {
        return <SingleBarChart data={chart.data} aspect={aspect} />;
    }
    if (chart.chart_type === "bar_tag" && chart.data_type === "bar_tag") {
        return <BarTagChart data={chart.data} aspect={aspect} />;
    }
    if (chart.chart_type === "lines" && chart.data_type === "line_date") {
        return <Lines data={chart.data} aspect={aspect} />;
    }
    return null;
}

type NumbersProps = {
    data: INumberChartData;
    is_pinned?: boolean;
    is_editable?: boolean;
    onTogglePin?: () => void;
}

function Numbers(props: NumbersProps) {
    const { data, is_pinned, is_editable, onTogglePin } = props;

    const user = useSelector(selectUser);

    const metrics: { name: string, stat: string }[] = [];
    for (const [key, value] of Object.entries(data)) {
        const rounded_value = niceRound(value);
        metrics.push({ name: key, stat: prettyNumber(rounded_value, 2, undefined, user.number_format_locale) });
    }

    return <Fragment>
        {metrics.map((metric) => (
            <div key={metric.name} className="bg-white p-4 rounded-lg shadow border border-gray-200">
                <div className="flex flex-col relative">
                    <span className="text-xs font-medium uppercase text-gray-500 truncate" title={metric.name}>{metric.name}</span>
                    <span className="text-2xl font-bold text-gray-900 mt-1">{metric.stat}</span>
                    {is_pinned !== undefined && is_editable !== undefined && onTogglePin !== undefined && <div
                        className="absolute bottom-0 right-0" onClick={onTogglePin}
                        title={is_editable ? "Pin to dashboard" : "Unpin from dashboard"}
                    >
                        {is_editable && is_pinned && <PinIcon className="h-3 w-3 text-gray-400 hover:text-gray-600 cursor-pointer" />}
                        {!is_editable && is_pinned && <PinSlashIcon className="h-3 w-3 text-gray-400 hover:text-gray-600 cursor-not-allowed" />}
                        {!is_pinned && <PinSlashIcon className="h-3 w-3 text-gray-400 hover:text-gray-600 cursor-pointer" />}
                    </div>}
                </div>
            </div>
        ))}
    </Fragment>;
}

type IndicatorProps = {
    chart: IChart;
    is_pinned?: boolean;
    is_editable?: boolean;
    onTogglePin?: () => void;
}

export function Indicator(props: IndicatorProps) {
    const { chart, is_pinned, is_editable, onTogglePin } = props;
    if (chart.chart_type === "numbers" && chart.data_type === "numbers") {
        return <Numbers data={chart.data} is_pinned={is_pinned} is_editable={is_editable} onTogglePin={onTogglePin} />;
    }
    return null;
}