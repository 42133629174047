import { useState } from "react";
import { useSelector } from "react-redux";
import CodeMirror from "@uiw/react-codemirror";
import { json, jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";

import * as hi from "@heroicons/react/24/outline";
import { classNames } from "../lib/utils";
import { BackendObj } from "../lib/backend";
import { selectIsSidebarLarge } from "../lib/scraper.slice";
import { Button } from "../components/Button";
import { CompactTabs } from "../components/Tabs";
import { Textbox } from "../components/Textbox";
import { IContextBase, ITemplateAdmin } from "../lib/backend/extractions.types.generated";
import { Toggle } from "../components/Toggle";

export function AdminDB() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [active_tab, setActiveTab] = useState<string>("endpoints");
    const [endpoint_uuid, setEndpointUuid] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [success_message, setSuccessMessage] = useState<string | undefined>(undefined);
    const [data_loaded, setDataLoaded] = useState<boolean>(false);

    // Endpoint data
    const [endpoint_details, setEndpointDetails] = useState<string>("");
    const [endpoint_custom_output, setEndpointCustomOutput] = useState<string>("");
    const [endpoint_triggers, setEndpointTriggers] = useState<string>("");

    // Endpoint metadata
    const [endpoint_metadata, setEndpointMetadata] = useState<{
        uuid: string;
        user_uuid: string;
        name: string;
        created_at: string;
        is_deleted: boolean;
        org_uuid: string;
        ip_whitelist: string[];
        type: string;
        output_name: any[];
        template_uuid: string;
        dw_last_update_ts: string;
        alias: string;
    } | null>(null);

    // JSON validation states
    const [is_valid_details, setIsValidDetails] = useState<boolean>(true);
    const [is_valid_custom_output, setIsValidCustomOutput] = useState<boolean>(true);
    const [is_valid_triggers, setIsValidTriggers] = useState<boolean>(true);

    // Template-related state
    const [template_uuid, setTemplateUuid] = useState<string>("");
    const [template_data, setTemplateData] = useState<ITemplateAdmin | null>(null);
    const [selected_context_uuid, setSelectedContextUuid] = useState<string>("");
    const [selected_context, setSelectedContext] = useState<IContextBase & { is_deleted: boolean } | null>(null);
    const [template_loading, setTemplateLoading] = useState<boolean>(false);

    const [endpoint_is_deleted, setEndpointIsDeleted] = useState<boolean>(false);
    const [template_is_deleted, setTemplateIsDeleted] = useState<boolean>(false);

    const tabs = [
        { key: "endpoints", name: "Endpoints" },
        { key: "templates", name: "Templates" }
    ];

    const loadEndpoint = async () => {
        if (!endpoint_uuid) {
            setErrorMessage("Please enter an endpoint UUID");
            return;
        }

        setLoading(true);
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setDataLoaded(false);
        setEndpointMetadata(null);

        try {
            const { endpoint } = await BackendObj.extractions.getEndpointAdmin({ endpoint_uuid });

            if (endpoint === undefined) {
                setErrorMessage("Endpoint not found");
                setLoading(false);
                return;
            }

            setEndpointDetails(JSON.stringify(endpoint.details, null, 2));
            setEndpointCustomOutput(JSON.stringify(endpoint.custom_output, null, 2));
            setEndpointTriggers(JSON.stringify(endpoint.endpoint_triggers, null, 2));

            // Set endpoint metadata
            setEndpointMetadata({
                uuid: endpoint.uuid,
                user_uuid: endpoint.user_uuid,
                name: endpoint.name,
                created_at: endpoint.created_at ? endpoint.created_at.toString() : "",
                is_deleted: endpoint.is_deleted,
                org_uuid: endpoint.org_uuid,
                ip_whitelist: endpoint.ip_whitelist || [],
                type: endpoint.type,
                output_name: endpoint.output_name || [],
                template_uuid: endpoint.template?.uuid || "",
                dw_last_update_ts: (endpoint as any).dw_last_update_ts ? (endpoint as any).dw_last_update_ts.toString() : "",
                alias: endpoint.alias || ""
            });

            // In the loadEndpoint function, after setting endpoint metadata
            setEndpointIsDeleted(endpoint.is_deleted);

            setIsValidDetails(true);
            setIsValidCustomOutput(true);
            setIsValidTriggers(true);
            setDataLoaded(true);

            setSuccessMessage("Endpoint loaded successfully");
        } catch (error) {
            console.error("Error loading endpoint:", error);
            setErrorMessage("Error loading endpoint: " + (error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const saveEndpoint = async () => {
        if (!endpoint_uuid) {
            setErrorMessage("Please enter an endpoint UUID");
            return;
        }

        if (!is_valid_details || !is_valid_custom_output || !is_valid_triggers) {
            setErrorMessage("Please fix the invalid JSON before saving");
            return;
        }

        setSaving(true);
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        try {
            // First get the current endpoint to preserve other fields
            const { endpoint } = await BackendObj.extractions.getEndpoint({ endpoint_uuid });

            if (endpoint === undefined) {
                setErrorMessage("Endpoint not found");
                setSaving(false);
                return;
            }

            // Parse the JSON strings
            const details = JSON.parse(endpoint_details);
            const custom_output = JSON.parse(endpoint_custom_output);
            const endpoint_triggers_parsed = JSON.parse(endpoint_triggers);

            // Update the endpoint
            await BackendObj.extractions.updateEndpoint({
                uuid: endpoint_uuid,
                type: endpoint.type,
                name: endpoint.name,
                alias: endpoint.alias,
                org_uuid: endpoint.org_uuid,
                user_uuid: endpoint.user_uuid,
                description: endpoint.description,
                ip_whitelist: endpoint.ip_whitelist,
                details: details,
                custom_output: custom_output,
                endpoint_triggers: endpoint_triggers_parsed,
                output_name: endpoint.output_name,
                template_uuid: endpoint.template.uuid
            });

            setSuccessMessage("Endpoint updated successfully");
        } catch (error) {
            console.error("Error saving endpoint:", error);
            setErrorMessage("Error saving endpoint: " + (error as Error).message);
        } finally {
            setSaving(false);
        }
    };

    const loadTemplate = async () => {
        if (!template_uuid) {
            setErrorMessage("Please enter a template UUID");
            return;
        }

        setTemplateLoading(true);
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setTemplateData(null);
        setSelectedContext(null);
        setSelectedContextUuid("");

        try {
            const { template } = await BackendObj.extractions.getTemplateAdmin({ template_uuid });
            if (template === undefined) {
                setErrorMessage("Template not found");
                setTemplateLoading(false);
                return;
            }

            setTemplateData(template);
            setTemplateIsDeleted(template.is_deleted);
            setSuccessMessage("Template loaded successfully");
        } catch (error) {
            console.error("Error loading template:", error);
            setErrorMessage("Error loading template: " + (error as Error).message);
        } finally {
            setTemplateLoading(false);
        }
    };

    const handleContextSelection = (context_uuid: string) => {
        setSelectedContextUuid(context_uuid);
        if (template_data) {
            const context = template_data.contexts.find(c => c.uuid === context_uuid);
            setSelectedContext(context || null);
        }
    };

    const handleIsDeletedToggle = async () => {
        if (!endpoint_uuid) return;

        setSaving(true);
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        try {
            if (!endpoint_is_deleted) {
                // If currently not deleted, delete the endpoint
                await BackendObj.extractions.deleteEndpointAdmin({ endpoint_uuid });
                setEndpointIsDeleted(true);
            } else {
                // If currently deleted, restore the endpoint
                await BackendObj.extractions.restoreEndpointAdmin({ endpoint_uuid });
                setEndpointIsDeleted(false);
            }
            setSuccessMessage(`Endpoint successfully ${!endpoint_is_deleted ? "deleted" : "restored"}`);
        } catch (error) {
            console.error("Error toggling endpoint deletion:", error);
            setErrorMessage(`Error ${!endpoint_is_deleted ? "deleting" : "restoring"} endpoint: ${(error as Error).message}`);
        } finally {
            setSaving(false);
        }
    };

    const handleTemplateIsDeletedToggle = async () => {
        if (!template_uuid) return;

        setTemplateLoading(true);
        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        try {
            if (!template_is_deleted) {
                // If currently not deleted, delete the template
                await BackendObj.extractions.deleteTemplateAdmin({ template_uuid });
                setTemplateIsDeleted(true);
            } else {
                // If currently deleted, restore the template
                await BackendObj.extractions.restoreTemplateAdmin({ template_uuid });
                setTemplateIsDeleted(false);
            }
            setSuccessMessage(`Template successfully ${!template_is_deleted ? "deleted" : "restored"}`);
        } catch (error) {
            console.error("Error toggling template deletion:", error);
            setErrorMessage(`Error ${!template_is_deleted ? "deleting" : "restoring"} template: ${(error as Error).message}`);
        } finally {
            setTemplateLoading(false);
        }
    };

    return (
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Database Administration
                </h2>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>

            <div className="mx-16">
                <CompactTabs tabs={tabs} selected_tab_key={active_tab} setSelectedTab={setActiveTab} />
            </div>

            {active_tab === "endpoints" && (
                <div className="mx-16 mt-6">
                    <div className="flex flex-row items-end space-x-4 mb-6">
                        <div className="w-96">
                            <Textbox
                                value={endpoint_uuid}
                                onChange={setEndpointUuid}
                                placeholder="Enter endpoint UUID"
                            />
                        </div>
                        <Button
                            text="Load"
                            onClick={loadEndpoint}
                            disabled={loading || !endpoint_uuid}
                            highlight={true}
                            icon={loading ? undefined : hi.ArrowDownTrayIcon}
                            loading={loading}
                        />
                    </div>

                    {error_message && (
                        <div className="mb-4 p-4 bg-torch_red-50 text-torch_red-700 rounded-md">
                            {error_message}
                        </div>
                    )}

                    {success_message && (
                        <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
                            {success_message}
                        </div>
                    )}

                    {data_loaded && endpoint_metadata && (
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Endpoint Metadata</h3>
                                <div className="bg-gray-50 p-4 rounded-md shadow-sm overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Field</th>
                                                <th scope="col" className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Value</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">UUID</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{endpoint_metadata.uuid}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">User UUID</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{endpoint_metadata.user_uuid}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Name</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{endpoint_metadata.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Created At</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{endpoint_metadata.created_at}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Is Deleted</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">
                                                    <div className="flex items-center">
                                                        <Toggle
                                                            enabled={endpoint_is_deleted}
                                                            setEnabled={handleIsDeletedToggle}
                                                            bg_color="bg-gray-200"
                                                            toggle_color="bg-sea_blue-600"
                                                        />
                                                        <span className="ml-3">
                                                            {saving ? "Saving..." : endpoint_is_deleted ? "Yes" : "No"}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Org UUID</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{endpoint_metadata.org_uuid}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">IP Whitelist</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{endpoint_metadata.ip_whitelist.join(", ")}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Type</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{endpoint_metadata.type}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Output Name</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{JSON.stringify(endpoint_metadata.output_name)}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Template UUID</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{endpoint_metadata.template_uuid}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">DW Last Update TS</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{endpoint_metadata.dw_last_update_ts}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Alias</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{endpoint_metadata.alias}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">Details</h3>
                                <div className="w-full shadow border">
                                    <CodeMirror
                                        value={endpoint_details}
                                        height="300px"
                                        theme="light"
                                        extensions={[json(), linter(jsonParseLinter())]}
                                        onChange={(value) => {
                                            setEndpointDetails(value);
                                            try {
                                                JSON.parse(value);
                                                setIsValidDetails(true);
                                            } catch (e) {
                                                setIsValidDetails(false);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={`mt-1 text-sm ${is_valid_details ? "text-green-600" : "text-torch_red-600"}`}>
                                    {is_valid_details ? "Valid JSON" : "Invalid JSON"}
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">Custom Output</h3>
                                <div className="w-full shadow border">
                                    <CodeMirror
                                        value={endpoint_custom_output}
                                        height="300px"
                                        theme="light"
                                        extensions={[json(), linter(jsonParseLinter())]}
                                        onChange={(value) => {
                                            setEndpointCustomOutput(value);
                                            try {
                                                JSON.parse(value);
                                                setIsValidCustomOutput(true);
                                            } catch (e) {
                                                setIsValidCustomOutput(false);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={`mt-1 text-sm ${is_valid_custom_output ? "text-green-600" : "text-torch_red-600"}`}>
                                    {is_valid_custom_output ? "Valid JSON" : "Invalid JSON"}
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">Endpoint Triggers</h3>
                                <div className="w-full shadow border">
                                    <CodeMirror
                                        value={endpoint_triggers}
                                        height="300px"
                                        theme="light"
                                        extensions={[json(), linter(jsonParseLinter())]}
                                        onChange={(value) => {
                                            setEndpointTriggers(value);
                                            try {
                                                JSON.parse(value);
                                                setIsValidTriggers(true);
                                            } catch (e) {
                                                setIsValidTriggers(false);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={`mt-1 text-sm ${is_valid_triggers ? "text-green-600" : "text-torch_red-600"}`}>
                                    {is_valid_triggers ? "Valid JSON" : "Invalid JSON"}
                                </div>
                            </div>

                            <div className="flex justify-end">
                                <Button
                                    text="Save"
                                    onClick={saveEndpoint}
                                    disabled={saving || !is_valid_details || !is_valid_custom_output || !is_valid_triggers}
                                    highlight={true}
                                    icon={saving ? undefined : hi.ArrowUpTrayIcon}
                                    loading={saving}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            {active_tab === "templates" && (
                <div className="mx-16 mt-6">
                    <div className="flex flex-row items-end space-x-4 mb-6">
                        <div className="w-96">
                            <Textbox
                                value={template_uuid}
                                onChange={setTemplateUuid}
                                placeholder="Enter template UUID"
                            />
                        </div>
                        <Button
                            text="Load"
                            onClick={loadTemplate}
                            disabled={template_loading || !template_uuid}
                            highlight={true}
                            icon={template_loading ? undefined : hi.ArrowDownTrayIcon}
                            loading={template_loading}
                        />
                    </div>

                    {error_message && (
                        <div className="mb-4 p-4 bg-torch_red-50 text-torch_red-700 rounded-md">
                            {error_message}
                        </div>
                    )}

                    {success_message && (
                        <div className="mb-4 p-4 bg-green-50 text-green-700 rounded-md">
                            {success_message}
                        </div>
                    )}

                    {template_data && (
                        <div className="space-y-6">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Template Information</h3>
                                <div className="bg-gray-50 p-4 rounded-md shadow-sm overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200">
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">UUID</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{template_data.uuid}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Name</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{template_data.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Is Deleted</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">
                                                    <div className="flex items-center">
                                                        <Toggle
                                                            enabled={template_is_deleted}
                                                            setEnabled={handleTemplateIsDeletedToggle}
                                                            bg_color="bg-gray-200"
                                                            toggle_color="bg-sea_blue-600"
                                                        />
                                                        <span className="ml-3">
                                                            {template_loading ? "Saving..." : template_is_deleted ? "Yes" : "No"}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Organization UUID</td>
                                                <td className="py-2 px-3 text-sm text-gray-500 font-mono">{template_data.org_uuid}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Created At</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{new Date(template_data.created_at).toISOString()}</td>
                                            </tr>
                                            <tr>
                                                <td className="py-2 px-3 text-sm font-medium text-gray-900">Email Address</td>
                                                <td className="py-2 px-3 text-sm text-gray-500">{template_data.email_address}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Template Facts</h3>
                                <div className="bg-gray-50 p-4 rounded-md shadow-sm overflow-x-auto">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entity</th>
                                                <th scope="col" className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {template_data.facts.map((fact, index) => (
                                                <tr key={index}>
                                                    <td className="py-2 px-3 text-sm text-gray-500">{fact.entity}</td>
                                                    <td className="py-2 px-3 text-sm text-gray-500">{fact.role}</td>
                                                </tr>
                                            ))}
                                            {template_data.facts.length === 0 && (
                                                <tr>
                                                    <td colSpan={2} className="py-2 px-3 text-sm text-gray-500 text-center">No facts available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Template Details</h3>
                                <div className="w-full shadow border">
                                    <CodeMirror
                                        value={JSON.stringify(template_data.details, null, 2)}
                                        height="200px"
                                        theme="light"
                                        extensions={[json()]}
                                        readOnly={true}
                                    />
                                </div>
                            </div>

                            {template_data.endpoints.length > 0 && (
                                <div className="mt-4">
                                    <h3 className="text-lg font-medium">Endpoints</h3>
                                    <div className="mt-2 border rounded-md overflow-hidden">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UUID</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Is Deleted</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {template_data.endpoints.map((endpoint) => (
                                                    <tr key={endpoint.uuid}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{endpoint.name}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{endpoint.uuid}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{endpoint.is_deleted ? "Yes" : "No"}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Contexts</h3>
                                <div className="flex flex-row items-end space-x-4 mb-6">
                                    <div className="w-96">
                                        <select
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sea_blue-600 sm:text-sm sm:leading-6"
                                            value={selected_context_uuid}
                                            onChange={(e) => handleContextSelection(e.target.value)}
                                        >
                                            <option value="">Select a context</option>
                                            {template_data.contexts.map((context) => (
                                                <option key={context.uuid} value={context.uuid}>
                                                    {context.name} ({context.uuid})
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="w-96">
                                        <Textbox
                                            value={selected_context_uuid}
                                            onChange={handleContextSelection}
                                            placeholder="Or paste context UUID"
                                        />
                                    </div>
                                </div>

                                {selected_context && (
                                    <div className="space-y-6">
                                        <div className="bg-gray-50 p-4 rounded-md shadow-sm overflow-x-auto">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <tbody className="divide-y divide-gray-200">
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">UUID</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500 font-mono">{selected_context.uuid}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">Name</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500">{selected_context.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">Is Deleted</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500">{selected_context.is_deleted ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">Type</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500">{selected_context.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">Created At</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500">{new Date(selected_context.created_at * 1000).toLocaleString()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">Weight Score</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500">{selected_context.weight_score}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-2 px-3 text-sm font-medium text-gray-900">Code</td>
                                                        <td className="py-2 px-3 text-sm text-gray-500 font-mono whitespace-pre-wrap">{selected_context.code}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div>
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Facts</h4>
                                            <div className="bg-gray-50 p-4 rounded-md shadow-sm overflow-x-auto">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entity</th>
                                                            <th scope="col" className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200">
                                                        {selected_context.facts.map((fact, index) => (
                                                            <tr key={index}>
                                                                <td className="py-2 px-3 text-sm text-gray-500">{fact.entity}</td>
                                                                <td className="py-2 px-3 text-sm text-gray-500">{fact.role}</td>
                                                            </tr>
                                                        ))}
                                                        {selected_context.facts.length === 0 && (
                                                            <tr>
                                                                <td colSpan={2} className="py-2 px-3 text-sm text-gray-500 text-center">No facts available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Fields</h4>
                                            <div className="w-full shadow border">
                                                <CodeMirror
                                                    value={JSON.stringify(selected_context.fields, null, 2)}
                                                    height="200px"
                                                    theme="light"
                                                    extensions={[json()]}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="pb-8">
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Extract Parameters</h4>
                                            <div className="w-full shadow border">
                                                <CodeMirror
                                                    value={JSON.stringify(selected_context.extract_params, null, 2)}
                                                    height="200px"
                                                    theme="light"
                                                    extensions={[json()]}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Postprocess</h4>
                                            <div className="w-full shadow border">
                                                <CodeMirror
                                                    value={JSON.stringify(selected_context.postprocess, null, 2)}
                                                    height="200px"
                                                    theme="light"
                                                    extensions={[json()]}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Row Validators</h4>
                                            <div className="w-full shadow border">
                                                <CodeMirror
                                                    value={JSON.stringify(selected_context.row_validators, null, 2)}
                                                    height="200px"
                                                    theme="light"
                                                    extensions={[json()]}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Context Validators</h4>
                                            <div className="w-full shadow border">
                                                <CodeMirror
                                                    value={JSON.stringify(selected_context.context_validators, null, 2)}
                                                    height="200px"
                                                    theme="light"
                                                    extensions={[json()]}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <h4 className="text-md font-medium leading-6 text-gray-900 mb-2">Overrides</h4>
                                            <div className="w-full shadow border">
                                                <CodeMirror
                                                    value={JSON.stringify(selected_context.overrides, null, 2)}
                                                    height="200px"
                                                    theme="light"
                                                    extensions={[json()]}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}