import {
    forwardRef,
    Fragment,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";
import { Link, useNavigate } from "react-router-dom";

import {
    ArrowPathIcon,
    BookOpenIcon,
    BugAntIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    TrashIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import { TbQuestionMark, TbQuote } from "react-icons/tb";

import { Tooltip } from "react-tooltip";

import {
    CONTEXT_TYPES,
    EXTRACT_CONFIRMATION_STATUS,
    USER_ROLES
} from "../lib/consts";
import {
    IRecord,
    IContextBase,
    IScrapeBase,
    IItem,
    IItemBase,
    IContextField,
    IScrapeEvalDiff,
    IRecordRaw,
    IScrapeDocument,
    ITemplate,
    IScrapeEvalMetrics,
    IFieldNameUuidPair
} from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";
import {
    classNames,
    deepCopy,
    getHierarchicalContextSchema,
    getHierarchicalRecord,
    prettyDate,
    prettyDateTime,
    prettyNumber,
    primitiveToStr,
    strToPrimitive
} from "../lib/utils";
import {
    ContextType,
    IConfidence,
    IContextFieldDataType,
    IContextFieldType,
    IContextNoUUID,
    IExtractConfirmationLog,
    IItemRangeValues,
    IItemValidationSummary,
    IPrimitiveType,
    IScrapeDebugLog,
    IScrapeSlimVerify,
    IValidationResult,
    IValidations,
    IVerifyItemRes
} from "../lib/backend/extractions.types.generated";

import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { ConfirmModal } from "./ConfirmModal";
import { Checkbox } from "./Checkbox";
import { FullScreen, FullScreenText } from "./FullScreen";
import { NewExampleModal, EditExampleModal } from "./ExampleModals";
import { TimeoutErrorMessageBar } from "./ErrorMessageBar";
import { ProcessFlowBody, ProcessFlowHead } from "./ProcessFlow";
import { ValidationSummary } from "./ValidationSummary";
import { Textbox } from "./Textbox";
import { PencilIcon } from "@primer/octicons-react";

const TOOLTIP_STYLE = {
    backgroundColor: "#f0f9ff",
    borderWidth: "1px",
    color: "#111827",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    fontSize: "0.875rem"
};

function FieldValueRender(props: { value: IPrimitiveType }) {
    const { value } = props;

    // check if not string
    if (typeof value !== "string") {
        return <Fragment>{value}</Fragment>
    }

    // check if we have new line characters
    const has_new_lines = value.includes("\n");
    if (has_new_lines) {
        return <div className="flex flex-col items-start gap-1">
            {value.split("\n").map((line, idx) => <p key={idx} className="text-sm">{line}&nbsp;</p>)}
        </div>
    }

    return <Fragment>{value}</Fragment>
}

type ScrapeTableProps = {
    scrape_idx: number,
    context: IContextBase,
    scrape: IScrapeBase,
    range_values: IItemRangeValues[],
    setRecords: (scrape_idx: number, records: IRecord[]) => void,
    markChanged: () => void,
}

function ScrapeTable(props: ScrapeTableProps) {
    const { scrape_idx, context, scrape, range_values, setRecords, markChanged } = props;
    const { field_name_uuid_pairs, records } = scrape;

    const user = useSelector(selectUser);

    const [selected_range_field, setSelectedRangeField] = useState<{ row_idx: number, col_idx: number } | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);

    const is_object = context?.type === CONTEXT_TYPES.object;
    const is_array = context?.type === CONTEXT_TYPES.array || context?.type === CONTEXT_TYPES.lookup_table;

    const columns = context.fields.map((f, idx) => ({
        title: f.confirm_name ?? f.name,
        field_datatype: f.datatype,
        field_uuid: f.uuid,
        field_name: field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? f.name,
        idx,
        hide: f.skip_on_confirm === true,
        validators: f.validators && f.validators.length > 0,
        required: f.validators && f.validators.some(v => ["fail", "reject"].includes(v.level)),
        range_values: range_values.find(r => r.field_uuid === f.uuid) ?? undefined
    }));


    /// TABLE EDITING

    const updateField = (value: string, row_idx: number, col_idx: number): string => {
        const { title, field_datatype, field_name } = columns[col_idx];
        const existing_value = primitiveToStr(records[row_idx].val[field_name], field_datatype, user.number_format_locale);
        if (value !== existing_value) {
            const new_records: IRecord[] = [...records];
            const new_value = strToPrimitive(value, field_datatype, user.number_format_locale);
            // if new value is undefined, it means it is not a valid type
            if (new_value !== undefined) {
                new_records[row_idx].val[field_name] = new_value;
                setRecords(scrape_idx, deepCopy(new_records));
                return primitiveToStr(new_value, field_datatype, user.number_format_locale);
            }
            if (field_datatype === "number") {
                const correct_value = prettyNumber(1234.56, 2, 2, user.number_format_locale);
                setErrorMessage(`Invalid numeric value for ${title}: ${value}. Sample correct value: ${correct_value}`);
            } else if (field_datatype === "date") {
                const correct_value_local = prettyDate(Date.now());
                const correct_value_iso = new Date().toISOString().split("T")[0];
                setErrorMessage(`Invalid date value for ${title}: ${value}. Sample correct values: ${correct_value_local} or ${correct_value_iso}`);
            } else {
                setErrorMessage(`Invalid value for ${title}: ${value}`);
            }
            return existing_value
        }
        return existing_value;
    }

    const handleBlur = (e: any, row_idx: number, col_idx: number) => {
        const value = e.target.innerText.trim();
        const new_value = updateField(value, row_idx, col_idx);
        e.target.innerHTML = new_value;
    };

    const handleFocus = (e: any, _row_idx: number, _col_idx: number) => {
        // Use setTimeout to delay the selection process
        // This gives Safari time to complete its own focus handling
        setTimeout(() => {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(e.target);
            selection?.removeAllRanges();
            selection?.addRange(range);
        }, 0);
    }

    /// ROW EDITING

    const deleteRow = (row_idx: number) => {
        const new_records: IRecord[] = [
            ...records.slice(0, row_idx),
            ...records.slice(row_idx + 1)
        ];
        setRecords(scrape_idx, deepCopy(new_records));
    }

    const addRow = () => {
        const val: IRecordRaw = {};
        for (const column of columns) {
            val[column.field_name] = "";
        }
        const new_records: IRecord[] = [...records, { val, val_raw: val }];
        setRecords(scrape_idx, deepCopy(new_records));
    }

    const moveRow = (row_idx: number, direction: "up" | "down") => {
        if (direction === "up" && row_idx === 0) { return; }
        if (direction === "down" && row_idx === records.length - 1) { return; }
        const diff = direction === "up" ? -1 : 1;
        const new_records: IRecord[] = [...records];
        const temp = new_records[row_idx];
        new_records[row_idx] = new_records[row_idx + diff];
        new_records[row_idx + diff] = temp;
        setRecords(scrape_idx, deepCopy(new_records));
    }

    // shortcuts for case if object
    const record_val: IRecordRaw = records[0]?.val || {}
    const confidence: IConfidence = records[0]?.confidence || {}
    const fields_validations: IValidations = records[0]?.fields_validations || {}

    const handleKeyDownObject = (e: React.KeyboardEvent, col_idx: number) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            // find previous cell in the same row that is not hidden
            let prev_col_idx = col_idx - 1;
            while (prev_col_idx >= 0 && columns[prev_col_idx].hide) {
                prev_col_idx--;
            }
            if (prev_col_idx >= 0) {
                const previous_row_cell = document.querySelector(
                    `[data-table-id="${scrape_idx}"][data-row-idx="${0}"][data-col-idx="${prev_col_idx}"]`
                ) as HTMLElement;
                if (previous_row_cell) {
                    previous_row_cell.focus();
                }
            }
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            // find next cell in the same row that is not hidden
            let next_col_idx = col_idx + 1;
            while (next_col_idx < columns.length && columns[next_col_idx].hide) {
                next_col_idx++;
            }
            if (next_col_idx < columns.length) {
                const next_row_cell = document.querySelector(
                    `[data-table-id="${scrape_idx}"][data-row-idx="${0}"][data-col-idx="${next_col_idx}"]`
                ) as HTMLElement;
                if (next_row_cell) {
                    next_row_cell.focus();
                }
            }
        }
    };

    const handleKeyDownArray = (e: React.KeyboardEvent, row_idx: number, col_idx: number) => {
        if (e.key === "ArrowUp") {
            e.preventDefault();
            // Find the same column in the previous row within the same table
            if (row_idx > 0) {
                const previous_row_cell = document.querySelector(
                    `[data-table-id="${scrape_idx}"][data-row-idx="${row_idx - 1}"][data-col-idx="${col_idx}"]`
                ) as HTMLElement;
                if (previous_row_cell) {
                    previous_row_cell.focus();
                }
            }
        } else if (e.key === "ArrowDown") {
            e.preventDefault();
            // Find the same column in the next row within the same table
            const next_row_cell = document.querySelector(
                `[data-table-id="${scrape_idx}"][data-row-idx="${row_idx + 1}"][data-col-idx="${col_idx}"]`
            ) as HTMLElement;
            if (next_row_cell) {
                next_row_cell.focus();
            }
        } else if (e.key === "ArrowLeft" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            // find previous cell in the same row that is not hidden
            let prev_col_idx = col_idx - 1;
            while (prev_col_idx >= 0 && columns[prev_col_idx].hide) {
                prev_col_idx--;
            }
            if (prev_col_idx >= 0) {
                const prev_cell = document.querySelector(
                    `[data-table-id="${scrape_idx}"][data-row-idx="${row_idx}"][data-col-idx="${prev_col_idx}"]`
                ) as HTMLElement;
                if (prev_cell) {
                    prev_cell.focus();
                }
            }
        } else if (e.key === "ArrowRight" && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            // find next cell in the same row that is not hidden
            let next_col_idx = col_idx + 1;
            while (next_col_idx < columns.length && columns[next_col_idx].hide) {
                next_col_idx++;
            }
            if (next_col_idx < columns.length) {
                const next_cell = document.querySelector(
                    `[data-table-id="${scrape_idx}"][data-row-idx="${row_idx}"][data-col-idx="${next_col_idx}"]`
                ) as HTMLElement;
                if (next_cell) {
                    next_cell.focus();
                }
            }
        }
    };

    return <div className="pr-4">
        {is_object && <table>
            <tbody>
                {columns.filter(c => !c.hide).map((column, idx) => <tr key={idx}>
                    <td className="py-2 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top" colSpan={3}>
                        {column.title}
                    </td>

                    {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2, undefined, user.number_format_locale) : "N/A"}`}
                        className={classNames("w-1 border-l border-t border-b border-gray-200 cursor-pointer",
                            confidence && confidence[column.field_name] ?
                                (confidence[column.field_name] < 0.5 ? "bg-torch_red-100" :
                                    confidence[column.field_name] < 0.8 ? "bg-candy_corn-100" :
                                        "bg-gray-100") :
                                "bg-gray-50",
                        )}></td>}

                    {column.field_name === undefined && <td className="w-1 border-l border-t border-b border-gray-200 cursor-pointer bg-gray-50"></td>}

                    {column.field_name !== undefined && <td
                        className={classNames(
                            "border-t border-b border-gray-200 py-2 px-2 min-w-[100px] max-w-[600px] whitespace-pre-wrap overflow-wrap hover:bg-sea_blue-50 text-left text-sm align-top cursor-text",
                            (fields_validations && fields_validations[column.field_name]) ?
                                (fields_validations[column.field_name].every(v => v.status === "pass") ? "text-green-600" : "text-torch_red-600") :
                                (column.range_values === undefined ? "border-r" : ""),
                            (fields_validations && fields_validations[column.field_name]) &&
                            (fields_validations[column.field_name].some(v => v.status === "fail") ? "bg-torch_red-50" :
                                fields_validations[column.field_name].some(v => v.status === "reject") ? "bg-torch_red-200" : "")
                        )}
                        colSpan={1 + (column.range_values ? 0 : 1) + (fields_validations && fields_validations[column.field_name] ? 0 : 1)}
                        contentEditable={true}
                        data-table-id={scrape_idx}
                        data-row-idx={0}
                        data-col-idx={column.idx}
                        onInput={() => markChanged()}
                        onBlur={e => handleBlur(e, 0, column.idx)}
                        onKeyDown={e => handleKeyDownObject(e, column.idx)}
                        onFocus={e => handleFocus(e, 0, column.idx)}
                        dangerouslySetInnerHTML={{ __html: record_val[column.field_name] !== undefined ? primitiveToStr(record_val[column.field_name], column.field_datatype, user.number_format_locale) : "" }}
                    />}

                    {column.field_name === undefined && <td
                        className="border-t border-r border-b border-gray-200 py-1 px-2 min-w-[100px] whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text"
                        colSpan={1 + (column.range_values ? 0 : 1) + (column.validators ? 0 : 1)}>
                    </td>}

                    {column.range_values && <td
                        className={classNames("px-1 py-2 w-4 align-middle text-sea_blue-500 border-t border-b border-gray-200 cursor-pointer hover:bg-sea_blue-50",
                            (column.field_name !== undefined && fields_validations && fields_validations[column.field_name]) ? "" : "border-r")}
                    >
                        <MagnifyingGlassIcon className="w-5 h-5" onClick={() => setSelectedRangeField({ row_idx: 0, col_idx: column.idx })} />
                    </td>}

                    {(column.field_name !== undefined && fields_validations && fields_validations[column.field_name]) && <td
                        className="px-1 py-2 w-4 align-middle  text-gray-300 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white"
                        data-tooltip-id={`validation-tooltip-${scrape.uuid}`}
                        data-tooltip-html={fields_validations[column.field_name]
                            .map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("")}
                    >
                        {fields_validations[column.field_name].every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                        {fields_validations[column.field_name].some(v => v.status === "warn") && <XMarkIcon className="w-5 h-5 text-candy_corn-500" />}
                        {fields_validations[column.field_name].some(v => v.status === "fail") && <XMarkIcon className="w-5 h-5 text-torch_red-500" />}
                        {fields_validations[column.field_name].some(v => v.status === "reject") && <XMarkIcon className="w-5 h-5 text-rose-500" />}
                    </td>}
                </tr>)}
            </tbody>
        </table>}

        {is_array && <table>
            <thead>
                <tr>
                    <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {columns.filter(c => !c.hide).map((column, idx) => <Fragment key={idx}>
                        <th className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top"
                            colSpan={2 + (column.validators ? 1 : 0) + (column.range_values ? 1 : 0)}>
                            {column.title}
                        </th>
                    </Fragment>)}
                </tr>
            </thead>
            <tbody>
                {records.map(({ val, confidence, fields_validations, validations }, row_idx) => <tr key={row_idx}>
                    <td className="py-2 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>

                    {columns.filter(c => !c.hide).map((column) => <Fragment key={column.idx}>
                        {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2, undefined, user.number_format_locale) : "N/A"}`}
                            className={classNames("w-1 border-l border-t border-b border-gray-200 cursor-pointer",
                                confidence && confidence[column.field_name] ?
                                    (confidence[column.field_name] < 0.5 ? "bg-torch_red-100" :
                                        confidence[column.field_name] < 0.8 ? "bg-candy_corn-100" :
                                            "bg-gray-100") :
                                    "bg-gray-50",
                            )}></td>}
                        {column.field_name === undefined && <td className="w-1 border-l border-t border-b border-gray-200 cursor-pointer bg-gray-50"></td>}

                        {column.field_name !== undefined && <td
                            className={classNames(
                                "border-t border-b border-gray-200 py-2 px-2 min-w-[100px] max-w-[200px] whitespace-pre-wrap overflow-wrap hover:bg-sea_blue-50 text-left text-sm align-top cursor-text",
                                (fields_validations && fields_validations[column.field_name]) ?
                                    (fields_validations[column.field_name].every(v => v.status === "pass") ? "text-green-600" :
                                        fields_validations[column.field_name].some(v => v.status === "warn") ? "text-candy_corn-600" :
                                            fields_validations[column.field_name].some(v => v.status === "fail") ? "text-torch_red-600" :
                                                fields_validations[column.field_name].some(v => v.status === "reject") ? "text-rose-600" : "") :
                                    "",
                                (fields_validations && fields_validations[column.field_name]) ?
                                    (fields_validations[column.field_name].some(v => v.status === "pass") ? "" :
                                        fields_validations[column.field_name].some(v => v.status === "warn") ? "" :
                                            fields_validations[column.field_name].some(v => v.status === "fail") ? "bg-torch_red-50" :
                                                fields_validations[column.field_name].some(v => v.status === "reject") ? "bg-rose-50" : "") :
                                    ""
                            )}
                            contentEditable={true}
                            data-table-id={scrape_idx}
                            data-row-idx={row_idx}
                            data-col-idx={column.idx}
                            onInput={() => markChanged()}
                            onBlur={e => handleBlur(e, row_idx, column.idx)}
                            onKeyDown={e => handleKeyDownArray(e, row_idx, column.idx)}
                            onFocus={e => handleFocus(e, row_idx, column.idx)}
                            dangerouslySetInnerHTML={{ __html: val[column.field_name] !== undefined ? primitiveToStr(val[column.field_name], column.field_datatype, user.number_format_locale) : "" }}
                        />}
                        {column.field_name === undefined && <td className="border-t border-b border-gray-200 py-1 px-2 min-w-[100px] max-w-[200px] whitespace-normal overflow-wrap hover:bg-sea_blue-100 text-left text-sm align-top cursor-text"></td>}

                        {column.range_values && <td
                            className={classNames("px-1 py-2 w-4 align-middle text-sea_blue-500 border-t border-b border-gray-200 cursor-pointer hover:bg-sea_blue-50",
                                (column.field_name !== undefined && column.validators) ? "" : "border-r")}
                        >
                            <MagnifyingGlassIcon className="w-5 h-5" onClick={() => setSelectedRangeField({ row_idx: row_idx, col_idx: column.idx })} />
                        </td>}

                        {column.field_name !== undefined && column.validators && <td
                            className={classNames(
                                "px-1 py-2 w-4 align-middle  text-gray-300 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white",
                                (fields_validations && fields_validations[column.field_name]) ?
                                    (fields_validations[column.field_name].some(v => v.status === "pass") ? "" :
                                        fields_validations[column.field_name].some(v => v.status === "warn") ? "" :
                                            fields_validations[column.field_name].some(v => v.status === "fail") ? "bg-torch_red-50" :
                                                fields_validations[column.field_name].some(v => v.status === "reject") ? "bg-rose-50" : "") :
                                    ""
                            )}
                            data-tooltip-id={`validation-tooltip-${scrape.uuid}`}
                            data-tooltip-html={fields_validations && fields_validations[column.field_name] ?
                                fields_validations[column.field_name]
                                    .map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("") : ""}
                        >
                            {fields_validations && fields_validations[column.field_name] && fields_validations[column.field_name].every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                            {fields_validations && fields_validations[column.field_name] && fields_validations[column.field_name].some(v => v.status !== "pass") && <XMarkIcon className="w-5 h-5 text-torch_red-500" />}
                            {column.validators && !fields_validations && <TbQuestionMark className="w-5 h-5 text-gray-300" />}
                        </td>}
                    </Fragment>)}
                    <td
                        className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b  border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                        onClick={() => moveRow(row_idx, "up")}>
                        <ChevronUpIcon className="w-4 h-4" />
                    </td>
                    <td
                        className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border-t border-b border-r border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                        onClick={() => moveRow(row_idx, "down")}>
                        <ChevronDownIcon className="w-4 h-4" />
                    </td>
                    <td
                        className="px-1 py-2 w-4 align-middle bg-gray-100 text-gray-600 border border-gray-200 cursor-pointer hover:text-white hover:bg-sea_blue-300"
                        onClick={() => deleteRow(row_idx)}>
                        <TrashIcon className="w-4 h-4" />
                    </td>
                    {validations && validations.length > 0 && <td
                        className="py-2 px-2 text-left text-sm font-semibold align-top"
                        data-tooltip-id={`validation-tooltip-${scrape.uuid}`}
                        data-tooltip-html={validations.map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("")}
                    >
                        {validations.every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                        {validations.some(v => v.status === "warn") && <XMarkIcon className="w-5 h-5 text-candy_corn-500" />}
                        {validations.some(v => v.status === "fail") && <XMarkIcon className="w-5 h-5 text-torch_red-500" />}
                        {validations.some(v => v.status === "reject") && <XMarkIcon className="w-5 h-5 text-rose-500" />}
                    </td>}
                </tr>)}
                <tr>
                    <td
                        className="py-1 px-2 bg-gray-100 border border-gray-200 text-center text-sm text-gray-600 align-middle cursor-pointer hover:text-white hover:bg-sea_blue-300"
                        onClick={addRow}>
                        +
                    </td>
                </tr>
            </tbody>
        </table>}
        <Tooltip
            id={`validation-tooltip-${scrape.uuid}`}
            place="bottom"
            style={TOOLTIP_STYLE}
            opacity={1} />
        <TimeoutErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)}
            is_sidebar_large_override={false}
        />
        <ConfirmModal
            open={selected_range_field !== undefined}
            title={`Select value for ${columns[selected_range_field?.col_idx ?? 0].title}`}
            onClose={() => setSelectedRangeField(undefined)}
            hide_icon={true}
            hide_cancel={true}
            confirm="Close"
            size="3xl"
        >
            {selected_range_field !== undefined && <RangeValuesTable
                range_values={columns[selected_range_field.col_idx].range_values}
                selectValue={(value, connected_fields) => {
                    updateField(value, selected_range_field.row_idx, selected_range_field.col_idx);
                    for (const cf of connected_fields) {
                        const col_idx = columns.findIndex(c => c.field_uuid === cf.field_uuid);
                        if (col_idx === -1) { continue; }
                        updateField(cf.value, selected_range_field.row_idx, col_idx);
                    }
                    setSelectedRangeField(undefined);
                }}
            />}
        </ConfirmModal>
    </div>;
}

type RangeValuesTableProps = {
    range_values?: IItemRangeValues;
    selectValue: (value: string, connected_fields: { field_uuid: string, value: string }[]) => void;
}

function RangeValuesTable(props: RangeValuesTableProps) {
    const { range_values, selectValue } = props;

    const [filter_text, setFilterText] = useState("");
    const [filtered_values, setFilteredValues] = useState<string[]>([]);
    const [filtered_display_values, setFilteredDisplayValues] = useState<string[][]>([]);
    const [filtered_connected_fields, setFilteredConnectedFields] = useState<{ field_uuid: string, value: string }[][]>([]);

    useEffect(() => {
        if (range_values === undefined) { return; }
        // reset filter text and filtered values
        setFilterText("");
        setFilteredValues(range_values.values);
        setFilteredDisplayValues(range_values.display_values);
        setFilteredConnectedFields(range_values.connected_fields);
    }, [range_values]);

    useEffect(() => {
        if (range_values === undefined) { return; }
        const { values, display_values, connected_fields } = range_values;
        if (filter_text === "") {
            setFilteredValues(values);
            setFilteredDisplayValues(display_values);
            setFilteredConnectedFields(connected_fields);
            return;
        }
        const lower_filter = filter_text.toLowerCase();
        const new_filtered_values: string[] = [];
        const new_filtered_display_values: string[][] = [];
        const new_filtered_connected_fields: { field_uuid: string, value: string }[][] = [];
        for (const [idx, value] of values.entries()) {
            if (value.toLowerCase().includes(lower_filter) || display_values[idx].some(display_value => display_value.toString().toLowerCase().includes(lower_filter))) {
                new_filtered_values.push(value);
                new_filtered_display_values.push(display_values[idx]);
                new_filtered_connected_fields.push(connected_fields[idx]);
            }
        }
        setFilteredValues(new_filtered_values);
        setFilteredDisplayValues(new_filtered_display_values);
        setFilteredConnectedFields(new_filtered_connected_fields);
    }, [filter_text, range_values]);

    if (range_values === undefined) {
        return <div />;
    }

    const { title, display_titles } = range_values;

    return <Fragment>
        <div className="pt-4 flex items-center">
            <Textbox
                placeholder="Search values..."
                value={filter_text}
                onChange={value => setFilterText(value)}
            />
        </div>

        <div className="mt-4 outer-div max-h-[400px] overflow-auto">
            <table className="w-full">
                <thead className="sticky z-10">
                    <tr>
                        <th className="sticky left-0 z-20 py-1 px-2 min-w-[150px] max-w-[250px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">
                            {title}
                        </th>
                        {display_titles.map((title, idx) => <th
                            key={idx}
                            className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top"
                        >
                            {title}
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    {filtered_values.map((value, idx) => <tr
                        key={idx}
                        onClick={() => selectValue(value, filtered_connected_fields[idx])}
                        className="cursor-pointer hover:bg-sea_blue-100 group"
                    >
                        <td className="sticky left-0 z-10 py-1 px-2 min-w-[150px] max-w-[250px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top group-hover:bg-sea_blue-100">
                            {value}
                        </td>
                        {filtered_display_values[idx].map((value, idx) => <td
                            key={idx}
                            className="py-1 px-2 min-w-[100px] max-w-[200px] border border-gray-200 text-left text-sm align-top"
                        >
                            {value}
                        </td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>
    </Fragment>;
}

function checkIfAnyFail(scrapes: IScrapeSlimVerify[]): boolean {
    // check if at least one field, row or context validation fails
    for (const scrape of scrapes) {
        for (const record of scrape.records) {
            if (record.fields_validations) {
                for (const field of Object.keys(record.fields_validations)) {
                    for (const validation_result of record.fields_validations[field]) {
                        if (validation_result.status === "fail") {
                            return true;
                        }
                    }
                }
            }
            if (record.validations) {
                for (const validation_result of record.validations) {
                    if (validation_result.status === "fail") {
                        return true;
                    }
                }
            }
        }
        for (const validation_result of scrape.validations ?? []) {
            if (validation_result.status === "fail") {
                return true;
            }
        }
    }
    // all fine
    return false;
}

function getDeepCopyRecords(scrape: IScrapeBase, is_object: boolean): IRecord[] {
    // in case we have an object, we need to make sure we have at least one record
    if (is_object && scrape.records.length === 0) {
        const record: IRecord = { val: {}, val_raw: {} };
        for (const field of scrape.field_name_uuid_pairs) {
            record.val[field.name] = "";
            record.val_raw[field.name] = "";
        }
        return [record];
    }
    // in case we have an array or enough records for an object, we can just deep copy
    return deepCopy(scrape.records);
}

export interface ItemTableHandles {
    reject: () => Promise<void>;
    confirm: () => Promise<void>;
}

type ItemTablesProps = {
    item: IItem;
    range_values: IItemRangeValues[];
    validation_summary?: IItemValidationSummary;
    onItemUpdate: () => void;
    changeDecimalSeparator: (decimal_separator: "," | ".") => Promise<void>;
};

export const ItemTables = forwardRef<ItemTableHandles, ItemTablesProps>((props, ref) => {
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const { item: init_item, range_values, validation_summary: init_validation_summary, onItemUpdate, changeDecimalSeparator } = props;

    // current state
    const [scrapes, setScrapes] = useState<{ scrape: IScrapeBase, context: IContextBase }[]>([]);
    // validation state
    const [validation_summary, setValidationSummary] = useState<IItemValidationSummary | undefined>(undefined);
    // decimal separator
    const [decimal_separator, setDecimalSeparator] = useState<"," | "." | undefined>(undefined);
    const [change_decimal_separator, setChangeDecimalSeparator] = useState<"," | "." | undefined>(undefined);
    const [is_decimal_recompute, setIsDecimalRecompute] = useState(false);
    const [decimal_confirm_open, setDecimalConfirmOpen] = useState(false);
    // example cloning
    const [item_example_uuid, setItemExampleUuid] = useState<string | undefined>(undefined);
    const [is_new_example_modal_open, setIsNewExampleModalOpen] = useState<boolean>(false);
    const [is_edit_example_modal_open, setIsEditExampleModalOpen] = useState<boolean>(false);
    // flags
    const [is_changed, setIsChanged] = useState(false);
    const [is_confirming, setIsConfirming] = useState(false);
    const [is_rejecting, setIsRejecting] = useState(false);
    const [is_removing, setIsRemoving] = useState(false);
    const [is_saving, setIsSaving] = useState(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);
    const [retry_confirm_open, setRetryConfirmOpen] = useState(false);

    // when loaded, copy over initial values for scrapes and scrape validations
    useEffect(() => {
        // put scrapes and contexts together in pairs
        const new_scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
        for (const scrape of init_item.scrapes) {
            const context = init_item.template.contexts.find(c => c.uuid === scrape.context_uuid);
            if (context === undefined) { continue; }
            // we have a scrape
            new_scrapes.push({
                scrape: {
                    ...scrape,
                    records: getDeepCopyRecords(scrape, context.type === CONTEXT_TYPES.object)
                },
                context
            });
            // check if we have decimal separator (at least one field was numeric)
            if (scrape.extraction_info.decimal_separator !== undefined) {
                setDecimalSeparator(scrape.extraction_info.decimal_separator);
            }
        }
        // order scrapes so we first have any single-row scrapes, then any multi-row scrapes
        new_scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);
        setScrapes(new_scrapes);
        // check if item is cloned
        setItemExampleUuid(init_item.details.clone_example_item_uuid);
    }, [init_item]);

    // when loaded, copy over initial value for validation summary
    useEffect(() => {
        setValidationSummary(init_validation_summary);
    }, [init_validation_summary]);

    // expose methods to parent
    useImperativeHandle(ref, () => ({
        reject: handleReject,
        confirm: handleConfirm
    }));

    const setRecords = (scrape_idx: number, records: IRecord[]) => {
        const new_scrapes = [...scrapes];
        new_scrapes[scrape_idx].scrape.records = records;
        handleVerify(new_scrapes);
        setScrapes(new_scrapes);
        setIsChanged(true);
    };

    const handleUndo = async () => {
        for (const scrape of init_item.scrapes) {
            const scrape_context = scrapes.find(({ context }) => context.uuid === scrape.context_uuid);
            if (scrape_context === undefined) { continue; }
            scrape_context.scrape.records = getDeepCopyRecords(scrape, scrape_context.context.type === CONTEXT_TYPES.object);
        }
        setIsChanged(false);
    };

    const verifyScrapes = async (verify_scrapes: { scrape: IScrapeBase, context: IContextBase }[]): Promise<IVerifyItemRes> => {
        return await BackendObj.extractions.verifyItem({
            item_uuid: init_item.uuid,
            scrapes: verify_scrapes.map(s => ({
                uuid: s.scrape.uuid,
                context_uuid: s.context.uuid,
                // remove existing validations
                records: s.scrape.records.map((r): IRecord => ({
                    val_raw: r.val_raw,
                    val: r.val,
                    confidence: r.confidence
                })),
            })),
        })
    };

    const saveScrapes = async (save_scrapes: { scrape: IScrapeBase }[]): Promise<boolean> => {
        setIsSaving(true);
        // editable items have confirmation status so we can safely assume that confirmation_uuid exists
        await BackendObj.extractions.updateExtractConfirmationScrape({
            confirmation_uuid: init_item.extract_confirmations_uuid as string,
            scrapes: save_scrapes.map(s => ({
                scrape_uuid: s.scrape.uuid,
                records: s.scrape.records,
                extraction_info: { decimal_separator },
                validations: s.scrape.validations
            })),
        });
        setIsChanged(false);
        setIsSaving(false);
        return true;
    };

    const handleVerify = async (verify_scrapes: { scrape: IScrapeBase, context: IContextBase }[]): Promise<boolean> => {
        let success = false;
        try {
            // verify
            const { validated_scrapes, validation_summary: new_validation_summary, message } = await verifyScrapes(verify_scrapes);
            if (message) { throw new Error(message); }
            if (validated_scrapes !== undefined) {
                // compute new scrapes but update only validations statuses, not values on records
                setScrapes(prev => {
                    const new_scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
                    for (const scrape of validated_scrapes) {
                        const prev_scrape = prev.find(s => s.scrape.uuid === scrape.uuid);
                        if (prev_scrape !== undefined) {
                            new_scrapes.push({
                                scrape: {
                                    ...prev_scrape.scrape,
                                    validations: scrape.validations ?? [],
                                    records: prev_scrape.scrape.records.map((r, idx) => ({
                                        ...r,
                                        validations: scrape.records[idx].validations ?? [],
                                        fields_validations: scrape.records[idx].fields_validations ?? {}
                                    }))
                                },
                                context: prev_scrape.context
                            });
                        }
                    }
                    return new_scrapes;
                });
                // we update only validations update validations
                setValidationSummary(new_validation_summary);
                success = !checkIfAnyFail(validated_scrapes);
            } else {
                throw new Error("Error verifying the item");
            }
        } catch (err) {
            setErrorMessage("Error verifying the item");
        }
        return success;
    };

    // only available when verification required for the extraction
    // must make sure to resolve only once the save is done to avoid race conditions when confirming after verification
    const handleVerifyAndSave = (): Promise<boolean> => {
        return new Promise(resolve => {
            setIsSaving(true);
            // verify
            verifyScrapes(scrapes)
                .then(result => {
                    const { validated_scrapes, validation_summary: new_validation_summary, message } = result;
                    // check if all ok
                    if (message) {
                        // handle error
                        setErrorMessage(message);
                        setIsSaving(false);
                        resolve(false);
                    } else if (validated_scrapes) {
                        // check the result status
                        const success = !checkIfAnyFail(validated_scrapes);
                        // update scrapes
                        setScrapes(prev => {
                            const new_scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
                            for (const scrape of validated_scrapes) {
                                const scrape_idx = prev.findIndex(s => s.scrape.uuid === scrape.uuid);
                                if (scrape_idx !== -1) {
                                    new_scrapes.push({
                                        scrape: {
                                            ...prev[scrape_idx].scrape,
                                            records: deepCopy(scrape.records),
                                            validations: scrape.validations ?? []
                                        },
                                        context: prev[scrape_idx].context
                                    });
                                }
                            }
                            // save new scrapes in the background and resolve the promise
                            saveScrapes(new_scrapes).finally(() => { resolve(success); });
                            // return new scrapes
                            return new_scrapes;
                        });
                        // update validation summary
                        setValidationSummary(new_validation_summary);
                    }
                })
                .catch(err => {
                    console.error(err);
                    setErrorMessage("Error verifying the item");
                    setIsSaving(false);
                    resolve(false);
                });
        });
    };

    const handleChangeDecimalSeparator = async (decimal_separator: "," | ".") => {
        setIsDecimalRecompute(true);
        await changeDecimalSeparator(decimal_separator);
        setIsDecimalRecompute(false);
    };

    // item-level actions

    const handleRemove = async () => {
        setIsRemoving(true);
        try {
            await Backend.deleteItem({ item_uuid: init_item.uuid });
            if (onItemUpdate !== undefined) { onItemUpdate(); }
        } catch (err) {
            setErrorMessage("Error deleting the item");
        }
        setIsRemoving(false);
    };

    const handleRetry = async () => {
        navigate(`/extraction/new/${init_item.endpoint_uuid ?? init_item.template_uuid}/${init_item.uuid}`);
    }

    const handleReject = async () => {
        setIsRejecting(true);
        await BackendObj.extractions.updateExtractConfirmationItemStatus({
            confirmation_uuid: init_item.extract_confirmations_uuid as string,
            status: EXTRACT_CONFIRMATION_STATUS.rejected
        });
        setIsRejecting(false);
        if (onItemUpdate !== undefined) { onItemUpdate(); }
    };

    const handleConfirm = async () => {
        // first try to verify and save
        const can_confirm = is_verify ? await handleVerifyAndSave() : await saveScrapes(scrapes);
        if (!can_confirm) {
            setErrorMessage("Not all required values pass verification.");
            return;
        }
        // all ok, confirm and move on
        setIsConfirming(true);
        await BackendObj.extractions.updateExtractConfirmationItemStatus({
            confirmation_uuid: init_item.extract_confirmations_uuid as string,
            status: EXTRACT_CONFIRMATION_STATUS.confirmed
        });
        setIsConfirming(false);
        if (onItemUpdate !== undefined) { onItemUpdate(); }
    };

    const startCreateExample = () => {
        setIsNewExampleModalOpen(true);
    };

    const createExample = async (example_uuid: string) => {
        setIsNewExampleModalOpen(false);
        setItemExampleUuid(example_uuid);
    };

    // for now, only lookup tables are not editable
    const isScrapeEditable = (scrape: IScrapeBase) => {
        const scrape_context = scrapes.find(({ context }) => context.uuid === scrape.context_uuid);
        if (scrape_context === undefined) { return false; }
        return scrape_context.context.type !== "lookup_table";
    };

    const is_numeric = scrapes.some(({ context }) => context.fields.some(f => f.datatype === "number")) && decimal_separator !== undefined;
    const is_verify = scrapes.some(({ context }) => context.fields.some(f => f.validators)) ||
        scrapes.some(({ context }) => context.row_validators.length > 0) ||
        scrapes.some(({ context }) => context.context_validators.length > 0);

    const disable_buttons = is_confirming || is_rejecting || is_removing;

    const view_scrapes: {
        idx: number,
        type: "editable" | "view" | "hidden",
        scrape: IScrapeBase,
        context: IContextBase,
        show_rows: boolean,
        hidden_scrapes: {
            idx: number,
            scrape: IScrapeBase,
            context: IContextBase,
            show_rows: boolean
        }[]
    }[] = [];

    for (const [idx, { scrape, context }] of scrapes.entries()) {
        const show_rows = ["array", "lookup_table"].includes(context.type);
        if (context.extract_params.skip_on_confirm) {
            // check if first in a row of hidden scrapes
            if (view_scrapes.length > 0 && view_scrapes[view_scrapes.length - 1].type === "hidden") {
                view_scrapes[view_scrapes.length - 1].hidden_scrapes.push({ idx, scrape, context, show_rows });
            } else {
                view_scrapes.push({ idx, type: "hidden", scrape, context, show_rows, hidden_scrapes: [{ idx, scrape, context, show_rows }] });
            }
        } else {
            view_scrapes.push({ idx, type: isScrapeEditable(scrape) ? "editable" : "view", scrape, context, show_rows, hidden_scrapes: [] });
        }
    }

    return <div className="space-y-4">
        {init_item.details.retry_new_item_uuids !== undefined && init_item.details.retry_new_item_uuids.length > 0 &&
            <div className="p-4 border bg-torch_red-100 rounded text-gray-900 text-sm max-w-5xl">
                {init_item.details.retry_new_item_uuids.length === 1 && <p>
                    There is a <Link to={`/confirm/${init_item.details.retry_new_item_uuids[0]}`} className="text-space_blue-600 underline">new version</Link> of this extraction.
                </p>}
                {init_item.details.retry_new_item_uuids.length > 1 && <p>
                    There are {init_item.details.retry_new_item_uuids.length} new versions of this extraction: {init_item.details.retry_new_item_uuids.map((uuid, idx) =>
                        <Fragment key={idx}>{idx > 0 && ", "}<Link to={`/confirm/${uuid}`} className="text-space_blue-600 underline">version {idx + 1}</Link></Fragment>)}
                </p>}
            </div>}

        <div className="max-w-5xl">
            {validation_summary && <ValidationSummary summary={validation_summary} />}
        </div>

        <div className="flex flex-row items-center max-w-5xl">
            <Button icon={TrashIcon} text="Remove" disabled={disable_buttons} onClick={() => handleRemove()} loading={is_removing} />
            <Button icon={ArrowPathIcon} text="Retry" disabled={disable_buttons} onClick={() => setRetryConfirmOpen(true)} />
            {item_example_uuid === undefined && <Button icon={PlusCircleIcon} text="Report Error" disabled={disable_buttons} onClick={startCreateExample} />}
            {item_example_uuid !== undefined && <Button icon={PencilIcon} text="Report Error" disabled={disable_buttons} onClick={() => setIsEditExampleModalOpen(true)} />}

            <div className="flex-grow"></div>
            {is_changed && <p className="pr-2 text-gray-500 font-thin text-sm">(unsaved changes)</p>}
            {is_numeric && <div className="pr-2">
                <Dropdown
                    ids={[",", "."]}
                    values={["Decimal comma", "Decimal dot"]}
                    selected={decimal_separator}
                    disabled={is_decimal_recompute}
                    onChange={(id) => { setChangeDecimalSeparator(id as "," | "."); setDecimalConfirmOpen(true); }} />
            </div>}
            <Button text="Undo" highlight={false} disabled={!is_changed} onClick={handleUndo} />
            {!is_verify && <Button text="Save" disabled={!is_changed || is_saving} loading={is_saving} onClick={() => saveScrapes(scrapes)} />}
            {is_verify && <Button text="Save" disabled={!is_changed || is_saving} loading={is_saving} onClick={handleVerifyAndSave} />}
        </div>

        <div className="py-4">
            {view_scrapes.map(({ idx, type, scrape, context, show_rows, hidden_scrapes }, key_idx) => <Fragment key={key_idx}>
                <ProcessFlowHead label={type === "hidden" ? "..." : `Step ${idx + 1}`}>
                    {type !== "hidden" && <div className="flex flex-row items-center max-w-4xl gap-2">
                        <span className={classNames(
                            "flex flex-row items-center gap-x-2 text-sm font-semibold text-gray-900",
                            scrapes[idx]?.scrape.validations.length > 0 ?
                                (scrapes[idx]?.scrape.validations.every(v => v.status === "pass") ? "text-green-600 cursor-pointer" :
                                    scrapes[idx]?.scrape.validations.some(v => v.status === "warn") ? "text-candy_corn-600 cursor-pointer" :
                                        scrapes[idx]?.scrape.validations.some(v => v.status === "fail") ? "text-torch_red-600 cursor-pointer" :
                                            scrapes[idx]?.scrape.validations.some(v => v.status === "reject") ? "text-rose-600 cursor-pointer" :
                                                "text-gray-600 cursor-pointer") :
                                "")}
                            data-tooltip-id={`step-${idx}-validation-tooltip`}
                            data-tooltip-html={scrapes[idx]?.scrape.validations.map(v => `<p style="color: ${v.status === "pass" ? "green" : "red"};">${v.name}${v.reason ? `: ${v.reason}` : ""}</p>`).join("")}
                        >
                            {context.name}
                            {scrapes[idx]?.scrape.validations.length > 0 && scrapes[idx]?.scrape.validations.every(v => v.status === "pass") && <CheckIcon className="w-5 h-5 text-green-500" />}
                            {scrapes[idx]?.scrape.validations.length > 0 && scrapes[idx]?.scrape.validations.some(v => v.status !== "pass") && <XMarkIcon className="w-5 h-5 text-torch_red-500" />}
                        </span>

                        {is_admin && scrape.debug_log.length > 0 && (
                            <BugAntIcon
                                className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                                onClick={() => setFullScreenText(JSON.stringify(scrape.debug_log, null, 2))}
                            />
                        )}
                        {show_rows && <p className="text-gray-400 text-xs font-normal">{scrape.records.length} row{scrape.records.length === 1 ? "" : "s"}</p>}
                        {context.extract_params.skip_on_confirm && <p className="text-gray-400 text-xs font-normal">
                            details hidden
                        </p>}
                    </div>}
                    {type === "hidden" && <div className="flex flex-row items-center gap-x-2 text-sm">
                        {hidden_scrapes.map(({ idx, scrape, context, show_rows }, index) => (
                            <Fragment key={idx}>
                                <p className="text-gray-500">{index > 0 && "|"}</p>
                                <p className="font-semibold text-gray-900">
                                    {context.name}
                                </p>
                                {show_rows && <p className="text-gray-400 text-xs font-normal">{scrape.records.length} row{scrape.records.length === 1 ? "" : "s"}</p>}
                                {is_admin && scrape.debug_log.length > 0 && (
                                    <BugAntIcon
                                        className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                                        onClick={() => setFullScreenText(JSON.stringify(scrape.debug_log, null, 2))}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </div>}
                </ProcessFlowHead>
                <ProcessFlowBody show_scrollbar={false}>
                    {type === "editable" && <ScrapeTable key={idx}
                        scrape_idx={idx}
                        context={context}
                        scrape={scrape}
                        range_values={range_values}
                        setRecords={setRecords}
                        markChanged={() => setIsChanged(true)}
                    />}
                    {type === "view" && <ViewScrapeTableSimple
                        key={idx}
                        context={context}
                        scrape={scrape}
                        input_documents={init_item.documents}
                        show_all_fields={false}
                        confirmation_status={init_item.extract_confirmations_status}
                    />}
                </ProcessFlowBody>
                <FullScreenText
                    text={full_screen_text || ""}
                    show={full_screen_text !== undefined}
                    onClose={() => setFullScreenText(undefined)}
                />
                <Tooltip
                    id={`step-${idx}-validation-tooltip`}
                    place="bottom"
                    style={TOOLTIP_STYLE}
                    opacity={1} />
            </Fragment>)}
            <ProcessFlowHead label="Done" />
        </div>

        <ConfirmModal
            open={decimal_confirm_open}
            title="Change Decimal Separator"
            message={["Changing the decimal separator will reset all unsaved changes. Do you want to continue?"]}
            confirm="Change"
            cancel="Cancel"
            onClose={confirm => {
                setDecimalConfirmOpen(false);
                if (confirm && change_decimal_separator) {
                    handleChangeDecimalSeparator(change_decimal_separator);
                } else {
                    setChangeDecimalSeparator(undefined);
                }
            }} />
        <ConfirmModal
            open={retry_confirm_open}
            title="Retry Extraction"
            message={["Retrying will delete the current extraction and create a new one. Neither reject nor confirm will be called on the deleted extraction."]}
            confirm="Retry"
            cancel="Cancel"
            onClose={confirm => {
                setRetryConfirmOpen(false);
                if (confirm) {
                    handleRetry();
                }
            }} />
        {init_item && <NewExampleModal
            open={is_new_example_modal_open}
            item_uuid={init_item.uuid}
            template_uuid={init_item.template_uuid}
            onUpdateExample={createExample}
            onClose={() => setIsNewExampleModalOpen(false)} />}
        {item_example_uuid && <EditExampleModal
            open={is_edit_example_modal_open}
            example_item_uuid={item_example_uuid}
            template_uuid={init_item.template_uuid}
            onClose={() => setIsEditExampleModalOpen(false)} />}
        <TimeoutErrorMessageBar
            message={error_message}
            clearMessage={() => setErrorMessage(undefined)}
            is_sidebar_large_override={false} />
    </div >;
});

type ViewObjectScrapeTableProps = {
    context: IContextBase,
    scrape: IScrapeBase,
    show_validation_results: boolean,
    show_all_fields: boolean
}

function ViewObjectScrapeTable(props: ViewObjectScrapeTableProps) {
    const { context, scrape, show_validation_results, show_all_fields } = props;

    const user = useSelector(selectUser);

    const fields = show_all_fields ? context.fields.map(f => ({
        title: f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type,
        datatype: f.datatype
    })) : context.fields.filter(f => !f.skip_on_confirm).map(f => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type,
        datatype: f.datatype
    }));

    const record_val: IRecordRaw = scrape.records[0]?.val || {}
    const confidence: IConfidence = scrape.records[0]?.confidence || {}
    const fields_validations: IValidations = scrape.records[0]?.fields_validations || {}

    return <div className="px-1 outer-div">
        <table className="py-4">
            <tbody>
                {fields.map(({ title, field_name, type, datatype }, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top",
                        type === "compute" ? "italic" : ""
                    )}>
                        {title}
                    </td>
                    <td title={`Confidence: ${field_name && confidence[field_name] ? prettyNumber(confidence[field_name], 2, undefined, user.number_format_locale) : "N/A"}`}
                        className={classNames("w-1 border-l border-t border-b cursor-pointer",
                            field_name && confidence[field_name] ?
                                (confidence[field_name] < 0.5 ? "bg-torch_red-100" :
                                    confidence[field_name] < 0.8 ? "bg-candy_corn-100" :
                                        "bg-gray-100") :
                                "bg-gray-50",
                        )}></td>
                    {field_name === undefined && <td className="border-t border-b border-r py-1 px-2"></td>}
                    {field_name !== undefined && <td
                        className={classNames(
                            "border-t border-b border-r py-1 px-2 min-w-[100px] overflow-hidden hover:bg-sea_blue-100 text-left text-sm align-top cursor-text",
                            (show_validation_results && field_name && fields_validations[field_name]) ?
                                (fields_validations[field_name].every(v => v.status === "pass") ? "text-green-600" :
                                    fields_validations[field_name].some(v => v.status === "reject") ? "text-rose-600" :
                                        fields_validations[field_name].some(v => v.status === "fail") ? "text-torch_red-600" :
                                            fields_validations[field_name].some(v => v.status === "warn") ? "text-candy_corn-600" :
                                                "") :
                                ""
                        )}
                    >
                        <FieldValueRender value={primitiveToStr(record_val[field_name] ?? "", datatype, user.number_format_locale)} />
                    </td>}
                </tr>)}
            </tbody>
        </table>
    </div>
}

type ViewArrayScrapeTableProps = {
    context: IContextBase,
    scrape: IScrapeBase,
    show_validation_results: boolean,
    show_all_fields: boolean
}

function ViewArrayScrapeTable(props: ViewArrayScrapeTableProps) {
    const { context, scrape, show_validation_results, show_all_fields } = props;

    const user = useSelector(selectUser);

    const columns = show_all_fields ? context.fields.map(f => ({
        title: f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type,
        datatype: f.datatype
    })) : context.fields.filter(f => !f.skip_on_confirm).map(f => ({
        title: f.confirm_name ?? f.name,
        field_name: scrape.field_name_uuid_pairs.find(p => p.uuid === f.uuid)?.name ?? undefined,
        type: f.type,
        datatype: f.datatype
    }));

    return <div className="px-1 outer-div">
        <table className="py-4">
            <thead>
                <tr>
                    <th className="min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {columns.map((column, idx) => <th key={idx} className={classNames(
                        "py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top",
                        column.type === "compute" ? "italic" : ""
                    )} colSpan={2}>
                        {column.title}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {scrape.records.map(({ val, confidence, fields_validations }, row_idx) => <tr key={row_idx}>
                    <td className="py-1 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {columns.map((column, col_idx) => <Fragment key={col_idx}>
                        {column.field_name !== undefined && <td title={`Confidence: ${confidence && confidence[column.field_name] ? prettyNumber(confidence[column.field_name], 2, undefined, user.number_format_locale) : "N/A"}`}
                            className={classNames("w-1 border-l border-t border-b cursor-pointer",
                                confidence && confidence[column.field_name] ?
                                    (confidence[column.field_name] < 0.5 ? "bg-torch_red-100" :
                                        confidence[column.field_name] < 0.8 ? "bg-candy_corn-100" :
                                            "bg-gray-100") :
                                    "bg-gray-50",
                            )}></td>}
                        {column.field_name === undefined && <td className="w-1 border-l border-t border-b bg-gray-50"></td>}

                        {column.field_name !== undefined && <td
                            className={classNames(
                                "border-t border-b border-r py-1 px-2 min-w-[100px] max-w-[200px] overflow-hidden hover:bg-sea_blue-100 text-left text-sm align-top cursor-text",
                                (show_validation_results && fields_validations && fields_validations[column.field_name]) ?
                                    (fields_validations[column.field_name].every(v => v.status === "pass") ? "text-green-600" :
                                        fields_validations[column.field_name].some(v => v.status === "reject") ? "text-rose-600" :
                                            fields_validations[column.field_name].some(v => v.status === "fail") ? "text-torch_red-600" :
                                                fields_validations[column.field_name].some(v => v.status === "warn") ? "text-candy_corn-600" : "") :
                                    ""
                            )}
                        >
                            <FieldValueRender value={primitiveToStr(val[column.field_name] ?? "", column.datatype, user.number_format_locale)} />
                        </td>}

                        {column.field_name === undefined && <td className="border-t border-b border-r py-1 px-2 min-w-[100px] max-w-[200px] hover:bg-sea_blue-100 text-left text-sm align-top cursor-text"></td>}
                    </Fragment>)}
                </tr>)}
            </tbody>
        </table>
    </div>
}

function HierarchicalRecordFieldQuote(props: { quote: string, input_lines: string[] }) {
    const { quote, input_lines } = props;

    const lines: number[] = [];
    if (/^L\d+$/.test(quote)) {
        // format "L123" return [123]
        lines.push(parseInt(quote.slice(1)));
    } else if (/^L\d+-L\d+$/.test(quote)) {
        // format "L123-L125" return [123, 124, 125]
        const [start, end] = quote.split("-").map(s => parseInt(s.slice(1)));
        lines.push(...Array.from({ length: end - start + 1 }, (_, i) => start + i));
    } else if (/^L\d+(, L\d+)*$/.test(quote)) {
        // format "L123, L125, ... L130" return [123, 125, ..., 130]
        lines.push(...quote.slice(1).split(", ").map(s => parseInt(s)));
    }

    // get identified lines from input lines
    const identified_lines = lines.map(l => input_lines[l] || "");
    // prepare tooltip (cannot use tailwind)
    const tooltip = `<p style="padding-left: 1rem; border-left-width: 2px; color: #111827; border-color: #4b5563">${identified_lines.join("\n<br/>")}</p>`;

    return lines.length > 0 ? <TbQuote
        className="ml-1 w-4 h-4 text-space_blue-500 cursor-context-menu"
        data-tooltip-id="quote-tooltip"
        data-tooltip-html={tooltip} /> :
        <Fragment />
}

type HierarchicalRecordProps = {
    val: any;
    input_documents: IScrapeDocument[];
    show_all: boolean;
}

export function HierarchicalRecord(props: HierarchicalRecordProps) {
    const { val, input_documents, show_all } = props;

    const input_lines = input_documents.flatMap(d => d.pages).flatMap(p => p.text.split("\n"));

    const has_reference_line_number = (val: any) => {
        return typeof val === "object" && val.hasOwnProperty("reference_line_number");
    }

    const is_empty = (val: any) => {
        if (show_all) { return false; }
        if (val === undefined || val === null) { return true; }
        if (val === "N/A") { return true; }
        if (typeof val === "string" && val.trim() === "") { return true; }
        if (Array.isArray(val) && val.length === 0) { return true; }
        if (typeof val === "object") {
            if (Object.keys(val).length === 0) { return true; }
            if (Object.values(val).every(v => is_empty(v))) { return true; }
        }
        return false;
    }

    if (Array.isArray(val)) {
        return <div className="pl-8">
            <table>
                <tbody>
                    {val.map((val, idx) => <tr key={idx} className="">
                        <td className="pt-3 font-bold text-sm align-top flex flex-row">
                            {idx + 1}
                            {has_reference_line_number(val) && <HierarchicalRecordFieldQuote quote={val.reference_line_number} input_lines={input_lines} />}
                        </td>
                        <td className="pt-2 pl-4 text-sm align-top">
                            <HierarchicalRecord val={val} input_documents={input_documents} show_all={show_all} />
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </div>;
    }

    if (typeof val === "object") {
        // split keys into simple key-value pairs and nested objects
        const simple_keys = Object.keys(val)
            .filter(k => typeof val[k] === "string" && k !== "reference_line_number")
            .filter(k => val[k] !== "N/A");
        const nested_keys = Object.keys(val)
            .filter(k => typeof val[k] !== "string")
            .filter(k => !is_empty(val[k]));

        return <div className="pl-8">
            {simple_keys.length > 0 && <table>
                <tbody>
                    {simple_keys.map((key, idx) => <tr key={idx} className="">
                        <td className="pt-2 font-bold text-sm  align-top">{key}</td>
                        <td className="pt-2 pl-4 text-sm  align-top">{val[key] === "N/A" ? "/" : val[key]}</td>
                    </tr>)}
                </tbody>
            </table>}
            {nested_keys.length > 0 && <ul className="">
                {nested_keys.map((key, idx) => <li key={idx} className="pt-1">
                    <div className="flex flex-row">
                        <span className="font-bold text-sm">{key}</span>
                        {has_reference_line_number(val[key]) && <HierarchicalRecordFieldQuote quote={val[key].reference_line_number} input_lines={input_lines} />}
                    </div>
                    <HierarchicalRecord val={val[key]} input_documents={input_documents} show_all={show_all} />
                </li>)}
            </ul>}
        </div>;
    }

    return <li className="pl-4">
        <p>{val}</p>
    </li>;
}

type ViewHierarchicalScrapeTableProps = {
    context: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
}

function ViewHierarchicalScrapeTable(props: ViewHierarchicalScrapeTableProps) {
    const { context, scrape, input_documents } = props;

    const { context_schema, array_placeholders } = getHierarchicalContextSchema(context.fields);
    const flat_record_val = scrape.records ? scrape.records[0].val : {};
    const { record } = getHierarchicalRecord(flat_record_val, context_schema, array_placeholders);

    return <div className="px-1 max-w-3xl">
        <HierarchicalRecord val={record} input_documents={input_documents} show_all={false} />
        <Tooltip
            id="quote-tooltip"
            place="bottom"
            style={TOOLTIP_STYLE}
            opacity={1} />
    </div>;
}

type ViewScrapeTableSimpleProps = {
    context: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
    show_all_fields: boolean;
    confirmation_status?: string;
}

function ViewScrapeTableSimple(props: ViewScrapeTableSimpleProps) {
    const { context, scrape, input_documents, show_all_fields, confirmation_status } = props;

    const is_array = context.type === CONTEXT_TYPES.array;
    const is_object = context.type === CONTEXT_TYPES.object;
    const is_hierarchical = context.type === CONTEXT_TYPES.hierarchical;
    const is_lookup_table = context.type === CONTEXT_TYPES.lookup_table;

    return <Fragment>
        {is_object && <ViewObjectScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
        {is_array && <ViewArrayScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
        {is_hierarchical && <ViewHierarchicalScrapeTable
            context={context}
            scrape={scrape}
            input_documents={input_documents} />}
        {is_lookup_table && <ViewArrayScrapeTable
            context={context}
            scrape={scrape}
            show_validation_results={confirmation_status !== EXTRACT_CONFIRMATION_STATUS.confirmed}
            show_all_fields={show_all_fields} />}
    </Fragment>
}

type ViewScrapeTableProps = {
    context_idx: number;
    context?: IContextBase;
    scrape: IScrapeBase;
    input_documents: IScrapeDocument[];
    confirmation_status?: string;
    confirmation_log?: IExtractConfirmationLog[];
}

export function ViewScrapeTable(props: ViewScrapeTableProps) {
    const { context_idx, context, scrape, input_documents, confirmation_status, confirmation_log } = props;
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [show_all_fields, setShowAllFields] = useState(false);
    const [show_history, setShowHistory] = useState(false);
    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);

    if (!context) {
        return null;
    }

    const is_array = context.type === CONTEXT_TYPES.array;
    const is_object = context.type === CONTEXT_TYPES.object;
    const is_lookup_table = context.type === CONTEXT_TYPES.lookup_table;
    // check if at least one field is either hidden or has different title on confirmation screen
    const is_view_confirmed_diff = context.fields.some(f => f.skip_on_confirm === true || f.confirm_name !== undefined);

    // prepare history log scrapes if we have any
    const is_history = confirmation_log !== undefined && confirmation_log.length > 0;
    // order from oldest to newest
    const history_scrapes: IScrapeBase[] = [];
    if (is_history) {
        confirmation_log.sort((a, b) => a.created_at - b.created_at);
        history_scrapes.push({
            uuid: scrape.uuid,
            context_uuid: context.uuid,
            field_name_uuid_pairs: scrape.field_name_uuid_pairs,
            input_item_uuid: scrape.input_item_uuid,
            records: confirmation_log[0].old_records,
            validations: [],
            created_at: scrape.created_at,
            extraction_info: scrape.extraction_info,
            debug_log: scrape.debug_log
        });
        for (const log of confirmation_log) {
            history_scrapes.push({
                uuid: scrape.uuid,
                context_uuid: context.uuid,
                field_name_uuid_pairs: scrape.field_name_uuid_pairs,
                input_item_uuid: scrape.input_item_uuid,
                records: log.new_records,
                validations: [],
                created_at: log.created_at,
                extraction_info: scrape.extraction_info,
                debug_log: scrape.debug_log
            });
        }
    }

    return <Fragment>
        <ProcessFlowHead label={`Step ${context_idx + 1}`}>
            <div className="flex flex-row items-center max-w-4xl gap-2">
                <span className="text-sm font-semibold text-gray-900">{context.name}</span>
                {is_admin && scrape.debug_log.length > 0 && (
                    <BugAntIcon
                        className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                        onClick={() => setFullScreenText(JSON.stringify(scrape.debug_log, null, 2))}
                    />
                )}
                {context?.type !== "object" && <p className="text-gray-400 text-xs font-normal">{scrape.records.length} row{scrape.records.length === 1 ? "" : "s"}</p>}
                <div className="grow" />
                <div className="flex flex-row items-center px-6 gap-x-4">
                    {(is_view_confirmed_diff || context?.extract_params.skip_on_confirm) && <div className="flex flex-row items-center font-normal text-sm">
                        <Checkbox checked={show_all_fields} setChecked={setShowAllFields} id={`${context.uuid}show_all_fields`} />
                        <label htmlFor={`${context.uuid}show_all_fields`} className="ml-2">Show all</label>
                    </div>}
                    {is_history && <Button icon={BookOpenIcon} text="History" onClick={() => setShowHistory(true)} />}
                </div>
            </div>
        </ProcessFlowHead>

        <ProcessFlowBody show_scrollbar={false}>
            {(context?.extract_params.skip_on_confirm === false || show_all_fields) && <Fragment>
                {scrape.records.length === 0 && context?.extract_params.ok_to_be_empty === false && context?.type !== "classifier" &&
                    <div className="mb-4 mx-2 p-4 border bg-gray-100 rounded text-gray-900 text-sm">
                        <p className="pb-2">We were unable to extract any data for template <span className="font-semibold">{context.name}</span>.</p>
                        <p className="pb-1">Potential reasons for this:</p>
                        <ul className="list-disc list-inside">
                            <li className="pb-1/2">The input didn't contain any relevant information about the template.</li>
                            <li>Email had attachments in a format that our current extraction algorithms cannot process.</li>
                        </ul>
                    </div>}

                <ViewScrapeTableSimple
                    context={context}
                    scrape={scrape}
                    input_documents={input_documents}
                    show_all_fields={show_all_fields}
                    confirmation_status={confirmation_status}
                />
            </Fragment>}

            <FullScreen show={show_history} onClose={() => setShowHistory(false)}>
                <div className="p-4">
                    <div className="font-bold text-lg leading-6">
                        History of changes
                    </div>
                    {history_scrapes.map((scrape, idx) => <div key={idx} className="mb-4">
                        <div className="pt-8 pb-4">
                            <span className="text-gray-600 text-sm">{prettyDateTime(scrape.created_at)}</span>
                            <span className="ml-4 font-semibold text-gray-600 text-sm">
                                {idx === 0 ? "(Extracted)" : (idx === history_scrapes.length - 1 ? "(Current)" : "")}
                            </span>
                        </div>
                        {is_object && <ViewObjectScrapeTable
                            context={context}
                            scrape={scrape}
                            show_validation_results={true}
                            show_all_fields={show_all_fields} />}
                        {is_array && <ViewArrayScrapeTable
                            context={context}
                            scrape={scrape}
                            show_validation_results={true}
                            show_all_fields={show_all_fields} />}
                        {is_lookup_table && <ViewArrayScrapeTable
                            context={context}
                            scrape={scrape}
                            show_validation_results={true}
                            show_all_fields={show_all_fields} />}
                    </div>)}
                </div>
            </FullScreen>
        </ProcessFlowBody>
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)}
        />
    </Fragment>;
}

type ViewItemTablesProps = {
    item: IItem;
};

export function ViewItemTables(props: ViewItemTablesProps) {
    const { item } = props;

    // put scrapes and contexts together in pairs
    const scrapes: { scrape: IScrapeBase, context: IContextBase }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context !== undefined) {
            scrapes.push({ scrape, context });
        }
    }

    // order scrapes so we first have any single-row scrapes, then any multi-row scrapes
    scrapes.sort((a, b) => a.context.weight_score - b.context.weight_score);

    return <div className="pl-6">
        {scrapes.map((scrape, i) => <ViewScrapeTable key={i}
            context_idx={i}
            context={scrape.context} scrape={scrape.scrape}
            input_documents={item.documents}
            confirmation_status={item.extract_confirmations_status}
            confirmation_log={item.extract_confirmation_log !== undefined ?
                item.extract_confirmation_log.filter(ecl => ecl.scrape_uuid === scrape.scrape.uuid) : undefined}
        />)}
        <ProcessFlowHead label="Done" />
    </div >;
}

type DemoTableProps = {
    fields: IContextField[];
    records: IRecord[];
}

export function DemoTable(props: DemoTableProps) {
    const { fields, records } = props;

    return <div>
        <div className="px-1 overflow-x-auto"><table className="py-4">
            <thead>
                <tr>
                    <th className="w-6 bg-gray-100 border border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className="py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {records.map(({ val: record }, row_idx) => <tr key={row_idx}>
                    <td className="py-1 px-2 bg-gray-100 border border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {fields.map((field, col_idx) => <td
                        key={col_idx}
                        className="py-1 px-2 min-w-[100px] max-w-[200px] border bg-white text-left text-sm align-top cursor-text">
                        {record[field.name]}
                    </td>)}
                </tr>)}
            </tbody>
        </table>
        </div>
    </div>;
}

type RenderFieldVals = {
    field_type: IContextFieldType,
    field_name: string,
    field_val: IPrimitiveType,
    field_validations: IValidationResult[],
    field_datatype: IContextFieldDataType
};

type ExampleObjectScrapeTableProps = {
    fields: IContextField[];
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
    show_full_tables: boolean;
}

function ExampleObjectScrapeTable(props: ExampleObjectScrapeTableProps) {
    const { fields, records, field_name_uuid_pairs, show_full_tables } = props;

    const user = useSelector(selectUser);

    // prepare field uuid => name map
    const field_uuid_name_map: { [key: string]: string } = {};
    field_name_uuid_pairs.forEach(p => field_uuid_name_map[p.uuid] = p.name);

    const record_val: IRecordRaw = records[0]?.val || {}
    const record_fields_validations: IValidations = records[0]?.fields_validations || {}

    // prepare field type => name => value map
    const field_vals: RenderFieldVals[] = [];
    for (const field of fields) {
        field_vals.push({
            field_type: field.type,
            field_name: field.name,
            field_val: record_val[field_uuid_name_map[field.uuid]] ?? record_val[field.name] ?? "",
            field_validations: record_fields_validations[field_uuid_name_map[field.uuid]] ?? record_fields_validations[field.name] ?? [],
            field_datatype: field.datatype
        });
    }

    return <div className={classNames("max-w-4xl pr-6 outer-div  border-gray-200", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="border-l border-t">
            <tbody>
                {field_vals.map(({ field_type, field_name, field_val, field_validations, field_datatype }, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border-r border-b  border-gray-200 text-left text-sm font-semibold align-top w-[250px]",
                        field_type === "compute" ? "italic" : ""
                    )}>
                        {field_name}
                    </td>
                    <td className={classNames(
                        "border-b border-r py-1 px-2 min-w-[200px] max-w-[350px] overflow-x-auto overflow-y-hidden text-left text-sm align-top",
                        field_validations.length > 0 ? (field_validations.every(v => v.status === "pass") ? "text-green-600" :
                            field_validations.some(v => v.status === "warn") ? "text-candy_corn-600" :
                                field_validations.some(v => v.status === "fail") ? "text-torch_red-600" :
                                    field_validations.some(v => v.status === "reject") ? "text-rose-600" : "") : "",
                        field_validations.some(v => v.status === "fail") ? "bg-torch_red-50" :
                            field_validations.some(v => v.status === "reject") ? "bg-rose-50" :
                                "bg-white"
                    )}>
                        <FieldValueRender value={primitiveToStr(field_val, field_datatype, user.number_format_locale)} />
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleArrayScrapeTableProps = {
    fields: IContextField[];
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
    show_full_tables: boolean;
}

function ExampleArrayScrapeTable(props: ExampleArrayScrapeTableProps) {
    const { fields, records, field_name_uuid_pairs, show_full_tables } = props;

    const user = useSelector(selectUser);

    // prepare field uuid => name map
    const field_uuid_name_map: { [key: string]: string } = {};
    field_name_uuid_pairs.forEach(p => field_uuid_name_map[p.uuid] = p.name);

    // prepare field type => name => value map
    const records_field_vals: RenderFieldVals[][] = [];
    for (const record of records) {
        const record_field_vals: RenderFieldVals[] = [];
        for (const field of fields) {
            record_field_vals.push({
                field_type: field.type,
                field_name: field.name,
                field_val: record.val[field_uuid_name_map[field.uuid]] ?? record.val[field.name] ?? "",
                field_validations: record.fields_validations?.[field_uuid_name_map[field.uuid]] ?? record.fields_validations?.[field.name] ?? [],
                field_datatype: field.datatype
            });
        }
        records_field_vals.push(record_field_vals);
    }

    return <div className={classNames("outer-div", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="border-l border-t border-gray-200">
            <thead>
                <tr>
                    <th className="sticky left-0 top-0 z-11 min-w-[20px] border-r border-b bg-gray-100 border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className={classNames(
                        "sticky top-0 z-10 py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border-r border-b border-gray-200 text-left text-sm font-semibold align-top",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {records_field_vals.map((record_field_vals: RenderFieldVals[], row_idx) => <tr key={row_idx}>
                    <td className="sticky left-0 z-9 py-1 px-2 border-r border-b bg-gray-100 border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    {record_field_vals.map(({ field_val, field_validations, field_datatype }, col_idx) => <td key={col_idx} className={classNames(
                        "py-1 px-2 min-w-[100px] max-w-[200px] border-r border-b text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden",
                        field_validations.length > 0 ? (field_validations.every(v => v.status === "pass") ? "text-green-600" :
                            field_validations.some(v => v.status === "reject") ? "text-rose-600" :
                                field_validations.some(v => v.status === "fail") ? "text-torch_red-600" :
                                    field_validations.some(v => v.status === "warn") ? "text-candy_corn-600" : "") : "",
                        field_validations.some(v => v.status === "fail") ? "bg-torch_red-50" :
                            field_validations.some(v => v.status === "reject") ? "bg-rose-50" :
                                "bg-white"
                    )}>
                        <FieldValueRender value={primitiveToStr(field_val, field_datatype, user.number_format_locale)} />
                    </td>)}
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleScrapeTableProps = {
    fields: IContextField[];
    context_type: ContextType;
    records: IRecord[];
    field_name_uuid_pairs: IFieldNameUuidPair[],
    show_full_tables: boolean;
}

export function ExampleScrapeTable(props: ExampleScrapeTableProps) {
    const { fields, context_type, records, field_name_uuid_pairs, show_full_tables } = props;

    return <div className="px-1 py-4">
        {["object"].includes(context_type) && <ExampleObjectScrapeTable fields={fields} records={records} field_name_uuid_pairs={field_name_uuid_pairs} show_full_tables={show_full_tables} />}
        {["array", "lookup_table"].includes(context_type) && <ExampleArrayScrapeTable fields={fields} records={records} field_name_uuid_pairs={field_name_uuid_pairs} show_full_tables={show_full_tables} />}
    </div >;
}

type ExampleItemTablesProps = {
    template: ITemplate;
    item: IItemBase;
    show_full_tables: boolean;
};

export function ExampleItemTables(props: ExampleItemTablesProps) {
    const { template, item, show_full_tables } = props;
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [full_screen_text, setFullScreenText] = useState<string | undefined>(undefined);

    const scrapes: {
        scrape: IScrapeBase,
        context_name: string,
        context_type: ContextType,
        context_weight_score: number,
        fields: IContextField[]
    }[] = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = template.contexts.find(c => c.uuid === scrape.context_uuid);
        if (context !== undefined && !context.extract_params.skip_on_confirm) {
            scrapes.push({
                scrape,
                context_name: context.name,
                context_type: context.type,
                context_weight_score: context.weight_score,
                fields: context.fields.filter(f => f.skip_on_confirm !== true)
            });
        }
    }
    scrapes.sort((a, b) => a.context_weight_score - b.context_weight_score);

    return <Fragment>
        {scrapes.map(({ scrape, context_name, context_type, fields }, i) => <div className="pt-4" key={i}>
            {context_name.length > 0 && <div className="px-2 font-medium text-sm flex items-center gap-2">
                {context_name}
                {is_admin && scrape.debug_log.length > 0 && (
                    <BugAntIcon
                        className="h-4 w-4 text-gray-500 cursor-pointer hover:text-gray-700"
                        onClick={() => setFullScreenText(JSON.stringify(scrape.debug_log, null, 2))}
                    />
                )}
            </div>}
            <ExampleScrapeTable key={i}
                fields={fields}
                context_type={context_type}
                records={scrape.records}
                field_name_uuid_pairs={scrape.field_name_uuid_pairs}
                show_full_tables={show_full_tables}
            />
        </div>)}
        <FullScreenText
            text={full_screen_text || ""}
            show={full_screen_text !== undefined}
            onClose={() => setFullScreenText(undefined)}
        />
    </Fragment >;
}

type ExampleCellDiffProps = {
    field: IContextField;
    diff: IScrapeEvalDiff;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
}

function ExampleCellDiff(props: ExampleCellDiffProps) {
    const { field, diff, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs } = props;

    const user = useSelector(selectUser);
    // prepare field uuid => name map
    const old_field_name: string = old_field_name_uuid_pairs.find(p => p.uuid === field.uuid)?.name ?? field.name;
    const new_field_name: string = new_field_name_uuid_pairs.find(p => p.uuid === field.uuid)?.name ?? field.name;

    // first try to find the field by the uuid, then by the name
    const field_diff = diff.diff?.find(d => d.prop === new_field_name) ?? diff.diff?.find(d => d.prop === field.name);

    if (diff.type === "extra") {
        const record = new_records[diff.idx_new].val;
        const val = record[new_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-mint-100 text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
            <FieldValueRender value={primitiveToStr(val, field.datatype, user.number_format_locale)} />
        </td>;
    }
    if (diff.type === "missing") {
        const record = old_records[diff.idx_old].val;
        const val = record[old_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-torch_red-100 line-through text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
            <FieldValueRender value={primitiveToStr(val, field.datatype, user.number_format_locale)} />
        </td>;
    }
    if (diff.type === "exact") {
        const record = new_records[diff.idx_new].val;
        const val = record[new_field_name] ?? record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-white text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
            <FieldValueRender value={primitiveToStr(val, field.datatype, user.number_format_locale)} />
        </td>;
    }
    if (diff.type === "approx") {
        const old_record = old_records[diff.idx_old].val;
        const old_val = old_record[old_field_name] ?? old_record[field.name];
        const new_record = new_records[diff.idx_new].val;
        const new_val = new_record[new_field_name] ?? new_record[field.name];
        return <td className="py-1 px-2 min-w-[200px] max-w-[350px] border-r border-b bg-white text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
            {field_diff !== undefined ?
                <Fragment>
                    {new_val !== undefined && <span className="bg-mint-100">
                        <FieldValueRender value={primitiveToStr(new_val, field.datatype, user.number_format_locale)} />
                    </span>}
                    {old_val !== undefined && new_val !== undefined && <span>&nbsp;/&nbsp;</span>}
                    {old_val !== undefined && <span className="bg-torch_red-100 line-through">
                        <FieldValueRender value={primitiveToStr(old_val, field.datatype, user.number_format_locale)} />
                    </span>}
                </Fragment> :
                <FieldValueRender value={primitiveToStr(new_record[field.name] ?? "", field.datatype, user.number_format_locale)} />}
        </td>;
    }

    return null;
}

type ExampleDiffObjectScrapeTableProps = {
    fields: IContextField[];
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
    show_full_tables: boolean;
}

export function ExampleDiffObjectScrapeTable(props: ExampleDiffObjectScrapeTableProps) {
    const { fields, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs, show_full_tables } = props;

    return <div className={classNames("max-w-4xl pr-6 outer-div  border-gray-200", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="border-l border-t">
            <tbody>
                {fields.map((field, idx) => <tr key={idx}>
                    <td className={classNames(
                        "py-1 px-2  bg-gray-100 border-r border-b  border-gray-200 text-left text-sm font-semibold align-top w-[250px]",
                        field.type === "compute" ? "italic" : ""
                    )}>
                        {field.name}
                    </td>
                    {diffs.map((diff, idx) => <ExampleCellDiff
                        key={idx}
                        field={field}
                        diff={diff}
                        old_records={old_records}
                        old_field_name_uuid_pairs={old_field_name_uuid_pairs}
                        new_records={new_records}
                        new_field_name_uuid_pairs={new_field_name_uuid_pairs} />)}
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleRowDiffProps = {
    fields: IContextField[];
    diff: IScrapeEvalDiff;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
}

function ExampleRowDiff(props: ExampleRowDiffProps) {
    const { fields, diff, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs } = props;

    const user = useSelector(selectUser);

    // prepare field uuid => name map
    const old_field_uuid_name_map: { [key: string]: string } = {};
    old_field_name_uuid_pairs.forEach(p => old_field_uuid_name_map[p.uuid] = p.name);
    const new_field_uuid_name_map: { [key: string]: string } = {};
    new_field_name_uuid_pairs.forEach(p => new_field_uuid_name_map[p.uuid] = p.name);

    if (diff.type === "extra") {
        const record = new_records[diff.idx_new].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] max-w-[200px] border-r border-b bg-mint-100 text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
                    <FieldValueRender value={primitiveToStr(record[new_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? "", field.datatype, user.number_format_locale)} />
                </td>)}
        </Fragment>;
    }
    if (diff.type === "missing") {
        const record = old_records[diff.idx_old].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] max-w-[200px] border-r border-b bg-torch_red-100 line-through text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
                    <FieldValueRender value={primitiveToStr(record[old_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? "", field.datatype, user.number_format_locale)} />
                </td>)}
        </Fragment>;
    }
    if (diff.type === "exact") {
        const record = new_records[diff.idx_new].val;
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] max-w-[200px] border-r border-b bg-white text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
                    <FieldValueRender value={primitiveToStr(record[new_field_uuid_name_map[field.uuid]] ?? record[field.name] ?? "", field.datatype, user.number_format_locale)} />
                </td>)}
        </Fragment>;
    }
    if (diff.type === "approx") {
        const old_record = old_records[diff.idx_old].val;
        const new_record = new_records[diff.idx_new].val;
        const diff_fields = diff.diff ? diff.diff.map(d => d.prop) : [];
        return <Fragment>
            {fields.map((field, col_idx) =>
                <td key={col_idx} className="py-1 px-2 min-w-[100px] max-w-[200px] border-r border-b bg-white text-left text-sm align-top cursor-text overflow-x-auto overflow-y-hidden">
                    {diff_fields.includes(new_field_uuid_name_map[field.uuid] ?? field.name) ?
                        <Fragment>
                            {(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]) !== undefined &&
                                <span className="bg-mint-100">
                                    <FieldValueRender value={primitiveToStr(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name], field.datatype, user.number_format_locale)} />
                                </span>}
                            {(new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name]) !== undefined &&
                                (old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]) !== undefined && <span>&nbsp;/&nbsp;</span>}
                            {(old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name]) !== undefined &&
                                <span className="bg-torch_red-100 line-through">
                                    <FieldValueRender value={primitiveToStr(old_record[old_field_uuid_name_map[field.uuid]] ?? old_record[field.name], field.datatype, user.number_format_locale)} />
                                </span>}
                        </Fragment> :
                        (new_record[new_field_uuid_name_map[field.uuid]] ?? new_record[field.name] ?? "")}
                </td>)}
        </Fragment>;
    }

    return null;
}

type ExampleDiffArrayScrapeTableProps = {
    fields: IContextField[];
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[],
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
    show_full_tables: boolean;
}

export function ExampleDiffArrayScrapeTable(props: ExampleDiffArrayScrapeTableProps) {
    const { fields, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs, show_full_tables } = props;

    return <div className={classNames("outer-div", show_full_tables ? "" : "max-h-[500px]")}>
        <table className="border-l border-t border-gray-200">
            <thead>
                <tr>
                    <th className="sticky left-0 top-0 z-11 min-w-[20px] bg-gray-100 border border-gray-200"></th>
                    {fields.map((field, idx) => <th key={idx} className="sticky top-0 z-10 py-1 px-2 min-w-[100px] max-w-[200px] bg-gray-100 border-r border-b border-gray-200 text-left text-sm font-semibold align-top">
                        {field.name}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {diffs.map((diff, row_idx) => <tr key={row_idx}>
                    <td className="sticky left-0 z-9 py-1 px-2 border-r border-b bg-gray-100 border-gray-200 text-left text-sm font-semibold align-top">{row_idx + 1}</td>
                    <ExampleRowDiff
                        fields={fields}
                        diff={diff}
                        old_records={old_records}
                        old_field_name_uuid_pairs={old_field_name_uuid_pairs}
                        new_records={new_records}
                        new_field_name_uuid_pairs={new_field_name_uuid_pairs} />
                </tr>)}
            </tbody>
        </table>
    </div>;
}

type ExampleDiffScrapeTableProps = {
    fields: IContextField[];
    context_type: ContextType;
    old_records: IRecord[];
    old_field_name_uuid_pairs: IFieldNameUuidPair[];
    new_records: IRecord[];
    new_field_name_uuid_pairs: IFieldNameUuidPair[];
    diffs: IScrapeEvalDiff[];
    show_full_tables: boolean;
}

export function ExampleDiffScrapeTable(props: ExampleDiffScrapeTableProps) {
    const { fields, context_type, old_records, old_field_name_uuid_pairs, new_records, new_field_name_uuid_pairs, diffs, show_full_tables } = props;

    const is_object = context_type === CONTEXT_TYPES.object && (0 <= diffs.length && diffs.length <= 2);
    const is_array = (context_type === CONTEXT_TYPES.object && diffs.length > 2) || ["array", "lookup_table"].includes(context_type);

    return <div className="px-1 py-4">
        {is_object && <ExampleDiffObjectScrapeTable
            fields={fields}
            old_records={old_records}
            old_field_name_uuid_pairs={old_field_name_uuid_pairs}
            new_records={new_records}
            new_field_name_uuid_pairs={new_field_name_uuid_pairs}
            diffs={diffs}
            show_full_tables={show_full_tables} />}
        {is_array && <ExampleDiffArrayScrapeTable
            fields={fields}
            old_records={old_records}
            old_field_name_uuid_pairs={old_field_name_uuid_pairs}
            new_records={new_records}
            new_field_name_uuid_pairs={new_field_name_uuid_pairs}
            diffs={diffs}
            show_full_tables={show_full_tables} />}
    </div>;
}

type ExampleDiffTablesProps = {
    contexts: (IContextNoUUID & { uuid: string })[];
    item: IItemBase;
    scrapes_eval_metrics?: IScrapeEvalMetrics[];
    hidden_contexts: string[];
    hidden_fields: string[];
    show_diff: boolean;
    show_full_tables: boolean;
}

export function ExampleDiffTables(props: ExampleDiffTablesProps) {
    const { contexts, item, scrapes_eval_metrics, hidden_contexts, hidden_fields, show_diff, show_full_tables } = props;
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const [scrape_debug_log, setScrapeDebugLog] = useState<IScrapeDebugLog[] | undefined>(undefined);

    const sheets: {
        debug_log: IScrapeDebugLog[],
        context_name: string,
        context_type: ContextType,
        fields: IContextField[],
        old_records: IRecord[],
        old_field_name_uuid_pairs: IFieldNameUuidPair[],
        new_records?: IRecord[],
        new_field_name_uuid_pairs?: IFieldNameUuidPair[],
        diffs?: IScrapeEvalDiff[]
    }[] = [];

    for (const context of contexts) {
        if (hidden_contexts.includes(context.uuid)) { continue; }
        const visible_fields = context.fields.filter(f => !hidden_fields.includes(f.uuid));
        if (visible_fields.length === 0) { continue; }
        const old_scrape = item.scrapes.find(s => s.context_uuid === context.uuid);
        const scrape_eval_metrics = scrapes_eval_metrics?.find(s => s.new_scrape.context_uuid === context.uuid);
        if (scrape_eval_metrics === undefined) {
            sheets.push({
                debug_log: old_scrape?.debug_log ?? [],
                context_name: context.name,
                context_type: context.type,
                fields: visible_fields,
                old_records: old_scrape?.records ?? [],
                old_field_name_uuid_pairs: old_scrape?.field_name_uuid_pairs ?? []
            });
        } else {
            sheets.push({
                debug_log: scrape_eval_metrics.new_scrape.debug_log,
                context_name: context.name,
                context_type: context.type,
                fields: visible_fields,
                old_records: old_scrape?.records ?? [],
                old_field_name_uuid_pairs: old_scrape?.field_name_uuid_pairs ?? [],
                new_records: scrape_eval_metrics.new_scrape.records,
                new_field_name_uuid_pairs: scrape_eval_metrics.new_scrape.field_name_uuid_pairs,
                diffs: scrape_eval_metrics.diffs
            });
        }
    }

    return <Fragment>
        {sheets.map((sheet, i) => (<div key={i}>
            <div className="px-2 font-medium text-sm flex items-center gap-2">
                {sheet.context_name}
                {is_admin && sheet.debug_log.length > 0 && (
                    <BugAntIcon
                        className={`h-4 w-4 cursor-pointer ${sheet.debug_log?.some(log => log.type === "error")
                            ? "text-torch_red-500 hover:text-torch_red-700"
                            : "text-gray-500 hover:text-gray-700"
                            }`}
                        onClick={() => setScrapeDebugLog(sheet.debug_log)}
                    />
                )}
            </div>
            {sheet.new_records !== undefined && sheet.diffs !== undefined && show_diff && <ExampleDiffScrapeTable
                fields={sheet.fields}
                context_type={sheet.context_type}
                old_records={sheet.old_records}
                old_field_name_uuid_pairs={sheet.old_field_name_uuid_pairs}
                new_records={sheet.new_records ?? []}
                new_field_name_uuid_pairs={sheet.new_field_name_uuid_pairs ?? []}
                diffs={sheet.diffs ?? []}
                show_full_tables={show_full_tables}
            />}
            {sheet.new_records !== undefined && sheet.diffs !== undefined && !show_diff && <ExampleScrapeTable
                fields={sheet.fields}
                context_type={sheet.context_type}
                records={sheet.new_records}
                field_name_uuid_pairs={sheet.new_field_name_uuid_pairs ?? []}
                show_full_tables={show_full_tables}
            />}
            {(sheet.new_records === undefined || sheet.diffs === undefined) && <ExampleScrapeTable
                fields={sheet.fields}
                context_type={sheet.context_type}
                records={sheet.old_records}
                field_name_uuid_pairs={sheet.old_field_name_uuid_pairs}
                show_full_tables={show_full_tables}
            />}

            <FullScreen
                show={scrape_debug_log !== undefined}
                onClose={() => setScrapeDebugLog(undefined)}
            >
                <div className="p-4 text-sm font-medium">Debug log</div>
                <table className="w-full border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="text-xs text-left font-light px-2 py-1">Rec</th>
                            <th className="text-xs text-left font-light px-2 py-1">Field</th>
                            <th className="text-xs text-left font-light px-2 py-1">Type</th>
                            <th className="text-xs text-left font-light px-2 py-1">Message</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {scrape_debug_log?.map((log, idx) => (
                            <tr key={idx}>
                                <td className="text-xs font-mono px-2 py-1">{log.record_idx}</td>
                                <td className="text-xs font-mono font-light px-2 py-1">{log.field_name ?? "-"}</td>
                                <td className="text-xs font-mono font-light px-2 py-1">{log.type}</td>
                                <td className="text-xs font-mono font-light px-2 py-1">{log.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FullScreen>
        </div>)
        )}
    </Fragment>;
}
