import {
    useEffect,
    useState
} from "react";

import * as hi from "@heroicons/react/24/outline";

import { Backend } from "../lib/backend";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { SidePanel } from "../components/SidePanel";

export function ListSourceIPs() {
    const [ips, setIps] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [show_ips_panel, setShowIpsPanel] = useState<boolean>(false);
    const [copy_success, setCopySuccess] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setError(null);

        Backend.getSourceIps()
            .then(({ ips }) => {
                setIps(ips);
            })
            .catch((error: any) => {
                console.error("Failed to fetch IP addresses:", error);
                setError("Failed to load IP addresses.");
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const handleCopyAll = () => {
        const ip_text = ips.join("\n");
        navigator.clipboard.writeText(ip_text);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    return (
        <>
            <Button
                icon={hi.ServerIcon}
                text="Source IP list"
                onClick={() => setShowIpsPanel(true)}
            />
            <SidePanel
                title="Source IP list"
                open={show_ips_panel}
                onClose={() => setShowIpsPanel(false)}
                size="md"
            >
                <div className="p-6">
                    {loading && (
                        <div className="flex justify-center py-12">
                            <LoadingSpinner />
                        </div>
                    )}

                    {error && (
                        <div className="text-red-500 p-4 bg-red-50 rounded-md mb-4">{error}</div>
                    )}

                    {!loading && ips.length === 0 && !error && (
                        <div className="text-gray-500 p-4 bg-gray-50 rounded-md mb-4">No IP addresses found.</div>
                    )}

                    {!loading && ips.length > 0 && (
                        <>
                            <div className="mb-6 bg-gray-50 p-4 rounded-md text-sm text-gray-600">
                                <p>These IP addresses are used by our services to call webhook endpoints.
                                    <br /> <br />
                                You may need to whitelist these IPs in your firewall configuration to allow
                                incoming requests from our services.</p>
                            </div>

                            <div className="flex justify-between items-center mb-4">
                                <h3 className="text-sm font-medium text-gray-700">Source IPs</h3>
                                <Button
                                    text={copy_success ? "Copied!" : "Copy All"}
                                    onClick={handleCopyAll}
                                    icon={copy_success ? hi.CheckIcon : hi.ClipboardDocumentIcon}
                                />
                            </div>

                            <div className="border rounded-md overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                IP Address
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {ips.map((ip, index) => (
                                            <tr key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-mono">
                                                    {ip}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </SidePanel>
        </>
    );
}