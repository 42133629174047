import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { classNames } from "../lib/utils";
import { scraperUser, selectIsSidebarLarge, selectMemberships, selectUser } from "../lib/scraper.slice";
import { Backend, BackendObj } from "../lib/backend";
import { AppDispatch } from "../store";

import { LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { Pill } from "../components/Pill";
import { ITemplatesFilter } from "./Templates";
import { IEndpointsFilter } from "./Endpoints";
import { IOrganization } from "../lib/backend/auth.types.generated";
import { Textbox } from "../components/Textbox";
import { Button } from "../components/Button";
import { Checkbox } from "../components/Checkbox";

export function AdminOrgs() {
    const dispatch = useDispatch<AppDispatch>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const logged_in_user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const [org_list, setOrgList] = useState<IOrganization[] | undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const [next_search, setNextSearch] = useState<string>("");
    const [show_disabled, setShowDisabled] = useState<boolean>(false);

    useEffect(() => {
        BackendObj.auth.getOrganizations({
            search: search.length > 0 ? search : undefined,
            exclude_disabled: show_disabled ? undefined : "yes"
        }).then(res => { setOrgList(res.orgs); });
    }, [search, show_disabled]);

    const addUserToOrg = async (org_uuid: string) => {
        if (logged_in_user === undefined) { return; }
        await Backend.adminAddUserToOrg({ org_uuid, user_uuid: logged_in_user.uuid, role: "admin" });
        // add filter for templates and integrations
        const org = org_list?.find(o => o.uuid === org_uuid);
        if (org !== undefined) {
            const template_filter: ITemplatesFilter[] = [{ type: "org", value: org.name, uuid: org_uuid }];
            localStorage.setItem("filters_templates", JSON.stringify(template_filter));
            const integrations_filter: IEndpointsFilter[] = [{ type: "org", value: org.name, uuid: org_uuid }];
            localStorage.setItem("filters_endpoints", JSON.stringify(integrations_filter));
        }
        dispatch(scraperUser());
    };

    const removeUserFromOrg = async (org_uuid: string) => {
        if (logged_in_user === undefined) { return; }
        await Backend.adminRemoveUserFromOrg({ org_uuid, user_uuid: logged_in_user.uuid });
        // clear template and integrations filters
        localStorage.setItem("filters_templates", "[]");
        localStorage.setItem("filters_endpoints", "[]");
        dispatch(scraperUser());
    };

    return <Fragment>
        <div className={classNames("flex-col lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="m-16 mb-6 max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                        Organizations
                    </h2>
                </div>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>
            <div className="px-10 py-5 max-w-6xl flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="sm:px-6 lg:px-8 pb-4 space-y-4 text-sm">
                        <div className="flex flex-row items-center gap-x-10">
                            <div className="flex flex-row items-center gap-x-2 flex-grow">
                                <Textbox
                                    placeholder="Search organizations..."
                                    value={next_search || ""}
                                    onChange={setNextSearch}
                                    keyHandlers={{
                                        Enter: () => setSearch(next_search)
                                    }}
                                />
                                <Button text="Search" onClick={() => setSearch(next_search)} />
                            </div>
                            <div className="flex flex-row items-center gap-x-2">
                                <label htmlFor="show_disabled" className="inline-flex items-center cursor-pointer">
                                    <Checkbox
                                        id="show_disabled"
                                        checked={show_disabled}
                                        setChecked={setShowDisabled}
                                    />
                                    <span className="ml-2 text-gray-500 whitespace-nowrap">Show disabled</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tags</th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {org_list && org_list.map((org, idx) => (
                                        <tr key={idx} className={classNames(org.is_enabled ? "" : "bg-gray-200")}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{org.name}</td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 flex flex-row items-center gap-2">
                                                {org.tags.admin_org && <Pill text="Admin" />}
                                                {org.tags.eval_check_credits && <Pill text="Eval Credits" />}
                                                {org.tags.sso_enabled && <Pill text="SSO" />}
                                                {org.tags.csv_use_semicolon && <Pill text={"CSV Semicolon"} />}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{org.type}</td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex flex-row gap-2 justify-end">
                                                {memberships.find(m => m.org.uuid === org.uuid) !== undefined && org.tags.admin_org && <span className="text-gray-400">Member</span>}
                                                {memberships.find(m => m.org.uuid === org.uuid) !== undefined && !org.tags.admin_org &&
                                                    <span className="text-space_blue-600 hover:text-space_blue-900 cursor-pointer bg-candy_corn-200 px-2 py-0 rounded" onClick={() => removeUserFromOrg(org.uuid)}>Detach</span>}
                                                {memberships.find(m => m.org.uuid === org.uuid) === undefined && !org.tags.admin_org &&
                                                    <span className="text-space_blue-600 hover:text-space_blue-900 cursor-pointer" onClick={() => addUserToOrg(org.uuid)}>Attach</span>}
                                                <span>|</span>
                                                <Link to={`/admin/org/${org.uuid}`} className="text-space_blue-600 hover:text-space_blue-900">Edit</Link>
                                            </td>
                                        </tr>
                                    ))}
                                    {!org_list && <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={4}>
                                            <LoadingSpinnerLimit />
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >;
};
