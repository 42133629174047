import { ArrowUturnLeftIcon, ChevronDownIcon, ChevronUpIcon, QuestionMarkCircleIcon, SparklesIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Checkbox } from "./Checkbox";
import { Dropdown } from "./Dropdown";
import { Button } from "./Button";
import { PlusIcon } from "@heroicons/react/20/solid";

import * as t from "../lib/types";
import { getExcelColumnName } from "../lib/utils";
import { useRef, useState } from "react";
import { IPrevField } from "./FieldsTable";
import { AiFuzzyLookupMatcher } from "./AiFuzzyLookupMatcher";
import { ILookupMapFilter } from "../lib/backend/extractions.types.generated";

interface FallbackSequenceProps {
    field_idx: number;
    field?: t.IContextField;
    fields: t.IContextField[];
    prev_fields: IPrevField[];
    lookup_tables: t.ILookupTableBase[];
    fallback_sequence: t.IFallbackSequence;
    setField: (field_idx: number, field: t.IContextField) => void;
    fallback_sequence_idx?: number;
}

export function FallbackSequence({
    field_idx,
    field,
    fields,
    prev_fields,
    lookup_tables,
    fallback_sequence,
    setField,
    fallback_sequence_idx
}: FallbackSequenceProps) {

    const sequenceRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);

    const onFallbackFieldChange = (sequence_idx: number, key: "field_uuid" | "fail_on_empty" | "fail_on_non_unique", val: string | boolean) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_field") {
                    if (key === "field_uuid") {
                        sequence_item.field_uuid = val as string;
                    } else if (key === "fail_on_empty") {
                        sequence_item.fail_on_empty = val as boolean;
                    } else if (key === "fail_on_non_unique") {
                        sequence_item.fail_on_non_unique = val as boolean;
                    }
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const updateFallbackSequence = (key: "val_on_empty" | "handle_non_unique" | "final_fallback_constant" | "final_fallback_field_uuid", value: any) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                if (key === "val_on_empty") {
                    new_field.compute.fallback_sequence.val_on_empty = value;
                    if (value === "constant") {
                        const old_constant = new_field.compute.fallback_sequence.final_fallback_constant;
                        new_field.compute.fallback_sequence.final_fallback_constant = old_constant ?? "";
                    } else if (value === "field") {
                        const old_field_uuid = new_field.compute.fallback_sequence.final_fallback_field_uuid;
                        new_field.compute.fallback_sequence.final_fallback_field_uuid = old_field_uuid ?? fields[0]?.uuid;
                    }
                } else if (key === "handle_non_unique") {
                    new_field.compute.fallback_sequence.handle_non_unique = value;
                } else if (key === "final_fallback_constant") {
                    new_field.compute.fallback_sequence.final_fallback_constant = value;
                } else if (key === "final_fallback_field_uuid") {
                    new_field.compute.fallback_sequence.final_fallback_field_uuid = value;
                }
                setField(field_idx, new_field);
            }
        }
    };

    const deleteSequenceItem = (sequence_idx: number) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                new_field.compute.fallback_sequence.sequence.splice(sequence_idx, 1);
                setField(field_idx, new_field);
            }
        }
    };

    const highlightItem = (sequence_idx: number) => {
        setHighlightedIndex(sequence_idx);
        setTimeout(() => setHighlightedIndex(null), 1000); // Remove highlight after 1000ms
    };

    const scrollToItem = (sequence_idx: number) => {
        const item = sequenceRefs.current[sequence_idx];
        if (item) {
            item.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    };

    const moveSequenceItemUp = (sequence_idx: number) => {
        if (field && sequence_idx > 0) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence = new_field.compute.fallback_sequence.sequence;
                // destructuring assignment to swap two elements
                [sequence[sequence_idx - 1], sequence[sequence_idx]] = [sequence[sequence_idx], sequence[sequence_idx - 1]];
                setField(field_idx, new_field);
                scrollToItem(sequence_idx - 1);
                highlightItem(sequence_idx - 1);
            }
        }
    };

    const moveSequenceItemDown = (sequence_idx: number) => {
        if (field && sequence_idx < fallback_sequence.sequence.length - 1) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence = new_field.compute.fallback_sequence.sequence;
                // destructuring assignment to swap two elements
                [sequence[sequence_idx], sequence[sequence_idx + 1]] = [sequence[sequence_idx + 1], sequence[sequence_idx]];
                setField(field_idx, new_field);
                scrollToItem(sequence_idx + 1);
                highlightItem(sequence_idx + 1);
            }
        }
    };

    const addSequenceItem = (type: "fallback_lookup_map" | "fallback_field" ) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const new_item: t.IFallbackComputation = (type === "fallback_lookup_map")
                    ? {
                        type,
                        lookup_table_uuid: lookup_tables[0]?.uuid || "",
                        filters: [{ type: "field", field_uuid: fields[0]?.uuid ?? "", header_idx: 0, comparison: "exact" }],
                        ai_fuzzy_filter: undefined,
                        value_header_idx: 0,
                        fail_on_empty: true,
                        fail_on_non_unique: true
                    } : {
                        type: "fallback_field",
                        field_uuid: fields[0]?.uuid ?? "",
                        fail_on_empty: true,
                        fail_on_non_unique: true
                    };

                new_field.compute.fallback_sequence.sequence.push(new_item);
                setField(field_idx, new_field);
            }
        }
    };

    const updateLookupMapField = (sequence_idx: number, key: "lookup_table_uuid" | "value_header_idx" | "fail_on_empty" | "fail_on_non_unique", val: string | number | boolean) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    if (key === "lookup_table_uuid") {
                        sequence_item.lookup_table_uuid = val as string;
                        // Reset filters and value header when lookup table changes
                        sequence_item.filters = [{
                            type: "field",
                            field_uuid: fields[0]?.uuid ?? "",
                            header_idx: 0,
                            comparison: "exact"
                        }];
                        sequence_item.value_header_idx = 0;
                    } else if (key === "value_header_idx") {
                        sequence_item.value_header_idx = val as number;
                    } else if (key === "fail_on_empty") {
                        sequence_item.fail_on_empty = val as boolean;
                    } else if (key === "fail_on_non_unique") {
                        sequence_item.fail_on_non_unique = val as boolean;
                    }
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const updateAiFuzzyMatcher = (sequence_idx: number, key: "source_fields" | "lookup_fields" | "guidelines", val: any) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    if (sequence_item.ai_fuzzy_filter === undefined) {
                        sequence_item.ai_fuzzy_filter = {
                            source_fields: [],
                            lookup_fields: [],
                            guidelines: [""]
                        };
                    }
                    if (key === "source_fields") {
                        sequence_item.ai_fuzzy_filter.source_fields = val;
                    } else if (key === "lookup_fields") {
                        sequence_item.ai_fuzzy_filter.lookup_fields = val;
                    } else if (key === "guidelines") {
                        sequence_item.ai_fuzzy_filter.guidelines = val;
                    }
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const deleteLookupMapFilter = (sequence_idx: number, idx: number) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    sequence_item.filters.splice(idx, 1);
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const addLookupMapFilter = (sequence_idx: number) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    sequence_item.filters.push({
                        type: "field",
                        field_uuid: fields[0]?.uuid ?? "",
                        header_idx: 0,
                        comparison: "exact"
                    });
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const addFuzzyMatcher = (sequence_idx: number) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    // Get default source field
                    const default_source_field_uuid = prev_fields[0]?.field_uuid || fields[0]?.uuid || "";
                    const source_fields: string[] = [];
                    if (default_source_field_uuid !== "") {
                        source_fields.push(default_source_field_uuid);
                    }

                    // Get default lookup field
                    const lookup_fields: number[] = [];
                    const selected_lookup_table = lookup_tables.find(lt => lt.uuid === sequence_item.lookup_table_uuid);
                    if (selected_lookup_table !== undefined && selected_lookup_table.headers?.length > 0) {
                        // default lookup field is the first header with index == 0
                        lookup_fields.push(0);
                    }

                    sequence_item.ai_fuzzy_filter = {
                        source_fields,
                        lookup_fields,
                        guidelines: [""]
                    };
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const updateLookupFieldValue = (sequence_idx: number, idx: number, key: "field_uuid" | "constant_value"| "header_idx" | "comparison" | "compensate_ocr_errors" | "compensate_leading_zeros" | "match_whole_word" | "case_insensitive", val: string | number | boolean) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    if (key === "field_uuid") {
                        const filter = sequence_item.filters[idx] as ILookupMapFilter;
                        sequence_item.filters[idx] = {
                            type: "field",
                            field_uuid: val as string,
                            header_idx: filter.header_idx,
                            comparison: filter.comparison,
                            compensate_ocr_errors: filter.compensate_ocr_errors,
                            compensate_leading_zeros: filter.compensate_leading_zeros,
                            match_whole_word: filter.match_whole_word,
                            case_insensitive: filter.case_insensitive
                        };
                    }
                    if (key === "constant_value") {
                        const filter = sequence_item.filters[idx] as ILookupMapFilter;
                        sequence_item.filters[idx] = {
                            type: "constant",
                            constant_value: val as string,
                            header_idx: filter.header_idx,
                            comparison: filter.comparison,
                            compensate_ocr_errors: filter.compensate_ocr_errors,
                            compensate_leading_zeros: filter.compensate_leading_zeros,
                            match_whole_word: filter.match_whole_word,
                            case_insensitive: filter.case_insensitive
                        };
                    }
                    if (key === "header_idx") { sequence_item.filters[idx].header_idx = val as number; }
                    if (key === "comparison") { sequence_item.filters[idx].comparison = val as "exact" | "approx" | "not_equals" | "contains" | "contained_in"; }
                    if (key === "compensate_ocr_errors") { sequence_item.filters[idx].compensate_ocr_errors = val as boolean; }
                    if (key === "compensate_leading_zeros") { sequence_item.filters[idx].compensate_leading_zeros = val as boolean; }
                    if (key === "match_whole_word") { sequence_item.filters[idx].match_whole_word = val as boolean; }
                    if (key === "case_insensitive") { sequence_item.filters[idx].case_insensitive = val as boolean; }
                    setField(field_idx, new_field);
                }
            }
        }
    }

    const deleteAiFuzzyMatcher = (sequence_idx: number) => {
        if (field) {
            const new_field = { ...field };
            if (new_field.compute?.fallback_sequence) {
                const sequence_item = new_field.compute.fallback_sequence.sequence[sequence_idx];
                if (sequence_item.type === "fallback_lookup_map") {
                    sequence_item.ai_fuzzy_filter = undefined;
                    setField(field_idx, new_field);
                }
            }
        }
    };

    function hasApproximateFilter(filters: t.IFallbackLookupMap["filters"]): boolean {
        return filters.some(filter => filter.comparison === "approx");
    }

    return (
        <div className="pt-4 space-y-4">
            <div className="space-y-4">
                {fallback_sequence.sequence.map((item, sequence_idx) => (
                    <div
                        key={"fallback_sequence_" + sequence_idx}
                        className={`border rounded p-4 bg-gray-100 transition-colors duration-1000 ${highlightedIndex === sequence_idx ? "bg-candy_corn-100" : ""}`}
                        ref={(el) => (sequenceRefs.current[sequence_idx] = el)}
                    >
                        <div className="flex items-center justify-between">
                            <span className="text-base font-bold text-gray-900">
                                {sequence_idx === 0 ? "Default Value" : `Backup Value ${sequence_idx}`}
                            </span>
                            <div className="flex space-x-2">
                                <Button
                                    icon={ChevronUpIcon}
                                    onClick={() => moveSequenceItemUp(sequence_idx)}
                                    disabled={sequence_idx === 0}
                                />
                                <Button
                                    icon={ChevronDownIcon}
                                    onClick={() => moveSequenceItemDown(sequence_idx)}
                                    disabled={sequence_idx === fallback_sequence.sequence.length - 1}
                                />
                                <Button
                                    icon={TrashIcon}
                                    onClick={() => deleteSequenceItem(sequence_idx)}
                                />
                            </div>
                        </div>
                        {item.type === "fallback_lookup_map" ? (
                            <LookupTableFilters
                                field_idx={field_idx}
                                fallback_sequence_idx={sequence_idx}
                                fields={fields.slice(0, field_idx)}
                                prev_fields={prev_fields}
                                lookup_tables={lookup_tables}
                                lookup_map={item}
                                updateLookupMapField={(key, val) => updateLookupMapField(sequence_idx, key, val)}
                                deleteLookupMapFilter={(idx) => deleteLookupMapFilter(sequence_idx, idx)}
                                addLookupMapFilter={() => addLookupMapFilter(sequence_idx)}
                                addFuzzyMatcher={() => addFuzzyMatcher(sequence_idx)}
                                updateLookupFieldValue={(idx, key, val) => updateLookupFieldValue(sequence_idx, idx, key, val)}
                                updateAiFuzzyMatcher={(key, val) => updateAiFuzzyMatcher(sequence_idx, key, val)}
                                deleteAiFuzzyMatcher={() => deleteAiFuzzyMatcher(sequence_idx)}
                                hasApproximateFilter={hasApproximateFilter(item.filters)}
                            />
                        ) : (
                            <div>
                                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Field
                                </label>
                                <div className="my-2">
                                    <Dropdown
                                        values={prev_fields.map(f => `[${f.context_name}] ${f.field_name}`).concat(fields.slice(0, field_idx).map(f => f.name))}
                                        ids={prev_fields.map(f => f.field_uuid).concat(fields.slice(0, field_idx).map(f => f.uuid))}
                                        selected={item.field_uuid}
                                        onChange={(uuid) => onFallbackFieldChange(sequence_idx, "field_uuid", uuid)}
                                    />
                                </div>
                                <div className="py-3 grid grid-cols-3 gap-x-4">
                                    <fieldset>
                                        <legend className="sr-only">Fail on Empty</legend>
                                        <div className="space-y-5">
                                            <div className="relative flex items-start">
                                                <div className="flex h-6 items-center">
                                                    <Checkbox
                                                        checked={item.fail_on_empty ?? true}
                                                        setChecked={(checked) => onFallbackFieldChange(sequence_idx, "fail_on_empty", checked)}
                                                        id={`fail_on_empty_${sequence_idx}`}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6 relative flex items-start">
                                                    <label htmlFor={`fail_on_empty_${sequence_idx}`} className="font-medium text-gray-900">
                                                        Fail on empty
                                                    </label>
                                                    <QuestionMarkCircleIcon
                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                        data-tooltip-id="fields-table-tooltip-id"
                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">If unchecked, the computation will stop on empty values and return the final fallback value.</p>`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <legend className="sr-only">Fail on Non-unique</legend>
                                        <div className="space-y-5">
                                            <div className="relative flex items-start">
                                                <div className="flex h-6 items-center">
                                                    <Checkbox
                                                        checked={item.fail_on_non_unique ?? true}
                                                        setChecked={(checked) => onFallbackFieldChange(sequence_idx, "fail_on_non_unique", checked)}
                                                        id={`fail_on_non_unique_${sequence_idx}`}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6 relative flex items-start">
                                                    <label htmlFor={`fail_on_non_unique_${sequence_idx}`} className="font-medium text-gray-900">
                                                        Fail on non-unique
                                                    </label>
                                                    <QuestionMarkCircleIcon
                                                        className="w-5 h-5 ml-1 text-gray-400"
                                                        data-tooltip-id="fields-table-tooltip-id"
                                                        data-tooltip-html={`<p class="pb-4 max-w-sm">If unchecked, the computation will stop on non unique values and return the final fallback value.</p>`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className="flex gap-2 border-b pb-4">
                <Button
                    text="Add Lookup Map"
                    icon={PlusIcon}
                    onClick={() => addSequenceItem("fallback_lookup_map")}
                />
                <Button
                    text="Add Field"
                    icon={PlusIcon}
                    onClick={() => addSequenceItem("fallback_field")}
                />
            </div>

            <div className="grid grid-cols-2 gap-x-4">
                <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-gray-900">On Empty Value</label>
                    <Dropdown
                        values={["Use Constant", "Use Field"]}
                        ids={["constant", "field"]}
                        selected={fallback_sequence.val_on_empty}
                        onChange={(val) => updateFallbackSequence("val_on_empty", val)}
                    />
                </div>
                <div className="col-span-1">
                    {fallback_sequence.val_on_empty === "constant" ? (
                        <div>
                            <label className="block text-sm font-medium text-gray-900">Constant Value</label>
                            <input
                                type="text"
                                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={fallback_sequence.final_fallback_constant ?? ""}
                                onChange={(e) => updateFallbackSequence("final_fallback_constant", e.target.value)}
                            />
                        </div>
                    ) : (
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">Field Name</label>
                            <Dropdown
                                values={fields.slice(0, field_idx).map(f => f.name)}
                                ids={fields.slice(0, field_idx).map(f => f.uuid)}
                                selected={fallback_sequence.final_fallback_field_uuid ?? ""}
                                onChange={(uuid) => updateFallbackSequence("final_fallback_field_uuid", uuid)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
                <div className="col-span-1">
                    <label className="block mb-2 text-sm font-medium text-gray-900">Handle Non-unique</label>
                    <Dropdown
                        values={["First Match", "Last Match", "All Matches"]}
                        ids={["first", "last", "list"]}
                        selected={fallback_sequence.handle_non_unique}
                        onChange={(val) => updateFallbackSequence("handle_non_unique", val)}
                    />
                </div>
            </div>
        </div>
    );
}

interface LookupTableFiltersProps {
    field_idx: number;
    fallback_sequence_idx?: number;
    fields: t.IContextField[];
    prev_fields: IPrevField[];
    lookup_tables: t.ILookupTableBase[];
    lookup_map: t.IFallbackLookupMap;
    updateLookupMapField: (key: "lookup_table_uuid" | "value_header_idx" | "fail_on_empty" | "fail_on_non_unique", val: string | number | boolean) => void;
    deleteLookupMapFilter: (idx: number) => void;
    addLookupMapFilter: () => void;
    addFuzzyMatcher: () => void;
    updateLookupFieldValue: (idx: number, key: "field_uuid" | "constant_value" | "header_idx" | "comparison" | "compensate_ocr_errors" | "compensate_leading_zeros" | "match_whole_word" | "case_insensitive", val: string | number | boolean) => void;
    updateAiFuzzyMatcher: (key: "source_fields" | "lookup_fields" | "guidelines", val: any) => void;
    deleteAiFuzzyMatcher: () => void;
    hasApproximateFilter: boolean;
}

export function LookupTableFilters({
    field_idx,
    fallback_sequence_idx,
    fields,
    prev_fields,
    lookup_tables,
    lookup_map,
    updateLookupMapField,
    deleteLookupMapFilter,
    addLookupMapFilter,
    addFuzzyMatcher,
    updateLookupFieldValue,
    updateAiFuzzyMatcher,
    deleteAiFuzzyMatcher,
    hasApproximateFilter
}: LookupTableFiltersProps) {

    const lookup_tables_names: string[] = lookup_tables.map((lookup_table) => lookup_table.name);
    const lookup_tables_ids: string[] = lookup_tables.map((lookup_table) => lookup_table.uuid);

    const getLookupTableHeaderValues = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((header, idx) => `${getExcelColumnName(idx)} - ${header}`);
    };
    const getLookupTableHeaderIds = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((_header, idx) => `${idx}`);
    };

    return (
        <div>
            <div className="grid grid-cols-2 gap-x-4">
                <div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Lookup Table
                    </label>
                    <div className="my-2 sm:mt-0">
                        <div className="w-full">
                            <Dropdown
                                values={lookup_tables_names}
                                ids={lookup_tables_ids}
                                selected={lookup_map.lookup_table_uuid || ""}
                                onChange={(uuid: string) => updateLookupMapField("lookup_table_uuid", uuid)} />
                        </div>
                    </div>
                </div>

                <div>
                    <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Lookup Value
                    </label>
                    <div className="my-2 sm:mt-0">
                        <div className="w-full">
                            <Dropdown
                                values={getLookupTableHeaderValues(lookup_map.lookup_table_uuid || "")}
                                ids={getLookupTableHeaderIds(lookup_map.lookup_table_uuid || "")}
                                selected={lookup_map.value_header_idx.toString() || "0"}
                                onChange={(value_header_idx: string) => updateLookupMapField("value_header_idx", parseInt(value_header_idx, 10))} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                {lookup_map.filters.map((filter, filter_idx) =>
                    <div key={`lookup_filter_${filter_idx}_${fallback_sequence_idx ?? ""}`} className="py-3">
                        <div className="flex flex-row items-center">
                            <span className="text-sm font-semibold">{filter_idx + 1}. Lookup Table Match Key</span>
                            <div className="grow" />
                            <Button icon={TrashIcon} onClick={() => deleteLookupMapFilter(filter_idx)} />
                        </div>
                        <div className="grid grid-cols-5 gap-x-4">
                            <div className="col-span-2">
                                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    {filter.type === "field" ? "Field Key" : "Constant Value"}
                                </label>
                                <div className="my-2 sm:mt-0">
                                    <div className="w-full">
                                        {filter.type === "field" ? (
                                            <Dropdown
                                                values={["Constant Value..."].concat(prev_fields.map((field) => `[${field.context_name}] ${field.field_name}`)).concat(fields.slice(0, field_idx).map((field) => field.name))}
                                                ids={["__custom__"].concat(prev_fields.map((field) => field.field_uuid)).concat(fields.slice(0, field_idx).map((field) => field.uuid))}
                                                selected={filter.field_uuid}
                                                onChange={(value: string) => {
                                                    if (value === "__custom__") {
                                                        updateLookupFieldValue(filter_idx, "constant_value", "");
                                                    } else {
                                                        updateLookupFieldValue(filter_idx, "field_uuid", value);
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <div className="flex  items-center">
                                                <input
                                                    type="text"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={filter.constant_value || ""}
                                                    placeholder="Enter constant value..."
                                                    onChange={(e) => updateLookupFieldValue(filter_idx, "constant_value", e.target.value)}
                                                />
                                                <Button
                                                    icon={ArrowUturnLeftIcon}
                                                    onClick={() => {
                                                        updateLookupFieldValue(filter_idx, "field_uuid", prev_fields[0]?.field_uuid || fields[0]?.uuid || "");
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Type
                                </label>
                                <div className="my-2 sm:mt-0">
                                    <div className="w-full">
                                        <Dropdown
                                            values={["=", "≈", "≠", "contains", "in"]}
                                            ids={["exact", "approx", "not_equals", "contains", "contained_in"]}
                                            selected={filter.comparison || "exact"}
                                            onChange={(comparison: string) => updateLookupFieldValue(filter_idx, "comparison", comparison)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-2">
                                <label htmlFor="title" className="my-2 block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                    Lookup Key
                                </label>
                                <div className="my-2 sm:mt-0">
                                    <div className="w-full">
                                        <Dropdown
                                            values={getLookupTableHeaderValues(lookup_map.lookup_table_uuid || "")}
                                            ids={getLookupTableHeaderIds(lookup_map.lookup_table_uuid || "")}
                                            selected={filter.header_idx.toString() || "0"}
                                            onChange={(header_idx: string) => updateLookupFieldValue(filter_idx, "header_idx", parseInt(header_idx, 10))} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-3 grid grid-cols-3 gap-x-4">
                            <fieldset>
                                <legend className="sr-only">Compensate for OCR errors</legend>
                                <div className="space-y-5">
                                    <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                            <Checkbox
                                                checked={filter.compensate_ocr_errors ?? false}
                                                setChecked={(checked) => updateLookupFieldValue(filter_idx, "compensate_ocr_errors", checked)}
                                                id={`compensate_ocr_errors_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                            <label htmlFor={`compensate_ocr_errors_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`} className="font-medium text-gray-900">
                                                Compensate OCR
                                            </label>
                                            <QuestionMarkCircleIcon
                                                className="w-5 h-5 ml-1 text-gray-400"
                                                data-tooltip-id="fields-table-tooltip-id"
                                                data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have OCR errors like confusing 0 and O, 1 and I, etc.This will help in matching the OCR extracted value with the lookup table value.</p>`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                                <div className="space-y-5">
                                    <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                            <Checkbox
                                                checked={filter.compensate_leading_zeros ?? false}
                                                setChecked={(checked) => updateLookupFieldValue(filter_idx, "compensate_leading_zeros", checked)}
                                                id={`compensate_leading_zeros_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                            <label htmlFor={`compensate_leading_zeros_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`} className="font-medium text-gray-900">
                                                Leading zeros
                                            </label>
                                            <QuestionMarkCircleIcon
                                                className="w-5 h-5 ml-1 text-gray-400"
                                                data-tooltip-id="fields-table-tooltip-id"
                                                data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have ID numbers with leading zeros. This will help in matching the extracted value with the lookup table value even when the number of leading zeros is different.</p>`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="sr-only">Case insensitive</legend>
                                <div className="space-y-5">
                                    <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                            <Checkbox
                                                checked={filter.case_insensitive ?? true}
                                                setChecked={(checked) => updateLookupFieldValue(filter_idx, "case_insensitive", checked)}
                                                id={`case_insensitive_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                            <label htmlFor={`case_insensitive_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`} className="font-medium text-gray-900">
                                                Ignore case
                                            </label>
                                            <QuestionMarkCircleIcon
                                                className="w-5 h-5 ml-1 text-gray-400"
                                                data-tooltip-id="fields-table-tooltip-id"
                                                data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you do not want to differentiate between uppercase and lowercase letters.</p>`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            {(filter.comparison === "contains" || filter.comparison === "contained_in") && <fieldset>
                                <legend className="sr-only">Match whole words</legend>
                                <div className="space-y-5">
                                    <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                            <Checkbox
                                                checked={filter.match_whole_word ?? false}
                                                setChecked={(checked) => updateLookupFieldValue(filter_idx, "match_whole_word", checked)}
                                                id={`match_whole_word_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6 relative flex items-start">
                                            <label htmlFor={`match_whole_word_${filter_idx}_${fallback_sequence_idx !== undefined ? fallback_sequence_idx : ""}`} className="font-medium text-gray-900">
                                                Match whole word
                                            </label>
                                            <QuestionMarkCircleIcon
                                                className="w-5 h-5 ml-1 text-gray-400"
                                                data-tooltip-id="fields-table-tooltip-id"
                                                data-tooltip-html={`<p class="pb-4 max-w-sm">The lookup value has to contain (or be contained within) the extracted value as a whole word (not as a part of a word).</p>`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>}
                        </div>
                    </div>
                )}
                {lookup_map.ai_fuzzy_filter !== undefined && fallback_sequence_idx !== undefined && (
                    <AiFuzzyLookupMatcher
                        filter_idx={lookup_map.filters.length}
                        fallback_sequence_idx={fallback_sequence_idx}
                        fields={fields}
                        prev_fields={prev_fields}
                        lookup_tables={lookup_tables}
                        ai_fuzzy_filter={lookup_map.ai_fuzzy_filter}
                        lookup_table_uuid={lookup_map.lookup_table_uuid}
                        updateSourceFields={(source_fields: string[]) => {
                            if (lookup_map.ai_fuzzy_filter === undefined) {
                                lookup_map.ai_fuzzy_filter = {
                                    source_fields: [],
                                    lookup_fields: [],
                                    guidelines: []
                                };
                            }
                            const new_fuzzy_filters = { ...lookup_map.ai_fuzzy_filter };
                            new_fuzzy_filters.source_fields = source_fields;
                        }}
                        updateAiFuzzyMatcher={(key, val) => updateAiFuzzyMatcher(key, val)}
                        deleteAiFuzzyMatcher={deleteAiFuzzyMatcher}
                    />
                )}
                <div className="py-3 flex justify-end space-x-2">
                    <Button
                        text="Add Filter"
                        icon={PlusIcon}
                        onClick={addLookupMapFilter}
                    />
                    {lookup_map.ai_fuzzy_filter === undefined && (
                        <div className="relative inline-block">
                            <Button
                                text="AI Matcher Agent"
                                icon={SparklesIcon}
                                onClick={addFuzzyMatcher}
                                disabled={hasApproximateFilter}
                                disabled_warning={hasApproximateFilter ?
                                    "AI Matcher cannot be added when there are approximative filters created" :
                                    undefined}
                            />
                        </div>
                    )}
                </div>
            </div>
            {lookup_map.type === "fallback_lookup_map" &&
                <div className="py-3 grid grid-cols-3 gap-x-4">
                    <fieldset>
                        <legend className="sr-only">Fail on Empty</legend>
                        <div className="space-y-5">
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <Checkbox
                                        checked={lookup_map.fail_on_empty ?? true}
                                        setChecked={(checked) => updateLookupMapField("fail_on_empty", checked)}
                                        id={`fail_on_empty_${fallback_sequence_idx}`}
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6 relative flex items-start">
                                    <label htmlFor={`fail_on_empty_${fallback_sequence_idx}`} className="font-medium text-gray-900">
                                        Fail on empty
                                    </label>
                                    <QuestionMarkCircleIcon
                                        className="w-5 h-5 ml-1 text-gray-400"
                                        data-tooltip-id="fields-table-tooltip-id"
                                        data-tooltip-html={`<p class="pb-4 max-w-sm">If unchecked, the computation will stop on empty values and return the final fallback value.</p>`}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend className="sr-only">Fail on Non-unique</legend>
                        <div className="space-y-5">
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <Checkbox
                                        checked={lookup_map.fail_on_non_unique ?? true}
                                        setChecked={(checked) => updateLookupMapField("fail_on_non_unique", checked)}
                                        id={`fail_on_non_unique_${fallback_sequence_idx}`}
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6 relative flex items-start">
                                    <label htmlFor={`fail_on_non_unique_${fallback_sequence_idx}`} className="font-medium text-gray-900">
                                        Fail on non-unique
                                    </label>
                                    <QuestionMarkCircleIcon
                                        className="w-5 h-5 ml-1 text-gray-400"
                                        data-tooltip-id="fields-table-tooltip-id"
                                        data-tooltip-html={`<p class="pb-4 max-w-sm">If unchecked, the computation will stop on non unique values and return the final fallback value.</p>`}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>}
        </div>
    );
}