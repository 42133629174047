import { Fragment, useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    BookOpenIcon,
    ClockIcon
} from "@heroicons/react/24/outline";

import * as st from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";
import { classNames, isValidInteger } from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { Button } from "../components/Button";
import { LoadingSpinner, LoadingSpinnerLimit } from "../components/LoadingSpinner";
import { TransactionHistory } from "../components/TransactionHistory";
import { ExtractJobs } from "../components/ExtractJobs";
import { OrgAuditLog } from "../components/OrgAuditLog";
import { Tabs } from "../components/Tabs";
import { CopyTextbox } from "../components/CopyTextbox";
import { Dropdown } from "../components/Dropdown";
import { ReadOnlyTextbox } from "../components/ReadOnlyTextbox";
import { AdminSubscriptions } from "../components/AdminSubscriptions";
import { IOrganization, NumberFormatLocale } from "../lib/backend/auth.types.generated";
import { Textbox } from "../components/Textbox";

export function AdminOrg() {
    const { org_uuid } = useParams<{ org_uuid: string }>();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const location = useLocation();
    const navigate = useNavigate();

    // Get tab and page from URL query params, defaulting to "users" and 0
    const query_params = new URLSearchParams(location.search);
    const initial_tab = query_params.get("tab") || "users";
    const initial_page = parseInt(query_params.get("page") || "0");

    const [org, setOrg] = useState<IOrganization | undefined>(undefined);
    const [users, setUsers] = useState<st.IOrgUser[] | undefined>(undefined);
    const [balance, setBalance] = useState<number | undefined>(undefined);
    const [sso, setSso] = useState<st.IOrgSingleSignOn | undefined>(undefined);
    const [selected_tab, setSelectedTab] = useState<string>(initial_tab);
    const [open_transaction_history, setOpenTransactionHistory] = useState<boolean>(false);
    const [models, setModels] = useState<st.IModel[]>([]);
    const [is_models_saving, setIsModelsSaving] = useState<boolean>(false);
    const [history_refresh_ticker, setHistoryRefreshTicker] = useState<number>(0);
    const [max_users, setMaxUsers] = useState<string>("");

    const tabs = [
        { name: "Users", key: "users" },
        { name: "Extract Jobs", key: "extract_jobs" },
        { name: "Audit Log", key: "audit_log" },
        { name: "Subscription", key: "subscription" },
        { name: "Models", key: "models" },
    ];

    // prepare list of available day-of-month values
    // show up to 28, other dates are not present in each month. unsupported for now.
    const days_of_month: string[] = [];
    for (let i = 1; i <= 28; i++) {
        days_of_month.push(i.toString());
    }

    useEffect(() => {
        if (!org_uuid) { return; }
        Backend.getAdminOrg({ org_uuid })
            .then(({ org, users, balance, sso }) => {
                setOrg(org);
                setUsers(users);
                setBalance(balance);
                setSso(sso);
                setMaxUsers(org.tags.max_users?.toString() ?? "");
            });
    }, [org_uuid]);

    useEffect(() => {
        BackendObj.extractions.getModels({})
            .then(({ models }) => {
                setModels(models);
            });
    }, []);

    const refreshCredits = async () => {
        if (!org_uuid) { return; }
        const { balance } = await Backend.getAdminOrg({ org_uuid });
        setBalance(balance);
        setHistoryRefreshTicker(history_refresh_ticker + 1);
    }

    const expireCredits = async () => {
        if (!org_uuid) { return; }
        await BackendObj.auth.expireAdminOrgCredits({ org_uuid });
        refreshCredits();
    }

    type OrgTag = "admin_org" | "sso_enabled" | "eval_check_credits" | "csv_use_semicolon" | "max_users" | "number_format_locale";

    const setOrgTag = async (tag: OrgTag, flag: boolean | number | NumberFormatLocale | undefined) => {
        if (!org) { return; }
        await Backend.setOrganizationTagFlag({ org_uuid: org.uuid, tag, flag });
        const { org: new_org } = await Backend.getAdminOrg({ org_uuid: org.uuid });
        setOrg(new_org);
    }

    const setOrgEnabled = async (is_enabled: boolean) => {
        if (!org) { return; }
        await BackendObj.auth.setOrgEnabled({ uuid: org.uuid, is_enabled });
        const { org: new_org } = await Backend.getAdminOrg({ org_uuid: org.uuid });
        setOrg(new_org);
    }

    const handleModelNameChange = (model_name: string, field_name: "default" | "default_fast" | "scrape_extract" | "scrape_heal" | "scrape_summarize" | "scrape_focused_summarize" | "decimal_separator" | "context_suggest" | "oc_date_validation" | "classify_document" | "match_lookup" | "table_splt") => {
        setOrg((prev_org) => {
            if (!prev_org) { return prev_org; }
            return {
                ...prev_org,
                models_overrides: {
                    ...prev_org.models_overrides,
                    [field_name]: (model_name === "/") ? undefined : model_name
                }
            };
        });
    };

    const handleModelOverridesSave = async () => {
        if (!org) { return; }
        setIsModelsSaving(true);
        await BackendObj.extractions.updateModelOverrides({
            org_uuid: org.uuid,
            models_overrides: org.models_overrides,
        });
        setIsModelsSaving(false);
    }

    // Add handler for tab changes
    const handleTabChange = (tab: string) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("tab", tab);
        // Use navigate without replace to create history entry
        navigate({ search: new_params.toString() });
        setSelectedTab(tab);
    };

    const handlePageChange = (page: number) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("page", page.toString());
        // Use navigate without replace to create history entry
        navigate({ search: new_params.toString() });
    };

    if (org === undefined) {
        return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>
    }

    const model_names = models.map((model) => model.name);

    return <Fragment>
        <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>

            <div className="m-16 mb-6">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                    Organization "{org?.name}"
                </h2>
                <div className="pt-5 border-b-4 border-sea_blue-700" />
            </div>

            <div className="px-10 py-5">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 text-sm">
                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Uuid:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={org.uuid} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Enabled:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.is_enabled ? "Yes" : "No"}
                                onChange={(value) => setOrgEnabled(value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Type:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={org.type as string} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Admin ORG:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.admin_org ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("admin_org", value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Eval uses credits:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.eval_check_credits ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("eval_check_credits", value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">SSO enabled:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.sso_enabled ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("sso_enabled", value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">CSV semicolon:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={["Yes", "No"]}
                                selected={org.tags.csv_use_semicolon ? "Yes" : "No"}
                                onChange={(value) => setOrgTag("csv_use_semicolon", value === "Yes")} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Max users:</h3>
                        <div className="text-sm text-gray-600 mt-2 flex flex-row items-center">
                            <Textbox value={max_users} onChange={(value) => setMaxUsers(value)} />
                            <Button
                                text="Set"
                                disabled={!isValidInteger(max_users)}
                                onClick={() => setOrgTag("max_users", parseInt(max_users))} />
                        </div>
                    </div>

                    <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Number format locale:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <Dropdown
                                values={[
                                    "Browser default",
                                    "en-US - 1,234.56 (dot decimal, comma grouping)",
                                    "en-GB - 1,234.56 (dot decimal, comma grouping)",
                                    "de-DE - 1.234,56 (comma decimal, dot grouping)",
                                    "fr-FR - 1 234,56 (comma decimal, space grouping)",
                                    "pt-BR - 1.234,56 (comma decimal, dot grouping)",
                                    "de-CH - 1'234.56 (apostrophe grouping, dot decimal)",
                                    "en-IN - 1,23,456.78 (comma decimal, lakh grouping)",
                                    "ja-JP - 1,234.56 (dot decimal, comma grouping)",
                                    "zh-CN - 1,234.56 (dot decimal, comma grouping)",
                                    "ru-RU - 1 234,56 (comma decimal, space grouping)"
                                ]}
                                ids={["", "en-US", "en-GB", "de-DE", "fr-FR", "pt-BR", "de-CH", "en-IN", "ja-JP", "zh-CN", "ru-RU"]}
                                selected={org.tags.number_format_locale ?? ""}
                                onChange={(id) => setOrgTag("number_format_locale", id === "" ? undefined : id as NumberFormatLocale)} />
                        </div>
                    </div>

                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Provider:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <ReadOnlyTextbox text={sso.provider} />
                        </div>
                    </div>}
                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Email domain:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={sso.email_suffix} />
                        </div>
                    </div>}
                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Tenant ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={sso.tenant_id} />
                        </div>
                    </div>}
                    {sso && <div className="py-2">
                        <h3 className="text-md font-semibold text-gray-800">Client ID:</h3>
                        <div className="text-sm text-gray-600 mt-2">
                            <CopyTextbox text={sso.client_id} />
                        </div>
                    </div>}
                </div>
            </div>

            <div className="px-10">
                <div className="py-4 grid grid-cols-1">
                    <table className="w-full divide-y divide-gray-300 border-gray-300 border">
                        <thead>
                            <tr>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Type</th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Credit Balance</th>
                                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Transactions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {org && balance !== undefined && <tr>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{org.type}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{balance}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right w-10">
                                    <Button onClick={expireCredits} text="Expire" icon={ClockIcon} highlight={false} />
                                    <Button onClick={() => setOpenTransactionHistory(true)} text="History" icon={BookOpenIcon} highlight={false} />
                                </td>
                            </tr>}
                            {balance === undefined && <tr><td className="text-center p-2" colSpan={4}><LoadingSpinnerLimit /></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="p-10">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={handleTabChange} />
                {selected_tab === "users" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Role
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {users && users.map((person) => (
                                <tr key={person.email}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        <div className="flex items-center">
                                            <div className="h-11 w-11 flex-shrink-0">
                                                <img className="h-11 w-11 rounded-full" src={person.picture_url} alt="" />
                                            </div>
                                            <div className="ml-4">
                                                <div className="font-medium text-gray-900">{person.first_name + " " + person.last_name}</div>
                                                <div className="mt-1 text-gray-500">{person.email}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.org_role}</td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <Link to={`/admin/user/${person.uuid}`} className="text-space_blue-600 hover:text-space_blue-900">Edit</Link>
                                    </td>
                                </tr>
                            ))}
                            {!users && <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" colSpan={4}>
                                    <LoadingSpinnerLimit />
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>}
                {selected_tab === "extract_jobs" && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <ExtractJobs
                        type="admin_org"
                        org_uuid={org_uuid}
                        org_users={users}
                        initial_page={initial_page}
                        onPageChange={handlePageChange}
                    />
                </div>}
                {selected_tab === "audit_log" && org_uuid && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <OrgAuditLog org_uuid={org_uuid} is_admin={true} />
                </div>}
                {selected_tab === "subscription" && org_uuid && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <AdminSubscriptions org_uuid={org_uuid} refreshCredits={refreshCredits} />
                </div>}

                {selected_tab === "models" && org && <div className="my-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg p-6">
                    <div className="text-sm text-gray-600 pb-2 flex flex-col gap-4">
                        <p>When specified, model overrides for organization will be used instead of the default ones for all templates belonging to this org.</p>
                        <p>Template model overrides override the organization-wide settings.</p>
                    </div>
                    <div className="sm:grid sm:grid-cols-6 sm:items-start sm:gap-4 sm:py-6">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Extract
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.scrape_extract || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_extract"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Heal
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.scrape_heal || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_heal"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Summarize
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.scrape_summarize || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_summarize"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Focused Summarize
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.scrape_focused_summarize || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "scrape_focused_summarize"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Decimal Separator
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.decimal_separator || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "decimal_separator"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Template suggest
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.context_suggest || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "context_suggest"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            OC Date Validation
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.oc_date_validation || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "oc_date_validation"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Classifier
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={org.models_overrides.classify_document || "/"}
                                    onChange={(model_name) => { handleModelNameChange(model_name, "classify_document"); }} />
                            </div>
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Default
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.default || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "default"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Default fast
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.default_fast || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "default_fast"); }} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Match Lookup
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.match_lookup || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "match_lookup"); }} />
                        </div>
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-800 sm:pt-1.5">
                            Table Splitting
                        </label>
                        <div className="mt-2 sm:mt-0 sm:col-span-2">
                            <Dropdown
                                values={["/", ...model_names]}
                                selected={org.models_overrides.table_splt || "/"}
                                onChange={(model_name) => { handleModelNameChange(model_name, "table_splt"); }} />
                        </div>
                    </div>
                    <div className="flex flex-row items-center">
                        <Button
                            text="Update"
                            highlight={true}
                            loading={is_models_saving}
                            disabled={is_models_saving}
                            onClick={handleModelOverridesSave} />
                    </div>
                </div>}
            </div>
        </div>
        {org && <TransactionHistory
            is_admin={true}
            org={org}
            open={open_transaction_history}
            setOpen={setOpenTransactionHistory}
            refreshCredits={refreshCredits}
            ticker={history_refresh_ticker} />}
    </Fragment >;
};
