import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";
import {
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";

import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import { BackendObj } from "../lib/backend";
import {
    IItemSlim,
    IViewExtractJob
} from "../lib/backend/extractions.types.generated";
import {
    classNames,
    prettyTime,
    redirectToExternalPageWithPostData,
    setDocumentTitle
} from "../lib/utils";
import { USER_ROLES } from "../lib/consts";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { ItemBreadcrumb } from "../components/ItemBreadcrumb";
import { ItemList } from "./Items";
import { Button } from "../components/Button";
import { ExtractButton } from "../components/ExtractButton";
import { isEventWebhook, prettyJobEventType } from "../components/ExtractJobs";
import { Tabs } from "../components/Tabs";
import { SupportLink } from "../components/SupportLink";
import { ButtonMenu, IButtonMenuItem } from "../components/ButtonMenu";
import { TwoRowHeader } from "../components/Header";

export function JobDetail() {
    const navigate = useNavigate();
    const { job_uuid } = useParams<{ job_uuid: string | undefined }>();
    const [search_params] = useSearchParams();
    const item_uuid = search_params.get("item_uuid");

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const is_admin = user.role === USER_ROLES.admin;

    const [job, setJob] = useState<IViewExtractJob | undefined>(undefined);
    const [last_refresh_ts, setLastRefreshTs] = useState<number>(Date.now());
    const [selected_tab, setSelectedTab] = useState<"items" | "events">("items");
    const [ticker, setTicker] = useState<number>(0);
    const [is_resending_uuid, setIsResendingUuid] = useState<string | undefined>(undefined);
    const [is_job_running, setIsJobRunning] = useState<boolean>(false);
    const [show_job_completed_message, setShowJobCompletedMessage] = useState<boolean>(false);

    useEffect(() => {
        if (job_uuid !== undefined) {
            BackendObj.extractions.getJobDetails({ job_uuid })
                .then(({ job }) => {
                    setJob(job);
                    setLastRefreshTs(Date.now());

                    const job_still_running = job?.status === "pending" || job?.status === "running";
                    if (job_still_running && ticker < 720) { // 720 = 12 minutes
                        if (is_job_running === false && selected_tab === "items") {
                            setSelectedTab("events");
                        }
                        setIsJobRunning(true);
                        setTimeout(() => { setTicker(ticker + 1); }, 1000);
                    } else {
                        if (is_job_running === true && selected_tab === "events") {
                            setSelectedTab("items");
                            setShowJobCompletedMessage(true);
                            setTimeout(() => { setShowJobCompletedMessage(false); }, 5000);
                        }
                        setIsJobRunning(false);
                    }
                });
        } else {
            setJob(undefined);
        }
    }, [job_uuid, ticker]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (job !== undefined) {
            setDocumentTitle(`Job - ${job.title.length > 0 ? job.title : "[no subject]"}`, env);
        } else {
            setDocumentTitle("Job - loading...", env);
        }
    }, [env, job]);

    const onItemSelected = (item: IItemSlim) => {
        navigate(`/item/${item.uuid}?job_uuid=${job_uuid}`);
    };

    if (job_uuid === undefined || job === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const resendWebHook = async (event_uuid: string) => {
        setIsResendingUuid(event_uuid);
        await BackendObj.extractions.resendWebhookData({ id: job_uuid, event_uuid });
        setTicker(ticker + 1);
        setIsResendingUuid(undefined);
    };

    const navigateBack = () => {
        if (item_uuid) {
            navigate(`/item/${item_uuid}`);
        } else {
            navigate("/items");
        }
    }

    const downloadItems = (type: "excel" | "json") => {
        const url = `/api/items/${type}`;
        const post_data = {
            item_uuids: items.map(item => item.uuid)
        };
        redirectToExternalPageWithPostData(url, true, post_data);
    }

    const download_buttons: IButtonMenuItem[] = [
        { title: "Excel", onClick: () => downloadItems("excel") },
        { title: "JSON", onClick: () => downloadItems("json") },
    ];

    const { details, items, events } = job;
    const job_org = memberships.find(m => m.org.uuid === job.org_uuid);

    const has_webhook_events = events.some(isEventWebhook);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <TwoRowHeader
            title={job.title.length > 0 ? job.title : "[no subject]"}
            subtitle={details.template_uuid !== undefined ? <ItemBreadcrumb
                template_uuid={details.template_uuid}
                template_name={details.template_name ?? ""}
                endpoint_uuid={details.endpoint_uuid}
                endpoint_name={details.endpoint_name}
            /> : <Fragment />}
            org_name={job_org?.org.name}
            buttons={<Fragment>
                <ButtonMenu title="Download" items={download_buttons} />
                {is_admin && <Button icon={Cog6ToothIcon} href={`/admin/prompt-log/${job.uuid}`} />}
                <ExtractButton org_uuid={job.org_uuid} template_uuid={details.template_uuid} endpoint_uuid={details.endpoint_uuid} />
            </Fragment>}
            tooltip={`Last update: ${last_refresh_ts ? prettyTime(last_refresh_ts) : "/"}`}
            onBack={navigateBack}
        />

        <div className="max-w-5xl pl-2 pr-2">
            <div className="px-4 max-w-5xl">
                {job.status === "running" && <div className="mt-4 p-4 border bg-candy_corn-50 rounded text-sm text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <div>Job is still being processed.</div>
                        <div>Extracted items will appear under "Results" tab. You can follow the progress in the "Event Log" tab.</div>
                    </div>
                </div>}
                {job.status === "error" && <div className="mt-4 p-4 border bg-torch_red-50 rounded text-sm text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>There was an error processing the job.</p>
                        <p>You can see the error message in the "Event Log" tab.</p>
                        <p>Please try again or contact <SupportLink />.</p>
                    </div>
                </div>}
                {show_job_completed_message && job.status !== "error" && <div className="mt-4 p-4 border bg-mint-50 rounded text-sm text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>Job completed.</p>
                        <p>Extracted items are listed under "Results" tab.</p>
                    </div>
                </div>}
            </div>
        </div>

        <div className="max-w-5xl p-6">
            <Tabs
                tabs={[
                    { name: "Results", key: "items" },
                    { name: "Event Log", key: "events" }
                ]}
                selected_tab_key={selected_tab}
                setSelectedTab={(key) => setSelectedTab(key as "items" | "events")}
            />

            <div className="px-4 py-4 space-y-4">
                {selected_tab === "items" && <Fragment>
                    {items.length > 0 && <ItemList items={items} onItemSelected={onItemSelected} show_template={true} is_endpoint={false} />}
                    {items.length === 0 && <div className="text-gray-400 text-xs">No results yet.</div>}
                </Fragment>}
                {selected_tab === "events" && <Fragment>
                    {events.length > 0 && <div className="rounded-lg overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                        <table className="w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Type</th>
                                    <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900" colSpan={2}>Details</th>
                                    <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Time</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {events?.map((event) => (
                                    <tr key={event.uuid} className={event.status === "error" ? "text-torch_red-500" : "text-gray-400"}>
                                        <td className="align-middle whitespace-nowrap px-3 py-3 text-xs text-gray-600">{prettyJobEventType(event.type)}</td>
                                        <td
                                            className="align-middle whitespace-normal px-3 py-3 text-xs text-gray-600"
                                            colSpan={has_webhook_events && isEventWebhook(event) ? 1 : 2}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: event.message }}></span>
                                        </td>
                                        {isEventWebhook(event) && <td className="whitespace-nowrap px-3 py-3 text-sm">
                                            <Button
                                                loading={is_resending_uuid === event.uuid}
                                                disabled={is_resending_uuid !== undefined}
                                                text={"Call webhook again"}
                                                onClick={() => resendWebHook(event.uuid)}
                                            />
                                        </td>}
                                        <td className="whitespace-nowrap px-3 py-3 text-sm">{prettyTime(event.ts)}</td>
                                    </tr>))}
                            </tbody>
                        </table>
                    </div>}
                    {events.length === 0 && <div className="text-gray-400 text-xs">No events yet.</div>}
                </Fragment>}
            </div>
        </div>
    </div >;
}
