import { IOrganization } from "./backend/auth.types.generated";

export function parseOrganization(o: any): IOrganization {
    return {
        uuid: o.uuid,
        name: o.name,
        tags: o.tags,
        type: o.type,
        models_overrides: o.models_overrides,
        created_at: o.created_at,
        is_enabled: o.is_enabled
    }
}
